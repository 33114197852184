import React, { useState } from "react";

const NouveauPost = () => {
  const [formData, setFormData] = useState({
    image: null,
    title: "",
    content: "",
    date: "",
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files[0]) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
      setPreviewImage(URL.createObjectURL(files[0]));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.image) tempErrors.image = "Une image est requise";
    if (!formData.title) tempErrors.title = "Le titre est requis";
    if (!formData.content) tempErrors.content = "Le contenu est requis";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Formulaire valide, envoi des données...", formData);
      
    }
  };

  return (
    <div className="w-full  shadow-md rounded-lg p-8">
    
      <form onSubmit={handleSubmit}>
      
        <div className="mb-6">
        <label htmlFor="image" className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full  border  rounded  ${errors.rapport ? 'border-red-500' : 'focus:ring-teal-300'}`}>
        Image
        </label>
          <input
            type="file"
            id="image"
            name="image"
            hidden
            accept="image/*"
            className={`w-full p-3 bg-transparent border rounded-lg focus:outline-none focus:ring focus:ring-green-500 ${errors.image ? 'border-red-500' : ''}`}
            onChange={handleChange}
          />
          {errors.image && (
            <p className="text-red-500 text-sm mt-1">{errors.image}</p>
          )}
         
          {previewImage && (
            <img
              src={previewImage}
              alt="Aperçu"
              className="mt-4 w-auto h-64 object-cover rounded-lg"
            />
          )}
        </div>

        <div className="mb-6">
          <label htmlFor="title" className="block text-lg font-bold mb-2">
            Titre
          </label>
          <input
            type="text"
            id="title"
            name="title"
            className={`w-full p-3 bg-transparent border rounded-lg focus:outline-none focus:ring focus:ring-green-500 ${errors.title ? 'border-red-500' : ''}`}
            value={formData.title}
            onChange={handleChange}
          />
          {errors.title && (
            <p className="text-red-500 text-sm mt-1">{errors.title}</p>
          )}
        </div>

      
        <div className="mb-6">
          <label
            htmlFor="content"
            className="block text-lg font-bold mb-2"
          >
            Contenu
          </label>
          <textarea
            id="content"
            name="content"
            rows="5"
            className={`w-full p-3 bg-transparent border rounded-lg focus:outline-none focus:ring focus:ring-green-500 ${errors.content ? 'border-red-500' : ''}`}
            value={formData.content}
            onChange={handleChange}
          ></textarea>
          {errors.content && (
            <p className="text-red-500 text-sm mt-1">{errors.content}</p>
          )}
        </div>

        
        <div className="mb-6">
          <label htmlFor="date" className="block text-lg font-bold mb-2">
            Date
          </label>
          <input
            type="text"
            id="date"
            name="date"
            className="w-full p-3 bg-transparent border rounded-lg focus:outline-none focus:ring focus:ring-green-500"
            value={formData.date}
            
          />
        </div>

      
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-[#3ad12c] mb-5 hover:scale-105  text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300"
          >
            Proposer
          </button>
        </div>
      </form>
    </div>
  );
};

export default NouveauPost;
