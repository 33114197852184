import React, { useState } from 'react'
import { animaux } from '../../../Data/Data'
import { useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'
import Footer from '../../Visiteurs/FootentContent/Footer'
import { Progress } from 'rsuite'
import AdminProjetNavBars from './AdminProjetNavBars'

function AdminProjetPropose() {
  const navig = useNavigate()
  const [progession, Getprogession] = useState(0)
  const { isDarVisitLoggined } = useThemes()
  return (
    <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
      <AdminProjetNavBars />
      <div className='flex w-full justify-between p-4 mb-4 items-center'>
        <div className='inline-flex gap-2'>
          <select class="bg-transparent border cursor-pointer border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border  focus:border-[#3ad12c] ">
            <option className='text-black'> 5</option>
            <option className='text-black'>10</option>
            <option className='text-black'>20</option>
          </select>
        </div>
        <div className='flex w-[60%]'>
          <div className='flex w-full items-center'>
            <div className='w-full'>
              <input className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' placeholder='Rechercher le cours' />
            </div>
            <button className="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
              Recherche
            </button>
          </div>
          <button onClick={() => navig("/projet/ajouter")} className='border ml-2 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]'>
            Ajouter
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-3 lg:grid-cols-4 gap-2">
        {animaux.map((animal, index) => (
          <div key={index} className={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-4`}>
            <img src="https://ftlvreunion.fr/fileadmin/user_upload/zoom_sur/2021-10-12_On_vous_parle_du_projet_professionnel.PNG" alt={animal.nom} className="w-full h-48 object-cover rounded-t-lg mb-4" />
            <h2 className="text-xl font-bold">Projet Eco-Ecoles</h2>
            <div className='text-[12px] p-2'>
              Sensibilisation et éducation environnementale dans les écoles du Burundi, encourageant les pratiques écologiques et la gestion durable des ressources.
            </div>
            <div>
              <Progress.Line percent={(progession * index) / 100} strokeWidth={5} strokeColor="#3ad12c" />
            </div>
            <div className='flex w-full justify-between items-center p-2'>
              <div onClick={() => navig("/projet/propo/detail")} className=' border p-1 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer'>
                Détail
              </div>
              <div  className=' border p-1 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer'>
                Valider
              </div>
              <div className=' border p-1 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer'>
                Annuler
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default AdminProjetPropose