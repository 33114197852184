import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminAcceuil from './AdminAcceuil'

function AdminAcceuillComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminAcceuil />
        </div>
    )
}

export default AdminAcceuillComponent
