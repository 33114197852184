/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import axios from "axios";
import { decryptData } from "../../../encryptionModule";

function AdminEspecesModifier() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const especeId = decryptData(id);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEspecesement = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/especes/${especeId}`
        );
        setEspeces({
          orderimage: response.data.image,
          nomfrancais: response.data.nomfrancais,
          nomlatin: response.data.nomlatin,
          nomkirundi: response.data.nomkirundi,
          description: response.data.description,
          region: response.data.region,
          preview: "",
        });
      } catch (err) {
        toast.error("Erreur lors de la récupération des détails de l'espèce.");
      } finally {
        setLoading(false);
      }
    };

    fetchEspecesement();
  }, [especeId]);

  const [mobile, setMobile] = useState(window.innerWidth < 501);
  useEffect(() => {
    const handleSize = () => {
      setMobile(window.innerWidth < 501);
    };
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  const [especes, setEspeces] = useState({
    image: "",
    nomfrancais: "",
    nomlatin: "",
    nomkirundi: "",
    description: "",
    region: "",
    preview: "",
    orderimage: "",
  });

  const handleInputChange = (field, value) => {
    if (field === "image") {
      setEspeces((prevEspeces) => ({
        ...prevEspeces,
        image: value,
        preview: URL.createObjectURL(value),
      }));
    } else {
      setEspeces((prevEspeces) => ({
        ...prevEspeces,
        [field]: value,
      }));
    }
  };

  const validateBloc = () => {
    const requiredFields = [
      "nomfrancais",
      "nomlatin",
      "nomkirundi",
      "description",
      "region",
    ];
    for (const field of requiredFields) {
      if (!especes[field]) {
        toast.warning(
          <div>
            Le <strong>{field}</strong> est obligatoire.
          </div>
        );
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateBloc()) {
      return;
    }

    const utilisateurId = localStorage.getItem("userId");
    const formData = new FormData();
    if (especes.image) {
      formData.append("image", especes.image);
    }
    formData.append("nomfrancais", especes.nomfrancais);
    formData.append("nomlatin", especes.nomlatin);
    formData.append("nomkirundi", especes.nomkirundi);
    formData.append("description", especes.description);
    formData.append("region", especes.region);
    formData.append("utilisateurId", utilisateurId);

    try {
      await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/especes/${especeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navig("/ressource/espece");
      toast.success("Les espèces ont été mises à jour avec succès");
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour des espèces."
      );
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/ressource/espece")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          Loading...
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className={`flex m-4 rounded-md ${
            isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
          } flex-col items-center`}
        >
          <div className="rounded-lg p-2 w-full sm:p-4">
            <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
              <div className="w-full">
                <div className="h-max w-full">
                  <div className="px-3 w-full">
                    <div className="w-full flex items-center justify-between">
                      <label className="block text-xs mt-5 font-[700] first-letter:uppercase tracking-wider text-grey-darker sm:text-lg mb-1">
                        Espèces
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <div className="rounded-xl p-2 w-full">
                  <div className="flex flex-col">
                    <div className="mb-2">
                      <div className="flex flex-col items-center">
                        <label
                          className={`w-full border border-gray-300 block cursor-pointer outline-none focus:border-[#3ad12c] rounded-md p-2.5 bg-transparent`}
                          htmlFor="id_photo"
                        >
                          Photos de couverture
                        </label>
                        <input
                          type="file"
                          accept=".jpeg,.png,.jpg,.gif"
                          className="w-full hidden mt-1"
                          id="id_photo"
                          onChange={(e) =>
                            handleInputChange("image", e.target.files[0])
                          }
                        />
                        <div className="flex w-full justify-start">
                          {especes.preview ? (
                            <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                              <img
                                src={especes.preview}
                                alt="Preview"
                                className="object-cover w-full h-full"
                              />
                            </div>
                          ) : (
                            <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                              <img
                                src={`https://imuhirabackend.eac-advisor.org/uploads/especes/${especes.orderimage}`}
                                alt="Preview"
                                className="object-cover w-full h-full"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="nom_francais"
                        className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Nom francais
                      </label>
                      <input
                        type="text"
                        id="nom_francais"
                        value={especes.nomfrancais}
                        onChange={(e) =>
                          handleInputChange("nomfrancais", e.target.value)
                        }
                        placeholder="Le nom francais"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c] mb-2"
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="nom_latin"
                        className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Nom latin
                      </label>
                      <input
                        type="text"
                        id="nom_latin"
                        value={especes.nomlatin}
                        onChange={(e) =>
                          handleInputChange("nomlatin", e.target.value)
                        }
                        placeholder="Le nom latin"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c] mb-2"
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="nom_kirundi"
                        className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Nom kirundi
                      </label>
                      <input
                        type="text"
                        id="nom_kirundi"
                        value={especes.nomkirundi}
                        onChange={(e) =>
                          handleInputChange("nomkirundi", e.target.value)
                        }
                        placeholder="Le nom kirundi"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c] mb-2"
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="description"
                        className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        value={especes.description}
                        onChange={(e) =>
                          handleInputChange("description", e.target.value)
                        }
                        placeholder="Description"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c] mb-2"
                      />
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor="region"
                        className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Region
                      </label>
                      <input
                        type="text"
                        id="region"
                        value={especes.region}
                        onChange={(e) =>
                          handleInputChange("region", e.target.value)
                        }
                        placeholder="Region"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c] mb-2"
                      />
                    </div>

                    <button
                      type="submit"
                      className="mt-5 mb-3 bg-[#3ad12c] p-2 rounded-lg text-white w-full"
                    >
                      Mettre à jour l'espèce
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      <Footer />
    </div>
  );
}

export default AdminEspecesModifier;
