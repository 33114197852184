/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'

function AdminPartenaire() {
    const { isDarVisitLoggined } = useThemes()
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div class=" ">
                <div class=" mx-auto">
                    <div class="rounded-lg">
                        <div class="p-6">

                            <div class="flex items-center justify-between w-full mb-4">
                                <div>
                                    <select class="bg-transparent border cursor-pointer border-gray-300 rounded-md py-2 px-3 mr-2 focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-transparent">
                                        <option className='text-black'>Tous</option>
                                        <option className='text-black'>1</option>
                                        <option className='text-black'>2</option>
                                        <option className='text-black'>3</option>
                                    </select>
                                </div>
                                <div className='flex w-[60%] items-center'>
                                    <div className='w-full'>
                                        <input className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' placeholder='Rechercher une ressource' />
                                    </div>
                                    <div class="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
                                        Recherche
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <h1 class="text-2xl font-bold mb-4 text-center">Liste des Partenaires</h1>
            <div class="grid grid-cols-5 gap-5 p-2">
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((d, i) => (
                    <div key={i} className={`rounded-md p-1 ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-1`}>

                        <img src="https://via.placeholder.com/50" alt="Logo Partenaire 3" class="mr-4 rounded-full overflow-hidden" />
                        <div>
                            <h2 class="text-lg font-semibold">Partenaire 3</h2>
                            <p class="">Description du partenaire 3, engagé pour le développement durable.</p>
                            <a href="#" class="text-blue-500 hover:underline">Visitez leur site</a>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default AdminPartenaire