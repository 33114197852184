import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'

function AdminRessourceNavBars() {
    const navig = useNavigate()
    const { isDarVisitLoggined } = useThemes()
    const { pathname } = useLocation()
    let ressource = /^\/ressource.*/
    let rapport = /^\/ressource\/rapport.*/
    let fait = /^\/ressource\/fait.*/
    let espece = /^\/ressource\/espece.*/
    let loi = /^\/ressource\/loi.*/
    let convention = /^\/ressource\/convention.*/
    let odd = /^\/ressource\/odd.*/
    return (
        <div className={`w-full flex justify-around border-b-[0.5px] border-gray-500 ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-gray-100'} grid grid-cols-7 irapports-center`}>
            <div onClick={() => navig("/ressource")} className={`p-2 cursor-pointer   transition-all  ${ressource.test(pathname) && !rapport.test(pathname) && !fait.test(pathname) && !espece.test(pathname) && !loi.test(pathname) && !convention.test(pathname) && !odd.test(pathname) ? 'text-[#3ad12c] border-b-2 border-[#3ad12c] text-center w-full' : 'text-center w-full'} `}>Ressource</div>
            <div onClick={() => navig("/ressource/rapport")} className={`p-2 cursor-pointer   transition-all  ${rapport.test(pathname) ? 'text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center' : 'text-center w-full'} `}>Rapport</div>
            <div onClick={() => navig("/ressource/fait")} className={`p-2 cursor-pointer   transition-all  ${fait.test(pathname) ? 'text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center' : 'text-center w-full'} `}>faits</div>
            <div onClick={() => navig("/ressource/espece")} className={`p-2 cursor-pointer   transition-all  ${espece.test(pathname) ? 'text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center' : 'text-center w-full'} `}>Espèces</div>
            <div onClick={() => navig("/ressource/loi")} className={`p-2 cursor-pointer   transition-all  ${loi.test(pathname) ? 'text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center' : 'text-center w-full'} `}>Loi</div>
            <div onClick={() => navig("/ressource/convention")} className={`p-2 cursor-pointer   transition-all  ${convention.test(pathname) ? 'text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center' : 'text-center w-full'} `}>Convention </div>
            <div onClick={() => navig("/ressource/odd")} className={`p-2 cursor-pointer   transition-all  ${odd.test(pathname) ? 'text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center' : 'text-center w-full'} `}>ODD</div>
        </div>
    )
}

export default AdminRessourceNavBars