import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import SignaleForm from "./SignaleForm";
import { Drawer } from "rsuite";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { encryptData } from "../../../encryptionModule";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
function Signale() {
  const { isDark, HundlwScrollTop } = useThemes();
  const navig = useNavigate();
  const [mobile2, setMobile2] = useState(window.innerWidth <= 768);
  const [signalements, setSignalements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isSigner, GetisSigner] = useState(false);
  const { isDarVisitLoggined } = useThemes();

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchSignalements(currentPage, pageSize);
    // Optional: You could add a cleanup function for axios requests here
  }, [currentPage, pageSize]);

  const fetchSignalements = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/signalements?page=${page}&size=${size}&searchItem=${
          search || ""
        }`
      );
      setSignalements(response.data.signalements);
      setTotalPages(response.data.totalPages);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des signalements :",
        error.message
      );
    }
  };

  const toggleStatus = async (id, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/signalements/${id}/status`,
        {
          status: newStatus,
        }
      );
      fetchSignalements(currentPage, pageSize);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalCount);
    return `${from} à ${to} signalements sur ${totalCount}`;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
    fetchSignalements(1, pageSize, event.target.value); // Trigger search
  };
  // Modifier le chemin de l'icône pour éviter un bug connu de Leaflet
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
    iconUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  });
  return (
    <div className=" mt-[18vh]  overflow-hidden">
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-center mb-8">
          Liste des Problèmes Signalés
        </h1>
        <div className="mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Rechercher un problème..."
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 transition-all"
          />
        </div>
        <div className={`${mobile2 ? "mb-5" : ""}`}>
          <div className="flex items-center mb-4">
            <p className="sm:text-xl">Taille de la page :</p>
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded-lg"
            >
              {[1, 5, 10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
        <MapContainer
          center={[-3.39289, 29.3949]}
          zoom={13}
          style={{ height: "100vh", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          {signalements.map((signalement) => (
            <Marker
              key={signalement.id}
              position={[signalement.latitude, signalement.longitude]}
            >
              <Popup>
                <strong>{signalement.nom}</strong>
                <br />
                Localisation: {signalement.location}
                <br />
                Description: {signalement.description}
                <br />
                Gravité: {signalement.gravite}
                <br />
                Coordonnées: {signalement.coordonnees}
                <br />
                <img
                  src={`https://imuhirabackend.eac-advisor.org/uploads/signal/${signalement.image}`}
                  alt="signalement"
                  width="100px"
                />
              </Popup>
            </Marker>
          ))}
        </MapContainer>
        <div
          data-aos="fade-up"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          {signalements.map((signalement, index) => (
            <div
              onClick={() => {
                navig(
                  `/signal/detail/${encryptData(signalement.id.toString())}`
                );
                HundlwScrollTop();
              }}
              key={index}
              className={`${
                isDark === "dark" ? "bg-gray-800" : "bg-white"
              } shadow-md rounded-lg p-6 cursor-pointer`}
            >
              {signalement.image && (
                <div className="mt-4">
                  <img
                    src={`https://imuhirabackend.eac-advisor.org/uploads/signal/${signalement.image}`}
                    alt={signalement.nom}
                    className="w-full h-auto rounded-lg object-cover"
                  />
                </div>
              )}

              <h2 className="text-xl font-semibold mb-2">{signalement.nom}</h2>
              <p className="">
                <strong>Contact :</strong> {signalement.coordonnees}
              </p>
              <p className=" text-lg">
                <strong>Description :</strong> {signalement.description}
              </p>
              <p className="">
                <strong>Lieu :</strong> {signalement.location}
              </p>
              <p className="">
                <strong>Gravité :</strong> {signalement.gravite}
              </p>
              <p className="">
                <strong>Actions :</strong> {signalement.actionprise}
              </p>
              <p className="">
                <strong>Attentes :</strong> {signalement.attentes}
              </p>
            </div>
          ))}
        </div>

        {signalements.length > 0 && (
          <>
            <div className="mt-6 flex items-center justify-center space-x-2">
              <button
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-1 rounded-lg transition-all ${
                      currentPage === pageNumber
                        ? "bg-blue-600 text-white"
                        : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                    }`}
                  >
                    {pageNumber}
                  </button>
                )
              )}
              <button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Suivant
              </button>
            </div>

            <div className="mt-4 text-center">
              <p className="text-lg">{getRange()}</p>
            </div>
          </>
        )}
        <div className="flex justify-end w-full">
          <div
            onClick={() => GetisSigner(true)}
            class="bg-[#3ad12c] cursor-pointer hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded-lg inline-block mt-4"
          >
            Signaler un problème
          </div>
        </div>
      </div>

      <Drawer
        size="full"
        open={isSigner}
        placement="top"
        onClose={() => GetisSigner(false)}
      >
        <Drawer.Header>
          <Drawer.Title className="text-center uppercase">
            Signaler un Problème
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <SignaleForm />
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default Signale;
