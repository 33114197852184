import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'
import { toast } from 'react-toastify'
import { Drawer } from 'rsuite'

function DetailEvent_a_Venir() {
    const navig = useNavigate()
    const { isDark } = useThemes()

    const [shoModalFomr, SetshoModalFomr] = useState(false)


    const [formData, setFormData] = useState({
        nom_complet: '',
        email: '',
        association: '',
        participer: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const validateForm = () => {
        const fields = ['nom_complet', 'email', 'quarter', 'organisation', 'tshirtSize'];
        for (let field of fields) {
            if (!formData[field]) {
                const element = document.getElementById(field);
                element.focus();
                toast.error(<>Veuillez remplir le champ <span className="font-bold">{element.previousElementSibling.textContent}</span></>);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            toast.success('Inscription réussie !');
            SetshoModalFomr(false)
        }
    };



    return (
        <div className=' mt-[18vh] sm:p-8  overflow-hidden'>
            <div onClick={() => navig("/communaute/event")} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div class=" rounded-lg shadow-md overflow-hidden">
                <img src="../../image/conference.jpg" alt="Journée de Reboisement à Kibira" class="w-full h-[70vh] object-cover" />
                <div class="p-4">
                    <h3 class="text-lg font-bold mb-2">Journée de Reboisement à Kibira</h3>
                    <p class=" mb-4">15 juillet 2023</p>
                    <p className='text-lg'>Forêt de Kibira, Province de Muramvya.</p>

                    <p className='text-lg'>
                        La Forêt de Kibira, dans la province de Muramvya au Burundi, est un joyau naturel méconnu. Située à plus de 2000 mètres d'altitude, cette immense forêt tropicale abrite une biodiversité exceptionnelle. Parmi les espèces qui y vivent, on compte le chimpanzé, le colobe guereza, le céphalophe à dos jaune et le léopard. Malheureusement, cette forêt fait face à de nombreuses menaces. Le braconnage, l'exploitation illégale du bois et l'empiètement des zones agricoles mettent en péril cet écosystème unique.
                        <br /><br />
                        Classée réserve naturelle en 1933, la Forêt de Kibira est aujourd'hui un parc national protégé. Mais les efforts de conservation peinent à suivre le rythme de la destruction. Le Burundi, l'un des pays les plus pauvres au monde, manque cruellement de moyens pour assurer la protection de cette forêt. Les gardes-forestiers luttent avec des moyens dérisoires face aux exploitants illégaux.
                        <br /><br />
                        Pourtant, la Forêt de Kibira recèle un potentiel écotouristique important. Ses paysages montagneux et ses sentiers peu fréquentés séduiraient les amateurs d'aventure et de nature sauvage. Un développement touristique durable pourrait permettre de générer des revenus pour financer la conservation. Mais cela nécessiterait des investissements conséquents que le Burundi n'est pas en mesure d'assurer seul. Un soutien de la communauté internationale serait indispensable pour sauver ce trésor naturel.
                    </p>
                </div>
                <div class={`rounded-lg shadow-md mb-5 ${isDark === "dark" ? "bg-gray-800" : "bg-white"} p-6`}>
                    <h3 class="text-lg font-bold mb-4">Participez un Événement</h3>
                    <p class="mb-6">Vous avez une idée d'événement pour promouvoir le développement durable au Burundi ? Partagez-la avec nous !</p>
                    <div onClick={() => SetshoModalFomr(true)} class="inline-block cursor-pointer bg-[#3ad12c] hover:scale-105 text-white px-4 py-2 rounded-md">Participez un Événement</div>
                </div>
            </div>

            <Drawer open={shoModalFomr} size="full" placement='top' onClose={() => SetshoModalFomr(false)} >
                <Drawer.Header>
                    <Drawer.Title className='text-center uppercase'>Participer un Événement</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <form onSubmit={handleSubmit} className="p-6 w-full sm:w-[67%] rounded-lg shadow-md">
                        <div className="mb-4">
                            <label htmlFor="nom_complet" className="block text-lg mb-2">Nom et Prénom :</label>
                            <input
                                type="text"
                                id="nom_complet"
                                value={formData.nom_complet}
                                onChange={handleChange}
                                className="w-full border bg-transparent border-gray-300 rounded-md p-3 outline-none focus:border-[#3ad12c]"
                                placeholder="Entrez votre nom et prénom"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-lg mb-2">Adresse e-mail:</label>
                            <input
                                type="tel"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full border bg-transparent border-gray-300 rounded-md p-3 outline-none focus:border-[#3ad12c]"
                                placeholder="Adresse e-mail"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="organisation" className="block text-lg mb-2">Organisme/association représenté (le cas échéant) :</label>
                            <textarea
                             value={formData.association}
                             onChange={handleChange}
                             className=' min-h-[15em] w-full outline-none bg-transparent rounded-md p-4  border focus:border-[#3ad12c]' placeholder='Organisme/association représenté (le cas échéant)'>
                                
                            </textarea>
                        </div>
                       
                        <div className="mb-4">
                            <label htmlFor="organisation" className="block text-lg mb-2">Motivation pour participer à l'événement:</label>
                            <textarea
                             value={formData.participer}
                             onChange={handleChange}
                             className=' min-h-[15em] w-full outline-none bg-transparent rounded-md p-4  border focus:border-[#3ad12c]' placeholder="Motivation pour participer à l'événement">
                                
                            </textarea>
                        </div>
                       
                       
                        <button type="submit" className="w-max mb-5 bg-[#3ad12c] hover:scale-105 text-white font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50">
                            Soumettre
                        </button>
                    </form>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default DetailEvent_a_Venir