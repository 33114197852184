import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";

function AdminProjetNavBars() {
  const navig = useNavigate();
  const { isDarVisitLoggined } = useThemes();
  const { pathname } = useLocation();
  let projet = /^\/projet.*/;
  let tem = /^\/projet\/tem.*/;
  let don = /^\/projet\/don.*/;
  let mater = /^\/projet\/mater.*/;
  let spons = /^\/projet\/spons.*/;
  let benevol = /^\/projet\/benevol.*/;
  return (
    <div
      className={`w-full flex justify-around border-b-[0.5px] border-gray-500 ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
      } grid grid-cols-7 items-center`}
    >
      <div
        onClick={() => navig("/projet")}
        className={`p-2 cursor-pointer   transition-all  ${
          projet.test(pathname) &&
          !tem.test(pathname) &&
          !don.test(pathname) &&
          !mater.test(pathname) &&
          !spons.test(pathname) &&
          !benevol.test(pathname)
            ? "text-[#3ad12c] border-b-2 border-[#3ad12c] text-center w-full"
            : "text-center w-full"
        } `}
      >
        Projets
      </div>
      <div
        onClick={() => navig("/projet/tem")}
        className={`p-2 cursor-pointer   transition-all  ${
          tem.test(pathname)
            ? "text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center"
            : "text-center w-full"
        } `}
      >
        Témoignage
      </div>
      <div
        onClick={() => navig("/projet/don")}
        className={`p-2 cursor-pointer   transition-all  ${
          don.test(pathname)
            ? "text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center"
            : "text-center w-full"
        } `}
      >
        Dons du projets
      </div>
      <div
        onClick={() => navig("/projet/mater")}
        className={`p-2 cursor-pointer   transition-all  ${
          mater.test(pathname)
            ? "text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center"
            : "text-center w-full"
        } `}
      >
        Dons du matériel
      </div>
      <div
        onClick={() => navig("/projet/spons")}
        className={`p-2 cursor-pointer   transition-all  ${
          spons.test(pathname)
            ? "text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center"
            : "text-center w-full"
        } `}
      >
        Sponsor
      </div>
      <div
        onClick={() => navig("/projet/benevol")}
        className={`p-2 cursor-pointer   transition-all  ${
          benevol.test(pathname)
            ? "text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center"
            : "text-center w-full"
        } `}
      >
        Bénévole
      </div>
    </div>
  );
}

export default AdminProjetNavBars;
