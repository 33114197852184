import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import NavBarsRessource from "./NavBarsRessource";
import axios from "axios";

function ODD() {
  const { isDark } = useThemes();
  const [odds, setodds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10000000);

  useEffect(() => {
    fetchodds(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchodds = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/odd?page=${page}&size=${size}`
      );
      setodds(response.data.odds);
    } catch (error) {
      console.error("Erreur lors de la récupération des odds :", error.message);
    }
  };
  return (
    <div
      data-aos-duration="1000"
      class={` mt-[18vh] ${
        isDark === "dark" ? null : "bg-gray-100"
      } overflow-hidden`}
    >
      <NavBarsRessource />
      <section data-aos="fade-up" class="mb-16 p-6">
        <h2 class="text-3xl font-bold  mb-6">Principales ODDs</h2>
        <ul class="list-disc list-inside space-y-4 text-lg ">
          {odds.map((ODD, index) => (
            <div>
              <li key={index} className="text-lg ml-3">
                {ODD.titre}
              </li>
            </div>
          ))}
        </ul>
      </section>

      <section className="p-8">
        <h2 data-aos="fade-up" class="text-3xl font-bold  mb-6">
          Détails des principales ODDs
        </h2>

        <div class="space-y-10">
          {odds.map((ODD, index) => (
            <div>
              <div
                data-aos="fade-up"
                class={`law-card  p-6 mb-8 ${
                  isDark === "dark" ? "bg-gray-800" : "bg-white"
                } shadow-lg rounded-lg`}
              >
                <h3 className="text-xl font-semibold mb-4">{ODD.titre}</h3>

                <p class=" mb-4">{ODD.description}</p>

                <ul class="list-disc list-inside space-y-2 ">
                  {ODD.elements_odds && ODD.elements_odds.length > 0 ? (
                    ODD.elements_odds.map((elements_odd, idx) => (
                      <li
                        key={idx}
                        className={`${
                          isDark === "dark" ? "text-gray-300" : "text-gray-700"
                        } text-lg ml-3`}
                      >
                        {elements_odd.titre}
                      </li>
                    ))
                  ) : (
                    <li>Aucun elements_odd disponible</li>
                  )}
                </ul>
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-2">
                    Document d'attachement :
                  </h3>
                  <a
                    target="_blank"
                    rel="noopener noreferrer" // Ajout de cette ligne pour des raisons de sécurité
                    href={`https://imuhirabackend.eac-advisor.org/uploads/odd/${ODD.attachement}`}
                    download
                    className="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded shadow-md transition duration-300 ease-in-out"
                  >
                    Télécharger le document
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default ODD;
