import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useNavigate } from "react-router-dom";
import AdminCoursNavBars from "./AdminCoursNavBars";
import {
  faEdit,
  faInfoCircle,
  faTrashAlt,
  faCheck,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import de FontAwesome
import axios from "axios"; // Importer axios
import Swal from "sweetalert2"; // Importer SweetAlert
import { toast } from "react-toastify";
import { encryptData } from "../../../encryptionModule";

function AdminCoursPropose() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchItem, setSearchItem] = useState("");

  // Fonction pour récupérer les cours
  const fetchCourses = async () => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/cours/nonvalide?page=${page}&size=${size}&searchItem=${encodeURIComponent(
          searchItem
        )}`
      );
      setCourses(response.data.courses);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Erreur lors de la récupération des cours:", error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [page, size, searchItem]);

  const handleValidation = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous allez valider ce cours.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, valider !",
    });

    if (result.isConfirmed) {
      try {
        await axios.put(
          `https://imuhirabackend.eac-advisor.org/apiv1/cours/valider/${id}`,
          {
            status: "validé",
          }
        );
        toast.success("Le cours a été validé.");
        fetchCourses(); // Récupérer à nouveau les cours après validation
      } catch (error) {
        console.error("Erreur lors de la validation du cours:", error);
        toast.error(
          "Une erreur s'est produite lors de la validation du cours."
        );
      }
    }
  };

  const handleAnnulation = async (id) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous allez annuler ce cours.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, annuler !",
    });

    if (result.isConfirmed) {
      try {
        await axios.put(
          `https://imuhirabackend.eac-advisor.org/apiv1/cours/annuler/${id}`,
          {
            status: "rejeté",
          }
        );
        toast.info("Le cours a été annulé.");
        fetchCourses(); // Récupérer à nouveau les cours après annulation
      } catch (error) {
        console.error("Erreur lors de l'annulation du cours:", error);
        toast.error("Une erreur s'est produite lors de l'annulation du cours.");
      }
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <div className="min-h-[87vh] p-4">
        <AdminCoursNavBars />
        <h1 className="text-2xl font-bold mb-4">Listes des cours proposées</h1>
        <div className="flex w-full justify-between mb-4 items-center">
          <div className="flex w-[60%] items-center">
            <div className="w-full">
              <input
                className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                placeholder="Rechercher le cours"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </div>
          </div>
        </div>

        {courses.length === 0 ? (
          <div className="text-center p-4">
            <p>Aucun cours proposé à afficher.</p>
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-4">
            {courses.map((d, i) => (
              <div
                key={i}
                className={`rounded-md ${
                  isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
                } p-4`}
              >
                <img
                  src={`https://imuhirabackend.eac-advisor.org/uploads/cours/${d.photocouverture}`}
                  alt={d.titre}
                  className="rounded-md mb-2"
                />
                <h2 className="text-lg font-semibold mb-2 text-[#3ad12c]">
                  {d.titre}
                </h2>
                <p>{d.context}</p>
                <div className="flex flex-col">
                  <div className="w-full flex justify-between">
                    <div className="font-semibold py-2 rounded inline-block text-yellow-500">
                      Niveau: {d.niveau}
                    </div>
                    <div className="py-2 rounded inline-block">
                      Durée: 10 semaines
                    </div>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <div
                      onClick={() =>
                        navig(`/cours/detail/${encryptData(d.id.toString())}`)
                      }
                      className="border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer"
                    >
                      Détail
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
                    </div>
                    <div
                      onClick={() => handleValidation(d.id)}
                      className="border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer"
                    >
                      Valider
                      <FontAwesomeIcon icon={faCheck} className="ml-1" />
                    </div>
                    <div
                      onClick={() => handleAnnulation(d.id)}
                      className="border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer"
                    >
                      Annuler
                      <FontAwesomeIcon icon={faBan} className="ml-1" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
            disabled={page === 1}
            className="p-2 border border-gray-300 rounded hover:bg-gray-200"
          >
            Précédent
          </button>
          <span className="flex items-center">
            Page {page} sur {totalPages}
          </span>
          <button
            onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={page === totalPages}
            className="p-2 border border-gray-300 rounded hover:bg-gray-200"
          >
            Suivant
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AdminCoursPropose;
