import React, { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useThemes } from '../../../UserContext/UserContext';
import { Document, Page, pdfjs } from 'react-pdf';

const pdfjsWorker = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


function ProposezCours() {
    const { SetisProposition } = useThemes()
    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        domaine: '',
        titre: '',
        niveau: '',
        duree: '',
        description: '',
        objectifs: '',
        experience: '',
        module: '',
    });

    const [previewImages, setPreviewImages] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [errors, setErrors] = useState({});
    const inputRefs = {
        nom: useRef(null),
        email: useRef(null),
        domaine: useRef(null),
        titre: useRef(null),
        niveau: useRef(null),
        duree: useRef(null),
        description: useRef(null),
        objectifs: useRef(null),
        experience: useRef(null),
        module: useRef(null),
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleFileChange = (e) => {
        const { name } = e.target;
        const uploadedFile = e.target.files[0];
        
        if (uploadedFile && uploadedFile.type.split("/")[0] === "image") {
            setPreviewImages(URL.createObjectURL(uploadedFile));
        } else if (uploadedFile && uploadedFile.type === 'application/pdf') {
            setPreviewImages(URL.createObjectURL(uploadedFile));
            console.log("PDF preview URL:", URL.createObjectURL(uploadedFile));
        }
    
        setFormData({
            ...formData,
            [name]: uploadedFile,
        });
    };


    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'nom':
                if (!value.trim()) {
                    error = 'Le nom complet est requis.';
                }
                break;
            case 'email':
                if (!value.trim()) {
                    error = "L'adresse e-mail est requise.";
                }


                if (!/\S+@\S+\.\S+/.test(value)) {
                    error = "L'adresse e-mail n'est pas valide.";
                }
                break;
            case 'domaine':
                if (!value.trim()) {
                    error = "Le domaine d'expertise est requis.";
                }
                break;
            case 'titre':
                if (!value.trim()) {
                    error = "Le titre du cours est requis.";
                }
                break;
            case 'niveau':
                if (!value) {
                    error = "Veuillez choisir un niveau.";
                }
                break;
            case 'duree':
                if (!value) {
                    error = "La durée estimée est requise.";
                }
                break;
            case 'description':
                if (!value.trim()) {
                    error = "La description du cours est requise.";
                }
                break;
            case 'objectifs':
                if (!value.trim()) {
                    error = "Les objectifs d'apprentissage sont requis.";
                }
                break;
            case 'module':
                if (value === "") {
                    error = "Le modules d'apprentissage sont requis.";
                }
                break;
            default:
                break;
        }

        if (error) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
            inputRefs[name].current.focus();
            toast.error(error);
        }

        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const error = validateField(name, value);

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: error,
        });
        return false
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        for (let field of Object.keys(formData)) {
            const error = validateField(field, formData[field]);
            if (error) {
                return; // Stop validation once the first error is encountered
            }
        }

        // If no errors, submit the form
        toast.success('Formulaire soumis avec succès !');
        SetisProposition(false)
        console.log('Formulaire soumis:', formData);
    };

    return (
        <div className="p-6 rounded-lg">
            <p className="mb-4 text-lg">
                Partagez votre expertise et contribuez à l'éducation environnementale au Burundi en proposant un cours sur IMUHIRA.
            </p>
            <div className="space-y-4">
                <div className="bg-[#3ad12c1e] p-2 border-l-2 border-[#3ad12c] rounded-xl">
                    <p className="font-bold text-[22px]">Pourquoi proposer un cours ?</p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li className="ml-4 text-lg">Partager vos connaissances avec une communauté engagée</li>
                        <li className="ml-4 text-lg">Contribuer au développement durable du Burundi</li>
                        <li className="ml-4 text-lg">Gagner en visibilité en tant qu'expert dans votre domaine</li>
                        <li className="ml-4 text-lg">Possibilité de rémunération pour les cours sélectionnés</li>
                    </ul>
                </div>
                <form className="space-y-4" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="nom" className="block font-bold text-lg mb-1">Nom complet :</label>
                        <input
                            type="text"
                            id="nom"
                            name="nom"
                            value={formData.nom}
                            onChange={handleChange}
                            ref={inputRefs.nom}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 ${errors.nom ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div>
                        <label htmlFor="email" className="block font-bold text-lg mb-1">Adresse e-mail :</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            ref={inputRefs.email}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 ${errors.email ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div>
                        <label htmlFor="domaine" className="block font-bold text-lg mb-1">Domaine d'expertise :</label>
                        <input
                            type="text"
                            id="domaine"
                            name="domaine"
                            value={formData.domaine}
                            onChange={handleChange}
                            ref={inputRefs.domaine}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 ${errors.domaine ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div>
                        <label htmlFor="titre" className="block font-bold text-lg mb-1">Titre du cours proposé :</label>
                        <input
                            type="text"
                            id="titre"
                            name="titre"
                            value={formData.titre}
                            onChange={handleChange}
                            ref={inputRefs.titre}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 ${errors.titre ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div>
                        <label htmlFor="niveau" className="block font-bold text-lg mb-1">Niveau du cours :</label>
                        <select
                            id="niveau"
                            name="niveau"
                            value={formData.niveau}
                            onChange={handleChange}
                            ref={inputRefs.niveau}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 ${errors.niveau ? 'border-red-500' : ''}`}
                        >
                            <option hidden value="">Sélectionnez un niveau</option>
                            <option className="text-black" value="debutant">Débutant</option>
                            <option className="text-black" value="intermediaire">Intermédiaire</option>
                            <option className="text-black" value="avance">Avancé</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="duree" className="block font-bold text-lg mb-1">Durée estimée du cours (en heures) :</label>
                        <input
                            type="number"
                            id="duree"
                            name="duree"
                            value={formData.duree}
                            onChange={handleChange}
                            ref={inputRefs.duree}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 ${errors.duree ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div>
                        <label htmlFor="description" className="block font-bold text-lg mb-1">Description du cours :</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            ref={inputRefs.description}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 h-24 ${errors.description ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div>
                        <label htmlFor="objectifs" className="block font-bold text-lg mb-1">Objectifs d'apprentissage :</label>
                        <textarea
                            id="objectifs"
                            name="objectifs"
                            value={formData.objectifs}
                            onChange={handleChange}
                            ref={inputRefs.objectifs}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 h-24 ${errors.objectifs ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div>
                        <label htmlFor="experience" className="block font-bold text-lg mb-1">Expérience pédagogique antérieure :</label>
                        <textarea
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleChange}
                            ref={inputRefs.experience}
                            className={`w-full border-gray-300 rounded-md border outline-none focus:border-[#3ad12c] bg-transparent p-3 h-24 ${errors.experience ? 'border-red-500' : ''}`}
                        />
                    </div>

                    <div className="my-5 w-full flex h-max">
                        <label htmlFor="module" className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full  border  rounded  ${errors.agree ? 'border-red-500' : 'focus:ring-teal-300'}`}>
                            Module
                        </label>
                        <input
                            type="file"
                            id="module"
                            name="agree"
                            hidden
                            ref={inputRefs.module}
                            onChange={handleFileChange}
                        />
                    </div>
                    {previewImages && <img src={previewImages} alt="Agréement Preview" className="mb-4 h-48" />}
                    {previewImages && (
    <Document
        file={previewImages} 
        onLoadSuccess={onDocumentLoadSuccess}
    >
        <Page pageNumber={pageNumber} />
    </Document>
)}

                    <button
                        type="submit"
                        className="bg-[#3ad12c] text-white font-bold py-2 px-4 rounded-md "
                    >
                        Soumettre la proposition
                    </button>
                </form>
                <div className='w-full justify-center flex'>
                    <p className='text-[17px] w-[20em] text-center'>Après soumission, notre équipe examinera votre proposition et vous contactera dans les meilleurs délais pour discuter des prochaines étapes.</p>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ProposezCours;
