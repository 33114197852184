import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminRessource from './AdminRessource'

function AdminRessourceComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminRessource />
        </div>
  )
}

export default AdminRessourceComponent