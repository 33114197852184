import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Document, Page, pdfjs } from 'react-pdf';
const pdfjsWorker = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function PartenariatForm() {
  const [formData, setFormData] = useState({
    organisation: '',
    contact: '',
    email: '',
    phone: '',
    partnership: '',
    message: '',
    agree: '',
    rapport: '',
    plan: '',
  });

  const [errors, setErrors] = useState({});
  const [pdfPreview, setPdfPreview] = useState(null);
  const [previewImages, setPreviewImages] = useState({
    agree: null,
    rapport: null,
    plan: null,
  });

  // Références pour chaque champ du formulaire
  const fieldRefs = {
    organisation: useRef(null),
    contact: useRef(null),
    email: useRef(null),
    phone: useRef(null),
    partnership: useRef(null),
    message: useRef(null),
    agree: useRef(null),
    rapport: useRef(null),
    plan: useRef(null),
  };

  const validateField = (name, value) => {
    let error = '';

    switch (name) {
      case 'organisation':
        if (!value.trim()) {
          error = "Le nom de l'organisation est requis.";
        }
        break;
      case 'contact':
        if (!value.trim()) {
          error = "Le nom du contact est requis.";
        }
        break;
      case 'email':
        if (!value.trim()) {
          error = "L'adresse e-mail est requise"
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "L'adresse e-mail n'est pas valide.";
        }
        break;
      case 'phone':
        if (!value.trim()) {
          error = "Le numéro de téléphone est requis.";
        } else if (!/^\+?\d{7,15}$/.test(value)) {
          error = "Le numéro de téléphone n'est pas valide.";
        }
        break;
      case 'partnership':
        if (!value) {
          error = "Veuillez choisir un type de partenariat.";
        }
        break;
      case 'message':
        if (!value.trim()) {
          error = "Le message est requis.";
        }
        break;
      case 'agree':
        if (value === "") {
          error = "L'agrement est requis.";
        }
        break; case 'rapport':
        if (value === "") {
          error = "Le rapport est requis.";
        }
        break;
      case 'plan':
        if (value === "") {
          error = "Le plan est requis.";
        }
        break;
      default:
        break;
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;

    const uploadedFile = e.target.files[0];

    console.log("uploadedFile", uploadedFile && uploadedFile.type.split("/")[0])

    if (uploadedFile && uploadedFile.type.split("/")[0] === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages((prev) => ({
          ...prev,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(uploadedFile);
    } else if (uploadedFile && uploadedFile.type === 'application/pdf') {
      // visualisation de premier page en image
    }


    setFormData({
      ...formData,
      [name]: uploadedFile,
    });
  }







  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    let hasError = false;

    for (const field in formData) {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
        toast.error(error);  // Affiche le toast pour la première erreur
        hasError = true;

        // Met le focus sur le champ correspondant
        fieldRefs[field].current.focus();
        break;  // Arrête la boucle dès qu'une erreur est trouvée
      }
    }

    setErrors(newErrors);

    if (!hasError) {
      toast.success("Formulaire soumis avec succès !");
      console.log("Formulaire soumis:", formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full sm:w-[70%] h-max">
      <div className="mb-4">
        <label htmlFor="organisation" className="block font-medium text-lg ">Nom de l'organisation :</label>
        <input
          type="text"
          id="organisation"
          name="organisation"
          ref={fieldRefs.organisation}
          className={`bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c] ${errors.organisation ? 'border-red-500' : 'focus:ring-teal-300'}`}
          value={formData.organisation}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="contact" className="block font-medium text-lg ">Nom du contact :</label>
        <input
          type="text"
          id="contact"
          name="contact"
          ref={fieldRefs.contact}
          className={`bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c] ${errors.contact ? 'border-red-500' : 'focus:ring-teal-300'}`}
          value={formData.contact}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block font-medium text-lg ">Adresse e-mail :</label>
        <input
          type="email"
          id="email"
          name="email"
          ref={fieldRefs.email}
          className={`bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c] ${errors.email ? 'border-red-500' : 'focus:ring-teal-300'}`}
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block font-medium text-lg ">Numéro de téléphone :</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          ref={fieldRefs.phone}
          className={`bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c] ${errors.phone ? 'border-red-500' : 'focus:ring-teal-300'}`}
          value={formData.phone}
          onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label htmlFor="partnership" className="block font-medium text-lg ">Type de partenariat envisagé :</label>
        <select
          id="partnership"
          name="partnership"
          ref={fieldRefs.partnership}
          className={`bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c] ${errors.partnership ? 'border-red-500' : 'focus:ring-teal-300'}`}
          value={formData.partnership}
          onChange={handleChange}
        >
          <option className='text-black' hidden value="">Choisissez une option</option>
          <option className='text-black' value="institutional">Partenariat institutionnel</option>
          <option className='text-black' value="academic">Partenariat académique</option>
          <option className='text-black' value="private">Partenariat privé</option>
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block font-medium text-lg ">Message (Décrivez brièvement votre intérêt pour un partenariat) :</label>
        <textarea
          id="message"
          name="message"
          ref={fieldRefs.message}
          rows="4"
          className={`bg-transparent py-3 pl-2 min-h-[15em]  border w-full rounded focus:border-[#3ad12c] ${errors.message ? 'border-red-500' : 'focus:ring-teal-300'}`}
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <div className="my-5 w-full flex h-max">
        <label htmlFor="agree" className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full  border  rounded  ${errors.agree ? 'border-red-500' : 'focus:ring-teal-300'}`}>
          Agréement
        </label>
        <input
          type="file"
          id="agree"
          name="agree"
          hidden
          ref={fieldRefs.agree}
          onChange={handleFileChange}
        />
      </div>
      {previewImages.agree && <img src={previewImages.agree} alt="Agréement Preview" className="mb-4 h-48" />}

      <div className="my-5 w-full flex h-max">
        <label htmlFor="rapport" className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full  border  rounded  ${errors.rapport ? 'border-red-500' : 'focus:ring-teal-300'}`}>
          Rapport d'activité
        </label>
        <input
          type="file"
          id="rapport"
          name="rapport"
          hidden
          ref={fieldRefs.rapport}
          onChange={handleFileChange}
        />
      </div>
      {previewImages.rapport && <img src={previewImages.rapport} alt="Rapport Preview" className="mb-4 h-48" />}

      <div className="my-5 w-full flex h-max">
        <label htmlFor="plan" className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full  border  rounded  ${errors.plan ? 'border-red-500' : 'focus:ring-teal-300'}`}>
          Plan d'action
        </label>
        <input
          type="file"
          id="plan"
          name="plan"
          hidden
          ref={fieldRefs.plan}
          onChange={handleFileChange}
        />
      </div>
      {previewImages.plan && <img src={previewImages.plan} alt="Plan Preview" className="mb-4 h-48" />}


      <button
        type="submit"
        className="bg-[#3ad12c] mb-10  text-white font-semibold  p-2 rounded"
      >
        Envoyer ma demande de partenariat
      </button>
    </form>
  );
}

export default PartenariatForm;
