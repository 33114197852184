import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminProjetModofier from './AdminProjetModofier'

function AdminProjetModofierComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
    <AdminSlide />
    <AdminProjetModofier />
</div>
  )
}

export default AdminProjetModofierComponent