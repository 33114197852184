import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const UserContext = createContext();
export function ContextPovider({ children }) {
  // .....................crypte.......................

  const [isVisitor, SetIsVisitor] = useState(false);
  const [isAdmin, SetIsAdmin] = useState(true);
  const [isGoingToSite, SetisGoingToSite] = useState(false);

  // ...............................scroll window......................
  const [getWidth, SetgetWidth] = useState(0);
  const [isScroll, SetisScroll] = useState(false);

  useEffect(() => {
    const hundleScroll = (e) => {
      const value = window.pageYOffset;
      if (value > 100) {
        SetisScroll(true);
      } else {
        SetisScroll(false);
      }
      SetgetWidth(
        value /
          (document.documentElement.scrollHeight -
            document.documentElement.clientHeight)
      );
    };

    window.addEventListener("scroll", hundleScroll);

    return () => {
      window.removeEventListener("scroll", hundleScroll);
    };
  }, []);

  const HundlwScrollTop = () => {
    document.documentElement.scrollTop = 0;
    document.documentElement.style.scrollBehavior = "smooth";
  };

  const triggerRef = useRef();
  const close = () => triggerRef.current && triggerRef.current.close();

  //visiteur avec evenement onClick
  const [isDark, setIsDark] = useState("light");
  const toggleDarkMode = () => {
    const newDarkMode = isDark === "dark" ? "light" : "dark";
    setIsDark(newDarkMode);
    localStorage.setItem("isDark", newDarkMode);
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("isDark");
    if (storedValue) {
      setIsDark(storedValue);
    }
  }, []);

  const [isDarVisitLoggined, GetisDarVisitLoggined] = useState("light");
  const handleChange = (value) => {
    GetisDarVisitLoggined(value);
    close();
    localStorage.setItem("isDark", value);
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("isDark");
    if (storedValue) {
      GetisDarVisitLoggined(storedValue);
    }
  }, [isDark]);

  //  ............pour scroll rejoindre
  const formRef = useRef(null);

  const handleScrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const EdicRef = useRef(null);

  const handleScrollToEduc = () => {
    if (EdicRef.current) {
      EdicRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const EconomismeRef = useRef(null);
  const handleScrollToEcomisme = () => {
    if (EconomismeRef.current) {
      EconomismeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const formateursRef = useRef(null);
  const handleScrollToFormateur = () => {
    if (formateursRef.current) {
      formateursRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const projetRef = useRef(null);
  const handleScrollToProjet = () => {
    if (projetRef.current) {
      projetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isRejoinder, SetisRejoinder] = useState(false);
  const [isAtelierComminity, SetisAtelierComminity] = useState(false);
  const [isPartenariat, SetisPartenariat] = useState(false);
  const [isProposition, SetisProposition] = useState(false);
  const [isPropoProjet, SetisPropoProjet] = useState(false);
  const [detailCours, SetDetailCours] = useState({});

  const [selectRessource, SetselectRessource] = useState("");
  const [openModal, SetOpenModal] = useState(false);

  const [detailEvenement, SetDetailEvenement] = useState({});

  // ............................login................
  const [isLogin, SetIsLogin] = useState(false);
  return (
    <UserContext.Provider
      value={{
        detailEvenement,
        isLogin,
        SetIsLogin,
        SetDetailEvenement,
        isPropoProjet,
        SetisPropoProjet,
        openModal,
        SetOpenModal,
        handleScrollToProjet,
        projetRef,
        selectRessource,
        SetselectRessource,
        isProposition,
        SetisProposition,
        detailCours,
        SetDetailCours,
        handleScrollToFormateur,
        formateursRef,
        isPartenariat,
        SetisPartenariat,
        EconomismeRef,
        handleScrollToEcomisme,
        handleScrollToEduc,
        EdicRef,
        handleChange,
        triggerRef,
        handleScrollToForm,
        formRef,
        isAtelierComminity,
        SetisAtelierComminity,
        isRejoinder,
        SetisRejoinder,
        isDarVisitLoggined,
        isDark,
        toggleDarkMode,
        isGoingToSite,
        SetisGoingToSite,
        HundlwScrollTop,
        getWidth,
        isScroll,
        isVisitor,
        SetIsVisitor,
        isAdmin,
        SetIsAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useThemes = () => {
  return useContext(UserContext);
};
