import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { Progress } from "rsuite";
import AdminProjetNavBars from "./AdminProjetNavBars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2"; // Importer SweetAlert2
import { encryptData } from "../../../encryptionModule";

function AdminProjet() {
  const navigate = useNavigate();
  const [projets, setProjets] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { isDarVisitLoggined } = useThemes();

  const fetchProjets = async (page = 1, size = 10, search = "") => {
    try {
      const response = await axios.get(
        "https://imuhirabackend.eac-advisor.org/apiv1/projet",
        {
          params: { page, size, search },
        }
      );
      setProjets(response.data.projets);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Erreur lors de la récupération des projets :", error);
    }
  };

  // Fonction pour supprimer un projet
  const handleDelete = async (id, titre) => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: `Vous êtes sur le point de supprimer le projet "${titre}". Cette action est irréversible !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(
          `https://imuhirabackend.eac-advisor.org/apiv1/projet/${id}`
        );
        fetchProjets(currentPage, pageSize, searchTerm);
        toast.success(`Projet "${titre}" supprimé avec succès !`); // Afficher un message de succès
      } catch (error) {
        console.error("Erreur lors de la suppression du projet :", error);
        toast.error("Erreur lors de la suppression du projet."); // Afficher un message d'erreur
      }
    }
  };

  useEffect(() => {
    fetchProjets(currentPage, pageSize, searchTerm);
  }, [currentPage, pageSize, searchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchProjets(1, pageSize, searchTerm);
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <ToastContainer /> {/* Ajouter le ToastContainer ici */}
      <AdminProjetNavBars />
      <div className="flex w-full justify-between p-4 mb-4 items-center">
        <div className="inline-flex gap-2">
          <select
            className="bg-transparent border cursor-pointer border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border focus:border-[#3ad12c]"
            value={pageSize}
            onChange={(e) => setPageSize(e.target.value)}
          >
            <option value={1} className="text-black">
              1
            </option>
            <option value={5} className="text-black">
              5
            </option>
            <option value={10} className="text-black">
              10
            </option>
            <option value={20} className="text-black">
              20
            </option>
            <option value={50} className="text-black">
              50
            </option>
            <option value={100} className="text-black">
              100
            </option>
          </select>
        </div>
        <div className="flex w-[60%]">
          <div className="flex w-full items-center">
            <div className="w-full">
              <input
                className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                placeholder="Rechercher un projet"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button
              onClick={handleSearch}
              className="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold p-2 rounded-e inline-block"
            >
              Rechercher
            </button>
          </div>
          <button
            onClick={() => navigate("/projet/ajouter")}
            className="border ml-2 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
          >
            Ajouter
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-3 lg:grid-cols-4 gap-2">
        {projets.map((projet) => (
          <div
            key={projet.id}
            className={`rounded-md ${
              isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
            } p-4`}
          >
            <img
              src={`https://imuhirabackend.eac-advisor.org/uploads/projet/${projet.photoCouverture}`}
              alt={projet.titre}
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-xl font-bold">{projet.titre}</h2>
            <div className="text-[12px] p-2">{projet.description}</div>
            <div>
              <Progress.Line
                percent={projet.progression}
                strokeWidth={5}
                strokeColor="#3ad12c"
              />
            </div>
            <div className="flex w-full justify-between items-center p-2">
              <div
                title="Modifier"
                onClick={() =>
                  navigate(`/projet/modif/${encryptData(projet.id.toString())}`)
                }
                className="border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer"
              >
                <FontAwesomeIcon icon={faEdit} />
              </div>
              <div
                title="Détail"
                onClick={() =>
                  navigate(
                    `/projet/detail/${encryptData(projet.id.toString())}`
                  )
                }
                className="border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
              <div
                title="Supprimer"
                onClick={() => handleDelete(projet.id, projet.titre)} // Passer le titre pour l'afficher dans le toast
                className="border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            className={`px-3 py-1 ${
              currentPage === index + 1
                ? "bg-[#3ad12c] text-white"
                : "bg-gray-300 text-black"
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default AdminProjet;
