import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";

function AdiminRapportDetail() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const rapportId = decryptData(id);

  // State pour stocker les données du rapport
  const [rapportData, setRapportData] = useState(null);

  // Fetch des données de l'API
  useEffect(() => {
    const fetchRapportData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/rapports/${rapportId}`
        );
        const data = await response.json();
        setRapportData(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données du rapport :",
          error
        );
      }
    };

    fetchRapportData();
  }, [rapportId]);

  if (!rapportData) {
    return <div>Chargement...</div>; // Affiche un message pendant le chargement
  }

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/ressource/rapport")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <div className={`p-4 m-4 text-white rounded-lg bg-[#3ad12c] shadow-md`}>
        <h1 className="text-xl font-bold">{rapportData.titre}</h1>
        <p className="mt-2">{rapportData.description}</p>
      </div>

      <div
        className={`p-6 m-4 rounded-lg ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } shadow-md`}
      >
        <h2 className="text-lg font-semibold">Actions réalisées :</h2>
        <ul className="list-disc list-inside mt-2">
          {rapportData.realiserapports.map((action) => (
            <li key={action.id}>{action.description}</li>
          ))}
        </ul>

        <h2 className="text-lg font-semibold mt-4">Actions à entreprendre :</h2>
        <ul className="list-disc list-inside mt-2">
          {rapportData.entreprendrereports.map((action) => (
            <li key={action.id}>{action.description}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AdiminRapportDetail;
