import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminProjetTemoi from './AdminProjetTemoi'

function AdminProjetTemoiComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminProjetTemoi />
        </div>
    )
}

export default AdminProjetTemoiComponent