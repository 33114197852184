import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'

function SujetDetail() {
    const navig = useNavigate()
    const { isDark } = useThemes()
    return (
        <div className=' mt-[18vh]  overflow-hidden'>
            <div onClick={() => navig("/communaute/forumu")} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div className={`w-full mx-auto  rounded-lg ${isDark === "dark" ? null : 'bg-white'}  p-8`}>
                <div class="flex justify-between items-center mb-6">
                    <div>
                        <h1 class="text-2xl font-bold">Comment démarrer un jardin communautaire à Bujumbura ?</h1>
                        <p class="pl-5 mt-2">Par Pierre M. | Catégorie : Jardinage Urbain | Il y a 2 heures</p>
                    </div>
                    <div class="flex items-center space-x-4">
                        <div class="flex items-center space-x-2 text-[#3ad12c] font-bold">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path>
                            </svg>
                            <span>89 Vues</span>
                        </div>
                        <div class="flex items-center space-x-2 text-[#3ad12c] font-bold">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.958A6.06 6.06 0 012 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd"></path>
                            </svg>
                            <span>12 Réponses</span>
                        </div>
                    </div>
                </div>
                <div class="space-y-6">
                    <div className="container mx-auto p-6">
                        <div className={`w-full mx-auto  rounded-lg ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-100'}  p-8`}>
                            <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
                            <p className="text-lg mb-4">
                                Démarrer un jardin communautaire à Bujumbura est une excellente initiative qui peut aider à fournir des aliments frais à la communauté tout en créant un espace de socialisation. Voici quelques étapes clés pour vous aider à commencer :
                            </p>
                            <h3 className="text-xl font-semibold mb-2">Étape 1 : Trouver un emplacement</h3>
                            <p className="mb-4">
                                Recherchez un espace disponible dans votre quartier qui peut être transformé en jardin. Assurez-vous que l'endroit est facilement accessible et reçoit suffisamment de lumière du soleil.
                            </p>
                            <h3 className="text-xl font-semibold mb-2">Étape 2 : Impliquer la communauté</h3>
                            <p className="mb-4">
                                Un jardin communautaire ne peut fonctionner sans l'implication des membres de la communauté. Organisez une réunion pour discuter de l'idée et encouragez les habitants à participer activement.
                            </p>
                            <h3 className="text-xl font-semibold mb-2">Étape 3 : Rassembler les ressources</h3>
                            <p className="mb-4">
                                Sollicitez des dons ou trouvez des sponsors locaux pour financer les fournitures nécessaires comme les outils de jardinage, les graines, et l'eau.
                            </p>
                            <h3 className="text-xl font-semibold mb-2">Étape 4 : Planter et entretenir le jardin</h3>
                            <p className="">
                                Une fois le jardin lancé, il est crucial d'avoir un plan d'entretien. Organisez des journées de travail communautaire pour planter, arroser et entretenir les plantes.
                            </p>
                        </div>

                        <div className={`w-full mx-auto mt-10  rounded-lg ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-100'}  p-8`}>
                            <h2 className="text-2xl font-semibold mb-4">Ajouter une réponse</h2>
                            <textarea
                                className="w-full h-32 p-4 bg-transparent border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#3ad12c]"
                                placeholder="Partagez votre expérience ou posez une question..."
                            ></textarea>
                            <button className="mt-4 bg-[#3ad12c] text-white py-2 px-6 rounded-lg hover:scale-105">
                                Soumettre
                            </button>
                        </div>


                        <div className={`w-full mx-auto mt-10  rounded-lg ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-100'}  p-8`}>
                            <h2 className="text-2xl font-semibold mb-4">Réponses (12)</h2>


                            <div className="mb-6  pb-4">
                                <p className="text-gray-800 font-bold">Marie K.</p>
                                <p className=" text-sm">Il y a 1 heure</p>
                                <p className="mt-2">
                                    Merci pour cet excellent guide ! Je suis en train de lancer un projet similaire à Kinama, et ces étapes m'ont vraiment aidée à structurer mes idées.
                                </p>
                            </div>
                            <hr />
                            <div className="mb-6 pb-4">
                                <p className="text-gray-800 font-bold">Jean D.</p>
                                <p className=" text-sm">Il y a 45 minutes</p>
                                <p className="mt-2">
                                    Très utile ! Quels types de plantes recommandez-vous pour un climat comme celui de Bujumbura ?
                                </p>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default SujetDetail

