import React from 'react'
import Carousel from 'react-multi-carousel';
import { useThemes } from '../../../UserContext/UserContext';
import { teamMembers } from '../../../Data/Data';
import { Drawer } from 'rsuite';
import PartenariatForm from './PartenariatForm';
function Economisme() {
    const { isDark, EconomismeRef, SetisPartenariat, isPartenariat } = useThemes()
    

    const CustomRightArrow = ({ onClick }) => {
        return (
            <div className='absolute flex justify-center items-center z-[1000] right-5 h-full'>
                <button
                    onClick={onClick}
                    className=' bg-[#5dca32] border-none p-[5px] cursor-pointer text-white  rounded-full'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                </button>
            </div>
        );
    };

    const CustomLeftArrow = ({ onClick }) => {
        return (
            <div className='absolute flex justify-center items-center z-[1000] h-full'>
                <button
                    onClick={onClick}
                    className='bg-[#5dca32]  border-none p-[5px] cursor-pointer text-white left-1 rounded-full'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                    </svg>
                </button>
            </div>
        );
    };



    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };



    return (
        <div ref={EconomismeRef} data-aos-duration="1000" class="rounded-lg  p-6 mt-6 ">
            <h2 class="text-2xl font-bold mb-4 border-b-[1px] pb-5">Collaborons pour un Burundi plus vert</h2>
            <p className='text-[18px]'>Le Mouvement Imuhira vise fermement à la pulsation des partenariats pour atteindre des objectifs de développement durable au Burundi. Nous recherchons activement des partenaires engagés pour nous aider à façonner un avenir écologique et prospère pour notre pays.</p>
            <h3 class="text-lg font-semibold mb-2 mt-3">Types de Partenariats</h3>
            <div class="grid grid-cols-3 gap-4">
                <div data-aos="zoom-in" data-aos-delay="100" className='flex flex-col items-center'>
                    <h4 class="text-[#3ad12c] font-semibold">Partenaires Institutionnels</h4>
                    <ul class="list-none mt-3  w-[19em] text-center text-[17px]">
                        <li>Ministères, agences gouvernementales et organisations internationales pour le développement de politiques nationales et internationales.</li>
                    </ul>
                </div>
                <div data-aos="zoom-in" data-aos-delay="150" className='flex flex-col items-center'>
                    <h4 class="text-[#3ad12c] font-semibold">Partenaires Académiques</h4>
                    <ul class="list-none text-center mt-3 w-[19em] text-[17px]">
                        <li>Universités et instituts de recherche pour soutenir l'innovation et la formation en matière de durabilité et de développement durable.</li>
                    </ul>
                </div>
                <div data-aos="zoom-in" data-aos-delay="200" className='flex flex-col items-center'>
                    <div class="text-[#3ad12c] text-[25px] font-semibold">Partenaires Privés</div>
                    <ul class="list-none w-[19em] mt-3 text-center text-[17px]">
                        <li>Entreprises engagées dans la responsabilité sociale et environnementale, désirant contribuer à un Burundi plus durable.</li>
                    </ul>
                </div>
            </div>
            <h3 class="text-lg mt-5 font-semibold mb-2">Nos partenaires actuels</h3>
            <div className='w-[100%] mt-1'>
                <Carousel
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    responsive={responsive}
                    containerClass="h-max p-2"
                >
                    {teamMembers.map((host, index) => (
                        <div data-position={index} key={index} className='m-1 p-1'>

                            <div className='h-[15em] w-[15em] relative rounded-xl overflow-hidden'>
                                <img
                                    onLoad={() => {
                                        document.querySelector(`[data-position="${index}"] .spinnerws`).style.display = 'none';
                                    }}
                                    src="image/partenaire.jpg"
                                    alt={host.name}
                                    className='w-full h-full object-cover object-center' />
                                <div className={`absolute inset-0 flex items-center justify-center ${isDark === "dark" ? 'bg-[#151616]' : 'bg-white'}    spinnerws`}>
                                    <svg
                                        className="animate-spin h-7 w-7 text-[#5dca32]"
                                        viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#5dca32" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="#5dca32" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.598A4.002 4.002 0 008 12H6v5.291z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className='mb-2 font-semibold' >Dr {host.name}</div>
                            <div className='mb-2'>{host.role}</div>
                            <div className='italic' >{host.profile}</div>
                        </div>
                    ))}
                </Carousel>
            </div>

            <div  class="rounded-lg  p-6 my-6">
                <h2 data-aos="zoom-in" class="text-2xl font-bold mb-4">Témoignage d'un Partenaire</h2>
                <blockquote data-aos="zoom-in" class="italic border p-2 my-4 pl-3 border-l-[#3ad12c] border-l-2 rounded-md">
                    <p>"Notre partenariat avec le Mouvement Imuhira nous a permis de concrétiser notre engagement environnemental. Ensemble, nous avons pu mettre en place des initiatives concrètes qui ont un impact réel sur les communautés locales et l'environnement au Burundi."</p>
                    <cite class="block font-medium text-[#3ad12c] mt-2">- Jean Nshimirimana, Directeur RSE, Brandt</cite>
                </blockquote>
                <h3 data-aos="zoom-in" class="text-lg font-semibold mb-2">Avantages du Partenariat</h3>
                <ul data-aos="zoom-out-up" class="list-disc">
                    <li className=' ml-[3em] text-lg'>Contribuer activement à la protection de l'environnement au Burundi</li>
                    <li className=' ml-[3em] text-lg'>Améliorer votre image de marque et votre réputation en matière de responsabilité sociale</li>
                    <li className=' ml-[3em] text-lg'>Accéder à notre réseau d'experts dans le domaine du développement durable</li>
                    <li className=' ml-[3em] text-lg'>Bénéficier d'opportunités de visibilité lors de nos événements et campagnes</li>
                    <li className=' ml-[3em] text-lg'>Participer à des projets innovants ayant un impact positif sur les communautés locales</li>
                </ul>
            </div>
            <div className='w-10 m-1 animate-ping absolute z-0 l right-3  rounded-lg h-10 bg-fuchsia-500'></div>
                            
            <div onClick={() => SetisPartenariat(true)} class="text-[#3ad12c71] border py-2 px-4 border-[#3ad12c] w-max rounded-lg hover:text-[#3ad12c] cursor-pointer font-bold">Demande de partenariat</div>
           
          
      
            <Drawer size="full" placement='top' open={isPartenariat} onClose={() => SetisPartenariat(false)}>
                <Drawer.Header>
                    <Drawer.Title className='text-center uppercase'>Formulaire de demande de partenariat</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <PartenariatForm />
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default Economisme