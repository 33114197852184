import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios"; // Importer axios
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "./AdminRessourceNavBars";

function AdminRessourceAjouter() {
  const { isDarVisitLoggined } = useThemes();
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(window.innerWidth <= 640);
  const [resource, setResource] = useState({
    image: "",
    titre: "",
    descript: "",
    file: "",
    link: "",
    label: "",
    preview: "",
    previewfile: "",
    fileName: "",
  });
  const inputRefs = useRef({
    image: React.createRef(null),
    titre: React.createRef(null),
    descript: React.createRef(null),
    file: React.createRef(null),
    link: React.createRef(null),
    label: React.createRef(null),
  });

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (field, value) => {
    const updatedResource = { ...resource };
    if (field === "image") {
      updatedResource.preview = URL.createObjectURL(value);
      updatedResource.image = value;
    } else if (field === "file") {
      updatedResource.fileName = value.name;
      updatedResource.previewfile = URL.createObjectURL(value);
      updatedResource.file = value;
    } else {
      updatedResource[field] = value;
    }
    setResource(updatedResource);
  };

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateResource = () => {
    const requiredFields = ["image", "titre", "descript", "label"];
    for (const field of requiredFields) {
      if (!resource[field]) {
        toast.warning(`Le champ ${field} est obligatoire.`);
        animateAndScrollToRef(inputRefs.current[field]);
        return false;
      }
    }

    if (!resource.file && !resource.link) {
      toast.warning(
        "Au moins un des champs 'Fichier' ou 'Lien' doit être rempli."
      );
      animateAndScrollToRef(inputRefs.current.file || inputRefs.current.link);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateResource()) return;

    const formData = new FormData();
    formData.append("titre", resource.titre);
    formData.append("description", resource.descript);
    formData.append("label", resource.label);
    formData.append("linkyoutube", resource.link);
    formData.append("photocouverture", resource.image);
    formData.append("fichier", resource.file);

    try {
      const response = await axios.post(
        "https://imuhirabackend.eac-advisor.org/apiv1/resources/create", // URL de l'API
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast.success("La ressource a été enregistrée avec succès");
        navigate("/ressource"); // Redirection après succès
      }
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de l'enregistrement de la ressource."
      );
      console.error("Erreur lors de l'enregistrement de la ressource:", error);
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navigate("/ressource")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="rounded-lg p-2 w-full sm:p-4">
          <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
            <div className="mb-2">
              <div className="flex flex-col items-center">
                <label className="block text-xs font-[700] mt-5 sm:text-lg mb-1">
                  Ajouter une ressource
                </label>

                <div className="flex flex-col mt-5 mb-5">
                  <label htmlFor="file" className="block text-lg mb-1">
                    Photo de couverture
                  </label>
                  <input
                    type="file"
                    accept=".jpeg,.png,.jpg,.gif"
                    className="block bg-[#3ad12c] text-white rounded-md px-4 py-2"
                    onChange={(e) =>
                      handleInputChange("image", e.target.files[0])
                    }
                    ref={inputRefs.current.image}
                  />
                </div>
                {resource.preview && (
                  <div className="relative w-full md:w-[15em] mt-5 overflow-hidden rounded-lg h-[15em] border border-gray-300 shadow-md">
                    <img
                      src={resource.preview}
                      alt="Preview"
                      className="object-cover w-full h-full rounded-lg transition-all duration-300 hover:scale-105"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="titre" className="block text-lg mb-1">
                Titre du ressource
              </label>
              <input
                type="text"
                placeholder="Titre du ressource"
                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("titre", e.target.value)}
                ref={inputRefs.current.titre}
              />
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="descript" className="block text-lg mb-1">
                Description
              </label>
              <textarea
                placeholder="Entrez une description"
                rows="3"
                className="w-full border border-gray-300 bg-transparent rounded-md px-2 py-2 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("descript", e.target.value)}
                ref={inputRefs.current.descript}
              />
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="file" className="block text-lg mb-1">
                Fichier associé
              </label>
              <input
                type="file"
                className="block bg-[#3ad12c] text-white rounded-md px-4 py-2"
                onChange={(e) => handleInputChange("file", e.target.files[0])}
                ref={inputRefs.current.file}
              />
            </div>

            {resource.previewfile && (
              <div className="flex flex-col items-center mt-2">
                <a
                  href={resource.previewfile}
                  download={resource.fileName}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Télécharger {resource.fileName}
                </a>
              </div>
            )}

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="link" className="block text-lg mb-1">
                Lien Youtube (si disponible)
              </label>
              <input
                type="text"
                placeholder="Lien Youtube"
                className="w-full border border-gray-300 bg-transparent rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("link", e.target.value)}
                ref={inputRefs.current.link}
              />
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="label" className="block text-lg mb-1">
                Catégorie
              </label>
              <input
                type="text"
                placeholder="Entrez le label"
                className="w-full border border-gray-300 bg-transparent rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("label", e.target.value)}
                ref={inputRefs.current.label}
              />
            </div>
          </div>
        </div>
        <div className="p-3 flex flex-col items-center justify-center">
          <button
            type="submit"
            className="bg-[#3ad12c] hover:bg-[#358d28] text-white font-[700] rounded-lg px-10 py-3"
          >
            Enregistrer
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminRessourceAjouter;
