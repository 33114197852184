import React from 'react'
import NavBarsRessource from './NavBarsRessource'
import { useThemes } from '../../../UserContext/UserContext'

function ConventionRegion() {
    const { isDark } = useThemes()
    return (

        <div data-aos-duration="1000" class={` mt-[18vh] ${isDark === "dark" ? null : 'bg-gray-100'} overflow-hidden`}>
            <NavBarsRessource />
            <main class="container mx-auto p-6">
                <section data-aos="fade-up" class="mb-12">
                    <h2 class="text-2xl font-bold mb-4 ">Principales conventions régionales</h2>
                    <ul class="list-disc list-inside space-y-2">
                        <li class="text-lg ml-3">Convention de Barcelone pour la protection de la Méditerranée</li>
                        <li class="text-lg ml-3">Accord de Paris sur le climat</li>
                        <li class="text-lg ml-3">Convention de Genève sur les espèces menacées en Europe</li>
                        <li class="text-lg ml-3">Convention de Bâle sur le contrôle des mouvements transfrontaliers de déchets dangereux</li>
                        <li class="text-lg ml-3">Convention de Sapporo sur la biodiversité</li>
                    </ul>
                </section>

                <section>
                    <h2 class="text-2xl font-bold mb-6 ">Détails des principales conventions régionales</h2>
                    <div class="space-y-6">
                         <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 ">Convention de Barcelone pour la protection de la Méditerranée</h3>
                            <p class="mb-4">Cette convention vise à protéger l'environnement méditerranéen contre la pollution et à promouvoir le développement durable dans cette région. Ses principaux axes sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Réduction des pollutions provenant des activités terrestres</li>
                                <li class="text-lg ml-3">Protection des zones maritimes et côtières</li>
                                <li class="text-lg ml-3">Gestion des ressources maritimes</li>
                                <li class="text-lg ml-3">Coopération régionale pour le suivi et l'évaluation</li>
                            </ul>
                        </div>

                         <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 ">Accord de Paris sur le climat</h3>
                            <p class="mb-4">L'Accord de Paris, signé en 2015, a pour but de limiter le réchauffement climatique en-dessous de 2°C par rapport aux niveaux préindustriels. Ses principaux éléments sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Objectifs de réduction des émissions de gaz à effet de serre</li>
                                <li class="text-lg ml-3">Planification nationale des contributions déterminées (NDC)</li>
                                <li class="text-lg ml-3">Adaptation aux impacts du changement climatique</li>
                                <li class="text-lg ml-3">Transfert de technologies et soutien financier</li>
                            </ul>
                        </div>
                         <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 ">Convention de Genève sur les espèces menacées en Europe</h3>
                            <p class="mb-4">Cette convention vise à protéger les espèces de faune et de flore menacées en Europe. Ses objectifs principaux incluent :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Protection des espèces menacées</li>
                                <li class="text-lg ml-3">Préservation des habitats naturels</li>
                                <li class="text-lg ml-3">Éducation et sensibilisation sur la biodiversité</li>
                                <li class="text-lg ml-3">Coordination entre les pays européens</li>
                            </ul>
                        </div>
                         <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 ">Convention de Bâle sur le contrôle des mouvements transfrontaliers de déchets dangereux</h3>
                            <p class="mb-4">La convention de Bâle régule les mouvements transfrontaliers de déchets dangereux pour protéger la santé humaine et l'environnement. Ses principales dispositions sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Contrôle et notification des mouvements de déchets</li>
                                <li class="text-lg ml-3">Gestion et élimination écologiques des déchets</li>
                                <li class="text-lg ml-3">Coopération internationale pour l'application</li>
                                <li class="text-lg ml-3">Assistance technique et financière aux pays en développement</li>
                            </ul>
                        </div>
                         <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 ">Convention de Sapporo sur la biodiversité</h3>
                            <p class="mb-4">La convention de Sapporo vise à promouvoir la conservation et l'utilisation durable de la biodiversité au niveau régional. Ses principaux axes sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Évaluation des écosystèmes et des espèces</li>
                                <li class="text-lg ml-3">Plans de gestion durable</li>
                                <li class="text-lg ml-3">Création de zones protégées</li>
                                <li class="text-lg ml-3">Promotion de la recherche et de la sensibilisation</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
        </div>

    )
}

export default ConventionRegion