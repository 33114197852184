import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminConvention from './AdminConvention'

function AdminConventionComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminConvention />
        </div>
    )
}

export default AdminConventionComponent