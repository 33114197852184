import React, { useState } from 'react';

const CreerSujet = () => {
  const [formData, setFormData] = useState({
    subject: '',
    category: '',
    content: '',
  });

  const [errors, setErrors] = useState({
    subject: false,
    category: false,
    content: false,
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let hasErrors = false;

    if (formData.subject.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, subject: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, subject: false }));
    }

    if (formData.category.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, category: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, category: false }));
    }

    if (formData.content.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, content: true }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, content: false }));
    }

    if (!hasErrors) {
      console.log('Formulaire valide :', formData);
    
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="mb-4">
        <label className="block text-lg font-bold mb-2" htmlFor="subject">
          Sujet
        </label>
        <input
          className={`shadow bg-transparent appearance-none border rounded w-full  p-3 focus:outline-none focus:shadow-outline ${
            errors.subject ? 'border-red-500' : ''
          }`}
          id="subject"
          type="text"
          placeholder="Entrez le sujet"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
        />
        {errors.subject && (
          <p className="text-red-500 text-xs italic">Le sujet est requis.</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-lg font-bold mb-2" htmlFor="category">
          Catégorie
        </label>
        <input
          className={`shadow bg-transparent appearance-none border rounded w-full  p-3 focus:outline-none focus:shadow-outline ${
            errors.category ? 'border-red-500' : ''
          }`}
          id="category"
          type="text"
          placeholder="Entrez la catégorie"
          name="category"
          value={formData.category}
          onChange={handleInputChange}
        />
        {errors.category && (
          <p className="text-red-500 text-xs italic">La catégorie est requise.</p>
        )}
      </div>

      <div className="mb-6">
        <label className="block text-lg font-bold mb-2" htmlFor="content">
          Contenu
        </label>
        <textarea
          className={`shadow bg-transparent appearance-none border min-h-[15em] rounded w-full  p-3 focus:outline-none focus:shadow-outline ${
            errors.content ? 'border-red-500' : ''
          }`}
          id="content"
          rows="3"
          placeholder="Entrez le contenu"
          name="content"
          value={formData.content}
          onChange={handleInputChange}
        ></textarea>
        {errors.content && (
          <p className="text-red-500 text-xs italic">Le contenu est requis.</p>
        )}
      </div>

      <div className="flex items-center justify-between">
        <button
          className="bg-[#3ad12c] hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Envoyer
        </button>
      </div>
    </form>
  );
};

export default CreerSujet;