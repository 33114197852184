/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import { decryptData } from "../../../encryptionModule";
import axios from "axios";

function AdminCommunauteBlogModifier() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const blogId = decryptData(id);

  const [mobile, setMobile] = useState({
    smallMobile: window.innerWidth < 501,
    largeMobile: window.innerWidth <= 640,
  });

  useEffect(() => {
    const handleResize = () => {
      setMobile({
        smallMobile: window.innerWidth < 501,
        largeMobile: window.innerWidth <= 640,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [formData, setFormData] = useState({
    titre: "",
    categorie: "",
    description: "",
  });

  const inputRefs = {
    titre: useRef(null),
    categorie: useRef(null),
    description: useRef(null),
  };

  const handleInputChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateBlog = () => {
    const requiredFields = ["titre", "description", "categorie"];
    for (const field of requiredFields) {
      if (!formData[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "titre"
                ? "titre"
                : field === "description"
                ? "description"
                : "catégorie"}
            </strong>{" "}
            est obligatoire.
          </div>
        );
        animateAndScrollToRef(inputRefs[field]);
        return false;
      }
    }
    return true;
  };

  const [boutLoading, setBoutLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateBlog()) {
      return;
    }

    // Set loading state
    setBoutLoading(true);

    axios
      .put(
        `https://imuhirabackend.eac-advisor.org/apiv1/blog/${blogId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Le blog est enregistré avec succès");
        navig("/comm/blog");
      })
      .catch((error) => {
        if (error.response) {
          toast.error("Erreur lors de l'enregistrement du blog.");
        } else if (error.request) {
          toast.error("Erreur de connexion au serveur.");
        } else {
          toast.error("Une erreur s'est produite.");
        }
      })
      .finally(() => {
        setBoutLoading(false);
      });
  };

  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/blog/${blogId}`
        );
        const data = response.data;

        // Initialize formData with fetched blog data
        setFormData({
          titre: data.blog.titre || "",
          description: data.blog.description || "",
          categorie: data.blog.categorie || "",
        });

        setBlogData(data);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des données du blog.");
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [blogId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/comm/blog")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="rounded-lg p-2 w-full sm:p-4">
          <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
            <div className="mb-2">
              <div className="rounded-xl p-2 w-full">
                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <label
                      htmlFor="titre"
                      className="mt-5 tracking-wide text-lg mb-1"
                    >
                      Titre du blog
                    </label>
                    <input
                      type="text"
                      id="titre"
                      value={formData.titre}
                      onChange={(e) =>
                        handleInputChange("titre", e.target.value)
                      }
                      placeholder="Le titre du blog"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                      ref={inputRefs.titre}
                    />
                  </div>

                  <div className="flex flex-col mt-5 mb-5">
                    <label
                      htmlFor="description"
                      className="mt-5 tracking-wide text-lg mb-1"
                    >
                      Déscription
                    </label>
                    <textarea
                      id="description"
                      value={formData.description}
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                      placeholder="Déscription"
                      className="w-full border bg-transparent min-h-[30vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                      ref={inputRefs.description}
                    ></textarea>
                  </div>

                  <div className="flex flex-col ">
                    <label
                      htmlFor="categorie"
                      className="mt-5 tracking-wide text-lg mb-1"
                    >
                      Catégorie
                    </label>
                    <input
                      type="text"
                      id="categorie"
                      value={formData.categorie}
                      onChange={(e) =>
                        handleInputChange("categorie", e.target.value)
                      }
                      placeholder="Catégorie"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                      ref={inputRefs.categorie}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-3 mb-3">
              <button
                type="submit"
                disabled={boutLoading}
                className={`${
                  boutLoading
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-green-500"
                } w-full text-white py-2 px-4 rounded`}
              >
                {boutLoading ? "Enregistrement..." : "Modifier le Blog"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdminCommunauteBlogModifier;
