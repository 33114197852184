import React, { useEffect, useState } from "react";
import AdminCommunauteNavBars from "./AdminCommunauteNavBars";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { encryptData } from "../../../encryptionModule";

function AdminCommunauteBlog() {
  const navig = useNavigate();
  const { isDarVisitLoggined } = useThemes();
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchBlogs(currentPage, pageSize, searchTerm);
  }, [currentPage, pageSize]);

  const fetchBlogs = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/blog?page=${page}&size=${size}&search=${search}`
      );
      setBlogs(response.data.blogs);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des blogs :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
    fetchBlogs(1, pageSize, event.target.value);
  };

  const deleteBlogPost = async (id) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton:
          "bg-red-600 text-white hover:bg-red-700 border border-red-600 rounded-lg px-4 py-2",
        cancelButton:
          "bg-gray-300 text-gray-800 hover:bg-gray-400 border border-gray-300 rounded-lg px-4 py-2",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/blog/${id}`
          );
          setBlogs(blogs.filter((blog) => blog.id !== id));
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          toast.error("La suppression a échoué.");
        }
      }
    });
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminCommunauteNavBars />
      <div className="mx-auto">
        <div className="rounded-lg">
          <div className="p-6">
            <div className="flex items-center justify-between w-full mb-4">
              <div>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  className="ml-2 p-2 bg-white border border-gray-300 rounded-lg"
                >
                  {[5, 10, 20, 50, 100].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex w-[60%] items-center">
                <div className="w-full">
                  <input
                    value={searchTerm}
                    onChange={handleSearch}
                    className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                    placeholder="Rechercher une ressource"
                  />
                </div>
                <button
                  onClick={() => navig("/comm/blog/ajouter")}
                  className="border ml-5 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 p-3 gap-3">
        {blogs.length > 0 ? (
          blogs.map((post) => (
            <div
              key={post.id}
              className={`rounded-md p-3 border border-[#3ad12c] ${
                isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
              }`}
            >
              <h1 className="text-2xl font-bold text-center">{post.titre}</h1>
              <p className="mt-4 text-justify">{post.description}</p>
              <div className="flex justify-center mt-4 mb-4">
                <span className="inline-block px-4 py-2 bg-green-200 text-green-800 text-sm font-semibold rounded-full shadow-md">
                  Catégorie: {post.categorie}
                </span>
              </div>
              <hr />
              <div className="flex w-full justify-between items-center">
                <button
                  title="Modifier"
                  onClick={() =>
                    navig(
                      `/comm/blog/modifier/${encryptData(post.id.toString())}`
                    )
                  }
                  className="border p-1 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer"
                >
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Modifier
                </button>
                <button
                  title="Détails"
                  onClick={() =>
                    navig(
                      `/comm/blog/detail/${encryptData(post.id.toString())}`
                    )
                  }
                  className="border p-1 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer"
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                  Détails
                </button>
                <button
                  title="Supprimer"
                  onClick={() => deleteBlogPost(post.id)}
                  className="border p-1 border-red-600 rounded text-red-600 hover:scale-105 transition-all cursor-pointer"
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                  Supprimer
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Aucun article trouvé.</p>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center p-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="p-2 border rounded"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="p-2 border rounded"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
}

export default AdminCommunauteBlog;
