import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminCoursPropose from './AdminCoursPropose'

function AdminCoursProposeComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
    <AdminSlide/>
    <AdminCoursPropose/>
</div>
  )
}

export default AdminCoursProposeComponent