import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminProjetSponsor from './AdminProjetSponsor'

function AdminProjetSponsorComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminProjetSponsor />
        </div>
    )
}

export default AdminProjetSponsorComponent