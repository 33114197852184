import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function AdminCoursNavBars() {
    const navig = useNavigate()
    const { pathname } = useLocation()
    let coursPropose = /^\/cours\/propose.*/
    let cours = /^\/cours.*/
    return (
        <div className='w-full flex justify-between mb-5 items-center'>
            <div onClick={() => navig("/cours")} className={`border p-2 cursor-pointer rounded-md  transition-all  ${cours.test(pathname) && !coursPropose.test(pathname) ? 'bg-[#3ad12c] text-white' : ''} `}>Cours</div>
            <div onClick={() => navig("/cours/propose")} className={`border p-2 cursor-pointer rounded-md  transition-all  ${coursPropose.test(pathname) ? 'bg-[#3ad12c] text-white' : ''} `}>Cours proposées</div>
        </div>
    )
}

export default AdminCoursNavBars