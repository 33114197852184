import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import axios from "axios"; // Import axios
import { decryptData } from "../../../encryptionModule";

function AdminConventionModifiier() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const handleResize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [convention, setConvention] = useState({
    image: "",
    titre: "",
    description: "",
    pdf: "",
    type: "",
    preview: "",
    photoCouverture: "",
    attachement: "",
  });

  const inputRefs = {
    image: useRef(null),
    titre: useRef(null),
    description: useRef(null),
    pdf: useRef(null),
    type: useRef(null),
  };

  const handleInputChange = (field, value) => {
    if (field === "image") {
      setConvention((prevState) => ({
        ...prevState,
        image: value,
        preview: URL.createObjectURL(value),
      }));
    } else {
      setConvention((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };
  const { id } = useParams();
  const conventioId = decryptData(id); // Décrypter l'ID

  useEffect(() => {
    const fetchConvention = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/convention/${conventioId}`
        );
        setConvention(response.data);
      } catch (error) {
        console.log("Erreur lors de la récupération de la convention");
      }
    };

    fetchConvention();
  }, [conventioId]);

  const validateFields = () => {
    const requiredFields = ["titre", "description", "type"];
    for (const field of requiredFields) {
      if (!convention[field]) {
        toast.warning(`Le champ ${field} est obligatoire.`);
        animateAndScrollToRef(inputRefs[field]);
        return false;
      }
    }
    return true;
  };

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__shakeX",
        "border-2",
        "border-red-500"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__shakeX",
          "border-2",
          "border-red-500"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      const formData = new FormData();

      formData.append("titre", convention.titre);
      formData.append("description", convention.description);
      if (convention.pdf) {
        formData.append("pdf", convention.pdf);
      }
      if (convention.image) {
        formData.append("image", convention.image);
      }
      formData.append("type", convention.type);

      // Utilisation de axios pour la requête PUT
      await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/convention/${conventioId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("La convention a été mise à jour avec succès.");
      navig("/ressource/convention");
    } catch (error) {
      toast.error(error.response?.data?.message || "Une erreur est survenue.");
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/ressource/convention")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="rounded-lg p-2 w-full sm:p-4">
          <div className="w-full flex flex-col">
            <label className="block text-xs mt-5 font-[700] first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
              Convention
            </label>

            <label
              className={`w-full border border-gray-300 block cursor-pointer outline-none focus:border-[#3ad12c] rounded-md p-2.5 bg-transparent`}
              ref={inputRefs.image}
              htmlFor="image"
            >
              Photo de couverture
            </label>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.gif"
              className="w-full hidden mt-1"
              id="image"
              onChange={(e) => handleInputChange("image", e.target.files[0])}
            />
            {convention.preview ? (
              <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                <img
                  src={convention.preview}
                  alt="Aperçu"
                  className="object-cover w-full h-full"
                />
              </div>
            ) : (
              <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                <img
                  src={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.photoCouverture}`}
                  alt={convention.titre}
                  className="object-cover w-full h-full"
                />
              </div>
            )}

            <label
              className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
              htmlFor="titre"
            >
              Titre
            </label>
            <input
              type="text"
              id="titre"
              value={convention.titre}
              onChange={(e) => handleInputChange("titre", e.target.value)}
              placeholder="Le titre"
              className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
              ref={inputRefs.titre}
            />

            <label
              className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              id="description"
              value={convention.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              placeholder="Description"
              className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
              ref={inputRefs.description}
            ></textarea>

            <label
              className="w-full border border-gray-300 block cursor-pointer outline-none focus:border-[#3ad12c] rounded-md p-2.5 bg-transparent"
              ref={inputRefs.pdf}
              htmlFor="pdf"
            >
              Document PDF
            </label>
            <input
              type="file"
              accept=".pdf"
              className="w-full hidden mt-1"
              id="pdf"
              onChange={(e) => handleInputChange("pdf", e.target.files[0])}
            />
            <input
              type="file"
              accept=".pdf"
              className="w-full hidden mt-1"
              id="pdf"
              onChange={(e) => handleInputChange("pdf", e.target.files[0])}
            />

            {convention.pdf ? (
              <iframe
                src={URL.createObjectURL(convention.pdf)} // Utilise l'URL créé à partir du fichier
                title="Document PDF"
                width="100%"
                height="400px"
                className="border-2 border-gray-300 rounded-md"
              ></iframe>
            ) : (
              <iframe
                src={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.attachement}`}
                title="Attachement"
                width="100%"
                height="400px"
                className="border-2 border-gray-300 rounded-md"
              ></iframe>
            )}

            <label
              className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
              htmlFor="type"
            >
              Type de convention
            </label>
            <select
              id="type"
              value={convention.type}
              onChange={(e) => handleInputChange("type", e.target.value)}
              className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
              ref={inputRefs.type}
            >
              <option hidden value="">
                Sélectionne le type de convention
              </option>
              <option className="text-black" value="Local">
                Local
              </option>
              <option className="text-black" value="Régional">
                Régional
              </option>
              <option className="text-black" value="National">
                National
              </option>
              <option className="text-black" value="International">
                International
              </option>
              <option className="text-black" value="Global">
                Global
              </option>
              <option className="text-black" value="autre">
                Autre
              </option>
            </select>
          </div>
        </div>

        <div className="flex justify-end w-full p-3 sm:px-10">
          <button
            type="submit"
            className="bg-[#3ad12c] rounded-md text-sm px-3 py-2 text-white sm:text-base"
          >
            Mettre à jour
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminConventionModifiier;
