import React from "react";
import AdminSlide from "../AdminSlide/AdminSlide";
import AjouterCoursAdmin from "./AjouterCoursAdmin";

function AjouterCoursAdminComponent() {
  return (
    <div className="h-full w-full flex overflow-hidden">
      <AdminSlide />
      <AjouterCoursAdmin />
    </div>
  );
}

export default AjouterCoursAdminComponent;
