import React, { useEffect, useState } from "react";
import { Progress } from "rsuite";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";

import AdminProjetNavBars from "./AdminProjetNavBars";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { decryptData } from "../../../encryptionModule";

function AdminProjetDetail() {
  const { projetRef, isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [projectData, setProjectData] = useState(null);
  const { id } = useParams();
  const projetId = decryptData(id);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/projet/${projetId}`
        );
        const data = await response.json();
        setProjectData(data);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectData();
  }, [projetId]);

  if (!projectData) return <div className="text-center">Loading...</div>;

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminProjetNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/projet")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>
      <div
        className={`${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } pb-5 m-1 rounded`}
      >
        <div className="mx-auto p-8">
          <div className="space-y-4">
            <div className="w-full flex items-center text-xl gap-3 font-bold p-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
              <div>{projectData.titre}</div>
            </div>
          </div>
        </div>

        <div ref={projetRef} className="mx-auto px-4">
          <div className="rounded-md p-4">
            <h2 className="text-3xl font-semibold mb-2 text-[#3ad12c]">
              {projectData.titre}
            </h2>
            <img
              src={`https://imuhirabackend.eac-advisor.org/uploads/projet/${projectData.photoCouverture}`}
              alt={projectData.titre}
              className="rounded-md sm:w-[50em] mb-2 h-max"
            />
            <div className="flex text-lg">
              <span className="font-bold">Déscription</span> :{" "}
              {projectData.description}
            </div>
            <h3 className="text-xl font-semibold">Informations de contact:</h3>
            <p>Téléphone: {projectData.tel}</p>
            <p>Email: {projectData.email}</p>
            <p>Progression: {projectData.progression}%</p>
            <p>Validé: {projectData.valide ? "Oui" : "Non"}</p>
          </div>
          <div>
            <Progress.Line
              percent={projectData.progression}
              strokeWidth={20}
              strokeColor="#3ad12c"
            />
            <div className="pl-3 text-lg">
              <span className="font-bold">Progression</span>:
              <span className="ml-2">{projectData.progression}%</span>
            </div>
          </div>
          <p>
            message de demande du soutient:{" "}
            {projectData.messagesoutient && projectData.messagesoutient.text}
          </p>
          <p>
            message de demande d'etre sponsor:{" "}
            {projectData.messagesponsor && projectData.messagesponsor.text}
          </p>
          <p>
            message de demande du don materiel:{" "}
            {projectData.messagedonmateriel &&
              projectData.messagedonmateriel.text}
          </p>
          <p>
            Message du demande de don financier: {projectData.messageDeContact}
          </p>
          <div className="rounded-lg p-4">
            <div className="mb-6">
              <div className="text-lg font-semibold mb-2">
                Impact du projet :
              </div>
              {projectData.impacts && projectData.impacts.length > 0 ? (
                <ul className="list-disc pl-6 space-y-2 mx-5">
                  {projectData.impacts.map((impact) => (
                    <li className="text-lg" key={impact.id}>
                      {impact.titre}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Aucun impact trouvé.</p>
              )}
            </div>
            <div className="mb-6">
              <div className="text-lg font-semibold mb-2">
                Objectifs et résultats attendus :
              </div>
              {projectData.objectifprojets &&
              projectData.objectifprojets.length > 0 ? (
                <ul className="list-disc pl-6 space-y-2 mx-5">
                  {projectData.objectifprojets.map((objectif) => (
                    <li className="text-lg" key={objectif.id}>
                      {objectif.titre}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Aucun objectif trouvé.</p>
              )}
            </div>
          </div>
          <div className="rounded-lg p-4">
            <div className="mb-6">
              <div className="text-lg font-semibold mb-2">
                Devenir bénévoles:
              </div>
              {projectData.devenir_benevoles &&
              projectData.devenir_benevoles.length > 0 ? (
                <div className="list-disc pl-6 space-y-2 mx-5">
                  {projectData.devenir_benevoles.map((benevole) => (
                    <div key={benevole.id} className="text-lg">
                      {benevole.description}
                      {benevole.benevol_aides &&
                      benevole.benevol_aides.length > 0 ? (
                        <ul className="list-disc list-inside pl-6">
                          {benevole.benevol_aides.map((aide) => (
                            <li key={aide.id}>{aide.message}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>Aucune aide trouvée.</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p>Aucun devenir bénévole trouvé.</p>
              )}
            </div>

            <div className="mb-6">
              <div className="text-lg font-semibold mb-2">
                Impacts du soutien:
              </div>
              {projectData.impactdusoutiens &&
              projectData.impactdusoutiens.length > 0 ? (
                <ul className="list-disc pl-6 space-y-2 mx-5">
                  {projectData.impactdusoutiens.map((impact) => (
                    <li key={impact.id} className="text-lg">
                      {impact.titre}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Aucun impact du soutien trouvé.</p>
              )}
            </div>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Partenaires:</h3>
              {projectData.partenaires && projectData.partenaires.length > 0 ? (
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {projectData.partenaires.map((partenaire) => (
                    <div key={partenaire.id} className="text-center mb-2">
                      <p>{partenaire.nom}</p>
                      <img
                        className="w-20 h-20 mx-auto rounded-full"
                        src={`https://imuhirabackend.eac-advisor.org/uploads/projet/${partenaire.logo}`}
                        alt={partenaire.nom}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p>Aucun partenaire trouvé.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AdminProjetDetail;
