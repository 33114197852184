import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminODDModifier from './AdminODDModifier'

function AdminODDModifierComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminODDModifier />
        </div>
    )
}

export default AdminODDModifierComponent