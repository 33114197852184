/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminProjetNavBars from "./AdminProjetNavBars";
import axios from "axios";
function AdemailProjetAjouter() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", hundlesSize);
    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", hundleSize);
    return () => {
      window.removeEventListener("resize", hundleSize);
    };
  }, []);

  const [projets, setBlocs] = useState([
    {
      image: "",
      titre: "",
      association_responsable: "",
      email: "",
      progression: "",
      telephone: "",
      descript: "",
      message: "",
      messagedonmateriel: "",
      messagesoutient: "",
      messagesponsor: "",
      partenaire: [
        {
          nom_complet: "",
          profil: "",
          preview: "",
        },
      ],
      impact: [
        {
          sous_titre: "",
        },
      ],

      objectif: [
        {
          sous_titre: "",
        },
      ],
      benevole: [
        {
          message: "",
          liste: [
            {
              sous_titre: "",
            },
          ],
        },
      ],
      impact_soutien: [
        {
          sous_titre: "",
        },
      ],
      preview: "",
    },
  ]);

  const addBloc = () => {
    setBlocs((prevBlocs) => [
      ...prevBlocs,
      {
        image: "",
        titre: "",
        association_responsable: "",
        email: "",
        telephone: "",
        descript: "",
        message: "",
        progression: "",
        messagedonmateriel: "",
        messagesoutient: "",
        messagesponsor: "",
        partenaire: [
          {
            nom_complet: "",
            profil: "",
            preview: "",
          },
        ],
        impact: [
          {
            sous_titre: "",
          },
        ],

        objectif: [
          {
            sous_titre: "",
          },
        ],
        benevole: [
          {
            message: "",
            liste: [
              {
                sous_titre: "",
              },
            ],
          },
        ],
        impact_soutien: [
          {
            sous_titre: "",
          },
        ],
        preview: "",
      },
    ]);

    if (
      inputRefs.current[projets.length] &&
      inputRefs.current[projets.length].partenaire.length === 0
    ) {
      inputRefs.current[projets.length].partenaire.push({
        nom_complet: React.createRef(),
        profil: React.createRef(),
      });
    }
    if (
      inputRefs.current[projets.length] &&
      inputRefs.current[projets.length].impact.length === 0
    ) {
      inputRefs.current[projets.length].impact.push({
        sous_titre: React.createRef(),
      });
    }
    if (
      inputRefs.current[projets.length] &&
      inputRefs.current[projets.length].objectif.length === 0
    ) {
      inputRefs.current[projets.length].objectif.push({
        sous_titre: React.createRef(),
      });
    }

    if (
      inputRefs.current[projets.length] &&
      inputRefs.current[projets.length].benevole.length === 0
    ) {
      inputRefs.current[projets.length].benevole.push({
        message: React.createRef(),
        liste: [
          {
            sous_titre: React.createRef(),
          },
        ],
      });
    }

    if (
      inputRefs.current[projets.length] &&
      inputRefs.current[projets.length].impact_soutien.length === 0
    ) {
      inputRefs.current[projets.length].impact_soutien.push({
        sous_titre: React.createRef(),
      });
    }
  };

  const removeBloc = (index) => {
    const newBlocs = [...projets];
    newBlocs.splice(index, 1);
    setBlocs(newBlocs);
  };
  const inputRefs = useRef([]);

  if (inputRefs.current.length !== projets.length) {
    inputRefs.current = Array(projets.length)
      .fill({})
      .map((_, index) => ({
        image: React.createRef(null),
        titre: React.createRef(null),
        association_responsable: React.createRef(null),
        email: React.createRef(null),
        telephone: React.createRef(null),
        descript: React.createRef(null),
        message: React.createRef(null),
        progression: React.createRef(null),
        messagedonmateriel: React.createRef(null),
        messagesoutient: React.createRef(null),
        messagesponsor: React.createRef(null),
        partenaire: Array(projets[index].partenaire.length)
          .fill({})
          .map((__, tableIndex) => ({
            nom_complet: React.createRef(null),
            profil: React.createRef(null),
          })),
        impact: Array(projets[index].impact.length)
          .fill({})
          .map((__, tableIndex) => ({
            sous_titre: React.createRef(null),
          })),

        objectif: Array(projets[index].objectif.length)
          .fill({})
          .map((__, tableIndex) => ({
            sous_titre: React.createRef(null),
          })),

        benevole: Array(projets[index].benevole.length)
          .fill({})
          .map((__, tableIndex) => ({
            message: React.createRef(null),
            liste: Array(projets[index].benevole[tableIndex].liste.length)
              .fill({})
              .map((__, tableIndexOption) => ({
                sous_titre: React.createRef(null),
              })),
          })),

        impact_soutien: Array(projets[index].impact_soutien.length)
          .fill({})
          .map((__, tableIndex) => ({
            sous_titre: React.createRef(null),
          })),
      }));
  }
  const handleInputChange = (index, field, value) => {
    const newBlocs = [...projets];
    if (field === "image") {
      newBlocs[index].preview = URL.createObjectURL(value);
      newBlocs[index].image = value;
    } else {
      newBlocs[index][field] = value;
    }
    setBlocs(newBlocs);
  };

  // ...........................Partenariat................
  const AddPartenaire = (index) => {
    const newBlocs = [...projets];
    newBlocs[index].partenaire.push({
      nom_complet: "",
      profil: "",
      preview: "",
    });
    setBlocs(newBlocs);
    if (
      inputRefs.current[index].partenaire.length !==
      newBlocs[index].partenaire.length
    ) {
      inputRefs.current[index].partenaire.push({
        nom_complet: React.createRef(),
        profil: React.createRef(),
      });
    }
  };
  const removePartenaire = (blocIndex, tableIndex) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].partenaire.splice(tableIndex, 1);
    inputRefs.current[blocIndex].partenaire.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const handlePartinaireInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...projets];
    if (field === "profil") {
      newBlocs[blocIndex].partenaire[tableIndex].preview =
        URL.createObjectURL(value);
      newBlocs[blocIndex].partenaire[tableIndex].profil = value;
    } else {
      newBlocs[blocIndex].partenaire[tableIndex][field] = value;
    }
    setBlocs(newBlocs);
  };

  // ...........................Impact................
  const AddImpact = (index) => {
    const newBlocs = [...projets];
    newBlocs[index].impact.push({ sous_titre: "" });
    setBlocs(newBlocs);
    if (
      inputRefs.current[index].impact.length !== newBlocs[index].impact.length
    ) {
      inputRefs.current[index].impact.push({
        sous_titre: React.createRef(),
      });
    }
  };
  const removeImpact = (blocIndex, tableIndex) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].impact.splice(tableIndex, 1);
    inputRefs.current[blocIndex].impact.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const handleImpactInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].impact[tableIndex][field] = value;
    setBlocs(newBlocs);
  };

  // ........................................Objectif..................
  const AddObjectif = (index) => {
    const newBlocs = [...projets];
    newBlocs[index].objectif.push({ sous_titre: "" });
    setBlocs(newBlocs);
    if (
      inputRefs.current[index].objectif.length !==
      newBlocs[index].objectif.length
    ) {
      inputRefs.current[index].objectif.push({
        sous_titre: React.createRef(),
      });
    }
  };
  const removeObjectif = (blocIndex, tableIndex) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].objectif.splice(tableIndex, 1);
    inputRefs.current[blocIndex].objectif.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const handleObjectifInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].objectif[tableIndex][field] = value;
    setBlocs(newBlocs);
  };

  // ....................................Benevole......................................
  const AddBenevole = (index) => {
    const newBlocs = [...projets];
    newBlocs[index].benevole.push({ message: "", liste: [{ sous_titre: "" }] });
    setBlocs(newBlocs);
    if (
      inputRefs.current[index].benevole.length !==
      newBlocs[index].benevole.length
    ) {
      inputRefs.current[index].benevole.push({
        message: React.createRef(),
        liste: [
          {
            sous_titre: React.createRef(),
          },
        ],
      });
    }
  };

  const removeBenevole = (blocIndex, tableIndex) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].benevole.splice(tableIndex, 1);
    inputRefs.current[blocIndex].benevole.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const handleBenevoleInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].benevole[tableIndex][field] = value;
    setBlocs(newBlocs);
  };
  function handleBenevoleListeInputChange(
    blocIndex,
    tableIndex,
    optionIndex,
    field,
    value
  ) {
    const newBlocs = [...projets];
    newBlocs[blocIndex].benevole[tableIndex].liste[optionIndex][field] = value;
    setBlocs(newBlocs);
  }
  const addBenevoleListe = (blocIndex, moduleIndex) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].benevole[moduleIndex].liste.push({
      choix: "",
    });
    setBlocs(newBlocs);
    // Synchroniser les références dans inputRefs pour les nouvelles options
    if (
      inputRefs.current[blocIndex].benevole[moduleIndex].liste.length !==
      newBlocs[blocIndex].benevole[moduleIndex].liste.length
    ) {
      inputRefs.current[blocIndex].benevole[moduleIndex].liste.push({
        sous_titre: React.createRef(),
      });
    }
  };
  const removeBenevoleListe = (blocIndex, moduleIndex, optionIndex) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].benevole[moduleIndex].liste.splice(optionIndex, 1);
    setBlocs(newBlocs);
    if (
      inputRefs.current[blocIndex].benevole[moduleIndex].liste.length >
      optionIndex
    ) {
      inputRefs.current[blocIndex].benevole[moduleIndex].liste.splice(
        optionIndex,
        1
      );
    }
  };

  // ...........................Impact_soutien................
  const AddImpact_soutien = (index) => {
    const newBlocs = [...projets];
    newBlocs[index].impact_soutien.push({ sous_titre: "" });
    setBlocs(newBlocs);
    if (
      inputRefs.current[index].impact_soutien.length !==
      newBlocs[index].impact_soutien.length
    ) {
      inputRefs.current[index].impact_soutien.push({
        sous_titre: React.createRef(),
      });
    }
  };
  const removeImpact_soutien = (blocIndex, tableIndex) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].impact_soutien.splice(tableIndex, 1);
    inputRefs.current[blocIndex].impact_soutien.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const handleImpact_soutienInputChange = (
    blocIndex,
    tableIndex,
    field,
    value
  ) => {
    const newBlocs = [...projets];
    newBlocs[blocIndex].impact_soutien[tableIndex][field] = value;
    setBlocs(newBlocs);
  };

  // ....................................................................................................

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const valiassociation_responsableBloc = (bloc, index) => {
    const requiredFields = [
      "image",
      "titre",
      "association_responsable",
      "email",
      "telephone",
      "descript",
      "progression",
      "messagedonmateriel",
      "messagesoutient",
      "messagesponsor",
    ];
    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "image"
                ? "image  du projets"
                : field === "titre"
                ? "titre du projets "
                : field === "association_responsable"
                ? "association_responsable du projets "
                : field === "email"
                ? "emailute du projets "
                : field === "telephone"
                ? "telephone du projets "
                : field === "jours"
                ? "telephone du projets "
                : field === "progression"
                ? "progression du projets "
                : field === "semaines"
                ? "semaines du projets "
                : field === "mois"
                ? "mois du projets "
                : field === "annee"
                ? "annee du projets "
                : field === "descript"
                ? "déscription du projets "
                : field === "niveaux"
                ? "niveaux du projets"
                : field === "messagedonmateriel"
                ? "message don materiel du projets "
                : field === "messagesoutient"
                ? "message soutient du projets"
                : field === "messagesponsor"
                ? "messages ponsor du projets"
                : null}
            </strong>{" "}
            est obligatoire à la block {index + 1}.
          </div>
        );
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.classList.add(
            "animate__animated",
            "animate__shakeX",
            "border-2",
            "border-red-500",
            "outline-none"
          );
          setTimeout(() => {
            inputRefs.current[index][field].current.classList.remove(
              "animate__animated",
              "animate__shakeX",
              "border-2",
              "border-red-500",
              "outline-none"
            );
          }, 5000);
          // inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          inputRefs.current[index][field].current.focus();
          return false;
        }
      }
      animateAndScrollToRef(inputRefs.current[index].titreMateriel);
    }
    return true;
  };

  function toRoman(num) {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
      "XXI",
      "XXII",
      "XXIII",
      "XXIV",
      "XXV",
      "XXVI",
      "XXVII",
      "XXVIII",
      "XXIX",
      "XXX",
    ];
    return romanNumerals[num - 1] || num.toString();
  }
  const utilisateurId = localStorage.getItem("userId");
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Boucle sur chaque projet
    projets.forEach((projet, index) => {
      // Valider chaque projet
      if (!valiassociation_responsableBloc(projet, index)) {
        return;
      }

      // Ajouter les données du projet à FormData
      formData.append(`projets[${index}][titre]`, projet.titre);
      formData.append(
        `projets[${index}][association_responsable]`,
        projet.association_responsable
      );

      formData.append(`projets[${index}][email]`, projet.email);
      formData.append(`projets[${index}][telephone]`, projet.telephone);
      formData.append(`projets[${index}][descript]`, projet.descript);
      formData.append(`projets[${index}][message]`, projet.message);
      formData.append(
        `projets[${index}][messagesoutient]`,
        projet.messagesoutient
      );
      formData.append(
        `projets[${index}][messagesponsor]`,
        projet.messagesponsor
      );
      formData.append(
        `projets[${index}][messagedonmateriel]`,
        projet.messagedonmateriel
      );
      formData.append(`projets[${index}][progression]`, projet.progression);
      formData.append("utilisateurId", utilisateurId);
      // Ajouter l'image et le profil (fichiers)
      if (projet.image) {
        formData.append(`projets[${index}][image]`, projet.image);
      }
      projet.partenaire.forEach((partenaire, pIndex) => {
        if (partenaire.profil) {
          formData.append(
            `projets[${index}][partenaire][${pIndex}][profil]`,
            partenaire.profil
          );
        }
        formData.append(
          `projets[${index}][partenaire][${pIndex}][nom_complet]`,
          partenaire.nom_complet
        );
      });

      // Ajouter les sous-titres des sections
      projet.impact.forEach((impact, iIndex) => {
        formData.append(
          `projets[${index}][impact][${iIndex}][sous_titre]`,
          impact.sous_titre
        );
      });

      projet.objectif.forEach((objectif, oIndex) => {
        formData.append(
          `projets[${index}][objectif][${oIndex}][sous_titre]`,
          objectif.sous_titre
        );
      });

      projet.benevole.forEach((benevole, bIndex) => {
        formData.append(
          `projets[${index}][benevole][${bIndex}][message]`,
          benevole.message
        );
        benevole.liste.forEach((item, lIndex) => {
          formData.append(
            `projets[${index}][benevole][${bIndex}][liste][${lIndex}][sous_titre]`,
            item.sous_titre
          );
        });
      });

      projet.impact_soutien.forEach((impactSoutien, sIndex) => {
        formData.append(
          `projets[${index}][impact_soutien][${sIndex}][sous_titre]`,
          impactSoutien.sous_titre
        );
      });
    });

    // Envoyer les données au backend
    try {
      const response = await axios.post(
        "https://imuhirabackend.eac-advisor.org/apiv1/projet",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Le projet est enregistré avec succès");
      navig("/projet");
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'enregistrement du projet");
      console.error("Erreur lors de l'envoi des données:", error);
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminProjetNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/projet")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4  rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        }  flex-col items-center`}
      >
        <div className="rounded-lg p-2   w-full   sm:p-4">
          {projets.map((bloc, index) => (
            <div
              key={index}
              className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2  flex flex-col"
            >
              <div className="w-full">
                <div className="h-max w-full">
                  <div className="px-3 w-full">
                    <div className="w-full flex items_center justify-between">
                      <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                        {toRoman(index + 1)}. projets
                      </label>
                      {index > 0 && (
                        <button
                          type="button"
                          className="text-red-500 px-1 max-sm:text-xs py-2"
                          onClick={() => removeBloc(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <div className="rounded-xl p-2 w-full">
                  <div className="flex flex-col">
                    <div className="mb-2">
                      <div className="flex flex-col items-center">
                        <label
                          className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                          ref={inputRefs.current[index].image}
                          htmlFor={`id_photo_${index}`}
                        >
                          Photos de couverture
                        </label>
                        <input
                          type="file"
                          accept=".jpeg,.png,.jpg,.gif"
                          className="w-full hidden mt-1"
                          id={`id_photo_${index}`}
                          onChange={(e) =>
                            handleInputChange(index, "image", e.target.files[0])
                          }
                        />
                        <div className="flex w-full justify-start">
                          {bloc.preview && (
                            <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                              <img
                                src={bloc.preview}
                                alt={`Aperçu ${index}`}
                                className="object-cover w-full h-full"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor={`titre_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Titre du projets
                      </label>
                      <input
                        email="text"
                        id={`titre_${index}`}
                        value={bloc.titre}
                        onChange={(e) =>
                          handleInputChange(index, "titre", e.target.value)
                        }
                        placeholder="Le  titre  du projets"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].titre}
                      />
                    </div>
                    <div className="flex flex-col ">
                      <label
                        htmlFor={`association_responsable_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Association responsable
                      </label>
                      <input
                        type="text"
                        id={`association_responsable_${index}`}
                        value={bloc.association_responsable}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "association_responsable",
                            e.target.value
                          )
                        }
                        placeholder="Association responsable"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].association_responsable}
                      />
                    </div>
                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`descript_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Déscription
                      </label>
                      <textarea
                        email="text"
                        id={`descript_${index}`}
                        value={bloc.descript}
                        onChange={(e) =>
                          handleInputChange(index, "descript", e.target.value)
                        }
                        placeholder="Déscription"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].descript}
                      ></textarea>
                    </div>
                    <div className="flex flex-col ">
                      <label
                        htmlFor={`Progression_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Progression
                      </label>
                      <input
                        type="text"
                        id={`Progression_${index}`}
                        value={bloc.progression}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "progression",
                            e.target.value
                          )
                        }
                        placeholder="Progression"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].progression}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="flex flex-col ">
                        <label
                          htmlFor={`email_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          id={`email_${index}`}
                          value={bloc.email}
                          onChange={(e) =>
                            handleInputChange(index, "email", e.target.value)
                          }
                          placeholder="Email"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2  outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].email}
                        />
                      </div>

                      <div className="flex flex-col ">
                        <label
                          htmlFor={`telephone_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Téléphone
                        </label>
                        <input
                          type="text"
                          id={`telephone_${index}`}
                          value={bloc.telephone}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "telephone",
                              e.target.value
                            )
                          }
                          placeholder="Téléphone"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].telephone}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`messagesoutient_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        message de soutient
                      </label>
                      <textarea
                        id={`messagesoutient_${index}`}
                        value={bloc.messagesoutient}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "messagesoutient",
                            e.target.value
                          )
                        }
                        placeholder="Message du demande du soutient"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].messagesoutient}
                      ></textarea>
                    </div>
                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`messagedonmateriel_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Message don materiel
                      </label>
                      <textarea
                        id={`messagedonmateriel_${index}`}
                        value={bloc.messagedonmateriel}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "messagedonmateriel",
                            e.target.value
                          )
                        }
                        placeholder="Message don materiel"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].messagedonmateriel}
                      ></textarea>
                    </div>
                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`message_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Message de domande du don financier
                      </label>
                      <textarea
                        id={`message_${index}`}
                        value={bloc.message}
                        onChange={(e) =>
                          handleInputChange(index, "message", e.target.value)
                        }
                        placeholder="Message de domande du don financier"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].message}
                      ></textarea>
                    </div>

                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`messagesponsor_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Message pour ponsors
                      </label>
                      <textarea
                        id={`messagesponsor_${index}`}
                        value={bloc.message}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "messagesponsor",
                            e.target.value
                          )
                        }
                        placeholder="message pour ponsors"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].messagesponsor}
                      ></textarea>
                    </div>
                    <div className="flex flex-col mb-10">
                      {bloc.partenaire.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="relative mt-2 text-lg">
                            {toRoman(tableIndex + 1)}.partenaire{" "}
                            <span className="pl-2 font-bold">{bloc.titre}</span>{" "}
                          </div>
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-2">
                                    <label
                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                      htmlFor={`titre_${index}_${tableIndex}`}
                                    >
                                      Nom complet
                                    </label>
                                    <input
                                      ref={
                                        inputRefs.current[index].partenaire[
                                          tableIndex
                                        ].nom_complet
                                      }
                                      className="appearance-none block  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.nom_complet}
                                      onChange={(e) =>
                                        handlePartinaireInputChange(
                                          index,
                                          tableIndex,
                                          "nom_complet",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Nom complet"
                                      id={`titre_${index}_${tableIndex}`}
                                    />
                                  </div>
                                </div>

                                <div className="mb-2">
                                  <div className="flex flex-col items-center">
                                    <label
                                      className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                                      ref={
                                        inputRefs.current[index].partenaire[
                                          tableIndex
                                        ].profil
                                      }
                                      htmlFor={`Profil_${index}_${tableIndex}`}
                                    >
                                      Profil
                                    </label>
                                    <input
                                      type="file"
                                      accept=".jpeg,.png,.jpg,.gif"
                                      className="w-full hidden mt-1"
                                      id={`Profil_${index}_${tableIndex}`}
                                      onChange={(e) =>
                                        handlePartinaireInputChange(
                                          index,
                                          tableIndex,
                                          "profil",
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                    <div className="flex w-full justify-start">
                                      {table.preview && (
                                        <div className="w-[5em] mt-[2.5em] overflow-hidden relative rounded-lg h-[5em] border border-gray-300">
                                          <img
                                            src={table.preview}
                                            alt={`Aperçu ${index}`}
                                            className="object-cover w-full h-full"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {bloc.partenaire.length > 1 && (
                              <div className="flex justify-end">
                                <div
                                  montant="button"
                                  className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                  onClick={() =>
                                    removePartenaire(index, tableIndex)
                                  }
                                >
                                  Supprimer
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => AddPartenaire(index)}
                        >
                          Autre partenaire
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="relative mt-2 text-lg">
                        Impact du projet
                      </div>
                      {bloc.impact.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg flex w-full">
                                  <div className="w-full mb-2">
                                    <input
                                      ref={
                                        inputRefs.current[index].impact[
                                          tableIndex
                                        ].sous_titre
                                      }
                                      className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.sous_titre}
                                      onChange={(e) =>
                                        handleImpactInputChange(
                                          index,
                                          tableIndex,
                                          "sous_titre",
                                          e.target.value
                                        )
                                      }
                                      placeholder={`${tableIndex + 1}.Titre`}
                                      id={`sous_titre_${index}_${tableIndex}`}
                                    />
                                  </div>
                                  {bloc.impact.length > 1 && (
                                    <div className="flex justify-end">
                                      <div
                                        montant="button"
                                        className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                        onClick={() =>
                                          removeImpact(index, tableIndex)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash3-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => AddImpact(index)}
                        >
                          Autre Impact
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="relative mt-2 text-lg">
                        Objectifs et résultats attendus
                      </div>
                      {bloc.objectif.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg flex w-full">
                                  <div className="w-full mb-2">
                                    <input
                                      ref={
                                        inputRefs.current[index].objectif[
                                          tableIndex
                                        ].sous_titre
                                      }
                                      className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.sous_titre}
                                      onChange={(e) =>
                                        handleObjectifInputChange(
                                          index,
                                          tableIndex,
                                          "sous_titre",
                                          e.target.value
                                        )
                                      }
                                      placeholder={`${tableIndex + 1}.Titre`}
                                      id={`sous_titre_${index}_${tableIndex}`}
                                    />
                                  </div>
                                  {bloc.objectif.length > 1 && (
                                    <div className="flex justify-end">
                                      <div
                                        montant="button"
                                        className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                        onClick={() =>
                                          removeObjectif(index, tableIndex)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash3-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => AddObjectif(index)}
                        >
                          Autre objectif
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="relative mt-2 text-lg">
                        Devenir bénévole
                      </div>
                      {bloc.benevole.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg w-full">
                                  <div>Message</div>
                                  <div className="w-full mb-2">
                                    <input
                                      ref={
                                        inputRefs.current[index].benevole[
                                          tableIndex
                                        ].message
                                      }
                                      className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.message}
                                      onChange={(e) =>
                                        handleBenevoleInputChange(
                                          index,
                                          tableIndex,
                                          "message",
                                          e.target.value
                                        )
                                      }
                                      placeholder={`${tableIndex + 1}.message`}
                                      id={`message_${index}_${tableIndex}`}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <div className="w-full mb-2">
                                    <label
                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                      htmlFor={`titre_${index}_${tableIndex}`}
                                    >
                                      Liste:
                                    </label>
                                    <div className="ml-10">
                                      {table.liste.map((data, index_liste) => (
                                        <div className="flex items-center mb-5">
                                          <div className="text-lg mr-1">
                                            {index_liste + 1}.
                                          </div>
                                          <div className="w-full">
                                            <input
                                              placeholder="Question à choisir"
                                              className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2"
                                              value={data.sous_titre}
                                              ref={
                                                inputRefs.current[index]
                                                  .benevole[tableIndex].liste[
                                                  index_liste
                                                ].sous_titre
                                              }
                                              onChange={(e) =>
                                                handleBenevoleListeInputChange(
                                                  index,
                                                  tableIndex,
                                                  index_liste,
                                                  "sous_titre",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          {index_liste > 0 && (
                                            <div
                                              onClick={() =>
                                                removeBenevoleListe(
                                                  index,
                                                  tableIndex,
                                                  index_liste
                                                )
                                              }
                                              className="border-red-500 border cursor-pointer block p-2 ml-2 rounded-md"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="currentColor"
                                                class="bi text-red-700  bi-trash3-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                              </svg>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="flex justify-end pl-[4%] w-full">
                                      <div
                                        className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                        onClick={() =>
                                          addBenevoleListe(index, tableIndex)
                                        }
                                      >
                                        Autre liste
                                      </div>
                                    </div>
                                    <hr />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {bloc.benevole.length > 1 && (
                              <div className="flex justify-end">
                                <div
                                  montant="button"
                                  className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                  onClick={() =>
                                    removeBenevole(index, tableIndex)
                                  }
                                >
                                  Supprimer
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => AddBenevole(index)}
                        >
                          Autre bénévol
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="relative mt-2 text-lg">
                        Impact de votre soutien
                      </div>
                      {bloc.impact_soutien.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg flex w-full">
                                  <div className="w-full mb-2">
                                    <input
                                      className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      placeholder={`${tableIndex + 1}.Titre`}
                                      id={`sous_titre_${index}_${tableIndex}`}
                                      onChange={(e) =>
                                        handleImpact_soutienInputChange(
                                          index,
                                          tableIndex,
                                          "sous_titre",
                                          e.target.value
                                        )
                                      }
                                      value={table.sous_titre}
                                      ref={
                                        inputRefs.current[index].impact_soutien[
                                          tableIndex
                                        ].sous_titre
                                      }
                                    />
                                  </div>
                                  {tableIndex > 0 && (
                                    <div className="flex justify-end">
                                      <div
                                        montant="button"
                                        className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                        onClick={() =>
                                          removeImpact_soutien(
                                            index,
                                            tableIndex
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash3-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => AddImpact_soutien(index)}
                        >
                          Autre Impact de soutien
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}

          <div className="w-full flex justify-start">
            <div
              className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
              onClick={addBloc}
            >
              Ajouter projets
            </div>
          </div>
        </div>
        <div className="flex justify-end my-5  w-[98%]">
          <button
            type="submit"
            disabled={boutLoading}
            htmlFor="send"
            className="mt-3 cursor-pointer w-max flex justify-end bg-[#3ad12c] p-2 rounded text-white"
          >
            Enregistre
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdemailProjetAjouter;
