import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer } from "rsuite";
import DonationForm from "./DonationForm";
import SponsorForm from "./SponsorForm";
import EquipmentDonation from "./EquipmentDonation";
import Temoign from "./Temoign";
import { decryptData } from "../../../encryptionModule";

function Soutenir() {
  const { isDark } = useThemes();
  const navig = useNavigate();

  const [isDonantion, SetisDonantion] = useState(false);
  const [isSponsor, SetisSponsor] = useState(false);
  const [isTemoign, SetisTemoign] = useState(false);
  const [isEquipmentDonation, SetisEquipmentDonation] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const { id } = useParams();
  const projetId = decryptData(id);
  console.log(projetId);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/projet/${projetId}`
        );
        const data = await response.json();
        setProjectData(data);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectData();
  }, [projetId]);

  return (
    <div className=" mt-[18vh] w-full  px-4  overflow-hidden">
      <div
        onClick={() => navig("/projet")}
        className="w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-arrow-left-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
          />
        </svg>
      </div>
      <section
        className={`w-full mx-auto  rounded-lg ${
          isDark === "dark" ? null : "bg-white"
        }  p-8`}
      >
        <h2 data-aos="fade-up" className="text-2xl font-semibold mb-6">
          Comment soutenir le Projet {projectData && projectData.titre}
        </h2>

        <p data-aos="fade-up" className=" mb-4">
          {projectData &&
            projectData.messagesoutient &&
            projectData.messagesoutient.text}
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
          <div
            data-aos="fade-up"
            className="border p-6 rounded-lg hover:shadow-lg transition-shadow duration-300"
          >
            <h3 className="text-lg font-semibold mb-4">Faire un don</h3>
            <p className=" mb-4">
              {projectData && projectData.messageDeContact}
            </p>
            <button
              onClick={() => SetisDonantion(true)}
              className="bg-green-400 text-white py-2 px-4 rounded-lg hover:bg-[#3ad12c] "
            >
              Faire un don immédiat
            </button>
          </div>

          <div
            data-aos="fade-up"
            className="border p-6 rounded-lg hover:shadow-lg transition-shadow duration-300"
          >
            <h3 className="text-lg font-semibold mb-4">Devenir sponsor</h3>
            <p className=" mb-4">
              {projectData &&
                projectData.messagesponsor &&
                projectData.messagesponsor.text}
            </p>
            <button
              onClick={() => SetisSponsor(true)}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            >
              Devenir sponsor
            </button>
          </div>
        </div>

        <div
          data-aos="fade-up"
          className={` p-6 rounded-lg ${
            isDark === "dark" ? null : "bg-gray-100"
          } mb-6`}
        >
          <h3 className="text-lg font-semibold mb-4">Don de matériel</h3>
          <p className="mb-4">
            {projectData &&
              projectData.messagedonmateriel &&
              projectData.messagedonmateriel.text}
          </p>
          <button
            onClick={() => SetisEquipmentDonation(true)}
            className="bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600"
          >
            Faire un don de matériel
          </button>
        </div>

        <div data-aos="fade-up" className="mb-6">
          <h3 className="text-lg font-semibold mb-4">
            Impact de votre soutien
          </h3>
          <ul className="list-disc list-inside ">
            {projectData &&
            projectData.impactdusoutiens &&
            projectData.impactdusoutiens.length > 0 ? (
              <ul className="list-disc pl-6 space-y-2 mx-5">
                {projectData.impactdusoutiens.map((impact) => (
                  <li key={impact.id} className="text-lg">
                    {impact.titre}
                  </li>
                ))}
              </ul>
            ) : (
              <p>Aucun impact du soutien trouvé.</p>
            )}
          </ul>
        </div>

        <div
          data-aos="fade-up"
          className={`${
            isDark === "dark" ? null : "bg-gray-100"
          } p-6 rounded-lg mb-6`}
        >
          <h3 className="text-lg font-semibold mb-4">Témoignages</h3>
          <blockquote className="italic">
            "Grâce au projet Eco-Ecoles, nos élèves sont devenus de véritables
            ambassadeurs de l'environnement dans notre communauté. C'est
            inspirant de voir leur enthousiasme pour la protection de la
            nature."
            <span className="block mt-4 text-right ">
              - Jeanne Ndayizeye, Directrice de l'école primaire de Gitega
            </span>
          </blockquote>

          <div className="w-full mt-5  flex justify-end">
            <button
              onClick={() => SetisTemoign(true)}
              className="text-[#3ad12c] hover:scale-105 border transition-all border-[#3da12c] p-2 rounded-md"
            >
              Laisse votre témoignages
            </button>
          </div>
        </div>

        <div data-aos="fade-up" className="">
          <h3 className="text-lg font-semibold mb-4">Contactez-nous</h3>
          <p className="mt-2">Téléphone : {projectData && projectData.tel}</p>
          <p>
            Email :{" "}
            <a
              href={`mailto:${projectData && projectData.email}`}
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {projectData && projectData.email}
            </a>
          </p>
        </div>
      </section>

      <Drawer
        open={isTemoign}
        onClose={() => SetisTemoign(false)}
        placement="bottom"
        size="full"
      >
        <Drawer.Header>
          <Drawer.Title className="text-center font-bold uppercase">
            Votre témoignage
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Temoign />
        </Drawer.Body>
      </Drawer>

      <Drawer
        open={isDonantion}
        onClose={() => SetisDonantion(false)}
        placement="right"
        size="xs"
      >
        <Drawer.Header>
          <Drawer.Title className="text-center font-bold uppercase">
            Faire un don
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <DonationForm />
        </Drawer.Body>
      </Drawer>
      <Drawer
        open={isSponsor}
        onClose={() => SetisSponsor(false)}
        placement="right"
        size="md"
      >
        <Drawer.Header>
          <Drawer.Title className="text-center font-bold uppercase">
            Devenir Sponsor
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <SponsorForm />
        </Drawer.Body>
      </Drawer>
      <Drawer
        open={isEquipmentDonation}
        onClose={() => SetisEquipmentDonation(false)}
        placement="right"
        size="md"
      >
        <Drawer.Header>
          <Drawer.Title className="text-center font-bold uppercase">
            Faire un don de matériel
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <EquipmentDonation />
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default Soutenir;
