import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminProjetDonMateriel from './AdminProjetDonMateriel'

function AdminProjetDonMaterielComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide/>
            <AdminProjetDonMateriel />
        </div>
  )
}

export default AdminProjetDonMaterielComponent