import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import NavBarsRessource from "./NavBarsRessource";
import axios from "axios";

function Convention() {
  const { isDark } = useThemes();
  const [conventions, setConventions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10000000);

  useEffect(() => {
    fetchConventions(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchConventions = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/convention?page=${page}&size=${size}`
      );
      setConventions(response.data.conventions);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des conventions :",
        error.message
      );
    }
  };

  return (
    <div
      data-aos-duration="1000"
      className={`mt-[18vh] ${
        isDark === "dark" ? null : "bg-gray-100"
      } overflow-hidden`}
    >
      <NavBarsRessource />

      <section data-aos="fade-up" className="mb-16 p-6">
        <h2 className="text-3xl font-bold mb-6">
          Principales conventions internationales sur les ressources
        </h2>
        <ul className="list-disc list-inside space-y-4 text-lg">
          {conventions.map((convention, index) => (
            <li key={index} className="text-lg ml-3">
              {convention.titre}
              <p className="text-xl text-yellow-800 font-bold">
                {convention.type}
              </p>
            </li>
          ))}
        </ul>
      </section>

      <section className="p-8">
        <h2 data-aos="fade-up" className="text-3xl font-bold mb-6">
          Détails des principales conventions
        </h2>

        <div className="space-y-10">
          {conventions.map((convention, index) => (
            <div
              data-aos="fade-up"
              className={`law-card p-6 mb-8 ${
                isDark === "dark" ? "bg-gray-800" : "bg-white"
              } shadow-lg rounded-lg`}
              key={index}
            >
              <div
                className={`flex flex-col-reverse md:flex-row ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                } items-center space-y-4 md:space-y-0 md:space-x-6`}
              >
                {/* Texte de la convention */}
                <div className="md:w-1/2">
                  <h3 className="text-xl font-semibold mb-4">
                    {convention.titre}
                  </h3>
                  <h3 className="text-xl font-semibold mb-4">
                    {convention.type}
                  </h3>
                  <p className="mb-4">{convention.description}</p>

                  <ul className="list-disc list-inside space-y-2">
                    {convention.elements && convention.elements.length > 0 ? (
                      convention.elements.map((element, idx) => (
                        <li
                          key={idx}
                          className={`${
                            isDark === "dark"
                              ? "text-gray-300"
                              : "text-gray-700"
                          } text-lg ml-3`}
                        >
                          {element.titre}
                        </li>
                      ))
                    ) : (
                      <li>Aucun élément disponible</li>
                    )}
                  </ul>

                  <div className="flex justify-between items-center mt-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.attachement}`}
                      download
                      className="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded shadow-md transition duration-300 ease-in-out"
                    >
                      Télécharger le document
                    </a>
                  </div>
                </div>

                {/* Image de la convention */}
                <div className="md:w-1/2 h-[40vh] md:h-[60vh] lg:h-[70vh] overflow-hidden rounded-lg shadow-lg">
                  <img
                    className="object-cover w-full h-full"
                    src={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.photoCouverture}`}
                    alt={convention.titre}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Convention;
