/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { Progress } from 'rsuite'
import { useNavigate } from 'react-router-dom'

function CoursDetail() {
    const { detailCours } = useThemes()
    const [progession, Getprogession] = useState(30)
    const navig=useNavigate()
    return (
        <div className=' mt-[18vh]  px-4 py-8 overflow-hidden'>
             <div onClick={()=>navig("/cours")} className='w-7 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div data-aos="fade-up" class="rounded-md  p-4">
                <img src="https://file3.chinastory.cn/group1/M00/CB/77/CgoTp2BRseKAUAYrAA45N8sSgB842.jpeg" alt="Introduction à la Biodiversité au Burundi" class="rounded-md mb-2" />
                <h2 class="text-2xl font-semibold mb-2 ">{detailCours.titre}</h2>

                <div className='flex flex-col text-lg'>
                    <div className='w-full mt-2 flex justify-start'>
                        <div class="inline-block "><span>Niveau:</span> <span className='font-semibold '>{detailCours.niveau}</span></div>
                        <div class="inline-block mx-2 px-2 border-l-2"><span>Durée</span>: <span className='font-semibold'>10 semaines</span></div>
                    </div>
                </div>
                <p class="mt-5">{detailCours.context}</p>
            </div>
            <div>
                <Progress.Line percent={progession} strokeWidth={30} strokeColor="#3ad12c" />
                <div className='pl-3 text-lg'><span className='font-bold'>Progression</span>:<span className='ml-2'>{progession}%</span></div>
            </div>

            <div data-aos="fade-left" class="container mt-[5em] mx-auto">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className='border p-2 rounded-xl border-b-2 border-b-[#3ad12c]'>
                        <h2 class="text-xl font-bold mb-2">Module 1 : Introduction à l'Agroécologie</h2>
                        <p class=" mb-4">Découvrez les principes fondamentaux de l'agroécologie et son application dans l'agriculture burundaise.</p>
                        <div onClick={()=>navig("/cours/module")} class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Continuer le module</div>
                    </div>
                    <div className='border p-2 rounded-xl border-b-2 border-b-[#3ad12c]'>
                        <h2 class="text-xl font-bold mb-2">Module 2 : Techniques de Conservation des Sols</h2>
                        <p class=" mb-4">Apprenez les méthodes efficaces pour préserver la fertilité des sols et lutter contre l'érosion.</p>
                        <div onClick={()=>navig("/cours/module")} class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Commencer le module</div>
                    </div>
                    <div className='border p-2 rounded-xl border-b-2 border-b-[#3ad12c]'>
                        <h2 class="text-xl font-bold mb-2">Module 3 : Gestion Intégrée des Ravageurs</h2>
                        <p class=" mb-4">Découvrez des approches écologiques pour prolonger vos cultures sans nuisibles sans produits chimiques nocifs.</p>
                        <div onClick={()=>navig("/cours/module")} class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Module verrouillé</div>
                    </div>
                    <div className='border p-2 rounded-xl border-b-2 border-b-[#3ad12c]'>
                        <h2 class="text-xl font-bold mb-2">Module 4 : Agroforesterie au Burundi</h2>
                        <p class=" mb-4">Explorez les avantages de combiner arbres et cultures pour une agriculture plus productive et durable.</p>
                        <div onClick={()=>navig("/cours/module")} class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Module verrouillé</div>
                    </div>
                    <div className='border p-2 rounded-xl border-b-2 border-b-[#3ad12c]'>
                        <h2 class="text-xl font-bold mb-2">Module 5 : Gestion de l'Eau en Agroécologie</h2>
                        <p class=" mb-4">Apprenez des techniques innovantes pour optimiser l'utilisation de l'eau dans vos pratiques agricoles.</p>
                        <div onClick={()=>navig("/cours/module")} class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Module verrouillé</div>
                    </div>
                    <div className='border p-2 rounded-xl border-b-2 border-b-[#3ad12c]'>
                        <h2 class="text-xl font-bold mb-2">Module 6 : Étude de Cas et Projets Pratiques</h2>
                        <p onClick={()=>navig("/cours/module")} class=" mb-4">Mettez en pratique vos connaissances à travers des exemples concrets d'agroécologie réussie au Burundi.</p>
                        <div class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Module verrouillé</div>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" class="container mx-auto px-4 py-8">
                <h2 class="text-2xl font-bold mb-4">Ressources Complémentaires</h2>

                <ul class="space-y-2">
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Guide Pratique de l'Agroécologie au Burundi (PDF)</div>
                    </div>



                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Forum de discussion sur l'Agroécologie</div>
                    </div>
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Carte des Initiatives Agroécologiques au Burundi</div>
                    </div>
                </ul>

                <h2 class="text-2xl font-bold mt-8 mb-4">Cours Recommandés</h2>
                <ul class="space-y-2">
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Introduction à la Permaculture</div>
                    </div>
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Gestion Durable de l'Eau</div>
                    </div>
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Introduction à la Biodiversité du Burundi</div>
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default CoursDetail