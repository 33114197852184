import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Modifier le chemin de l'icône pour éviter un bug connu de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

// Simuler les données JSON fournies
const signalements = [
  {
    id: 1,
    nom: "Irakoze",
    location: "kayange",
    coordonnees: "+25762370904",
    description: "kakkakakkkaa",
    longitude: -3.39289,
    latitude: 29.3949,
    gravite: "Majeur",
    actionprise: "weiurwl ewriqwyrweb uryqweui reiueqw",
    attentes: "erru rweou eorwei",
    image: "b64d5ac7-a21b-4aca-b194-be7fecada930.png",
    status: true,
  },
  {
    id: 2,
    nom: "Masenge",
    location: "qrqeriweyu rweyi",
    coordonnees: "+25762350504",
    description: "rte tre tty",
    longitude: 29.395,
    latitude: -3.39298,
    gravite: "Majeur",
    actionprise: "e434iu iotyuou",
    attentes: "equ4e tuyri",
    image: "996a6257-d877-4abe-9255-172e0c346b59.jpg",
    status: false,
  },
  // Ajouter d'autres signalements ici...
];

function SignalementMap() {
  return (
    <MapContainer
      center={[-3.39289, 29.3949]}
      zoom={13}
      style={{ height: "100vh", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      {signalements.map((signalement) => (
        <Marker
          key={signalement.id}
          position={[signalement.latitude, signalement.longitude]}
        >
          <Popup>
            <strong>{signalement.nom}</strong>
            <br />
            Localisation: {signalement.location}
            <br />
            Description: {signalement.description}
            <br />
            Gravité: {signalement.gravite}
            <br />
            Coordonnées: {signalement.coordonnees}
            <br />
            <img
              src={`path_to_images/${signalement.image}`}
              alt="signalement"
              width="100px"
            />
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default SignalementMap;
