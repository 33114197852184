import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import AdminCommunauteNavBars from "./AdminCommunauteNavBars";
import { decryptData } from "../../../encryptionModule";

function AdminCommunauteBlogDetail() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const blogId = decryptData(id);

  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/blog/${blogId}`
        );
        const data = await response.json();
        setBlogData(data);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des données du blog.");
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [blogId]);

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const { blog, user } = blogData;

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminCommunauteNavBars />
      <div
        onClick={() => navig("/comm/blog/")}
        className="w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-arrow-left-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
          />
        </svg>
      </div>

      <div
        className={`w-full mx-auto rounded-lg ${
          isDarVisitLoggined === "dark" ? null : "bg-white"
        } p-8`}
      >
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold">{blog.titre}</h1>
            <p className="pl-5 mt-2">
              Par {user.nom} {user.prenom} | Catégorie : {blog.categorie} |
              Publié le {new Date(blog.createdAt).toLocaleDateString()}
            </p>
          </div>
        </div>

        <div className="space-y-6">
          {blog.partieblogs.map((partie) => (
            <div
              key={partie.id}
              className={`w-full mx-auto rounded-lg ${
                isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
              } p-8`}
            >
              <h2 className="text-2xl font-semibold mb-4">{partie.titre}</h2>
              <p className="text-lg mb-4">{partie.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminCommunauteBlogDetail;
