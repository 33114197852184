/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useThemes } from '../../../UserContext/UserContext';
import { useNavigate } from 'react-router-dom';

function InnovationForm() {
    const {SetisAtelierComminity}=useThemes()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        title: '',
        category: '',
        description: '',
        impact: '',
    });
    const navig=useNavigate()

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const titleRef = useRef(null);
    const categoryRef = useRef(null);
    const descriptionRef = useRef(null);
    const impactRef = useRef(null);

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'name':
                if (!value.trim()) {
                    error = "Le nom complet est requis.";
                }
                break;
            case 'email':
                if (!value.trim()) {
                    error = "L'adresse e-mail est requise.";
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = "L'adresse e-mail n'est pas valide.";
                }
                break;
            case 'title':
                if (!value.trim()) {
                    error = "Le titre de l'innovation est requis.";
                }
                break;
            case 'category':
                if (!value) {
                    error = "Veuillez choisir une catégorie.";
                }
                break;
            case 'description':
                if (!value.trim()) {
                    error = "La description est requise.";
                }
                break;
            case 'impact':
                if (!value.trim()) {
                    error = "Veuillez indiquer l'impact sur l'environnement.";
                }
                break;
            default:
                break;
        }

        return error;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const refs = { name: nameRef, email: emailRef, title: titleRef, category: categoryRef, description: descriptionRef, impact: impactRef };

        for (const field in formData) {
            const error = validateField(field, formData[field]);
            if (error) {
                toast.error(error);
                refs[field].current.focus();
                return;
            }
        }
        SetisAtelierComminity(false)
        toast.success("Formulaire soumis avec succès !");
        console.log("Formulaire soumis avec succès:", formData);
    };

    return (
        <form className="w-full sm:w-[70%] h-max" onSubmit={handleSubmit}>
            <div className='w-full mb-4'>
                <label htmlFor="name" className="block font-medium text-lg ">Nom complet :</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    ref={nameRef}
                    value={formData.name}
                    onChange={handleChange}
                    className="bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c]"
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="email" className="block font-medium text-lg ">Adresse e-mail :</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    ref={emailRef}
                    value={formData.email}
                    onChange={handleChange}
                    className="bg-transparent py-3 pl-2 border w-full rounded focus:border-[#3ad12c]"
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="title" className="block font-medium text-lg ">Titre de votre innovation :</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    ref={titleRef}
                    value={formData.title}
                    onChange={handleChange}
                    className="bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c]"
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="category" className="block font-medium text-lg  ">Catégorie :</label>
                <select
                    id="category"
                    name="category"
                    ref={categoryRef}
                    value={formData.category}
                    onChange={handleChange}
                    className="bg-transparent py-3 pl-2  border w-full rounded focus:border-[#3ad12c]"
                >
                    <option value="" hidden>Choisissez une catégorie</option>
                    <option className='text-black' value="category1">Catégorie 1</option>
                    <option className='text-black' value="category2">Catégorie 2</option>
                    <option className='text-black' value="category3">Catégorie 3</option>
                </select>
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="description" className="block font-medium text-lg ">Description de votre innovation :</label>
                <textarea
                    id="description"
                    name="description"
                    ref={descriptionRef}
                    value={formData.description}
                    onChange={handleChange}
                    className="bg-transparent py-3 pl-2 min-h-[15em] border w-full rounded focus:border-[#3ad12c]"
                />
            </div>
            <div className='w-full mb-4'>
                <label htmlFor="impact" className="block font-medium text-lg ">Impact potentiel sur l'environnement :</label>
                <textarea
                    id="impact"
                    name="impact"
                    ref={impactRef}
                    value={formData.impact}
                    onChange={handleChange}
                    className="bg-transparent py-3 pl-2 min-h-[15em] border w-full rounded focus:border-[#3ad12c]"
                />
            </div>
            <button
                type="submit"
                className="bg-[#3ad12c] mb-10  text-white font-semibold  p-2 rounded"
            >
                Soumettre mon Innovation
            </button>

            <div class=" rounded-lg shadow-md">
                <h2 class="text-2xl font-bold mb-4 text-[22px]">Innovations récentes</h2>
                <p className='text-[20px]'>Découvrez quelques-unes des innovations passionnantes qui ont émergé de notre communauté :</p>
                <ul class="list-disc  relative ml-10 text-gray-700 mb-4">
                    <li class="text-blue-400  cursor-pointer text-[17px] hover:text-blue-600">Biocapteurs communautaires pour la production de biogaz</li>
                    <li class="text-blue-400  cursor-pointer text-[17px] hover:text-blue-600">Application mobile pour encourager le recyclage en milieu urbain</li>
                    <li class="text-blue-400  cursor-pointer text-[17px] hover:text-blue-600">Système d'irrigation goutte-à-goutte à faible coût pour petits agriculteurs</li>
                </ul>
                <p className='text-[20px]'>L'innovation est un effort collectif. Rejoignez notre communauté d'innovateurs et contribuez à façonner un avenir durable pour le Burundi !</p>
                <div onClick={()=>navig("/communaute/event") } class="bg-transprent cursor-pointer mt-2 w-max mb-5 border border-[#3ad12c] text-[#3ad12c] font-semibold py-2 px-4 rounded">Voir nos prochains événements d'innovation</div>
            </div>
        </form>
    );
}

export default InnovationForm;
