import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";

function AdminCommunauteNavBars() {
  const navig = useNavigate();
  const { isDarVisitLoggined } = useThemes();
  const { pathname } = useLocation();
  let comm = /^\/comm.*/;
  let blog = /^\/comm\/blog.*/;
  return (
    <div
      className={`w-full flex justify-around border-b-[0.5px] border-gray-500 ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
      } grid grid-cols-7 irapports-center`}
    >
      <div
        onClick={() => navig("/comm")}
        className={`p-2 cursor-pointer   transition-all  ${
          comm.test(pathname) && !blog.test(pathname)
            ? "text-[#3ad12c] border-b-2 border-[#3ad12c] text-center w-full"
            : "text-center w-full"
        } first-letter:uppercase `}
      >
        événements
      </div>
      <div
        onClick={() => navig("/comm/blog")}
        className={`p-2 cursor-pointer   transition-all  ${
          blog.test(pathname)
            ? "text-[#3ad12c] font-bold  w-full border-b-2 border-[#3ad12c] text-center"
            : "text-center w-full"
        } `}
      >
        Forum
      </div>
    </div>
  );
}

export default AdminCommunauteNavBars;
