import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Drawer } from 'rsuite'
import CreerSujet from './CreerSujet'

function Forumu() {
    const navig = useNavigate()
    const [isSujet, SetisSujet] = useState(false)
    return (
        <div className=' mt-[18vh]  overflow-hidden'>
            <div onClick={() => navig("/communaute")} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div className="w-full  px-8 mx-auto">
                <h1 className="text-3xl font-bold mb-6">Bienvenue sur le Forum IMUHIRA</h1>
                <p className="text-lg mb-8">Échangez, apprenez et partagez vos expériences avec d'autres membres passionnés par un Burundi durable.</p>

                <div className="">
                    <div className="mb-6">
                        <input type="text" className="w-full px-4 bg-transparent py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#3ad12c] outline-none" placeholder="Rechercher dans le forum" />
                        <button className="bg-[#3ad12c] hover:scale-105 text-white font-medium py-2 px-4 rounded-md mt-2">Rechercher</button>
                    </div>
                    <button onClick={()=>SetisSujet(true)} className="bg-[#3ad12c] hover:scale-105 mb-2 text-white font-medium py-2 px-4 rounded-md">Créer un Nouveau Sujet</button>
                </div>

                <div className='flex w-full  justify-between'>
                    <div className="flex   w-1/4 flex-col border-r-2 border-[#3ad12c]">
                        <div className="w-full  rounded-lg  p-6">
                            <h2 className="text-xl font-bold mb-4">Catégories</h2>
                            <div className='mt-5  w-full'>
                                <div className='font-bold mb-2'>Environnement</div>
                                <ul className="space-y-2">
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Biodiversité</div></li>
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Changement Climatique</div></li>
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Conservation</div></li>
                                </ul>
                            </div>
                            <div className=" rounded-lg mt-5">
                                <h3 className="text-lg font-bold mb-2">Agriculture Durable</h3>
                                <ul className="space-y-2">
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Agroécologie</div></li>
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Permaculture</div></li>
                                </ul>
                            </div>

                            <div className="rounded-lg mt-5 ">
                                <h3 className="text-lg font-bold mb-2">Énergie et Ressources</h3>
                                <ul className="space-y-2">
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Énergies Renouvelables</div></li>
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Gestion de l'Eau</div></li>
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Zéro Déchet</div></li>
                                </ul>
                            </div>
                            <div className=" rounded-lg mt-5">
                                <h3 className="text-lg font-bold mb-2">Communauté</h3>
                                <ul className="space-y-2">
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Projets Locaux</div></li>
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Éducation Environnementale</div></li>
                                    <li><div className="text-teal-300 cursor-pointer hover:text-[#3ad12c]">Politique Environnementale</div></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg mx-4 w-full p-6">
                        <h2 className="text-3xl font-bold mb-4">Sujets Récents</h2>
                        <div className="space-y-4">
                            <div onClick={()=>navig("/communaute/forumu/detail")} className="flex justify-between transition-all hover:opacity-45 hover:scale-105 cursor-pointer">
                                <div>
                                    <div className="text-xl font-bold">Comment démarrer un jardin communautaire à Bujumbura ?</div>
                                    <p className="text-lg">Par Pierre M. | Catégorie : Jardinage Urbain | Il y a 2 heures</p>
                                    <p className="text-[#3ad12c] font-bold"> Réponses : 12 </p>
                                </div>
                                <p className="text-[#3ad12c] font-bold">Vues : 89</p>
                            </div>
                            <hr />

                            <div onClick={()=>navig("/communaute/forumu/detail")} className="flex justify-between transition-all  hover:opacity-45 hover:scale-105 cursor-pointer">
                                <div>
                                    <div className="text-xl font-bold">Techniques de compostage adaptées au climat burundais</div>
                                    <p className="text-lg">Par Marie N. | Catégorie : Agriculture Durable | Il y a 1 jour</p>
                                    <p className="text-[#3ad12c] font-bold"> Réponses : 8 </p>
                                </div>
                                <p className="text-[#3ad12c] font-bold">Vues : 132</p>
                            </div>

                            <hr />

                            <div onClick={()=>navig("/communaute/forumu/detail")} className="flex justify-between transition-all hover:opacity-45 hover:scale-105 cursor-pointer">
                                <div>
                                    <div className="text-xl font-bold">Initiative de reboisement : appel aux bénévoles</div>
                                    <p className="text-lg">Par Jean B. | Catégorie : Projets Locaux | Il y a 3 jours</p>
                                    <p className="text-[#3ad12c] font-bold"> Réponses : 25 </p>
                                </div>
                                <p className="text-[#3ad12c] font-bold">Vues : 310</p>
                            </div>
                            <hr />

                            <div onClick={()=>navig("/communaute/forumu/detail")} className="flex justify-between transition-all hover:opacity-45 hover:scale-105 cursor-pointer">
                                <div>
                                    <div className="text-xl font-bold">Débat : Quelle politique énergétique pour le Burundi ?</div>
                                    <p className="text-lg">Par Claire K. | Catégorie : Politique Environnementale | Il y a 5 jours</p>
                                    <p className="text-[#3ad12c] font-bold"> Réponses : 42 </p>
                                </div>
                                <p className="text-[#3ad12c] font-bold">Vues : 567</p>
                            </div>
                            <hr />

                            <div onClick={()=>navig("/communaute/forumu/detail")} className="flex justify-between transition-all hover:opacity-45 hover:scale-105 cursor-pointer">
                                <div>
                                    <div className="text-xl font-bold">Partage d'expérience : Mon potager en permaculture</div>
                                    <p className="text-lg">Par Emile R. | Catégorie : Permaculture | Il y a 1 semaine</p>
                                    <p className="text-[#3ad12c] font-bold"> Réponses : 18 </p>
                                </div>
                                <p className="text-[#3ad12c] font-bold">Vues : 245</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer open={isSujet} onClose={() => SetisSujet(false)} placement='right' size="full">
                <Drawer.Header>
                    <Drawer.Title className='text-center font-bold uppercase'>Devenir Sujet</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <CreerSujet />
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default Forumu