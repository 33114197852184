
import React from "react";
import { useThemes } from "../../../UserContext/UserContext";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from 'swiper/modules';
import { Sligersfade } from "../../../Data/Data";


function Sloger() {
  const { handleScrollToForm } = useThemes()
  return (

    <Swiper
    className="mySwiper"
      loop={true}
      effect={'fade'}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, EffectFade]}
    >
      {Sligersfade.map((data, i) => (
        <SwiperSlide>
          <div key={i} data-aos-duration="1000" className="relative overflow-hidden h-[95vh]">
            <img src={data.image} className="h-[95vh] w-full object-cover" alt="   " />
            <div className="absolute top-0 text-center bg-[#0f172b96] text-white left-0 w-full   flex items-start justify-around  h-full">
              <div className="w-full mt-[5em] flex items-center flex-col">
                <div data-aos="fade-up" className="text-7xl font-bold titre stroketext1">
                  {data.sujet}
                </div>
                <div className='bg-[#5a0200] animate-ping w-10 h-10 bottom-2 rounded-full left-3 absolute'></div>
                <div data-aos="fade-left" className="text-3xl relative mt-[2em] sm:w-[50%]">
                  {data.description}
                </div>
                <div className="w-full justify-center mt-5  flex">
                  <div onClick={handleScrollToForm} data-aos="fade-down" className="w-max mt-5 px-3 py-2 bg-[#3ad12c] border-[3px] rounded-md cursor-pointer border-white text-white">
                    {data.textButton}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

      ))}

    </Swiper>
  )
}

export default Sloger