import React, { useState } from 'react';
import { useThemes } from '../../../UserContext/UserContext';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const RejoignezFormulaire = () => {
    const{SetisRejoinder}=useThemes()
    const [formData, setFormData] = useState({
        nomComplet: '',
        adresseEmail: '',
        numeroTelephone: '',
        villeResidence: '',
        typeAdhesion: '',
        domainesInteret: [],
        pourquoiRejoindre: ''
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            let newDomainesInteret;
            if (checked) {
                // Ajouter le domaine d'intérêt s'il est sélectionné
                newDomainesInteret = [...formData.domainesInteret, name];
            } else {
                // Retirer le domaine d'intérêt s'il est désélectionné
                newDomainesInteret = formData.domainesInteret.filter(
                    (interest) => interest !== name
                );
            }

            setFormData({ ...formData, domainesInteret: newDomainesInteret });
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                if (newDomainesInteret.length > 0) {
                    delete newErrors.domainesInteret;
                }
                return newErrors;
            });
        } else {
            setFormData({ ...formData, [name]: value });

            // Check the specific field that was modified
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                if (value) {
                    delete newErrors[name];
                }
                return newErrors;
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            SetisRejoinder(false)
            Swal.fire({
                title: "Merci pour votre intérêt !",
                text: "Nous avons bien reçu votre demande et nous vous contacterons bientôt.Cela semble être une réponse standard ou automatique pour informer le destinataire que sa demande a bien été prise en compte et qu'il sera contacté prochainement.",
                icon: "success"
              });
            console.log(formData);
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.nomComplet) {
            errors.nomComplet = 'Le nom complet est obligatoire';
        }
        if (!formData.adresseEmail) {
            errors.adresseEmail = 'L\'adresse e-mail est obligatoire';
        } else if (!/\S+@\S+\.\S+/.test(formData.adresseEmail)) {
            errors.adresseEmail = 'L\'adresse e-mail n\'est pas valide';
        }
        if (!formData.numeroTelephone) {
            errors.numeroTelephone = 'Le numéro de téléphone est obligatoire';
        }
        if (!formData.villeResidence) {
            errors.villeResidence = 'La ville de résidence est obligatoire';
        }
        if (!formData.typeAdhesion) {
            errors.typeAdhesion = 'Le type d\'adhésion est obligatoire';
        }
        if (!formData.pourquoiRejoindre) {
            errors.pourquoiRejoindre = 'Veuillez expliquer pourquoi vous souhaitez nous rejoindre';
        }
        if (formData.domainesInteret.length === 0) {
            errors.domainesInteret = 'Veuillez sélectionner au moins un domaine d\'intérêt';
        }
        return errors;
    };

    return (
        <form onSubmit={handleSubmit} className="w-full shadow-md rounded-lg px-6">
            <div className="mb-4">
                <label htmlFor="nomComplet" className="block font-bold mb-2  text-lg">
                    Nom complet
                </label>
                <input
                    type="text"
                    id="nomComplet"
                    name="nomComplet"
                    value={formData.nomComplet}
                    onChange={handleInputChange}
                    className={`bg-transparent w-full p-3 border ${errors.nomComplet ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none focus:border-[#3ad12c]`}
                />
                {errors.nomComplet && (
                    <div className="text-red-500 text-sm mt-1">{errors.nomComplet}</div>
                )}
            </div>

            <div className="mb-4">
                <label htmlFor="adresseEmail" className="block font-bold mb-2 text-lg">
                    Adresse e-mail
                </label>
                <input
                    type="email"
                    id="adresseEmail"
                    name="adresseEmail"
                    value={formData.adresseEmail}
                    onChange={handleInputChange}
                    className={`bg-transparent w-full p-3 border ${errors.adresseEmail ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none focus:border-[#3ad12c]`}
                />
                {errors.adresseEmail && (
                    <div className="text-red-500 text-sm mt-1">{errors.adresseEmail}</div>
                )}
            </div>

            <div className="mb-4">
                <label htmlFor="numeroTelephone" className="block font-bold mb-2 text-lg">
                    Numéro de téléphone
                </label>
                <input
                    type="tel"
                    id="numeroTelephone"
                    name="numeroTelephone"
                    value={formData.numeroTelephone}
                    onChange={handleInputChange}
                    className={`bg-transparent w-full p-3 border ${errors.numeroTelephone ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none focus:border-[#3ad12c]`}
                />
                {errors.numeroTelephone && (
                    <div className="text-red-500 text-sm mt-1">{errors.numeroTelephone}</div>
                )}
            </div>

            <div className="mb-4">
                <label htmlFor="villeResidence" className="block font-bold mb-2 text-lg">
                    Ville de résidence
                </label>
                <input
                    type="text"
                    id="villeResidence"
                    name="villeResidence"
                    value={formData.villeResidence}
                    onChange={handleInputChange}
                    className={`bg-transparent w-full p-3 border ${errors.villeResidence ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none focus:border-[#3ad12c]`}
                />
                {errors.villeResidence && (
                    <div className="text-red-500 text-sm mt-1">{errors.villeResidence}</div>
                )}
            </div>

            <div className="mb-4">
                <label htmlFor="typeAdhesion" className="block font-bold mb-2 text-lg">
                    Type d'adhésion
                </label>
                <select
                    id="typeAdhesion"
                    name="typeAdhesion"
                    value={formData.typeAdhesion}
                    onChange={handleInputChange}
                    className={`bg-transparent w-full p-3 border ${errors.typeAdhesion ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none focus:border-[#3ad12c]`}
                >
                    <option hidden value="">Choisissez un type d'adhésion</option>
                    <option className='text-black' value="individuelle">Adhésion individuelle</option>
                    <option className='text-black' value="organisationnelle">Adhésion organisationnelle</option>
                </select>
                {errors.typeAdhesion && (
                    <div className="text-red-500 text-sm mt-1">{errors.typeAdhesion}</div>
                )}
            </div>

            <div className="mb-4">
                <label className="block font-bold mb-2 text-lg">Domaines d'intérêt</label>
                <div className="flex flex-wrap gap-2">
                    <label className="flex my-2 cursor-pointer w-max p-1 items-center">
                        <div className='w-4 h-4 rounded border flex justify-center items-center relative border-[#3ad12c]'>
                            <input
                                checked={formData.domainesInteret.includes('environnement')}
                                onChange={handleInputChange}
                                id='environnement'
                                hidden name="environnement" type="checkbox" className="h-4 w-4 border-gray-300 rounded" />
                            {formData.domainesInteret.includes('environnement') &&
                                <div className='w-2.5 h-2.5 flex justify-center items-center top-[2px] absolute bg-[#3ad12c]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check text-white" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                </div>}
                        </div>
                        <div className="ml-3 flex items-center">
                            <label htmlFor="environnement" className=''>Éducation environnementale</label>
                        </div>
                    </label>

                    <label className="flex my-2 cursor-pointer w-max p-1 items-center">
                        <div className='w-4 h-4 rounded border flex justify-center items-center relative border-[#3ad12c]'>
                            <input
                                id='dechets'
                                checked={formData.domainesInteret.includes('dechets')}
                                onChange={handleInputChange}
                                hidden name="dechets" type="checkbox" className="h-4 w-4 border-gray-300 rounded" />
                            {formData.domainesInteret.includes('dechets') &&
                                <div className='w-2.5 h-2.5 flex justify-center items-center top-[2px] absolute bg-[#3ad12c]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check text-white" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                </div>}
                        </div>
                        <div className="ml-3 flex items-center">
                            <label htmlFor="dechets" className=''>Gestion des déchets</label>
                        </div>
                    </label>


                    <label className="flex my-2 cursor-pointer w-max p-1 items-center">
                        <div className='w-4 h-4 rounded border flex justify-center items-center relative border-[#3ad12c]'>
                            <input
                                id='biodiversite'
                                checked={formData.domainesInteret.includes('biodiversite')}
                                onChange={handleInputChange}
                                hidden name="biodiversite" type="checkbox" className="h-4 w-4 border-gray-300 rounded" />
                            {formData.domainesInteret.includes('biodiversite') &&
                                <div className='w-2.5 h-2.5 flex justify-center items-center top-[2px] absolute bg-[#3ad12c]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check text-white" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                </div>}
                        </div>
                        <div className="ml-3 flex items-center">
                            <label htmlFor="biodiversite" className=''>Protection de la biodiversité</label>
                        </div>
                    </label>



                    <label className="flex my-2 cursor-pointer w-max p-1 items-center">
                        <div className='w-4 h-4 rounded border flex justify-center items-center relative border-[#3ad12c]'>
                            <input
                                id='eau'
                                checked={formData.domainesInteret.includes('eau')}
                                onChange={handleInputChange}
                                hidden name="eau" type="checkbox" className="h-4 w-4 border-gray-300 rounded" />
                            {formData.domainesInteret.includes('eau') &&
                                <div className='w-2.5 h-2.5 flex justify-center items-center top-[2px] absolute bg-[#3ad12c]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check text-white" viewBox="0 0 16 16">
                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                </div>}
                        </div>
                        <div className="ml-3 flex items-center">
                            <label htmlFor="eau" className=''>Conservation de l'eau</label>
                        </div>
                    </label>
                </div>
                {errors.domainesInteret && (
                    <div className="text-red-500 text-sm mt-1">{errors.domainesInteret}</div>
                )}
            </div>


            <div className="mb-4">
                <label htmlFor="pourquoiRejoindre" className="block font-bold mb-2 text-lg">
                    Pourquoi souhaitez-vous nous rejoindre ? (facultatif)
                </label>
                <textarea
                    id="pourquoiRejoindre"
                    name="pourquoiRejoindre"
                    value={formData.pourquoiRejoindre}
                    onChange={handleInputChange}
                    className={`bg-transparent w-full min-h-[20em] p-3 border ${errors.pourquoiRejoindre ? 'border-red-500' : 'border-gray-300'
                        } rounded-lg focus:outline-none focus:border-[#3ad12c]`}
                />
                {errors.pourquoiRejoindre && (
                    <div className="text-red-500 text-sm mt-1">{errors.pourquoiRejoindre}</div>
                )}
            </div>

            <div className="text-start mt-6">
                <button
                    type="submit"
                    className="bg-[#24a70e] text-white mb-10 px-4 py-2 rounded-lg hover:bg-[#3ad12c] transition-all duration-200"
                >
                    Envoyer ma demande
                </button>
            </div>
            <div  className=" mx-auto border-l-2 mb-5 border-[#3ad12c] bg-[#3ad12c1a] py-5 rounded-l-md px-4 sm:px-6 lg:px-8">
                Régulièrement, IMUHIRA a été l'une des meilleures décisions de ma vie. J'ai non seulement appris énormément sur l'environnement, mais j'ai aussi rencontré des personnes incroyables qui partagent ma passion pour la nature. Ensemble, nous faisons avancer la différence pour notre belle planète.
                Marie N., membre depuis 2 ans
            </div>
        </form>
    );
};

export default RejoignezFormulaire;
