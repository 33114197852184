import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdiminRapportDetail from './AdiminRapportDetail'

function AdiminRapportDetailComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdiminRapportDetail />
        </div>
  )
}

export default AdiminRapportDetailComponent