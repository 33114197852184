import React, { useEffect, useState } from "react";
import NavBarsRessource from "./NavBarsRessource";
import { useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
import axios from "axios";

function Climentique() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const [faitenvs, setfaitenvs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchFaitenvs(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchFaitenvs = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/faitenvs?page=${page}&size=${size}&search=${
          search || ""
        }`
      );
      setfaitenvs(response.data.faitenvs);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des faits :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset when searching
    fetchFaitenvs(1, pageSize, event.target.value);
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalItems);
    return `${from} à ${to} faits sur ${totalItems}`;
  };

  return (
    <div
      className="mt-14 container mx-auto p-5 md:p-10"
      data-aos-duration="1000"
    >
      <NavBarsRessource />
      <div className="flex flex-wrap w-full justify-between items-center mb-4">
        <div className="flex items-center mb-4">
          <label className="text-lg font-semibold">Taille de la page :</label>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="ml-2 p-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="flex w-full lg:w-[60%] items-center mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Rechercher..."
            className="flex-grow p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 transition"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {faitenvs.length > 0 ? (
          faitenvs.map((faitenv, i) => (
            <div
              key={i}
              className={`cursor-pointer p-5 border border-[#3ad12c] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ${
                isDarVisitLoggined === "dark"
                  ? "bg-gray-800 text-white"
                  : "bg-white text-gray-900"
              }`}
            >
              <p className="text-sm mt-2">
                <span className="font-semibold">Date : </span>
                {new Date(faitenv.createdAt).toLocaleDateString("fr-FR", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </p>

              <h2 className="text-xl md:text-2xl mt-5 font-bold mb-4">
                {faitenv.titre}
              </h2>
              <div className="grid grid-cols-1 gap-4">
                {faitenv.partiefaitenvs.map((partie, i) => (
                  <div
                    key={partie.id}
                    className="p-5 border-l-4 border-[#3ad12c] bg-[#3ad12c2a] rounded-lg"
                  >
                    <div className="text-lg font-bold mb-2">
                      {i + 1}. {partie.titre}
                    </div>
                    <p className="text-gray-700">{partie.description}</p>
                  </div>
                ))}
              </div>
              <p className="mt-4 text-sm">Source: {faitenv.source}</p>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Aucun fait trouvé.</p>
        )}
      </div>

      {faitenvs.length > 0 && (
        <div className="mt-6 flex justify-center space-x-2">
          <button
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200 hover:bg-gray-400 transition"
          >
            Précédent
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 rounded-lg ${
                currentPage === index + 1
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700"
              } hover:bg-blue-500 transition`}
            >
              {index + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200 hover:bg-gray-400 transition"
          >
            Suivant
          </button>
        </div>
      )}

      <div className="mt-4 text-center">
        <p className="text-lg">{getRange()}</p>
      </div>
    </div>
  );
}

export default Climentique;
