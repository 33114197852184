import React, { useEffect, useState } from "react";
import NavBarsRessource from "./NavBarsRessource";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext";

function Rapport() {
  const [rapports, setrapports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, settotalItems] = useState(0);
  const { isDarVisitLoggined } = useThemes();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchrapports(currentPage, pageSize);
    // Optional: You could add a cleanup function for axios requests here
  }, [currentPage, pageSize]);

  const fetchrapports = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/rapports?page=${page}&size=${size}&searchTerm=${
          search || ""
        }`
      );
      setrapports(response.data.rapports);
      setTotalPages(response.data.totalPages);
      settotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des rapports :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [mobile2, setMobile2] = useState(window.innerWidth <= 768);
  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalItems);
    return `${from} à ${to} rapports sur ${totalItems}`;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
    fetchrapports(1, pageSize, event.target.value); // Trigger search
  };

  return (
    <div data-aos-duration="1000" class="rounded-lg mt-[18vh] overflow-hidden">
      <NavBarsRessource />
      <div class="rounded-lg  p-6 md:p-8">
        <p data-aos="fade-up" className="text-lg my-3">
          Ce rapport présente une analyse approfondie des 15 défis
          environnementaux les plus pressants auxquels le Burundi est confronté.
          Pour chaque défi, nous détaillons la situation actuelle, les actions
          déjà entreprises et les mesures à mettre en place, le tout appuyé par
          des données chiffrées récentes.
        </p>
        <div class="flex items-center justify-between w-full mb-4">
          <div className={`${mobile2 ? "mb-5" : ""}`}>
            <div className="flex items-center mb-4">
              <p className="sm:text-xl">Taille de la page :</p>
              <select
                value={pageSize}
                onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded-lg"
              >
                {[1, 5, 10, 20, 50, 100].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex w-[60%] items-center">
            <div className="mb-4">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Rechercher un problème..."
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 transition-all"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {rapports.length > 0 ? (
            rapports.map((rapport, i) => (
              <div
                key={i}
                className={`rounded-xl overflow-hidden border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c] ${
                  isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
                }`}
              >
                <h2 className="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap py-4 text-start bg-[#3ad12c0e] text-ellipsis overflow-hidden">
                  {i + 1}. {rapport.titre}
                </h2>
                <p className="mb-1 p-2 text-[18px]">{rapport.description}</p>
                <div className="space-y-2 px-4">
                  <p className="text-lg font-bold mt-4 mx-4 mb-2">
                    Actions réalisées :
                  </p>
                  <ul className="list-disc pl-6 space-y-2 mx-5">
                    {rapport.realiserapports.map((action, index) => (
                      <li key={index}>{action.description}</li>
                    ))}
                  </ul>
                  <div>
                    <p className="text-lg font-bold mt-4 mx-4 mb-2">
                      Actions à entreprendre :
                    </p>
                    <ul className="list-disc pl-6 space-y-2 mx-5">
                      {rapport.entreprendrereports.map((action, index) => (
                        <li key={index}>{action.description}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Aucun rapport trouvé.</p>
          )}
        </div>

        {rapports.length > 0 && (
          <>
            <div className="mt-6 flex items-center justify-center space-x-2">
              <button
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-1 rounded-lg transition-all ${
                      currentPage === pageNumber
                        ? "bg-blue-600 text-white"
                        : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                    }`}
                  >
                    {pageNumber}
                  </button>
                )
              )}
              <button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Suivant
              </button>
            </div>

            <div className="mt-4 text-center">
              <p className="text-lg">{getRange()}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Rapport;
