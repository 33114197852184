import React, { useEffect, useState } from "react";
import NavBarsRessource from "./NavBarsRessource";
import { useThemes } from "../../../UserContext/UserContext";
import axios from "axios";
function Animal() {
  const { isDarVisitLoggined } = useThemes();
  const [especes, setespeces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchespeces(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchespeces = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/especes?page=${page}&size=${size}&searchItem=${
          search || ""
        }`
      );
      setespeces(response.data.especes);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des especes :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Réinitialiser lors de la recherche
    fetchespeces(1, pageSize, event.target.value);
  };

  return (
    <div div className="overflow-hidden">
      <div data-aos-duration="1000" class="rounded-lg mt-[18vh]">
        <NavBarsRessource />
        <div data-aos="fade-up" class=" mx-auto">
          <div class="rounded-lg">
            <div class="p-6">
              <p class="mb-4 text-lg">
                Découvrez ci-dessous une sélection d'espèces emblématiques de la
                biodiversité burundaise, avec leurs noms scientifiques, leurs
                correspondances en Kirundi, et leurs localisations principales.
              </p>

              <div class="flex items-center justify-between w-full mb-4">
                <div className="flex w-[100%] items-center">
                  <div className="w-full">
                    <input
                      value={searchTerm}
                      onChange={handleSearch}
                      placeholder="Proposer le nom  d'un espèces "
                      className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap w-full p-5 justify-between items-center mb-4">
        <div className="flex items-center mb-4">
          <label className="text-lg">Taille de la page :</label>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="ml-2 p-2 bg-white border border-gray-300 rounded-lg"
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-3 lg:grid-cols-4 gap-4">
        {especes.map((espece, index) => (
          <div
            key={index}
            className={`rounded-md ${
              isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
            } p-4`}
          >
            <img
              src={`https://imuhirabackend.eac-advisor.org/uploads/especes/${espece.image}`}
              alt={espece.nomfrancais}
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-xl font-bold">{espece.nomfrancais}</h2>
            <p className="">{espece.nomlatin}</p>
            <p className="italic">{espece.nomkirundi}</p>
            <p className="mt-4 text-sm">{espece.region}</p>
            <p className="mt-4 text-sm">{espece.description}</p>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {especes.length > 0 && (
        <div className="mt-6 flex justify-center space-x-2">
          <button
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Précédent
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 rounded-lg ${
                currentPage === index + 1
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Suivant
          </button>
        </div>
      )}
    </div>
  );
}

export default Animal;
