import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import axios from "axios";
import { encryptData } from "../../../encryptionModule";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

function AdminLois() {
  const navig = useNavigate();
  const { isDarVisitLoggined } = useThemes();
  const [lois, setlois] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    fetchlois(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchlois = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/lois?page=${page}&size=${size}&search=${
          search || ""
        }`
      );
      setlois(response.data.lois);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error("Erreur lors de la récupération des lois :", error.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Réinitialiser lors de la recherche
    fetchlois(1, pageSize, event.target.value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton:
          "bg-red-600 text-white hover:bg-red-700 border border-red-600 rounded-lg px-4 py-2",
        cancelButton:
          "bg-gray-300 text-gray-800 hover:bg-gray-400 border border-gray-300 rounded-lg px-4 py-2",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/lois/${id}`
          );
          setlois(lois.filter((loi) => loi.id !== id));
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          toast.error("La suppression a échoué.");
        }
      }
    });
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />

      {/* Section des Lois */}
      <div className="flex w-full justify-between p-4 mb-4 items-center">
        <div className="flex items-center mb-4">
          <label className="text-lg">Taille de la page :</label>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="ml-2 p-2 bg-white border border-gray-300 rounded-lg"
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="flex w-[60%]">
          <div className="flex w-full items-center">
            <div className="w-full">
              <input
                value={searchTerm}
                onChange={handleSearch}
                className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                placeholder="Rechercher la loi"
              />
            </div>
          </div>
          <button
            onClick={() => navig("/ressource/loi/ajouter")}
            className="border ml-2 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
          >
            Ajouter
          </button>
        </div>
      </div>

      {/* Liste des Lois */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-3 lg:grid-cols-4 gap-2">
        {lois.map((loi, index) => (
          <div
            key={index}
            className={`rounded-md ${
              isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
            } p-4`}
          >
            <img
              src={`https://imuhirabackend.eac-advisor.org/uploads/lois/${loi.photoCouverture}`}
              alt={loi.titre}
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-xl font-bold">{loi.titre}</h2>
            <p className="">{loi.description}</p>
            <div className="flex w-full justify-between items-center p-2">
              <button
                onClick={() =>
                  navig(
                    `/ressource/loi/modifi/${encryptData(loi.id.toString())}`
                  )
                }
                className="text-blue-600"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                onClick={() =>
                  navig(
                    `/ressource/loi/detail/${encryptData(loi.id.toString())}`
                  )
                }
                className="text-green-600"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </button>
              <button
                onClick={() => handleDelete(loi.id)}
                className="text-red-600"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center p-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="p-2 border rounded"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="p-2 border rounded"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <Footer />
    </div>
  );
}

export default AdminLois;
