/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Footer from './Footer';


function FootentContent() {
    const [mobile, Setmobile] = useState(window.innerWidth < 617);
    useEffect(() => {
        const hundleSize = () => {
            Setmobile(window.innerWidth < 617)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    return (
        <div className='w-full flex flex-col justify-center items-center'>
            <svg className=" relative  " preserveAspectRatio="none" viewBox="0 0 100 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M0,10  C20,35 40,5 60,15 C80,25 90.5,0 100,15 L100,30 L0,30 Z" fill="#0F172B"></path>
            </svg>

            <div className={`bg-[#0F172B] w-full text-white h-max p-2 flex ${mobile ? 'flex-col' : 'justify-around items-center'}  z-10 font-medium `}>
                <div className="flex flex-col  p-2 items-start leading-[1.6em] max-w rounded ">
                    <div className='font-bold font-serif underline cursor-default  uppercase text-[#3ad12c]'>Branche</div>
                    <Link to='/' className='flex text-white w-full p-1 transition-all hover:underline'>Condition d'utilisation</Link>
                    <Link to='/' className='flex text-white w-full p-1 transition-all hover:underline first-letter:uppercase'>À propos</Link>
                    <Link to='/' className='flex text-white w-full p-1 transition-all rounded hover:underline'>Politique de Confidentialité</Link>
                </div>

                <div className="flex flex-col  p-2 items-start leading-[1.6em] max-w rounded ">
                    <div className='font-bold font-serif underline cursor-default  uppercase text-[#3ad12c]'>Contact</div>
                    <Link to='/bourse' className='flex text-white w-full p-1 transition-all hover:underline'>+257 22 22 222 <span className='ml-2'>watsapp</span></Link>
                    <Link to='/bourse' className='flex text-white w-full p-1 transition-all hover:underline'>+257 22 22 222 <span className='ml-2'>SMS</span></Link>
                    <Link to='/universite' className='flex text-white w-full p-1 transition-all hover:underline'>fastfood@fastfood.org</Link>
                    <div className='flex text-white gap-2 w-full p-1 transition-all rounded hover:underline'>
                        <Link target='_blank' to="https://www.facebook.com/acapebu2017">FaceBook</Link>
                        <Link to="/">Youtube</Link>
                    </div>
                </div>
                <div className="flex flex-col  p-2 items-start leading-[1.6em] max-w rounded ">
                    <div className='font-bold font-serif underline cursor-default  uppercase text-[#3ad12c]'>ouverture</div>
                    <div className='flex text-white w-full p-1 transition-all'>Lundi - Samedi</div>
                    <div className='flex text-white w-full p-1 transition-all'>07H00 - 23H00</div>
                    <div className='flex text-white w-full p-1 transition-all'>Dimanche</div>
                    <div className='flex text-white w-full p-1 transition-all'>9H00 - 20H00</div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FootentContent
