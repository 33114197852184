import React from 'react'
import AdminProjetNavBars from './AdminProjetNavBars'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Visiteurs/FootentContent/Footer'

function AdminProjetTemoi() {
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <AdminProjetNavBars />
            <h1 class="text-2xl font-bold mb-4 text-center">Témoignages</h1>
            <div class="mx-auto grid grid-cols-4 w-full   gap-5  p-6">
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => (
                    <div className={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-4`}>
                        <h2 class="text-lg font-semibold">Jean Dupont</h2>
                        <p class="">Fonction : Responsable Marketing</p>
                        <p class="">Téléphone : 06 12 34 56 78</p>
                        <p class="mt-2 ">
                            "J'ai eu une expérience incroyable avec cette organisation. Leur soutien m'a été précieux et j'ai pu accomplir beaucoup de choses."
                        </p>
                        <p class="mt-2 italic text-[10px]">
                            le 12/01/2023
                        </p>
                        <div className='flex w-full justify-between items-center p-2'>
                            <div title='Modifier' onClick={() => navig("/projet/tem/detail")} className=' border px-2 py-1 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer'>
                                Détail
                            </div>
                            <div className=' border px-2 py-1 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer'>
                                Valider
                            </div>
                            <div title='Supprimer' className=' border px-2 py-1 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer'>
                                Annuler
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Footer/>
        </div>

    )
}

export default AdminProjetTemoi