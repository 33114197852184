/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Drawer } from "rsuite";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "./AdminRessourceNavBars";
import axios from "axios";
import { encryptData } from "../../../encryptionModule";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function AdminRessource() {
  const { isDarVisitLoggined, SetDetailCours } = useThemes();
  const navig = useNavigate();
  const [mobile2, setMobile2] = useState(window.innerWidth <= 768);
  const [resources, setresources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, settotalItems] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchresources(currentPage, pageSize);
    // Optional: You could add a cleanup function for axios requests here
  }, [currentPage, pageSize]);

  const fetchresources = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/resources?page=${page}&size=${size}&search=${
          search || ""
        }`
      );
      setresources(response.data.resources);
      setTotalPages(response.data.totalPages);
      settotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des resources :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalItems);
    return `${from} à ${to} resources sur ${totalItems}`;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
    fetchresources(1, pageSize, event.target.value); // Trigger search
  };

  const handleDelete = async (id) => {
    // Afficher la boîte de dialogue SweetAlert2
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/resources/${id}`
          );
          setresources(resources.filter((resource) => resource.id !== id));

          // Afficher un message de succès
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          console.error(
            "Erreur lors de la suppression de la ressource :",
            error.message
          );

          // Afficher un message d'erreur
          Swal.fire(
            "Erreur",
            "Un problème est survenu lors de la suppression de la ressource.",
            "error"
          );
        }
      } else {
        console.log("Suppression annulée.");
      }
    });
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div class=" ">
        <div class=" mx-auto">
          <div class="rounded-lg">
            <div class="p-6">
              <div class="flex items-center justify-between w-full mb-4">
                <div className={`${mobile2 ? "mb-5" : ""}`}>
                  <div className="flex items-center mb-4">
                    <p className="sm:text-xl">Taille de la page :</p>
                    <select
                      value={pageSize}
                      onChange={(e) =>
                        handlePageSizeChange(Number(e.target.value))
                      }
                      className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded-lg"
                    >
                      {[1, 5, 10, 20, 50, 100].map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="flex w-[60%] items-center">
                  <div className="mb-4">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearch}
                      placeholder="Rechercher un problème..."
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 transition-all"
                    />
                  </div>
                  <button
                    onClick={() => navig("/ressource/ajouter")}
                    className="border ml-5 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 m-2 sm:grid-cols-4 gap-4">
        {resources.length > 0 ? (
          resources.map((resource, i) => (
            <div
              key={i}
              className={`rounded-md ${
                isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
              } p-1`}
            >
              <div class="">
                <div className="bg-[#3ad12c] flex justify-center items-center">
                  <img
                    src={`https://imuhirabackend.eac-advisor.org/uploads/resources/${resource.photocouverture}`}
                    alt={resource.title}
                    className="rounded-md mb-2"
                  />
                </div>
                <div class="mb-4 p-3">
                  <h2 class="text-lg font-bold ">{resource.titre}</h2>
                  <p class="">{resource.description}</p>
                </div>
                <div className="flex w-full justify-between items-center">
                  <div
                    title="Modifier"
                    onClick={() =>
                      navig(
                        `/ressource/modifier/${encryptData(
                          resource.id.toString()
                        )}`
                      )
                    }
                    className=" border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                  </div>
                  <div
                    title="Détail"
                    onClick={() => {
                      navig(
                        `/ressource/detail/${encryptData(
                          resource.id.toString()
                        )}`
                      );
                      SetDetailCours(resource);
                    }}
                    className=" border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                    </svg>
                  </div>
                  <div
                    title="Supprimer"
                    onClick={() => handleDelete(resource.id)}
                    className=" border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-trash3-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Aucune ressource trouvée.</p>
        )}
      </div>

      {resources.length > 0 && (
        <>
          <div className="mt-6 flex items-center justify-center space-x-2">
            <button
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
              className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
            >
              Précédent
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={`px-3 py-1 rounded-lg transition-all ${
                    currentPage === pageNumber
                      ? "bg-blue-600 text-white"
                      : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                  }`}
                >
                  {pageNumber}
                </button>
              )
            )}
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
              className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
            >
              Suivant
            </button>
          </div>

          <div className="mt-4 text-center">
            <p className="text-lg">{getRange()}</p>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default AdminRessource;
