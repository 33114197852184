import React from 'react'
import AdminProjetNavBars from './AdminProjetNavBars'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Visiteurs/FootentContent/Footer'

function AdminProjetBenevolDetail() {
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <AdminProjetNavBars />
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/projet/benevol")} className="text-[#3ad12c] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div className={`m-4  p-5 rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'}`}>
                <h2 class="text-lg font-semibold">Paul Martin</h2>
                <p class="">Email : mmmmm@gmail.com</p>
                <p class="">Téléphone : 09 34 56 78 90</p>
                <p class="mt-2 ">
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet." "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    <br />
                    <br />
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    <br />
                    <br />
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    <br />
                    <br />
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                    "Une équipe dévouée et passionnée. Leur accompagnement a fait toute la différence dans mon projet."
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default AdminProjetBenevolDetail