import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminProjetNavBars from "./AdminProjetNavBars";
import { decryptData } from "../../../encryptionModule";
import axios from "axios";

function AdminProjetModifier() {
  const { isDarVisitLoggined } = useThemes();
  const navigate = useNavigate();
  const { id } = useParams();
  const projetId = decryptData(id);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/projet/${projetId}`
        );
        const data = await response.json();

        // Populate the projects state with fetched data
        if (data) {
          setProjets({
            image: data.image || "", // Assuming 'image' is a field in your data
            photoCouverture: data.photoCouverture || "",
            titre: data.titre || "",
            associationResponsable: data.associationResponsable || "",
            email: data.email || "",
            tel: data.tel || "",
            description: data.description || "",
            messageDeContact: data.messageDeContact || "",
            valide: data.valide || false,
            progression: data.progression || "",
          });
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectData();
  }, [projetId]);

  const [mobile, setMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setBoutLoading] = useState(false);

  useEffect(() => {
    const handleSize = () => {
      setMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  const [projets, setProjets] = useState({
    image: null,
    photoCouverture: null,
    titre: "",
    associationResponsable: "",
    email: "",
    tel: "",
    description: "",
    messageDeContact: "",
    valide: false,
    progression: "",
  });

  const handleInputChange = (field, value) => {
    setProjets((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare your data to send to the API
    const formData = new FormData();
    Object.entries(projets).forEach(([key, value]) => {
      // Append only valid fields to the FormData
      if (value) {
        // Ensure the value is not empty
        formData.append(key, value);
      }
    });

    // Post the data back to the API to update
    try {
      setBoutLoading(true);
      const response = await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/projet/${projetId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the content type is set for form data
          },
        }
      );

      // Check if the response is successful
      if (response.status === 200) {
        toast.success("Le projet est enregistré avec succès");
        navigate("/projet"); // Redirect after success
      } else {
        throw new Error("Failed to update project");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Erreur lors de l'enregistrement du projet");
    } finally {
      setBoutLoading(false);
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminProjetNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navigate("/projet")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="rounded-lg p-2 w-full sm:p-4">
          <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
            <div className="w-full">
              <div className="h-max w-full">
                <div className="px-3 w-full">
                  <div className="w-full flex items-center justify-between">
                    <label className="block text-xs mt-5 font-[700] first-letter:uppercase tracking-wide text-grey-darker sm:text-lg mb-1">
                      Projets
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="rounded-xl p-2 w-full">
                <div className="flex flex-col">
                  <div className="mb-2">
                    <div className="flex flex-col items-center">
                      <label
                        className={`w-full border border-gray-300 block cursor-pointer outline-none focus:border-[#3ad12c] rounded-md p-2.5 bg-transparent`}
                        htmlFor="id_photo"
                      >
                        Photos de couverture
                      </label>
                      <input
                        type="file"
                        accept=".jpeg,.png,.jpg,.gif"
                        className="w-full hidden mt-1"
                        id="id_photo"
                        onChange={(e) =>
                          handleInputChange("image", e.target.files[0])
                        }
                      />
                      <div className="flex w-full justify-start">
                        {projets.image ? (
                          <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                            <img
                              src={URL.createObjectURL(projets.image)}
                              alt="Aperçu"
                              className="object-cover w-full h-full"
                            />
                          </div>
                        ) : (
                          <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                            <img
                              src={`https://imuhirabackend.eac-advisor.org/uploads/projet/${projets.photoCouverture}`}
                              alt={projets.titre}
                              className="object-cover w-full h-full"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="titre"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Titre du projet
                    </label>
                    <input
                      type="text"
                      id="titre"
                      value={projets.titre}
                      onChange={(e) =>
                        handleInputChange("titre", e.target.value)
                      }
                      placeholder="Le titre du projet"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>
                  <div className="flex flex-col ">
                    <label
                      htmlFor="association_responsable"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Association responsable
                    </label>
                    <input
                      type="text"
                      id="association_responsable"
                      value={projets.associationResponsable}
                      onChange={(e) =>
                        handleInputChange(
                          "associationResponsable",
                          e.target.value
                        )
                      }
                      placeholder="Association responsable"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="email"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      value={projets.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      placeholder="Email"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="tel"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Téléphone
                    </label>
                    <input
                      type="text"
                      id="tel"
                      value={projets.tel}
                      onChange={(e) => handleInputChange("tel", e.target.value)}
                      placeholder="Téléphone"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="description"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      value={projets.description}
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                      placeholder="Description du projet"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c] h-[15em]"
                    ></textarea>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="message_de_contact"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Message de contact
                    </label>
                    <textarea
                      id="message_de_contact"
                      value={projets.messageDeContact}
                      onChange={(e) =>
                        handleInputChange("messageDeContact", e.target.value)
                      }
                      placeholder="Message de contact"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c] h-[15em]"
                    ></textarea>
                  </div>
                  <div className="flex flex-col">
                    <label className="flex items-center mt-5">
                      <input
                        type="checkbox"
                        checked={projets.valide}
                        onChange={() =>
                          handleInputChange("valide", !projets.valide)
                        }
                        className="mr-2"
                      />
                      Projet validé
                    </label>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="progression"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Progression
                    </label>
                    <input
                      type="text"
                      id="progression"
                      value={projets.progression}
                      onChange={(e) =>
                        handleInputChange("progression", e.target.value)
                      }
                      placeholder="Progression"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>
                  <div className="flex justify-center mt-4">
                    <button
                      type="submit"
                      className={`${
                        boutLoading
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-[#3ad12c] hover:bg-green-600"
                      } text-white font-bold py-2 px-4 rounded`}
                      disabled={boutLoading}
                    >
                      {boutLoading
                        ? "Chargement..."
                        : "Enregistrer les modifications"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminProjetModifier;
