import React, { useEffect, useState } from "react";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext";
import { Drawer } from "rsuite";
import { Link } from "react-router-dom";
import SignaleForm from "../../Visiteurs/Signal/SignaleForm";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { encryptData } from "../../../encryptionModule";

function AdminSignal() {
  const [mobile2, setMobile2] = useState(window.innerWidth <= 768);
  const [signalements, setSignalements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isSigner, GetisSigner] = useState(false);
  const { isDarVisitLoggined } = useThemes();

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchSignalements(currentPage, pageSize);
    // Optional: You could add a cleanup function for axios requests here
  }, [currentPage, pageSize]);

  const fetchSignalements = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/signalements?page=${page}&size=${size}&searchItem=${
          search || ""
        }`
      );
      setSignalements(response.data.signalements);
      setTotalPages(response.data.totalPages);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des signalements :",
        error.message
      );
    }
  };

  const toggleStatus = async (id, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/signalements/${id}/status`,
        {
          status: newStatus,
        }
      );
      fetchSignalements(currentPage, pageSize);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalCount);
    return `${from} à ${to} signalements sur ${totalCount}`;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
    fetchSignalements(1, pageSize, event.target.value); // Trigger search
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark"
          ? "bg-gray-900 text-white"
          : "bg-gray-100 text-gray-900"
      } overflow-x-hidden h-[87vh]`}
    >
      <div className="container mx-auto p-4">
        <h1 className="text-4xl font-bold text-center mb-8">
          Liste des Problèmes Signalés
        </h1>

        <div className="mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Rechercher un problème..."
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 transition-all"
          />
        </div>

        <div className={`${mobile2 ? "mb-5" : ""}`}>
          <div className="flex items-center mb-4">
            <p className="sm:text-xl">Taille de la page :</p>
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded-lg"
            >
              {[1, 5, 10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {signalements.map((signalement) => (
            <div
              key={signalement.id}
              className={`${
                isDarVisitLoggined === "dark"
                  ? "bg-gray-800 text-white"
                  : "bg-white text-gray-900"
              } shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow`}
            >
              <h2 className="text-2xl font-semibold mb-2">{signalement.nom}</h2>
              <p>
                <strong>Contact :</strong> {signalement.coordonnees}
              </p>
              <p>
                <strong>Description :</strong> {signalement.description}
              </p>
              <p>
                <strong>Gravité :</strong> {signalement.gravite}
              </p>
              <p>
                <strong>Actions :</strong> {signalement.actionprise}
              </p>
              <p>
                <strong>Attentes :</strong> {signalement.attentes}
              </p>

              <p>
                <strong>Date de création :</strong> Le{" "}
                {new Date(signalement.createdAt).toLocaleDateString("fr-FR")}
              </p>
              <p>
                <strong>Coordonnées :</strong> Longitude:{" "}
                {signalement.longitude}, Latitude: {signalement.latitude}
              </p>
              <p>
                <strong>Status :</strong>{" "}
                {signalement.status ? "Validé" : "Non validé"}
              </p>

              <button
                onClick={() => toggleStatus(signalement.id, signalement.status)}
                className={`mt-3 px-4 py-2 rounded-lg transition-all ${
                  signalement.status
                    ? "bg-red-500 hover:bg-red-600"
                    : "bg-green-500 hover:bg-green-600"
                } text-white font-bold`}
              >
                {signalement.status ? "Désactiver" : "Valider"}
              </button>

              <Link
                to={`/signal/detail/${encryptData(signalement.id.toString())}`}
                className="mt-4 px-4 py-2 rounded-lg transition-all bg-blue-500 hover:bg-blue-600 text-white font-bold"
              >
                Détails
              </Link>

              {signalement.image && (
                <div className="mt-4">
                  <img
                    src={`https://imuhirabackend.eac-advisor.org/uploads/signal/${signalement.image}`}
                    alt={signalement.nom}
                    className="w-full h-auto rounded-lg object-cover"
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        {signalements.length > 0 && (
          <>
            <div className="mt-6 flex items-center justify-center space-x-2">
              <button
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-1 rounded-lg transition-all ${
                      currentPage === pageNumber
                        ? "bg-blue-600 text-white"
                        : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                    }`}
                  >
                    {pageNumber}
                  </button>
                )
              )}
              <button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Suivant
              </button>
            </div>

            <div className="mt-4 text-center">
              <p className="text-lg">{getRange()}</p>
            </div>
          </>
        )}
        <div className="flex justify-end">
          <button
            onClick={() => GetisSigner(true)}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg transition-transform transform hover:scale-105"
          >
            Signaler un problème
          </button>
        </div>
      </div>

      <Drawer
        size="full"
        open={isSigner}
        placement="top"
        onClose={() => GetisSigner(false)}
      >
        <Drawer.Header>
          <Drawer.Title className="text-center uppercase text-2xl">
            Signaler un Problème
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <SignaleForm />
        </Drawer.Body>
      </Drawer>

      <Footer />
    </div>
  );
}

export default AdminSignal;
