import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import MapComponent from '../../Visiteurs/Signal/MapComponent'
import Footer from '../../Visiteurs/FootentContent/Footer'


function AdminSignalProposeDetail() {
    const { isDark,  isDarVisitLoggined } = useThemes()
    const navig = useNavigate()

    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className="container mx-auto p-4">
                <div onClick={() => navig("/signal/propose")} className='w-7 h-7 mt-2 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                    </svg>
                </div>
                <h1 className="text-3xl font-bold text-center mb-8">Problèmes signalés</h1>
                <div className={`${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} rounded`}>
                    <MapComponent />

                    <div

                        className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6 `}
                    >


                        <h2 className="text-xl  font-semibold mb-2">MUKINAMA</h2>
                        <p className="">
                            <strong>Contact :</strong> 683923
                        </p>
                        <p className=" text-lg">
                            <strong>Description :</strong> 82323jndjsd aknsajsna
                        </p>
                        <p className="flex">
                            <strong>Cordonne :</strong>
                            <div className='ml-3'>
                                <span className='ml-2'>long: 28,2829002</span>
                                <span className='ml-2'>lat: -34,373894</span>
                            </div>
                        </p>
                        <p className="">
                            <strong>Gravité :</strong> jasnajs
                        </p>
                        <p className="">
                            <strong>Actions :</strong> ajsnja
                        </p>
                        <p className="">
                            <strong>Attentes :</strong> ajsn
                        </p>
                    </div>

                    <div className='m-2  flex gap-4 pb-4 justify-end w-[95%]'>
                        <div class="bg-[green] w-max cursor-pointer  text-white font-semibold py-2 px-4 rounded inline-block">Valider</div>
                        <div class="bg-[red] w-max cursor-pointer  text-white font-semibold py-2 px-4 rounded inline-block">Annuler</div>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default AdminSignalProposeDetail