import React, { useState } from 'react'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

function OurProuts() {
    const [startCount, setStartCount] = useState(false);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 1,
        onChange: (inView) => {
            if (inView) {
                setStartCount(true);
            }
        }
    });


    return (
        <>
            <div data-aos-duration="400"  className='p-2 flex gap-5 w-full px-20 justify-center'>
                <div data-aos="fade-left" data-aos-delay="100" className='w-[17em] p-2 shadow-xl flex justify-center flex-col items-center rounded-lg border' >
                    <div className='w-[5em] h-[5em] rounded overflow-hidden'>
                        <img className='w-full h-full object-cover' src='image/Environnement.jpg' alt='' />
                    </div>
                    <div className='first-letter:uppercase font-extrabold mt-2 mb-4 text-center text-xl '>éducation Environnement</div>
                    <div className='text-center text-lg'>Accédez à des cours en ligne gratuits sur l'écologie et le développement durable</div>
                </div>
                <div data-aos="fade-left" data-aos-delay="300" className='w-[17em] p-2 shadow-xl flex justify-center flex-col items-center rounded-lg border' >
                    <div className='w-[5em] h-[5em] rounded overflow-hidden'>
                        <img className='w-full h-full object-cover' src='image/ProjetForet.jpg' alt='' />
                    </div>
                    <div className='first-letter:uppercase font-extrabold mt-2 mb-4 text-center text-xl '>
                        Projet de reforestation
                    </div>
                    <div className='text-center text-lg'>
                        Participez à  nos campagnes de plantation d'arbres à  travers le pays
                    </div>
                </div>

                <div data-aos="fade-left" data-aos-delay="600" className='w-[17em] p-2 shadow-xl flex justify-center flex-col items-center rounded-lg border' >
                    <div className='w-[5em] h-[5em] rounded overflow-hidden'>
                        <img className='w-full h-full object-cover' src='image/dechets.jpg' alt='' />
                    </div>
                    <div className='first-letter:uppercase font-extrabold mt-2 mb-4 text-center text-xl '>
                        Gestion des déchets
                    </div>
                    <div className='text-center text-lg'>
                        Apprenez les meilleurs pratiques pour réduire,réutiliser et recycler
                    </div>
                </div>

                <div data-aos="fade-left" data-aos-delay="800" className='w-[17em] p-2 shadow-xl flex justify-center flex-col items-center rounded-lg border' >
                    <div className='w-[5em] h-[5em] rounded overflow-hidden'>
                        <img className='w-full h-full object-cover' src='image/comminaute.jpg' alt='' />
                    </div>
                    <div className='first-letter:uppercase font-extrabold mt-2 mb-4 text-center text-xl '>
                        Communauté engage
                    </div>
                    <div className='text-center text-lg'>
                        Rejoignez un réseau de Burundais passionnés par l'environnement
                    </div>
                </div>
            </div>

            <div data-aos-duration="700" ref={ref} className={` grid grid-cols-3 px-44 gap-2 justify-around w-full`}>
                <div data-aos="fade-up" data-aos-delay="100" className='w-[95%] h-[10em] shadow-xl border flex flex-col border-[#3ad12c] border-dotted items-center justify-center m-2 rounded-md '>
                    <div className='flex justify-between'>
                        <div className={`text-[30px] text-[#3ad12c] font-bold`}> <CountUp start={0} end={startCount ? 30230 : 0} duration={2} separator="," /> + </div>
                    </div>
                    <div className={`font-serif text-gray-400 text-xl `}>Arbes plantés</div>
                </div>
                <div data-aos="fade-up" data-aos-delay="300" className='w-[95%] h-[10em] shadow-xl border flex flex-col border-[#3ad12c] border-dotted items-center justify-center m-2 rounded-md '>
                    <div className='flex justify-between'>
                        <div className={`text-[30px] text-[#3ad12c] font-bold`}> <CountUp start={0} end={startCount ? 53030 : 0} duration={2} separator="," /> + </div>
                    </div>
                    <div className={`font-serif text-gray-400 text-xl `}>Nombres actifs</div>
                </div>
                <div data-aos="fade-up" data-aos-delay="600" className='w-[95%] h-[10em] shadow-xl border flex flex-col border-[#3ad12c] border-dotted items-center justify-center m-2 rounded-md '>
                    <div className='flex justify-between'>
                        <div className={`text-[30px] text-[#3ad12c] font-bold`}> <CountUp start={0} end={startCount ? 38030 : 0} duration={2} separator="," /> + </div>
                    </div>
                    <div className={`font-serif text-gray-400 text-xl `}>Projets en cours</div>
                </div>
            </div>
        </>
    )
}

export default OurProuts


