import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'

function EquipmentDonation() {

    const [nomComplet, GetnomComplet] = useState('')
    const elementRef = useRef(null)

    const [email, Getemail] = useState('')
    const elementemailRef = useRef(null)

    const [isTextarea, GetisTextarea] = useState('')
    const elementisTextareaRef = useRef(null)

    const hundleSubmite = (e) => {
        e.preventDefault();
        if (nomComplet.trim() == "") {
            toast.warning("Le nom complet est obligatpire !!")
            if (elementRef && elementRef.current) {
                elementRef.current.focus()
            }
            return false
        } else if (email.trim() === "") {
            toast.warning("L'email est obligatpire !!")
            if (elementemailRef && elementemailRef.current) {
                elementemailRef.current.focus()
            }
        } else if (isTextarea.trim() === "") {
            toast.warning("Le détails du matériel est obligatpire !!")
            if (elementisTextareaRef && elementisTextareaRef.current) {
                elementisTextareaRef.current.focus()
            }
        } else {
            toast.success("Votre demande de parrainage a été soumise !");
        }


    }

    return (
        <div className='p-8'>
            <p className="mb-4 text-center ">
                Merci de remplir ce formulaire pour faire un don de matériel. Votre contribution aidera à fournir des équipements essentiels pour l'éducation et les jardins scolaires.
            </p>
            <form
                onSubmit={hundleSubmite}
            >
                <div className="mb-4">
                    <label htmlFor="name" className="block text-lg font-semibold mb-2">Nom</label>
                    <input
                        type="text"
                        id="name"
                        onInput={(e) => GetnomComplet(e.target.value)}
                        ref={elementRef}
                        className="block w-full  p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-outline-none bg-transparent"

                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-lg font-semibold mb-2">Email</label>
                    <input
                        type="email"
                        id="email"
                        onInput={(e) => Getemail(e.target.value)}
                        ref={elementemailRef}
                        className="block w-full  p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-outline-none bg-transparent"

                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="details" className="block text-lg font-semibold mb-2">Détails du matériel</label>
                    <textarea
                        id="details"
                        onInput={(e) => GetisTextarea(e.target.value)}
                        ref={elementisTextareaRef}
                        className="block w-full min-h-[15em]  p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-outline-none bg-transparent"

                    ></textarea>
                </div>
                <button type="submit" className="w-max mb-10 bg-outline-none  text-white py-2 px-4 bg-[#3ad12c] rounded-lg hover:scale-105 transition-all focus:outline-none focus:ring-2  focus:ring-opacity-50">
                    Soumettre
                </button>
            </form>
        </div>
    )
}

export default EquipmentDonation