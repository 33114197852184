import React, { useState } from 'react'
import { Drawer, Progress } from 'rsuite'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AdminProjetNavBars from './AdminProjetNavBars'
import Footer from '../../Visiteurs/FootentContent/Footer'

function AdminProjetProposeDetail() {
    const [progession, Getprogession] = useState(85)
    const { handleScrollToProjet, projetRef, HundlwScrollTop, isPropoProjet, SetisPropoProjet, isDarVisitLoggined } = useThemes()
    const navig = useNavigate()

    const [titre, setTitre] = useState('');
    const [description, setDescription] = useState('');
    const [association, setAssociation] = useState('');
    const [partenaires, setPartenaires] = useState('');
    const [parcent, setparcent] = useState('');
    const [objectifs, setObjectifs] = useState('');

    const validateForm = () => {
        if (!titre) {
            toast.warning("Titre du projet requis");
            return false;
        }
        if (!description) {
            toast.warning("Description du projet requise");
            return false;
        }
        if (!association) {
            toast.warning("Association responsable requise");
            return false;
        }
        if (!partenaires) {
            toast.warning("Partenaires potentiels requis");
            return false;
        }
        if (!objectifs) {
            toast.warning("Objectifs et résultats attendus requis");
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const formData = {
                titre,
                description,
                association,
                partenaires,
                objectifs
            };
            toast.success("Les données sont envoyées avec succèss")
            SetisPropoProjet(false)
            console.log('Formulaire soumis:', formData);

        }
    };

    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <AdminProjetNavBars />
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/projet/propo")} className="text-[#3ad12c] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div className={`${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} pb-5 m-1 rounded`}>
                <div class="mx-auto p-8">
                    <div class="space-y-4">
                        <div class="w-full  flex items-center  text-xl gap-3 font-bold p-2 ">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                </svg>
                            </div>
                            <div> Projet Eco-Ecoles</div>
                        </div>
                    </div>
                </div>


                <div ref={projetRef} class="mx-auto px-4">
                    <div class="rounded-md  p-4">
                        <h2 class="text-3xl font-semibold mb-2 text-[#3ad12c] ">Projet Eco-Ecoles</h2>
                        <img src="https://file3.chinastory.cn/group1/M00/CB/77/CgoTp2BRseKAUAYrAA45N8sSgB842.jpeg" alt="Introduction à la Biodiversité au Burundi" class="rounded-md sm:w-[50em] mb-2 h-max" />
                        <div className='flex  text-lg'>
                            <span className='font-bold'>Déscription</span> : Sensibilisation et éducation environnementale dans les écoles du Burundi, encourageant les pratiques écologiques et la gestion durable des ressources.
                        </div>
                    </div>
                    <div>
                        <Progress.Line percent={progession} strokeWidth={20} strokeColor="#3ad12c" />
                        <div className='pl-3 text-lg'><span className='font-bold'>Progression</span>:<span className='ml-2'>{progession}%</span></div>
                    </div>
                    <div class="rounded-lg p-4">
                        <div class="mb-6">
                            <div class="text-lg font-semibold  mb-2">Impact du projet :</div>
                            <ul class="list-disc pl-6 space-y-2  mx-5">
                                <li className='text-lg'>Sensibilisation de plus de 10 000 élèves à l'environnement</li>
                                <li className='text-lg'>Création de 50 jardins scolaires écologiques</li>
                                <li className='text-lg'>Réduction de 30% de la consommation d'eau dans les écoles participantes</li>
                            </ul>
                        </div>
                        <div class="mb-6">
                            <div class="text-lg font-semibold  mb-2">Objectifs et résultats attendus :</div>
                            <ul class="list-disc pl-6 space-y-2  mx-5">
                                <li className='text-lg'>Étendre le programme à 100 écoles d'ici 2025</li>
                                <li className='text-lg'>Former 500 enseignants aux pratiques écologiques</li>
                                <li className='text-lg'>Réduire de 50% les déchets produits dans les écoles participantes</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="w-full mx-auto px-6 rounded-lg">
                    <h1 class="text-2xl font-bold mb-4">Soumettez Votre Projet</h1>
                    <p class="mb-4 text-lg">Votre contribution est précieuse et peut faire une grande différence.</p>
                    <h2 class="text-xl font-semibold mb-2">Comment Soumettre Votre Projet :</h2>
                    <ul class="list-disc list-inside mb-4">
                        <li className='ml-10 text-lg'>Remplissez le formulaire de soumission avec les détails de votre projet.</li>
                        <li className='ml-10 text-lg'>Assurez-vous de fournir des informations claires et complètes.</li>
                        <li className='ml-10 text-lg'>Cliquez sur “Envoyer” pour soumettre votre projet.</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminProjetProposeDetail