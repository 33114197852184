import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import Footer from '../../Visiteurs/FootentContent/Footer'
import ReactApexChart from 'react-apexcharts';

function AdminAcceuil() {

  const { isDarVisitLoggined } = useThemes()
  const data = {
    series: [{
      name: 'Quantité',
      data: [45, 12, 30, 15, 5]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      xaxis: {
        categories: ['Cours', 'Problèmes', 'Quiz', 'Ressources', 'Projets'],
      },
      colors: ['#1E40AF'], // Couleur des barres
    }
  };
  return (
    <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
      <div className="mx-auto p-6 min-h-[87vh]">
        <h1 className="text-3xl font-bold mb-6">Tableau de Bord Administrateur</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className={`rounded-lg shadow-lg ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-6`}>
            <h2 className="text-xl font-semibold mb-2">Nombre de Cours</h2>
            <p className="text-4xl text-blue-600">45</p>
          </div>
          <div className={`rounded-lg shadow-lg ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-6`}>

            <h2 className="text-xl font-semibold mb-2">Problèmes Signalés</h2>
            <p className="text-4xl text-red-600">12</p>
          </div>
          <div className={`rounded-lg shadow-lg ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-6`}>

            <h2 className="text-xl font-semibold mb-2">Nombre de Quiz</h2>
            <p className="text-4xl text-green-600">30</p>
          </div>
          <div className={`rounded-lg shadow-lg ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-6`}>

            <h2 className="text-xl font-semibold mb-2">Nombre de Ressources</h2>
            <p className="text-4xl text-purple-600">15</p>
          </div>
          <div className={`rounded-lg shadow-lg ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-6`}>

            <h2 className="text-xl font-semibold mb-2">Nombre de Projets</h2>
            <p className="text-4xl text-orange-600">5</p>
          </div>
        </div>
        <div className={`rounded-lg shadow-lg w-full m-2 ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-6`}>
        <ApexChart />
      </div>
      </div>
      <Footer />
    </div>
  )
}

export default AdminAcceuil





class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        data: [400, 430, 448, 470, 540]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: false,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['Nombre de Cours', 'Problèmes Signalés', 'Nombre de Quiz', 'Nombre de Ressources', 'Nombre de Projets'
          ],
        }
      },


    };
  }



  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}
