import React, { useState } from 'react'
import AdminProjetNavBars from './AdminProjetNavBars'
import { useThemes } from '../../../UserContext/UserContext'
import Footer from '../../Visiteurs/FootentContent/Footer'
import { Drawer } from 'rsuite'
import AdminDonProjetRepondre from './AdminDonProjetRepondre'

function AdminProjetDonProjet() {
    const { isDarVisitLoggined } = useThemes()
    const [isDonantion, SetisDonantion] = useState(false)
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <AdminProjetNavBars />
            <h2 class="text-2xl font-bold text-center ">Liste des donateurs</h2>
            <div class="mx-auto grid grid-cols-4 w-full   gap-5  p-6">
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => (
                    <div className={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-4`}>
                        <div>
                            <p class="text-lg font-semibold ">John Doe</p>
                            <p class="text-sm ">Montant: <span class="font-medium ">$100</span></p>
                            <p class="text-sm ">Type de paye: <span class="font-medium ">Carte Bancaire</span></p>
                        </div>
                        <div class="mt-3 text-[10px] italic">
                            <p class="text-sm ">Date: 15 Septembre 2024</p>
                        </div>
                        <div className='flex w-full justify-end items-center p-2'>
                            <div onClick={() => SetisDonantion(true)} className=' border px-2 py-1 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer'>
                                Accepter
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Drawer open={isDonantion} onClose={() => SetisDonantion(false)} placement='right' size="xs">
                <Drawer.Header>
                    <Drawer.Title className='text-center font-bold uppercase'>Faire un don</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <AdminDonProjetRepondre />
                </Drawer.Body>
            </Drawer>
            <Footer />
        </div>
    )
}

export default AdminProjetDonProjet