/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import axios from "axios";
function AdminODDAjouter() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", hundlesSize);
    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", hundleSize);
    return () => {
      window.removeEventListener("resize", hundleSize);
    };
  }, []);
  const utilisateurId = localStorage.getItem("userId");
  const [odd, setBlocs] = useState([
    {
      image: "",
      titre: "",
      descript: "",
      type: "",
      pdf: "",
      elements_odd: [
        {
          sous_titre: "",
        },
      ],

      preview: "",
      isAutre: false,
    },
  ]);

  const addBloc = () => {
    setBlocs((prevBlocs) => [
      ...prevBlocs,
      {
        image: "",
        titre: "",
        descript: "",
        pdf: "",
        elements_odd: [
          {
            sous_titre: "",
          },
        ],
        preview: "",
        isAutre: false,
      },
    ]);

    if (
      inputRefs.current[odd.length] &&
      inputRefs.current[odd.length].elements_odd.length === 0
    ) {
      inputRefs.current[odd.length].elements_odd.push({
        sous_titre: React.createRef(),
      });
    }
  };
  const removeBloc = (index) => {
    const newBlocs = [...odd];
    newBlocs.splice(index, 1);
    setBlocs(newBlocs);
  };
  const inputRefs = useRef([]);
  if (inputRefs.current.length !== odd.length) {
    inputRefs.current = Array(odd.length)
      .fill({})
      .map((_, index) => ({
        image: React.createRef(null),
        titre: React.createRef(null),
        descript: React.createRef(null),
        pdf: React.createRef(null),
        type: React.createRef(null),
        elements_odd: Array(odd[index].elements_odd.length)
          .fill({})
          .map((__, tableIndex) => ({
            sous_titre: React.createRef(null),
          })),
      }));
  }

  const handleInputChange = (index, field, value) => {
    const newBlocs = [...odd];
    if (value === "autre") {
      newBlocs[index].isAutre = true;
    }
    if (field === "image") {
      newBlocs[index].image = value;
      newBlocs[index].preview = URL.createObjectURL(value);
    } else {
      newBlocs[index][field] = value;
    }

    setBlocs(newBlocs);
  };

  // ...........................Module................
  const addelements_odd = (index) => {
    const newBlocs = [...odd];
    newBlocs[index].elements_odd.push({ sous_titre: "" });
    setBlocs(newBlocs);
    // Vérifie si les références dans inputRefs sont synchronisées avec le nouveau elements_odd
    if (
      inputRefs.current[index].elements_odd.length !==
      newBlocs[index].elements_odd.length
    ) {
      inputRefs.current[index].elements_odd.push({
        sous_titre: React.createRef(),
      });
    }
  };
  const removeelements_odd = (blocIndex, tableIndex) => {
    const newBlocs = [...odd];
    newBlocs[blocIndex].elements_odd.splice(tableIndex, 1);
    inputRefs.current[blocIndex].elements_odd.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };

  const handleModuleInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...odd];
    newBlocs[blocIndex].elements_odd[tableIndex][field] = value;
    setBlocs(newBlocs);
  };

  // ....................................................................................................

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateBloc = (bloc, index) => {
    const requiredFields = ["image", "titre", "descript"];
    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "image"
                ? "image "
                : field === "titre"
                ? "titre "
                : field === "descript"
                ? "déscription "
                : null}
            </strong>{" "}
            est obligatoire à la block {index + 1}.
          </div>
        );
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.classList.add(
            "animate__animated",
            "animate__shakeX",
            "border-2",
            "border-red-500",
            "outline-none"
          );
          setTimeout(() => {
            inputRefs.current[index][field].current.classList.remove(
              "animate__animated",
              "animate__shakeX",
              "border-2",
              "border-red-500",
              "outline-none"
            );
          }, 5000);
          inputRefs.current[index][field].current.focus();
          return false;
        }
      }
      animateAndScrollToRef(inputRefs.current[index].titreMateriel);
    }
    return true;
  };

  function toRoman(num) {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
      "XXI",
      "XXII",
      "XXIII",
      "XXIV",
      "XXV",
      "XXVI",
      "XXVII",
      "XXVIII",
      "XXIX",
      "XXX",
    ];
    return romanNumerals[num - 1] || num.toString();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all blocs before submission
    for (let index = 0; index < odd.length; index++) {
      if (!validateBloc(odd[index], index)) {
        return;
      }
    }

    // Create a new FormData object
    const formData = new FormData();

    // Append each bloc's data to the FormData object
    odd.forEach((bloc, index) => {
      formData.append(`odds[${index}][image]`, bloc.image);
      formData.append(`odds[${index}][titre]`, bloc.titre);
      formData.append(`odds[${index}][description]`, bloc.descript);
      formData.append(`odds[${index}][pdf]`, bloc.pdf);
      formData.append(`utilisateurId`, utilisateurId);
      bloc.elements_odd.forEach((element, elemIndex) => {
        formData.append(
          `odds[${index}][elements_odd][${elemIndex}][sous_titre]`,
          element.sous_titre
        );
      });
    });

    // Send the FormData to the backend using axios
    try {
      const response = await axios.post(
        "https://imuhirabackend.eac-advisor.org/apiv1/odd",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Le odd est enregistré avec succès");
        navig("/ressource/odd");
        // Optionally, reset the form or handle the response as needed
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Une erreur est survenue lors de l'enregistrement du odd");
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/ressource/odd")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4  rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        }  flex-col items-center`}
      >
        <div className="rounded-lg p-2   w-full   sm:p-4">
          {odd.map((bloc, index) => (
            <div
              key={index}
              className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2  flex flex-col"
            >
              <div className="w-full">
                <div className="h-max w-full">
                  <div className="px-3 w-full">
                    <div className="w-full flex items_center justify-between">
                      <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                        {toRoman(index + 1)}. ODD
                      </label>
                      {index > 0 && (
                        <button
                          type="button"
                          className="text-red-500 px-1 max-sm:text-xs py-2"
                          onClick={() => removeBloc(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="flex flex-col items-center">
                  <label
                    className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                    ref={inputRefs.current[index].image}
                    htmlFor={`id_photo_${index}`}
                  >
                    Photos de couverture
                  </label>
                  <input
                    type="file"
                    accept=".jpeg,.png,.jpg,.gif"
                    className="w-full hidden mt-1"
                    id={`id_photo_${index}`}
                    onChange={(e) =>
                      handleInputChange(index, "image", e.target.files[0])
                    }
                  />
                  <div className="flex w-full justify-start">
                    {bloc.preview && (
                      <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                        <img
                          src={bloc.preview}
                          alt={`Aperçu ${index}`}
                          className="object-cover w-full h-full"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div className="rounded-xl p-2 w-full">
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <label
                        htmlFor={`titre_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Titre
                      </label>
                      <input
                        email="text"
                        id={`titre_${index}`}
                        value={bloc.titre}
                        onChange={(e) =>
                          handleInputChange(index, "titre", e.target.value)
                        }
                        placeholder="Le  titre"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].titre}
                      />
                    </div>
                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`descript_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Déscription
                      </label>
                      <textarea
                        email="text"
                        id={`descript_${index}`}
                        value={bloc.descript}
                        onChange={(e) =>
                          handleInputChange(index, "descript", e.target.value)
                        }
                        placeholder="Déscription"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].descript}
                      ></textarea>
                    </div>

                    <div className="mb-2">
                      <div className="flex flex-col items-center">
                        <label
                          className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                          ref={inputRefs.current[index].pdf}
                          htmlFor={`pdf_${index}`}
                        >
                          Document pdf
                        </label>
                        <input
                          type="file"
                          accept=".pdf"
                          className="w-full hidden mt-1"
                          id={`pdf_${index}`}
                          onChange={(e) =>
                            handleInputChange(index, "pdf", e.target.files[0])
                          }
                        />

                        <div className="flex w-full justify-start mt-2">
                          {bloc.pdf && (
                            <div className=" overflow-hidden relative ">
                              <span className="font-bold">Nom du fichier</span>:
                              <span className="ml-2">
                                {bloc.pdf.name.split(".")[0]}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="relative mt-2 text-lg">
                        elements_odd{" "}
                        <span className="pl-2 font-bold">{bloc.titre}</span>{" "}
                      </div>
                      {bloc.elements_odd.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="rounded-lg   w-[100%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg flex w-full">
                                  <div className="w-full">
                                    <input
                                      ref={
                                        inputRefs.current[index].elements_odd[
                                          tableIndex
                                        ].sous_titre
                                      }
                                      className="appearance-none block  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.sous_titre}
                                      onChange={(e) =>
                                        handleModuleInputChange(
                                          index,
                                          tableIndex,
                                          "sous_titre",
                                          e.target.value
                                        )
                                      }
                                      placeholder={`${
                                        tableIndex + 1
                                      }. elements_odd`}
                                      id={`titre_${index}_${tableIndex}`}
                                    />
                                  </div>
                                  {bloc.elements_odd.length > 1 && (
                                    <div className="flex justify-end">
                                      <div
                                        montant="button"
                                        className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                        onClick={() =>
                                          removeelements_odd(index, tableIndex)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          fill="currentColor"
                                          class="bi bi-trash3-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => addelements_odd(index)}
                        >
                          Autre elements_odd
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}

          <div className="w-full flex justify-start">
            <div
              className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
              onClick={addBloc}
            >
              Ajouter odd
            </div>
          </div>
        </div>
        <div className="flex justify-end my-5  w-[98%]">
          <button
            type="submit"
            disabled={boutLoading}
            htmlFor="send"
            className="mt-3 cursor-pointer w-max flex justify-end bg-[#3ad12c] p-2 rounded text-white"
          >
            Enregistre
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminODDAjouter;
