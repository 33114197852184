import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminCommunauteBlogDetail from './AdminCommunauteBlogDetail'

function AdminCommunauteBlogDetailComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminCommunauteBlogDetail />
        </div>
    )
}

export default AdminCommunauteBlogDetailComponent