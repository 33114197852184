import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import { encryptData } from "../../../encryptionModule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

function AdminEspeces() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const [especes, setespeces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchespeces(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchespeces = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/especes?page=${page}&size=${size}&searchItem=${
          search || ""
        }`
      );
      setespeces(response.data.especes);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des especes :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Réinitialiser lors de la recherche
    fetchespeces(1, pageSize, event.target.value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton:
          "bg-red-600 text-white hover:bg-red-700 border border-red-600 rounded-lg px-4 py-2", // Styles pour le bouton de confirmation
        cancelButton:
          "bg-gray-300 text-gray-800 hover:bg-gray-400 border border-gray-300 rounded-lg px-4 py-2", // Styles pour le bouton d'annulation
      },
      buttonsStyling: false, // Empêche l'application de styles par défaut de SweetAlert2
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/especes/${id}`
          );
          setespeces(especes.filter((espece) => espece.id !== id));
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          toast.error("La suppression a échoué.");
        }
      }
    });
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="flex flex-wrap w-full p-5 justify-between items-center mb-4">
        <div className="flex items-center mb-4">
          <label className="text-lg">Taille de la page :</label>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="ml-2 p-2 bg-white border border-gray-300 rounded-lg"
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="flex w-full lg:w-[60%] items-center mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Rechercher..."
            className="flex-grow p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 transition"
          />
          <button
            onClick={() => navig("/ressource/espece/ajouter")}
            className="border px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
          >
            Ajouter
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-3 lg:grid-cols-4 gap-4">
        {especes.map((espece, index) => (
          <div
            key={index}
            className={`rounded-md ${
              isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
            } p-4`}
          >
            <img
              src={`https://imuhirabackend.eac-advisor.org/uploads/especes/${espece.image}`}
              alt={espece.nomfrancais}
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-xl font-bold">{espece.nomfrancais}</h2>
            <p className="">{espece.nomlatin}</p>
            <p className="italic">{espece.nomkirundi}</p>
            <p className="mt-4 text-sm">{espece.region}</p>
            <p className="mt-4 text-sm">{espece.description}</p>

            <div className="flex w-full justify-between items-center p-2">
              <div
                title="Modifier"
                onClick={() =>
                  navig(
                    `/ressource/espece/modif/${encryptData(
                      espece.id.toString()
                    )}`
                  )
                }
                className="border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer"
              >
                <FontAwesomeIcon icon={faEdit} />
              </div>
              <div
                title="Détail"
                onClick={() => {
                  navig(
                    `/ressource/espece/detail/${encryptData(
                      espece.id.toString()
                    )}`
                  );
                }}
                className="border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
              <div
                title="Supprimer"
                onClick={() => handleDelete(espece.id)} // Appel de la fonction de suppression ici
                className="border p-2 border-red-600 rounded text-red-600 hover:scale-105 transition-all cursor-pointer"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {especes.length > 0 && (
        <div className="mt-6 flex justify-center space-x-2">
          <button
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Précédent
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 rounded-lg ${
                currentPage === index + 1
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Suivant
          </button>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default AdminEspeces;
