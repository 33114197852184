import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminRessourceDetail from './AdminRessourceDetail'

function AdminRessourceDetailComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminRessourceDetail />
        </div>
  )
}

export default AdminRessourceDetailComponent