import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'

function AdminDonProjetRepondre() {


    const [isTextarea, GetisTextarea] = useState('')
    const elementisTextareaRef = useRef(null)

    const hundleSubmite = (e) => {
        e.preventDefault();
        if (isTextarea.trim() === "") {
            toast.warning("Le détails du matériel est obligatpire !!")
            if (elementisTextareaRef && elementisTextareaRef.current) {
                elementisTextareaRef.current.focus()
            }
        } else {
            toast.success("Votre demande de parrainage a été soumise !");
        }


    }

    return (
        <div className='p-8'>
            <p className="mb-4 text-center ">
                Merci de remplir ce formulaire pour repondre un don du projet. </p>
            <form
                onSubmit={hundleSubmite}
            >

                <div className="mb-4">
                    <label htmlFor="details" className="block text-lg font-semibold mb-2">Remercement</label>
                    <textarea
                        id="details"
                        onInput={(e) => GetisTextarea(e.target.value)}
                        ref={elementisTextareaRef}
                        className="block w-full min-h-[10em]  p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-outline-none bg-transparent"

                    ></textarea>
                </div>
                <button type="submit" className="w-max mb-10 bg-outline-none  text-white py-2 px-4 bg-[#3ad12c] rounded-lg hover:scale-105 transition-all focus:outline-none focus:ring-2  focus:ring-opacity-50">
                    Repondre
                </button>
            </form>
        </div>
    )
}

export default AdminDonProjetRepondre