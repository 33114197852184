import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminODDAjouter from './AdminODDAjouter'

function AdminODDAjouterComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
    <AdminSlide />
    <AdminODDAjouter />
</div>
  )
}

export default AdminODDAjouterComponent