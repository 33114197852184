import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminSignalProposeDetail from './AdminSignalProposeDetail'

function AdminSignalProposeDetailComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminSignalProposeDetail />
        </div>
  )
}

export default AdminSignalProposeDetailComponent