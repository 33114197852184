import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminProjetDonMaterielDetail from './AdminProjetDonMaterielDetail'

function AdminProjetDonMaterielDetailCompnent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
    <AdminSlide />
    <AdminProjetDonMaterielDetail />
</div>
  )
}

export default AdminProjetDonMaterielDetailCompnent