
import React from 'react'

import AdminSlide from '../AdminSlide/AdminSlide'
import AdminCoursDetail from './AdminCoursDetail'

function AdminCoursComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
        <AdminSlide/>
        <AdminCoursDetail/>
    </div>
  )
}

export default AdminCoursComponent