import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";

function AdminConventioDetail() {
  const { isDark, isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const conventioId = decryptData(id); // Décrypter l'ID

  const [convention, setConvention] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConvention = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/convention/${conventioId}`
        );
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération de la convention");
        }
        const data = await response.json();
        setConvention(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchConvention();
  }, [conventioId]);

  if (loading) {
    return <div className="text-center py-10">Chargement...</div>;
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-10">Erreur : {error}</div>
    );
  }

  if (!convention) {
    return <div className="text-center py-10">La convention n'existe pas</div>;
  }

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark"
          ? "bg-gray-900 text-white"
          : "bg-gray-100 text-gray-900"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />

      <div className="container mx-auto px-4 lg:px-8">
        {/* Back button */}
        <div
          onClick={() => {
            navig("/ressource/convention");
          }}
          className="w-10 h-10 bg-green-500 hover:bg-green-600 rounded-full cursor-pointer flex justify-center items-center text-white mb-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-arrow-left-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
            />
          </svg>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Image Section */}
          <div className="w-full">
            <div className="h-[40vh] md:h-[60vh] lg:h-[70vh] overflow-hidden rounded-lg shadow-lg">
              <img
                className="object-cover w-full h-full"
                src={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.photoCouverture}`}
                alt={convention.titre}
              />
            </div>
          </div>

          {/* Convention Details */}
          <div
            className={`rounded-lg shadow-lg p-6 ${
              isDark === "dark" ? "bg-gray-800" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">{convention.titre}</h2>
            <p className="mb-2 font-semibold text-gray-600">
              Type: <span className="text-gray-900">{convention.type}</span>
            </p>
            <p className="mb-4 text-justify">{convention.description}</p>

            {/* Elements List */}
            <p className="font-semibold mb-2">Éléments de la convention :</p>
            <ul className="list-disc pl-5 mb-4">
              {convention.elements.map((element) => (
                <li key={element.id} className="mb-2">
                  {element.titre}
                </li>
              ))}
            </ul>

            {/* Display attachment with download option */}
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">
                Document d'attachement :
              </h3>
              <iframe
                src={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.attachement}`}
                title="Attachement"
                width="100%"
                height="400px"
                className="border-2 border-gray-300 rounded-md"
              ></iframe>
              <a
                target="_blank"
                rel="noopener noreferrer" // Ajout de cette ligne pour des raisons de sécurité
                href={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.attachement}`}
                download
                className="mt-4 inline-block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded shadow-md transition duration-300 ease-in-out"
              >
                Télécharger le document
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AdminConventioDetail;
