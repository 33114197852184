import React, { useEffect, useState } from "react";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { encryptData } from "../../../encryptionModule";

function AdiminRapport() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const [rapports, setrapports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, settotalItems] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchrapports(currentPage, pageSize);
    // Optional: You could add a cleanup function for axios requests here
  }, [currentPage, pageSize]);

  const fetchrapports = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/rapports?page=${page}&size=${size}&searchTerm=${
          search || ""
        }`
      );
      setrapports(response.data.rapports);
      setTotalPages(response.data.totalPages);
      settotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des rapports :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [mobile2, setMobile2] = useState(window.innerWidth <= 768);
  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalItems);
    return `${from} à ${to} rapports sur ${totalItems}`;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
    fetchrapports(1, pageSize, event.target.value); // Trigger search
  };

  const handleDelete = async (id) => {
    // Afficher la boîte de dialogue SweetAlert2
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/rapports/${id}`
          );

          setrapports(rapports.filter((rapport) => rapport.id !== id));

          // Afficher un message de succès
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          console.error(
            "Erreur lors de la suppression de la ressource :",
            error.message
          );

          // Afficher un message d'erreur
          Swal.fire(
            "Erreur",
            "Un problème est survenu lors de la suppression de la ressource.",
            "error"
          );
        }
      } else {
        console.log("Suppression annulée.");
      }
    });
  };
  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div class="rounded-lg  p-6 md:p-8">
        <div class=" ">
          <div class=" mx-auto">
            <div class="rounded-lg">
              <div class="p-6">
                <div class="flex items-center justify-between w-full mb-4">
                  <div className={`${mobile2 ? "mb-5" : ""}`}>
                    <div className="flex items-center mb-4">
                      <p className="sm:text-xl">Taille de la page :</p>
                      <select
                        value={pageSize}
                        onChange={(e) =>
                          handlePageSizeChange(Number(e.target.value))
                        }
                        className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded-lg"
                      >
                        {[1, 5, 10, 20, 50, 100].map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-[60%] items-center">
                    <div className="mb-4">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Rechercher un problème..."
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 transition-all"
                      />
                    </div>
                    <button
                      onClick={() => navig("/ressource/rapport/ajouter")}
                      className="border ml-5 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {rapports.length > 0 ? (
            rapports.map((rapport, i) => (
              <div
                key={i}
                className={`rounded-xl overflow-hidden border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c] ${
                  isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
                }`}
              >
                <h2 className="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap py-4 text-start bg-[#3ad12c0e] text-ellipsis overflow-hidden">
                  {i + 1}. {rapport.titre}
                </h2>
                <p className="mb-1 p-2 text-[18px]">{rapport.description}</p>
                <div className="space-y-2 px-4">
                  <p className="text-lg font-bold mt-4 mx-4 mb-2">
                    Actions réalisées :
                  </p>
                  <ul className="list-disc pl-6 space-y-2 mx-5">
                    {rapport.realiserapports.map((action, index) => (
                      <li key={index}>{action.description}</li>
                    ))}
                  </ul>
                  <div>
                    <p className="text-lg font-bold mt-4 mx-4 mb-2">
                      Actions à entreprendre :
                    </p>
                    <ul className="list-disc pl-6 space-y-2 mx-5">
                      {rapport.entreprendrereports.map((action, index) => (
                        <li key={index}>{action.description}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex w-full justify-between items-center p-2">
                  <div
                    title="Détail"
                    onClick={() => {
                      navig(
                        `/ressource/rapport/detail/${encryptData(
                          rapport.id.toString()
                        )}`
                      );
                    }}
                    className="border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                    </svg>
                  </div>

                  <div
                    onClick={() => handleDelete(rapport.id)}
                    title="Supprimer"
                    className="border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-trash3-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                    </svg>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Aucun rapport trouvé.</p>
          )}
        </div>

        {rapports.length > 0 && (
          <>
            <div className="mt-6 flex items-center justify-center space-x-2">
              <button
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Précédent
              </button>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-1 rounded-lg transition-all ${
                      currentPage === pageNumber
                        ? "bg-blue-600 text-white"
                        : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                    }`}
                  >
                    {pageNumber}
                  </button>
                )
              )}
              <button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
              >
                Suivant
              </button>
            </div>

            <div className="mt-4 text-center">
              <p className="text-lg">{getRange()}</p>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default AdiminRapport;
