import React, { useState, useEffect } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { decryptData } from "../../../encryptionModule";

function SignalerDetail() {
  const { id } = useParams();
  const signalId = decryptData(id); // Assuming you have a decryption function
  const utilisateurId = localStorage.getItem("userId");
  const { isDark, HundlwScrollTop } = useThemes();
  const navig = useNavigate();
  const [isCommentaire, setIsCommentaire] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [signalData, setSignalData] = useState(null); // State to store signal data

  useEffect(() => {
    const fetchSignalData = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/signalements/${signalId}`
        );
        setSignalData(response.data);
      } catch (error) {
        console.error("Error fetching signal data:", error);
        toast.error("Erreur lors du chargement des données du signal.");
      }
    };

    fetchSignalData();
  }, [signalId]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (newComment.trim() === "") {
      toast.warning("Le champ de commentaire ne doit pas être vide !!");
      return;
    } else {
      try {
        const response = await axios.post(
          `https://imuhirabackend.eac-advisor.org/apiv1/signalements/${signalId}/comments`,
          {
            utilisateurId: utilisateurId,
            comment: newComment,
          }
        );

        if (response.status === 201) {
          toast.success("Merci pour votre commentaire !");
          setNewComment(""); // Clear the comment input after success
          setIsCommentaire(false);
        } else {
          toast.error("Une erreur s'est produite. Veuillez réessayer.");
        }
      } catch (error) {
        console.error("Error submitting comment:", error);
        toast.error(
          "Erreur lors de l'ajout du commentaire. Veuillez réessayer."
        );
      }
    }
  };
  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Intl.DateTimeFormat("fr-FR", options).format(
      new Date(dateString)
    );
  };

  return (
    <div className="mt-[18vh] overflow-hidden">
      <div
        onClick={() => {
          navig("/signal");
          HundlwScrollTop();
        }}
        className="w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-arrow-left-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
          />
        </svg>
      </div>
      <div className="container mx-auto p-4">
        <div data-aos="fade-up" className="grid grid-cols-1 gap-6">
          {signalData && (
            <div
              className={`${
                isDark === "dark" ? "bg-gray-800" : "bg-white"
              } rounded-lg p-6`}
            >
              <div className="h-[60vh]">
                <img
                  className="w-auto h-full"
                  src={
                    signalData.image
                      ? `https://imuhirabackend.eac-advisor.org/images/${signalData.image}`
                      : "placeholder.jpg"
                  }
                  alt="SignalerDetailment"
                />
              </div>
              <h2 className="text-xl font-semibold mb-2">{signalData.nom}</h2>
              <p>
                <strong>Contact :</strong> {signalData.coordonnees}
              </p>
              <div className="text-lg my-2">{signalData.description}</div>
              <p>
                <strong>Lieu :</strong> {signalData.location}
              </p>
              <p>
                <strong>Gravité :</strong> {signalData.gravite}
              </p>
              <p>
                <strong>Actions :</strong> {signalData.actionprise}
              </p>
              <p>
                <strong>Attentes :</strong> {signalData.attentes}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-end w-full">
          <div
            onClick={() => setIsCommentaire(!isCommentaire)}
            className="bg-[#3ad12c] cursor-pointer hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded-lg inline-block mt-4"
          >
            Votre commentaire
          </div>
        </div>
        {isCommentaire && (
          <div className="mt-4">
            <textarea
              className="w-full h-[15em] rounded-md border outline bg-transparent focus:border-[#3ad12c] border-gray-300 p-2"
              placeholder="Ajouter un commentaire..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            ></textarea>
            <button
              className="bg-[#3ad12c] hover:scale-105 transition-all text-white font-medium py-2 px-4 rounded-md mt-2"
              onClick={handleCommentSubmit}
            >
              Envoyer
            </button>
          </div>
        )}
        <hr />
        <div className="mb-10 text-lg">Commentaires</div>
        {signalData?.comments?.map((comment, i) => (
          <div
            key={i}
            data-aos="fade-up"
            className={`mt-3 ${
              isDark === "dark" ? "bg-gray-800" : "bg-white"
            } shadow-md rounded-lg p-6`}
          >
            <div className="flex items-start">
              <div className="rounded-full bg-[#3ad12c] text-white w-8 h-8 flex items-center justify-center mr-4">
                {comment.utilisateur.nom.charAt(0).toUpperCase()}
              </div>
              <div>
                <h3 className="font-bold mb-1">{comment.utilisateur.nom}</h3>
                <p>{comment.contenu}</p>
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi text-[#3ad12c] bi-calendar-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>
                  <div className="text-sm">{formatDate(comment.date)}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SignalerDetail;
