/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'
import { Drawer } from 'rsuite'
import { questionsReponses } from '../../../Data/Data'
import { toast } from 'react-toastify'


function ModuleCours() {

    const navig = useNavigate()

    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
    const [detailLocal, SetDetailLocal] = useState(false)
    const [score, setScore] = useState(0);



    const handleOptionChange = (questionId, option) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [questionId]: option,
        }));
    };





    const handleSubmit = (e) => {
        e.preventDefault()
        const allAnswered = questionsReponses.every(
            (question) => selectedAnswers[question.id]
        );

        if (!allAnswered) {
            toast.warning('Veuillez répondre à toutes les questions.');
            return;
        }
       
        let totalScore = 0;
        questionsReponses.forEach((question) => {
          if (selectedAnswers[question.id] === question.correctAnswer) {
            totalScore += 2; // Ajouter 2 points pour chaque bonne réponse
          }
        });
        setScore(totalScore);
        setShowCorrectAnswers(true);
        console.log('Réponses sélectionnées:', selectedAnswers);


    };
    return (
        <div className=' mt-[18vh]  px-4  overflow-hidden'>
            <div onClick={() => navig("/cours/detail")} className='w-7 h-7 mt-2 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div class="grid py-8 grid-cols-1  px-4  gap-4">
                <div>
                    <h1 class="text-2xl font-bold mb-4">Module 1 : Introduction à l'Agroécologie</h1>
                    <div className='ml-3'>
                        <h2 class="text-xl font-bold mb-2 text-[#3ad12c]">1.1 Qu'est-ce que l'agroécologie ?</h2>
                        <p class="text-lg">L'agroécologie est une approche holistique de l'agriculture qui intègre les principes écologiques dans la production alimentaire. Elle vise à créer des systèmes agricoles durables, résilients et équitables.</p>

                        <div className='w-full mt-2 h-[70vh]'>

                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=3jGGXF4_YiE"
                                controls={true}
                            />
                        </div>

                        <div data-aos="fade-up" class="container mx-auto  py-2">
                            <h2 data-aos="fade-up" class="text-2xl font-bold mb-4 text-[#3ad12c]">1.2 Principes fondamentaux de l'agroécologie</h2>
                            <ul data-aos="fade-up" class="space-y-2 list-disc ">
                                <li className='ml-10 text-lg'>Augmenter le recyclage de la biomasse et optimiser la disponibilité des nutriments</li>
                                <li className='ml-10 text-lg'>Assurer des conditions de sol favorables à la croissance des plantes</li>
                                <li className='ml-10 text-lg'>Minimiser les pertes d'énergie solaire, d'air et d'eau</li>
                                <li className='ml-10 text-lg'>Diversifier les espèces et les ressources génétiques dans les agroécosystèmes</li>
                                <li className='ml-10 text-lg'>Améliorer les interactions biologiques bénéfiques et les synergies entre les composantes de l'agrobiodiversité</li>
                            </ul>

                            <h2 data-aos="fade-up" class="text-2xl font-bold mt-8 mb-4 text-[#3ad12c]">1.3 Importance de l'agroécologie pour le Burundi</h2>
                            <p data-aos="fade-up" class="mb-4 text-lg">Au Burundi, l'agroécologie offre des solutions durables pour :</p>
                            <ul data-aos="fade-up" class="space-y-2  list-disc">
                                <li className='ml-10 text-lg'>Améliorer la sécurité alimentaire face aux défis climatiques</li>
                                <li className='ml-10 text-lg'>Préserver la biodiversité locale et les savoirs traditionnels</li>
                                <li className='ml-10 text-lg'>Renforcer la résilience des petits exploitants agricoles</li>
                                <li className='ml-10 text-lg'>Réduire la dépendance aux intrants chimiques coûteux</li>
                                <li className='ml-10 text-lg'>Lutter contre l'érosion des sols et la dégradation des terres</li>
                            </ul>

                            <h2 data-aos="fade-up" class="text-2xl font-bold mt-8 mb-4 text-[#3ad12c]">1.4 Exemple local : La ferme agroécologique de Bukirasazi</h2>
                            <p data-aos="fade-up" class="text-lg">Découvrez comment la ferme de Bukirasazi, située dans la province de Gitega, a réussi à mettre en pratique les principes agroécologiques pour améliorer sa productivité et sa résilience face aux changements climatiques.</p>
                        </div>
                        <div data-aos="fade-up" onClick={() => SetDetailLocal(true)} class="bg-[#3ad12c86] mt-5 w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Lire d'étude de cas</div>


                        <div data-aos="fade-up" class="container mx-auto  mt-10">
                            <h2 data-aos="fade-up" class="text-2xl font-bold mb-4">Quiz rapide</h2>

                            <div >
                                <form class="space-y-2" onSubmit={handleSubmit}>
                                    {questionsReponses.map((data, i) => (
                                        <div key={data.id} data-aos="fade-up" className="mb-8">
                                            <p className="font-bold my-5 text-xl">{data.question}</p>
                                            <div className="space-y-2 text-lg flex flex-col">
                                                {data.options.map((option, index) => (
                                                    <label
                                                        key={index}
                                                        htmlFor={`option-${data.id}-${index}`}
                                                        className="flex cursor-pointer w-max items-center gap-2"
                                                    >
                                                        <div className="w-4 h-4 rounded-full border flex justify-center items-center relative border-[#3ad12c]">
                                                            <input
                                                                checked={selectedAnswers[data.id] === option}
                                                                onChange={() => handleOptionChange(data.id, option)}
                                                                id={`option-${data.id}-${index}`}
                                                                hidden
                                                                name={`options-${data.id}`}
                                                                type="radio"
                                                                className="h-4 w-4 border-gray-300 rounded"
                                                            />
                                                            {selectedAnswers[data.id] === option && (
                                                                <div className="w-2.5 h-2.5 rounded-full flex justify-center items-center top-[2px] absolute bg-[#3ad12c]"></div>
                                                            )}
                                                        </div>
                                                        {option}
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    ))}

                                    <button type='submit' class="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded">Vérifier mes réponses</button>
                                </form>

                                <div data-aos="fade-up" class="text-left mt-10">
                                    <div class="text-green-500 w-max cursor-pointer hover:text-green-600  border border-[#3ad12c] px-4 py-2 rounded">Passer au Module 2 : Techniques de Conservation des Sols</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Drawer open={detailLocal} placement='top' onClose={() => SetDetailLocal(false)} size="full">
                <Drawer.Header>
                    <Drawer.Title className='text-center text-lg'>Exemple local : La ferme agroécologique de Bukirasazi</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div className='text-lg space-y-3 whitespace-break-spaces p-6'>
                        Découvrez comment la ferme de Bukirasazi, située dans la province de Gitega, a réussi à mettre en pratique les principes agroécologiques pour améliorer sa productivité et sa résilience face aux changements climatiques.
                        <br />
                        <br />
                        Cette ferme familiale, gérée par Léonard et Jeanne, a fait le choix de s'éloigner des pratiques agricoles conventionnelles basées sur l'utilisation intensive d'intrants chimiques. En adoptant une approche agroécologique, ils ont réussi à diversifier leurs cultures, à enrichir naturellement leurs sols et à réduire drastiquement leur impact environnemental.

                        Aujourd'hui, la ferme de Bukirasazi produit une grande variété de cultures vivrières et maraîchères, tout en élevant quelques têtes de bétail. Grâce à la rotation des cultures, à l'utilisation d'engrais verts et de compost, ainsi qu'à la réintroduction de haies et de bosquets, le sol s'est considérablement régénéré, permettant des rendements stables malgré les aléas climatiques.
                        <br />
                        <br />
                        Les surplus de production sont vendus sur les marchés locaux, générant des revenus substantiels pour la famille. Mais au-delà des aspects économiques, c'est surtout la qualité de vie et la résilience de cette ferme qui sont remarquables. Léonard et Jeanne témoignent de leur épanouissement personnel et de leur fierté d'avoir adopté des pratiques respectueuses de l'environnement, tout en assurant la sécurité alimentaire de leur communauté.
                    </div>
                </Drawer.Body>
            </Drawer>

            <Drawer open={showCorrectAnswers} placement='top' onClose={() => setShowCorrectAnswers(false)} size="full">
                <Drawer.Header>
                    <Drawer.Title className='text-center text-lg'>Résultats du quiz</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div className='w-full pl-10'>
                    <p className="text-xl mb-4">Score: {score}/20 points</p>
                        {questionsReponses.map((data) => (
                            <div key={data.id} data-aos="fade-up" className="mb-8">
                                <p className="font-bold my-5 text-xl">{data.question}</p>
                                <div className="space-y-2 text-lg flex flex-col">
                                    {data.options.map((option, index) => (
                                        <label
                                            key={index}
                                            htmlFor={`option-${data.id}-${index}`}
                                            className="flex  pointer-events-none opacity-90 w-max items-center gap-2"
                                        >
                                            <div className="w-4 h-4 rounded-full border flex justify-center items-center relative border-[#3ad12c]">
                                                <input
                                                    checked={selectedAnswers[data.id] === option}
                                                    onChange={() => handleOptionChange(data.id, option)}
                                                    id={`option-${data.id}-${index}`}
                                                    hidden
                                                    name={`options-${data.id}`}
                                                    type="radio"
                                                    className="h-4 w-4 border-gray-300 rounded"
                                                />
                                                {selectedAnswers[data.id] === option && (
                                                    <div className="w-2.5 h-2.5 rounded-full flex justify-center items-center top-[2px] absolute bg-[#3ad12c]"></div>
                                                )}
                                            </div>
                                            <div className={`${option === data.correctAnswer ? 'text-green-500 font-bold' : option === selectedAnswers[data.id] ? 'text-red-500' : ''}`}>

                                                {option === selectedAnswers[data.id] ?
                                                    <div className='flex items-center gap-2'>
                                                        <div>{option}</div>
                                                        <div>
                                                            {option === data.correctAnswer ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                                    <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
                                                              </svg>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    option === data.correctAnswer ? `${option}` :
                                                        option
                                                }
                                            </div>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        ))}


                        <hr />

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>

                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default ModuleCours