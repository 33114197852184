import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";

const markerIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

// Composant pour recentrer la carte sur une nouvelle position
const RecenterMap = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng], 13);
  }, [lat, lng, map]);

  return null;
};

const MapComponent = () => {
  const [position, setPosition] = useState({ lat: -3.379539, lng: 29.3838169 });
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Obtenir la position actuelle de l'utilisateur
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosition({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        });
      });
    }
  }, []);

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
      },
    });

    return null;
  };

  // Fonction pour rechercher les lieux
  const searchLocation = async (query) => {
    if (query.length < 3) {
      setSuggestions([]);
      return;
    }

    const provider = new OpenStreetMapProvider();
    const results = await provider.search({ query });
    setSuggestions(results);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    searchLocation(value);
  };

  const handleSuggestionClick = (location) => {
    setPosition({
      lat: location.y,
      lng: location.x,
    });
    setSuggestions([]);
    setSearchTerm(location.label);
  };

  return (
    <div className="p-3 mb-5">
      <div className="w-full h-[90vh] overflow-hidden relative border-2 border-[#3ad12c] rounded-lg">
        <div className="absolute mt-5 w-full flex justify-center  pointer-events-none  items-center z-10">
          <input
            className="p-3 outline-none rounded-md text-black focus:border-[#3ad12c] pointer-events-auto w-[80%]"
            placeholder="Recherche avec adresse, nom du lieu ou coordonnées"
            value={searchTerm}
            onChange={handleInputChange}
          />
          {suggestions.length > 0 && (
            <ul className="absolute top-12 w-[80%] bg-white max-h-60 pointer-events-auto  overflow-auto z-20 rounded-md shadow-md">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="p-2 cursor-pointer border-b text-black"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.label}
                </li>
              ))}
            </ul>
          )}
        </div>

        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "100%", width: "100%", zIndex: 0 }}
          className="shadow-lg z-0"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={markerIcon}></Marker>
          <RecenterMap lat={position.lat} lng={position.lng} />
          <MapEvents />
        </MapContainer>
      </div>

      <div className="my-4 w-full flex justify-between">
        <div className="flex flex-col">
          <label className="font-semibold">Latitude:</label>
          <input
            type="text"
            value={position.lat}
            readOnly
            className="border p-2 bg-transparent rounded-lg"
          />
        </div>
        <div className="flex flex-col">
          <label className="font-semibold">Longitude:</label>
          <input
            type="text"
            value={position.lng}
            readOnly
            className="border bg-transparent p-2 rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
