import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";
import axios from "axios";

function AdminLoisDetail() {
  const { isDark, isDarVisitLoggined } = useThemes();
  const navigate = useNavigate();
  const { id } = useParams();
  const loiId = decryptData(id);

  const [loi, setLoi] = useState(null); // Stocker les détails de la loi
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const [error, setError] = useState(null); // Gestion des erreurs

  // Fonction pour récupérer les détails de la loi
  const fetchLoiDetails = async () => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/lois/${loiId}`
      ); // Requête API
      setLoi(response.data); // Mettre à jour le state avec les détails de la loi
      setLoading(false);
    } catch (err) {
      console.error(
        "Erreur lors de la récupération des détails de la loi :",
        err
      );
      setError("Erreur lors de la récupération des détails.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoiDetails();
  }, [loiId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg font-bold">Chargement...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-red-600">
        {error}
      </div>
    );
  }

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? "bg-gray-900" : "bg-gray-100"
      }  overflow-x-hidden h-[87vh] flex flex-col justify-between`}
    >
      {/* Barre de navigation */}
      <AdminRessourceNavBars />

      {/* Bouton retour */}
      <div
        onClick={() => {
          navigate("/ressource/loi");
        }}
        className="w-10 m-4 h-10 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white shadow-lg hover:bg-green-600 transition duration-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-arrow-left-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
          />
        </svg>
      </div>

      {/* Contenu principal */}
      <div className="container mx-auto p-6 flex-grow">
        {loi ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div
              className={`${
                isDark === "dark" ? "bg-gray-800" : "bg-white"
              } p-6 rounded-lg shadow-md`}
            >
              <div className="h-[60vh] overflow-hidden rounded-lg">
                <img
                  className="w-full h-full object-cover rounded-lg"
                  src={`https://imuhirabackend.eac-advisor.org/uploads/lois/${loi.photoCouverture}`}
                  alt={loi.titre}
                />
              </div>
              <h2
                className={`text-2xl font-bold mt-4 ${
                  isDark === "dark" ? "text-white" : "text-gray-900"
                }`}
              >
                {loi.titre}
              </h2>
              <p
                className={`text-base mt-3 ${
                  isDark === "dark" ? "text-gray-300" : "text-gray-700"
                } leading-relaxed`}
              >
                {loi.description}
              </p>
              <ul className="ml-4 mt-3 list-disc">
                {loi.axes && loi.axes.length > 0 ? (
                  loi.axes.map((axe, index) => (
                    <li
                      key={index}
                      className={`${
                        isDark === "dark" ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      {axe.titre}
                    </li>
                  ))
                ) : (
                  <li>Aucun axe disponible</li>
                )}
              </ul>
            </div>
          </div>
        ) : (
          <div className="text-center mt-10">Aucune loi trouvée.</div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default AdminLoisDetail;
