/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TranslateComponent from "../../TranslateComponent/TranslateComponent";
import { useThemes } from "../../../UserContext/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);

function NavBars() {
  const {
    isDark,
    toggleDarkMode,
    SetIsLogin,
    SetselectRessource,
    isDarVisitLoggined,
    SetisGoingToSite,
    isGoingToSite,
  } = useThemes();
  const { pathname } = useLocation();
  const navig = useNavigate();
  const acceuil = /^\/.*/;
  const cours = /^\/cours.*/;
  const signal = /^\/signal.*/;
  const ressource = /^\/ressource.*/;
  const projet = /^\/projet.*/;
  const communaute = /^\/communaute.*/;
  const propos = /^\/propos.*/;

  useEffect(() => {
    const showAnim = gsap
      .from(".scrollToBottomNavBars", {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: "top top",
      end: "max",
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }, []);

  useEffect(() => {
    const elementSelectedRessource = localStorage.getItem("ressourceSelected");
    if (elementSelectedRessource) {
      SetselectRessource(elementSelectedRessource);
    }
  }, []);

  return (
    <div
      className={`border-b rounded-b-xl fixed scrollToBottomNavBars z-[200] border-[#3ad12c] w-full h-[18vh] ${
        isDark === "dark" ? "bg-[#0F172B]" : "bg-white"
      }  ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
      } overflow-hidden`}
    >
      <div className="flex justify-between items-center w-full h-[60%]">
        <div
          onClick={() => navig("/")}
          className="flex justify-center items-center font-bold cursor-pointer"
        >
          <div className="h-[20em] border-red-500 mt-7 w-auto">
            <img
              src="image/3.png"
              className="w-full h-full object-cover"
              alt="Logo"
            />
          </div>
          <div className="font-bold mx-4 font-serif text-[30px] text-[#3ad12c] uppercase">
            Imuhira
          </div>
        </div>
        <div className="flex flex-row text-m">
          <TranslateComponent />

          <div
            className="relative p-1 ml-2 h-max"
            onClick={() => SetIsLogin(true)}
          >
            <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#3ad12c] rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-fill-add"
                viewBox="0 0 16 16"
              >
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
              </svg>
            </div>
          </div>

          <div onClick={toggleDarkMode} className="relative p-1 ml-2 h-max">
            {isDark === "dark" ? (
              <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#3ad12c] rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-brightness-high-fill animate-spin"
                  viewBox="0 0 16 16"
                >
                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                </svg>
              </div>
            ) : (
              <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#3ad12c] rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-moon-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                </svg>
              </div>
            )}
          </div>

          {isGoingToSite && (
            <button
              onClick={() => {
                SetisGoingToSite(false);
                navig("/");
              }}
              className="mx-1 relative bg-[#3ad12c2d] hover:bg-[#3ad12c44] transition-all p-2 rounded cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#3ad12c"
                className="bi bi-arrow-repeat"
                viewBox="0 0 16 16"
              >
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="flex w-full justify-around h-[40%] items-center">
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold ${
            acceuil.test(pathname) &&
            !signal.test(pathname) &&
            !cours.test(pathname) &&
            !ressource.test(pathname) &&
            !projet.test(pathname) &&
            !communaute.test(pathname) &&
            !propos.test(pathname)
              ? "text-[#3ad12c]"
              : null
          } px-4 py-2`}
          onClick={() => navig("/")}
        >
          Acceuil
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold ${
            signal.test(pathname) ? "text-[#3ad12c]" : null
          } px-4 py-2`}
          onClick={() => navig("/signal")}
        >
          Signal
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold ${
            cours.test(pathname) ? "text-[#3ad12c]" : null
          } px-4 py-2`}
          onClick={() => navig("/cours")}
        >
          Cours
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold ${
            ressource.test(pathname) ? "text-[#3ad12c]" : null
          } px-4 py-2`}
          onClick={() => navig("/ressource")}
        >
          Ressource
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold ${
            projet.test(pathname) ? "text-[#3ad12c]" : null
          } px-4 py-2`}
          onClick={() => navig("/projet")}
        >
          Projets
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold ${
            communaute.test(pathname) ? "text-[#3ad12c]" : null
          } px-4 py-2`}
          onClick={() => navig("/communaute")}
        >
          Communauté
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold ${
            propos.test(pathname) ? "text-[#3ad12c]" : null
          } px-4 py-2`}
          onClick={() => navig("/propos")}
        >
          À propos
        </div>
      </div>
    </div>
  );
}

export default NavBars;
