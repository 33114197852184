/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { courses } from '../../../Data/Data'
import { useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'
import { Drawer } from 'rsuite'
import ProposezCours from './ProposezCours'

function Cours() {
  const navig = useNavigate()
  const { SetDetailCours,isProposition,HundlwScrollTop,
    SetisProposition } = useThemes()

  return (
    <div class="mt-[18vh] rounded-lg  p-6 my-6 overflow-hidden">
      <h1 class="text-2xl font-bold mb-4">Explorez nos cours gratuits</h1>
      <p class="mb-4 text-lg">Découvrez une sélection de cours gratuits sur des sujets liés à l'environnement et au développement durable au Burundi.</p>
      <div className='flex w-full items-center'>
        <div className='w-full'>
          <input className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' placeholder='Rechercher le cours' />
        </div>
        <div class="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
          Recherche
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        {courses.map((d, i) => (
          <div data-aos="fade-up" key={i} class="rounded-md p-4">
            <img src="https://file3.chinastory.cn/group1/M00/CB/77/CgoTp2BRseKAUAYrAA45N8sSgB842.jpeg" alt="Introduction à la Biodiversité au Burundi" class="rounded-md mb-2" />
            <h2 class="text-lg font-semibold mb-2 text-[#3ad12c]">{d.titre}</h2>
            <p class="">{d.context}</p>
            <div className='flex flex-col'>
              <div className='w-full  flex justify-between'>
                <div class="font-semibold py-2  rounded inline-block text-yellow-500">Niveau: {d.niveau}</div>
                <div class="py-2  rounded inline-block">Durée: 10 semaines</div>
              </div>
              <div onClick={() => { navig("/cours/detail"); SetDetailCours(d) }} class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Commencez à suivre</div>
            </div>
          </div>
        ))}
      </div>


      
      <div class="text-center">
        <div onClick={()=>{SetisProposition(true);HundlwScrollTop()}} class="border-[#3ad12c86] border cursor-pointer hover:border-[#3ad12c] text-[#3ad12c] font-semibold py-2 px-4 rounded inline-block">Proposez un cours</div>
      </div>
      <Drawer placement='bottom' size="full" open={isProposition} onClose={()=>SetisProposition(false)}>
        <Drawer.Header>
          <Drawer.Title className='text-center uppercase'>Proposer un Nouveau Cours</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <ProposezCours />
        </Drawer.Body>
      </Drawer>
    </div>
  )
}

export default Cours