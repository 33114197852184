import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminODDDetail from './AdminODDDetail'

function AdminODDDetailComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminODDDetail />
        </div>
    )
}

export default AdminODDDetailComponent