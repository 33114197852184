import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminPartenaire from './AdminPartenaire'

function AdminPartenaireComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminPartenaire />
        </div>
  )
}

export default AdminPartenaireComponent