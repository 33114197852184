import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminCoursModule from './AdminCoursModule'

function AdminCoursModuleComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
    <AdminSlide/>
    <AdminCoursModule/>
</div>
  )
}

export default AdminCoursModuleComponent