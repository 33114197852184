/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import axios from "axios";
function AdminEspecesAjouter() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile, setMobile] = useState(window.innerWidth < 501);
  useEffect(() => {
    const handleSize = () => {
      setMobile(window.innerWidth < 501);
    };
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);

  // State for a single form entry
  const [especes, setEspeces] = useState({
    image: "",
    nomfrancais: "",
    nomlatin: "",
    nomkirundi: "",
    description: "",
    region: "",
    preview: "",
  });

  // Function to handle input change
  const handleInputChange = (field, value) => {
    if (field === "photos") {
      setEspeces((prevEspeces) => ({
        ...prevEspeces,
        image: value,
        preview: URL.createObjectURL(value),
      }));
    } else {
      setEspeces((prevEspeces) => ({
        ...prevEspeces,
        [field]: value,
      }));
    }
  };

  const validateBloc = () => {
    const requiredFields = [
      "image",
      "nomfrancais",
      "nomlatin",
      "nomkirundi",
      "description",
      "region",
    ];
    for (const field of requiredFields) {
      if (!especes[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "image"
                ? "image"
                : field === "nomfrancais"
                ? "nom du français"
                : field === "nomlatin"
                ? "nom du latin"
                : field === "nomkirundi"
                ? "nom du kirundi"
                : field === "description"
                ? "description"
                : field === "region"
                ? "région"
                : null}
            </strong>{" "}
            est obligatoire.
          </div>
        );
        return false; // Return false if validation fails
      }
    }
    return true; // Return true if validation is successful
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form before submission
    if (!validateBloc()) {
      return;
    }
    const utilisateurId = localStorage.getItem("userId");
    // Create a new FormData object
    const formData = new FormData();

    // Append the fields to the FormData object
    formData.append("image", especes.image); // Append the file (image)
    formData.append("nomfrancais", especes.nomfrancais);
    formData.append("nomlatin", especes.nomlatin);
    formData.append("nomkirundi", especes.nomkirundi);
    formData.append("description", especes.description);
    formData.append("region", especes.region);
    formData.append("utilisateurId", utilisateurId);

    try {
      // Send the form data to the API endpoint
      await axios.post(
        "https://imuhirabackend.eac-advisor.org/apiv1/especes",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify the content type
          },
        }
      );
      navig("/ressource/espece");

      toast.success("Les espèces ont été enregistrées avec succès");
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de l'enregistrement des espèces."
      );
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/ressource/espece")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="rounded-lg p-2 w-full sm:p-4">
          <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
            <div className="w-full">
              <div className="h-max w-full">
                <div className="px-3 w-full">
                  <div className="w-full flex items-center justify-between">
                    <label className="block text-xs mt-5 font-[700] first-letter:uppercase tracking-wider text-grey-darker sm:text-lg mb-1">
                      Espèces
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="rounded-xl p-2 w-full">
                <div className="flex flex-col">
                  <div className="mb-2">
                    <div className="flex flex-col items-center">
                      <label
                        className={`w-full border border-gray-300 block cursor-pointer outline-none focus:border-[#3ad12c] rounded-md p-2.5 bg-transparent`}
                        htmlFor="id_photo"
                      >
                        Photos de couverture
                      </label>
                      <input
                        type="file"
                        accept=".jpeg,.png,.jpg,.gif"
                        className="w-full hidden mt-1"
                        id="id_photo"
                        onChange={(e) =>
                          handleInputChange("photos", e.target.files[0])
                        }
                      />
                      <div className="flex w-full justify-start">
                        {especes.preview && (
                          <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                            <img
                              src={especes.preview}
                              alt="Preview"
                              className="object-cover w-full h-full"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Input fields styled as per your design */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="nom_francais"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Nom francais
                    </label>
                    <input
                      type="text"
                      id="nom_francais"
                      value={especes.nomfrancais}
                      onChange={(e) =>
                        handleInputChange("nomfrancais", e.target.value)
                      }
                      placeholder="Le nom francais"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label
                      htmlFor="nom_latin"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Nom latin
                    </label>
                    <input
                      type="text"
                      id="nom_latin"
                      value={especes.nomlatin}
                      onChange={(e) =>
                        handleInputChange("nomlatin", e.target.value)
                      }
                      placeholder="Le nom latin"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label
                      htmlFor="nom_kirundi"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Nom kirundi
                    </label>
                    <input
                      type="text"
                      id="nom_kirundi"
                      value={especes.nomkirundi}
                      onChange={(e) =>
                        handleInputChange("nomkirundi", e.target.value)
                      }
                      placeholder="Le nom kirundi"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label
                      htmlFor="description"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Role
                    </label>
                    <textarea
                      id="description"
                      value={especes.description}
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                      placeholder="Le role de l'espèce"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                      rows="4"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label
                      htmlFor="region"
                      className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                    >
                      Région
                    </label>
                    <input
                      type="text"
                      id="region"
                      value={especes.region}
                      onChange={(e) =>
                        handleInputChange("region", e.target.value)
                      }
                      placeholder="La région"
                      className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-[#3ad12c] text-white py-2 rounded-md hover:bg-[#36b72b] transition duration-200"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
}

export default AdminEspecesAjouter;
