import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { toast } from "react-toastify";
import axios from "axios";
const markerIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

// Composant pour recentrer la carte sur une nouvelle position
const RecenterMap = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng], 13);
  }, [lat, lng, map]);

  return null;
};

export default function SignaleForm() {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    description: "",
    location: "",
    severity: "",
    actions: "",
    expectations: "",
    image: "",
  });

  const [errors, setErrors] = useState({});
  const [previewImages, setPreviewImages] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "image") {
      const file = e.target.files[0];
      if (file) {
        setPreviewImages(URL.createObjectURL(file));
        setFormData({ ...formData, image: file });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Nom est requis";
    if (!formData.contact) newErrors.contact = "Coordonnées sont requises";
    if (!formData.description)
      newErrors.description = "Description est requise";
    if (!formData.location) newErrors.location = "Lieu est requis";
    if (!formData.severity) newErrors.severity = "Gravité est requise";
    if (!formData.actions) newErrors.actions = "Actions sont requises";
    if (!formData.expectations)
      newErrors.expectations = "Attentes sont requises";
    if (!formData.image) newErrors.image = "Prenez l'image";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const [position, setPosition] = useState({ lat: -3.379539, lng: 29.3838169 });
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Obtenir la position actuelle de l'utilisateur
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosition({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        });
      });
    }
  }, []);

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
      },
    });

    return null;
  };

  // Fonction pour rechercher les lieux
  const searchLocation = async (query) => {
    if (query.length < 3) {
      setSuggestions([]);
      return;
    }

    const provider = new OpenStreetMapProvider();
    const results = await provider.search({ query });
    setSuggestions(results);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    searchLocation(value);
  };

  const handleSuggestionClick = (location) => {
    setPosition({
      lat: location.y,
      lng: location.x,
    });
    setSuggestions([]);
    setSearchTerm(location.label);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Créer un objet FormData pour gérer les téléchargements de fichiers
      const data = new FormData();
      data.append("nom", formData.name);
      data.append("coordonnees", formData.contact);
      data.append("description", formData.description);
      data.append("longitude", position.lng); // Utiliser position.lng ici
      data.append("latitude", position.lat); // Utiliser position.lat ici
      data.append("gravite", formData.severity);
      data.append("actionprise", formData.actions);
      data.append("attentes", formData.expectations);
      data.append("location", formData.location);
      if (formData.image) {
        data.append("image", formData.image); // Ajouter l'image si sélectionnée
      }

      try {
        // Envoyer les données du formulaire à l'API
        await axios.post(
          "https://imuhirabackend.eac-advisor.org/apiv1/signalements",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true, // Cela permet d'inclure les cookies dans la requête
          }
        );

        // Réinitialiser les données du formulaire après une soumission réussie
        setFormData({
          name: "",
          contact: "",
          description: "",
          location: "",
          severity: "",
          actions: "",
          expectations: "",
          image: null, // Réinitialiser l'image
        });
        setPreviewImages(""); // Réinitialiser l'aperçu de l'image

        // Afficher un message de succès
        toast.success("Formulaire soumis avec succès !");
      } catch (error) {
        if (error.response) {
          // Erreurs du serveur (4xx ou 5xx)
          console.error("Erreur serveur:", error.response.data);
          toast.error(
            `Erreur de soumission (${error.response.status}): ${
              error.response.data.message || error.response.statusText
            }`
          );
        } else if (error.request) {
          // Pas de réponse reçue
          console.error("Aucune réponse du serveur:", error.request);
          toast.error(
            "Le serveur ne répond pas, veuillez vérifier votre connexion."
          );
        } else {
          // Autres erreurs
          console.error("Erreur lors de la requête:", error.message);
          toast.error("Une erreur est survenue lors de la soumission.");
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full p-5">
      <div className="mb-4">
        <label htmlFor="name" className="block text-lg font-bold mb-2">
          Votre nom :
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${
            errors.name ? "border-red-500" : ""
          }`}
          autoFocus
        />
        {errors.name && (
          <p className="text-red-500 text-xs mt-1">{errors.name}</p>
        )}
      </div>
      <div className="p-3 mb-5">
        <div className="w-full h-[90vh] overflow-hidden relative border-2 border-[#3ad12c] rounded-lg">
          <div className="absolute mt-5 w-full flex justify-center  pointer-events-none  items-center z-10">
            <input
              className="p-3 outline-none rounded-md text-black focus:border-[#3ad12c] pointer-events-auto w-[80%]"
              placeholder="Recherche avec adresse, nom du lieu ou coordonnées"
              value={searchTerm}
              onChange={handleInputChange}
            />
            {suggestions.length > 0 && (
              <ul className="absolute top-12 w-[80%] bg-white max-h-60 pointer-events-auto  overflow-auto z-20 rounded-md shadow-md">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="p-2 cursor-pointer border-b text-black"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.label}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <MapContainer
            center={position}
            zoom={13}
            style={{ height: "100%", width: "100%", zIndex: 0 }}
            className="shadow-lg z-0"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={markerIcon}></Marker>
            <RecenterMap lat={position.lat} lng={position.lng} />
            <MapEvents />
          </MapContainer>
        </div>

        <div className="my-4 w-full flex justify-between">
          <div className="flex flex-col">
            <label className="font-semibold">Latitude:</label>
            <input
              type="text"
              value={position.lat}
              readOnly
              className="border p-2 bg-transparent rounded-lg"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-semibold">Longitude:</label>
            <input
              type="text"
              value={position.lng}
              readOnly
              className="border bg-transparent p-2 rounded-lg"
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="contact" className="block text-lg font-bold mb-2">
          Vos coordonnées :
        </label>
        <input
          type="text"
          id="contact"
          name="contact"
          value={formData.contact}
          onChange={handleChange}
          className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${
            errors.contact ? "border-red-500" : ""
          }`}
        />
        {errors.contact && (
          <p className="text-red-500 text-xs mt-1">{errors.contact}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="description" className="block text-lg font-bold mb-2">
          Description du problème :
        </label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${
            errors.description ? "border-red-500" : ""
          }`}
          rows="3"
        />
        {errors.description && (
          <p className="text-red-500 text-xs mt-1">{errors.description}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="location" className="block text-lg font-bold mb-2">
          Lieu du problème :
        </label>
        <input
          type="text"
          id="location"
          name="location"
          value={formData.location}
          onChange={handleChange}
          className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${
            errors.location ? "border-red-500" : ""
          }`}
        />
        {errors.location && (
          <p className="text-red-500 text-xs mt-1">{errors.location}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="severity" className="block text-lg font-bold mb-2">
          Gravité du problème :
        </label>
        <select
          id="severity"
          name="severity"
          value={formData.severity}
          onChange={handleChange}
          className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${
            errors.severity ? "border-red-500" : ""
          }`}
        >
          <option hidden value="">
            Sélectionner la gravité
          </option>
          <option className="text-black" value="Mineur">
            Mineur
          </option>
          <option className="text-black" value="Majeur">
            Majeur
          </option>
          <option className="text-black" value="Critique">
            Critique
          </option>
        </select>
        {errors.severity && (
          <p className="text-red-500 text-xs mt-1">{errors.severity}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="actions" className="block text-lg font-bold mb-2">
          Actions déjà entreprises :
        </label>
        <textarea
          id="actions"
          name="actions"
          value={formData.actions}
          onChange={handleChange}
          className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${
            errors.actions ? "border-red-500" : ""
          }`}
          rows="3"
        />
        {errors.actions && (
          <p className="text-red-500 text-xs mt-1">{errors.actions}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="expectations" className="block text-lg font-bold mb-2">
          Vos attentes :
        </label>
        <textarea
          id="expectations"
          name="expectations"
          value={formData.expectations}
          onChange={handleChange}
          className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${
            errors.expectations ? "border-red-500" : ""
          }`}
          rows="3"
        />
        {errors.expectations && (
          <p className="text-red-500 text-xs mt-1">{errors.expectations}</p>
        )}
      </div>

      <div className="my-5 w-full flex h-max">
        <label
          htmlFor="image"
          className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full border rounded ${
            errors.image ? "border-red-500" : "focus:ring-teal-300"
          }`}
        >
          Image
        </label>
        <input
          type="file"
          id="image"
          name="image"
          hidden
          accept="image/*"
          onChange={handleChange}
        />
      </div>
      {previewImages && (
        <img src={previewImages} alt="Rapport Preview" className="mb-4 h-48" />
      )}

      <div className="flex justify-end">
        <button
          type="submit"
          className="bg-[#3ad12c] mb-5 hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Envoyer
        </button>
      </div>
    </form>
  );
}
