import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NavBarsRessource() {
  const { pathname } = useLocation();
  const navig = useNavigate();
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        setShowLeft(container.scrollLeft > 0);
        setShowRight(
          container.scrollWidth > container.clientWidth + container.scrollLeft
        );
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -containerRef.current.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: containerRef.current.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  const ressource = /^\/ressource.*/;
  const rapport = /^\/ressource\/rapport.*/;
  const Environnementaux = /^\/ressource\/Environnementaux.*/;
  const especes = /^\/ressource\/espece.*/;
  const lois = /^\/ressource\/lois.*/;
  const Convention = /^\/ressource\/Convention.*/;
  const ODD = /^\/ressource\/ODD.*/;

  return (
    <div className="relative">
      {showLeft && (
        <div
          className="absolute top-0 cursor-pointer left-0 mt-2 flex justify-center items-center right-0  mr-2 w-6 h-6  ml-2 bg-[#3ad12c] text-white rounded-full p-2  shadow-md"
          onClick={scrollLeft}
        >
          &#9664;
        </div>
      )}
      {showRight && (
        <div
          className="absolute top-0 cursor-pointer flex justify-center items-center right-0 mt-2 mr-2 w-6 h-6 bg-[#3ad12c] text-white p-2 rounded-full shadow-md "
          onClick={scrollRight}
        >
          &#9654;
        </div>
      )}
      <div
        ref={containerRef}
        className="flex w-full overflow-hidden whitespace-nowrap p-2"
      >
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold text-nowrap ${
            ressource.test(pathname) &&
            !rapport.test(pathname) &&
            !Environnementaux.test(pathname) &&
            !especes.test(pathname) &&
            !lois.test(pathname) &&
            !ODD.test(pathname) &&
            !Convention.test(pathname)
              ? "text-[#3ad12c]"
              : null
          } px-4 py-2`}
          onClick={() => navig("/ressource")}
        >
          Ressources Environnementales
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold text-nowrap ${
            rapport.test(pathname)
              ? "text-[#3ad12c] border-b-2 border-[#3ad12c] "
              : null
          } px-4 py-2`}
          onClick={() => navig("/ressource/rapport")}
        >
          Rapport Environnemental
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold text-nowrap ${
            Environnementaux.test(pathname)
              ? "text-[#3ad12c] border-b-2 border-[#3ad12c]"
              : null
          } px-4 py-2`}
          onClick={() => navig("/ressource/Environnementaux")}
        >
          Faits Environnementaux
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold text-nowrap ${
            especes.test(pathname)
              ? "text-[#3ad12c] border-b-2 border-[#3ad12c]"
              : null
          } px-4 py-2`}
          onClick={() => navig("/ressource/espece")}
        >
          Espèces Remarquables du Burundi
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold text-nowrap ${
            lois.test(pathname)
              ? "text-[#3ad12c] border-b-2 border-[#3ad12c]"
              : null
          } px-4 py-2`}
          onClick={() => navig("/ressource/lois")}
        >
          Loi
        </div>
        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold text-nowrap ${
            Convention.test(pathname)
              ? "text-[#3ad12c] border-b-2 border-[#3ad12c]"
              : null
          } px-4 py-2`}
          onClick={() => navig("/ressource/Convention")}
        >
          Convention
        </div>

        <div
          className={`first-letter:uppercase cursor-pointer text-lg font-bold text-nowrap ${
            ODD.test(pathname)
              ? "text-[#3ad12c] border-b-2 border-[#3ad12c]"
              : null
          } px-4 py-2`}
          onClick={() => navig("/ressource/ODD")}
        >
          ODD
        </div>
      </div>
    </div>
  );
}

export default NavBarsRessource;
