import React, { useState } from 'react'
import { Drawer } from 'rsuite'
import RejoignezFormulaire from './RejoignezFormulaire'
import { useThemes } from '../../../UserContext/UserContext'

function Rejoignez() {
   const {isRejoinder,SetisRejoinder,formRef}=useThemes()
    return (
        <div ref={formRef} class="py-8 ">
            <div data-aos-duration="1500" data-aos="fade-up" data-aos-delay="100" className="container mx-auto border-l-2 border-[#3ad12c] bg-[#3ad12c1a] py-5 rounded-l-2xl px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold mb-4">Devenez un acteur du changement</h1>
                <p class=" mb-8 text-lg">Chez IMUHIRA, nous croyons que chaque action compte. Que vous ayez beaucoup de temps à consacrer ou seulement quelques heures par mois, il y a une place pour vous dans notre communauté engagée pour l'environnement au Burundi.</p>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div class=" rounded-lg shadow-md p-6">
                        <h2 class="text-2xl font-bold mb-4">Bénévole</h2>
                        <p class="mb-6">Participez à nos projets sur le terrain, aidez à organiser des événements et continuez à nos campagnes de sensibilisation.</p>
                        <div onClick={()=>SetisRejoinder(true)} class="bg-[#3ad12c] text-white cursor-pointer py-2 px-4 rounded-lg inline-block">Devenir bénévole</div>
                    </div>
                    <div class=" rounded-lg shadow-md p-6">
                        <h2 class="text-2xl font-bold mb-4">Membre</h2>
                        <p class=" mb-6">Soutenez notre action par une cotisation annuelle et bénéficiez d'avantages exclusifs comme des formations et des visites de nos projets.</p>
                        <div onClick={()=>SetisRejoinder(true)} class="bg-[#3ad12c] text-white py-2 px-4 cursor-pointer rounded-lg inline-block">Devenir membre</div>
                    </div>
                </div>
            </div>
            <Drawer open={isRejoinder} onClose={()=>SetisRejoinder(false)} placement='top' size="full">
                <Drawer.Header>
                    <Drawer.Title className='text-center font-bold uppercase'>Formulaire d'adhésion</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <RejoignezFormulaire/>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default Rejoignez