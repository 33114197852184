import React, { useState } from 'react'
import AdminProjetNavBars from './AdminProjetNavBars'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Visiteurs/FootentContent/Footer'
import { Drawer } from 'rsuite'
import AdminDonProjetRepondre from './AdminDonProjetRepondre'

function AdminProjetBenevol() {
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()
    const [isDonantion, SetisDonantion] = useState(false)
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <AdminProjetNavBars />
            <h1 class="text-2xl font-bold mb-4 text-center">Liste des bénévole</h1>
            <div class="mx-auto grid grid-cols-4 w-full   gap-5  p-6">
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => (
                    <div className={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-4`}>
                        <h2 class="text-lg font-semibold">Jean Dupont</h2>
                        <p class="">Email : mmmmm@gmail.com</p>
                        <p class="">Téléphone : 06 12 34 56 78</p>
                        <p class="">Demande :partenaire</p>
                        <p class="">Disponibilité :toujours</p>
                        <p class="mt-2 ">
                            pourquoi:
                            "J'ai eu une expérience incroyable avec cette organisation. Leur soutien m'a été précieux et j'ai pu accomplir beaucoup de choses."
                        </p>
                        <p class="mt-2 italic text-[10px]">
                            le 12/01/2023
                        </p>
                        <div className='flex w-full justify-between items-center p-2'>
                            <div title='Détail' onClick={() => navig("/projet/benevol/detail")} className=' border px-2 py-1 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer'>
                                Détail
                            </div>
                            <div onClick={() => SetisDonantion(true)} className=' border px-2 py-1 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer'>
                                Accepter
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Drawer open={isDonantion} onClose={() => SetisDonantion(false)} placement='right' size="xs">
                <Drawer.Header>
                    <Drawer.Title className='text-center font-bold uppercase'>Faire un don</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <AdminDonProjetRepondre />
                </Drawer.Body>
            </Drawer>
            <Footer />
        </div>

    )
}

export default AdminProjetBenevol