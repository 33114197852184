import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdiminRapportModifier from './AdiminRapportModifier'

function AdiminRapportModifierComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
        <AdminSlide />
        <AdiminRapportModifier />
    </div>
    )
}

export default AdiminRapportModifierComponent