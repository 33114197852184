import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminCommunauteNavBars from "./AdminCommunauteNavBars";
import { decryptData } from "../../../encryptionModule";
import axios from "axios";

function AdminCommunauteModifier() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const evenementId = decryptData(id);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [evenements, setEvenements] = useState({
    image: "",
    titre: "",
    date: "",
    lieu: "",
    description: "",
    preview: "",
  });
  const [loading, setLoading] = useState(false);

  // Gérer la mise à jour du responsive
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const inputRefs = useRef({
    image: React.createRef(),
    titre: React.createRef(),
    date: React.createRef(),
    lieu: React.createRef(),
    description: React.createRef(),
  });
  const [encienPhoto, SetEncienPhoto] = useState(null);
  // Gérer les changements de champ
  const handleInputChange = (field, value) => {
    setEvenements((prevState) => ({
      ...prevState,
      [field]: field === "image" ? value : value,
      ...(field === "image" && { preview: URL.createObjectURL(value) }),
    }));
  };

  // Fonction pour vérifier les champs obligatoires
  const validateBloc = () => {
    const requiredFields = ["titre", "date", "description", "lieu"];
    for (const field of requiredFields) {
      if (!evenements[field]) {
        toast.warning(`Le champ ${field} est obligatoire.`);
        inputRefs.current[field].current.focus();
        return false;
      }
    }
    return true;
  };

  // Fonction pour formater la date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mois formaté à deux chiffres
    const day = String(date.getDate()).padStart(2, "0"); // Jour formaté à deux chiffres
    return `${year}-${month}-${day}`;
  };

  // Charger les données actuelles de l'événement
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/evenements/${evenementId}`
        );
        const data = await response.json();
        if (data) {
          setEvenements({
            image: data.image,
            titre: data.titre,
            date: formatDate(data.date), // Formater la date avant de la définir
            lieu: data.lieu,
            description: data.description,
          });
          SetEncienPhoto(data.photocouverture);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'événement:", error);
      }
    };

    fetchEventData();
  }, [evenementId]);

  // Gérer la soumission du formulaire pour la modification
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateBloc()) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", evenements.image);
      formData.append("titre", evenements.titre);
      formData.append("date", evenements.date);
      formData.append("lieu", evenements.lieu);
      formData.append("description", evenements.description);

      const response = await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/evenements/${evenementId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("L'événement a été modifié avec succès !");
        navig("/comm");
      } else {
        toast.error("Une erreur s'est produite lors de la modification.");
      }
    } catch (error) {
      console.error("Erreur lors de la modification:", error);
      toast.error("Erreur de connexion. Veuillez réessayer plus tard.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
      } h-[87vh]`}
    >
      <AdminCommunauteNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/comm")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        {/* Form inputs */}
        <div className="rounded-lg p-2 w-full sm:p-4">
          {/* Formulaire pour la modification */}
          <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
            <div className="flex flex-col mb-10">
              <label
                htmlFor="id_photo"
                className="block mt-5 tracking-wide text-lg mb-1 cursor-pointer"
              >
                Photos de couverture
              </label>
              <input
                type="file"
                accept=".jpeg,.png,.jpg,.gif"
                className="w-full hidden mt-1"
                id="id_photo"
                onChange={(e) => handleInputChange("image", e.target.files[0])}
                ref={inputRefs.current.image}
              />
              {evenements.preview ? (
                <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                  <img
                    src={evenements.preview}
                    alt="Aperçu"
                    className="object-cover w-full h-full"
                  />
                </div>
              ) : (
                <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                  <img
                    src={`https://imuhirabackend.eac-advisor.org/uploads/event/${encienPhoto}`}
                    alt={evenements.titre}
                    className="object-cover w-full h-full"
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="titre"
                className="block mt-5 tracking-wide text-lg mb-1"
              >
                Titre de l'événement
              </label>
              <input
                type="text"
                id="titre"
                value={evenements.titre}
                onChange={(e) => handleInputChange("titre", e.target.value)}
                placeholder="Le titre de l'événement"
                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                ref={inputRefs.current.titre}
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="date"
                className="block mt-5 tracking-wide text-lg mb-1"
              >
                Date de l'événement
              </label>
              <input
                type="date"
                id="date"
                value={evenements.date}
                onChange={(e) => handleInputChange("date", e.target.value)}
                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                ref={inputRefs.current.date}
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="lieu"
                className="block mt-5 tracking-wide text-lg mb-1"
              >
                Lieu de l'événement
              </label>
              <input
                type="text"
                id="lieu"
                value={evenements.lieu}
                onChange={(e) => handleInputChange("lieu", e.target.value)}
                placeholder="Lieu de l'événement"
                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                ref={inputRefs.current.lieu}
              />
            </div>

            <div className="flex flex-col mb-10">
              <label
                htmlFor="description"
                className="block mt-5 tracking-wide text-lg mb-1"
              >
                Description
              </label>
              <textarea
                id="description"
                value={evenements.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
                rows="6"
                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                ref={inputRefs.current.description}
              />
            </div>
          </div>
        </div>

        {/* Bouton de soumission */}
        <button
          type="submit"
          className="text-white bg-[#3ad12c] px-4 py-2 rounded-lg font-semibold mb-4"
          disabled={loading}
        >
          {loading ? "Modification en cours..." : "Modifier l'événement"}
        </button>
      </form>
      <Footer />
    </div>
  );
}

export default AdminCommunauteModifier;
