import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Drawer } from 'rsuite'
import RejoignezFormulaire from '../Acceuil/RejoignezFormulaire'

function Communaute() {
  const navig = useNavigate()
  const [isRejoinder,SetisRejoinder]=useState()
  return (
    <div className=' mt-[18vh]  overflow-hidden'>

      <div data-aos-duration="1000" className="relative overflow-hidden h-[60vh]">
        <img src="https://celinegainsburgrey.com/wp-content/uploads/2021/07/Communaute-engagee.jpg" className="h-[95vh] w-full object-cover" alt="   " />
        <div className="absolute top-0 text-center bg-[#0f172b96] text-white left-0 w-full   flex items-start justify-around  h-full">
          <div className="w-full mt-[2em] flex items-center flex-col">
            <div data-aos="fade-up" className="text-7xl font-bold titre stroketext1">
              Bienvenue dans notre Communauté
            </div>
            <div className='bg-[#5a0200] animate-ping w-10 h-10 bottom-2 rounded-full left-3 absolute'></div>
            <div data-aos="fade-left" className="text-3xl relative mt-[1em] sm:w-[50%]">
              Rejoignez une communauté dynamique de Burundais engagés pour un avenir durable. Partagez vos idées, apprenez des autres et participez à des initiatives locales.
            </div>
            <div className="w-full justify-center mt-5  flex">
              <div onClick={()=>SetisRejoinder(true)} data-aos="fade-down" className="w-max mt-5 cursor-pointer px-3 py-2 bg-[#3ad12c] border-[3px] rounded-md  border-white text-white">
                Devenir Membre
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class=" p-8">
        <div class="w-full mx-auto">

          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div data-aos="fade-up" class=" rounded-lg shadow-md p-2">
              <img src="image/entreprise-seminaire-lyon.jpg.cropped.jpeg" alt="Forum de Discussion" class="w-full h-48 object-cover rounded-t-lg" />
              <h2 class="text-lg font-bold my-4">Forum de Discussion</h2>
              <p class="">Échangez des idées et des conseils avec d'autres personnes passionnées par l'environnement.</p>
              <div onClick={() => navig("/communaute/forumu")} class="text-[#3ad12c] w-max mt-3 font-medium cursor-pointer">Accéder au Forum</div>
            </div>
            <div data-aos="fade-up" class=" rounded-lg shadow-md p-2">
              <img src="image/entreprise-seminaire-lyon.jpg.cropped.jpeg" alt="Événements Locaux" class="w-full h-48 object-cover rounded-t-lg" />
              <h2 class="text-lg font-bold my-4">Événements Locaux</h2>
              <p class="">Participez à des actions concrètes près de chez vous pour un Burundi plus vert.</p>
              <div class="text-[#3ad12c] w-max mt-3 font-medium cursor-pointer">Voir les Événements</div>
            </div>
            <div data-aos="fade-up" class=" rounded-lg shadow-md p-2">
              <img src="image/entreprise-seminaire-lyon.jpg.cropped.jpeg" alt="Apprentissage en Ligne" class="w-full h-48 object-cover rounded-t-lg" />
              <h2 class="text-lg font-bold my-4">Apprentissage en Ligne</h2>
              <p class="">Accédez à des cours et des ressources pour approfondir vos connaissances.</p>
              <div class="text-[#3ad12c] w-max mt-3 font-medium cursor-pointer">Explorer les Cours</div>
            </div>
          </div>
        </div>
      </div>




      <div class=" p-8">
        <div class="w-full mx-auto">
          <h1 data-aos="fade-up"  class="text-3xl font-bold mb-8">Événements à Venir</h1>
          <div data-aos="fade-up" class=" rounded-lg  border-l-2 border-[#3ad12c] p-5 bg-[#3ad12c15]">
            <h2 class="text-xl font-bold mb-4">Journée de Reboisement à Kibira</h2>
            <p class=" mb-4 text-lg">
              Date : 15 juillet 2023<br />
              Lieu : Forêt de Kibira
            </p>
            <div onClick={()=>navig("/communaute/event")} class="bg-[#3ad12c] w-max mt-3 hover:scale-105 transition-all cursor-pointer text-white font-medium py-2 px-4 rounded-md inline-block">Plus d'infos</div>
          </div>
          <div data-aos="fade-up" class=" rounded-lg  border-l-2 border-[#3ad12c] mt-8 p-5 bg-[#3ad12c15]">
            <h2 class="text-xl font-bold mb-4">Atelier : Fabrication de Produits Ménagers Écologiques</h2>
            <p class=" mb-4 text-lg">
              Date : 22 juillet 2023<br />
              Lieu : Centre Culturel de Gitega
            </p>
            <div  onClick={()=>navig("/communaute/event")} class="bg-[#3ad12c] w-max mt-3 hover:scale-105 transition-all cursor-pointer text-white font-medium py-2 px-4 rounded-md inline-block">Plus d'infos</div>
          </div>
        </div>
      </div>

      <div class="p-8">
        <div class="w-full p-3  mx-auto">
          <div data-aos="fade-up" className=' border-[0.2px] rounded-xl border-[#3ad12c] p-4'>
            <div class="flex items-center mb-4">
              <div class="w-24 h-24 rounded-full overflow-hidden mr-6">
                <img src="image/partenaire.jpg" alt=" " class="w-full h-full object-cover" />
              </div>
              <h2 class="text-2xl font-bold">Membre du Mois</h2>
            </div>
            <div class=" rounded-lg  p-2">
              <h3 class="text-2xl font-bold mb-4">Jeanne K.</h3>
              <p class=" mb-6 text-lg">"Grâce à l'MJHIRA, j'ai pu lancer un projet de jardin communautaire dans mon quartier. Cela a non seulement amélioré notre sécurité alimentaire, mais a aussi renforcé les liens dans notre communauté."</p>
            </div>
          </div>
          <div data-aos="fade-up" class="mt-8">
            <h2 class="text-2xl font-bold mb-4">Rejoignez-nous</h2>
            <p class=" mb-6 text-lg">Ensemble, nous pouvons faire la différence. Rejoignez notre communauté aujourd'hui et participez à la construction d'un Burundi durable.</p>
            <div  onClick={()=>SetisRejoinder(true)} class="bg-[#3ad12c] w-max mt-3 hover:scale-105 transition-all cursor-pointer text-white font-medium py-2 px-4 rounded-md inline-block">Devenir Membre</div>
          </div>
        </div>
      </div>

      <Drawer open={isRejoinder} onClose={()=>SetisRejoinder(false)} placement='top' size="full">
                <Drawer.Header>
                    <Drawer.Title className='text-center font-bold uppercase'>Formulaire d'adhésion</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <RejoignezFormulaire/>
                </Drawer.Body>
            </Drawer>
    </div>
  )
}

export default Communaute