import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminFaits from './AdminFaits'

function AdminFaitsComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
    <AdminSlide />
    <AdminFaits />
</div>
  )
}

export default AdminFaitsComponent