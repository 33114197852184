import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify';

function SponsorForm() {
    const [nomComplet, setNomComplet] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [soutien, setsoutien] = useState('');
    const [temoignage, setTemoignage] = useState('');

    const textareaRef = useRef(null);
    const inputRefs = {
        nomComplet: useRef(null),
        tel: useRef(null),
        email: useRef(null),
        soutien: useRef(null),
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation des champs
        if (nomComplet.trim() === '') {
            toast.warning("Veuillez remplir votre nom complet !");
            inputRefs.nomComplet.current.focus();
            return;
        }

        if (tel.trim() === '') {
            toast.warning("Veuillez remplir votre numéro de téléphone !");
            inputRefs.tel.current.focus();
            return;
        }

        if (email.trim() === '') {
            toast.warning("Veuillez remplir votre email !");
            inputRefs.email.current.focus();
            return;
        }

        if (soutien.trim() === '') {
            toast.warning("Veuillez remplir votre soutien !");
            inputRefs.soutien.current.focus();
            return;
        }

        if (temoignage.trim() === '') {
            toast.warning("Complétez votre témoignage !");
            textareaRef.current.focus();
            return;
        }

        // Logique pour soumettre les données, par exemple, un appel API.
        toast.success("Témoignage soumis avec succès !");
    };

    return (
        <div className='p-4'>
            <p className="mb-4 text-center">
                En tant que sponsor, vous aurez l'opportunité de recevoir une reconnaissance sur nos plateformes et lors de nos événements locaux. Merci de remplir le formulaire ci-dessous pour nous indiquer votre intérêt.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="nom" className="block text-lg font-semibold mb-2">Nom complet</label>
                    <input
                        type="text"
                        id="nom"
                        value={nomComplet}
                        onChange={(e) => setNomComplet(e.target.value)}
                        ref={inputRefs.nomComplet}
                        className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="tel" className="block text-lg font-semibold mb-2">Numéro de téléphone</label>
                    <input
                        type="text"
                        id="tel"
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                        ref={inputRefs.tel}
                        className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="email" className="block text-lg font-semibold mb-2">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={inputRefs.email}
                        className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="soutien" className="block text-lg font-semibold mb-2">Type de soutien</label>
                    <select
                        type="text"
                        id="soutien"
                        value={soutien}
                        onChange={(e) => setsoutien(e.target.value)}
                        ref={inputRefs.soutien}
                        className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    >
                        <option value="" hidden className='text-black'>Type de soutien</option>
                        <option  className='text-black'>financier</option>
                        <option  className='text-black'>Matériel</option>
                        <option  className='text-black'>Autre</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label htmlFor="temoignage" className="block text-lg font-semibold mb-2">Votre témoignage</label>
                    <textarea
                        id="temoignage"
                        value={temoignage}
                        onChange={(e) => setTemoignage(e.target.value)}
                        ref={textareaRef}
                        className="block w-full min-h-[10em] p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    />
                </div>

                <div className='w-full flex justify-end items-center'>
                    <button type="submit" className=" w-max text-white py-2 px-4 bg-[#3ad12c] rounded-lg hover:scale-105 transition-all focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
                        Envoyer
                    </button>

                </div>
            </form>
        </div>
    )
}

export default SponsorForm