/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminCommunauteNavBars from "./AdminCommunauteNavBars";
import axios from "axios";

function AdminCommunauteAjouter() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", hundlesSize);
    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", hundleSize);
    return () => {
      window.removeEventListener("resize", hundleSize);
    };
  }, []);

  const [evenements, setBlocs] = useState([
    {
      image: "",
      titre: "",
      date: "",
      lieu: "",
      description: "",
      preview: "",
    },
  ]);

  const addBloc = () => {
    setBlocs((prevBlocs) => [
      ...prevBlocs,
      {
        image: "",
        titre: "",
        date: "",
        lieu: "",
        description: "",
        preview: "",
      },
    ]);
  };
  const removeBloc = (index) => {
    const newBlocs = [...evenements];
    newBlocs.splice(index, 1);
    setBlocs(newBlocs);
  };
  const inputRefs = useRef([]);
  if (inputRefs.current.length !== evenements.length) {
    inputRefs.current = Array(evenements.length)
      .fill({})
      .map((_, index) => ({
        image: React.createRef(null),
        titre: React.createRef(null),
        date: React.createRef(null),
        lieu: React.createRef(null),
        description: React.createRef(null),
      }));
  }

  const handleInputChange = (index, field, value) => {
    const newBlocs = [...evenements];
    if (field === "image") {
      newBlocs[index].preview = URL.createObjectURL(value);
      newBlocs[index].image = value;
    } else {
      newBlocs[index][field] = value;
    }
    setBlocs(newBlocs);
  };

  // ....................................................................................................

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateBloc = (bloc, index) => {
    const requiredFields = ["image", "titre", "date", "description", "lieu"];
    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "image"
                ? "image  de couverture"
                : field === "titre"
                ? "titre de l'événement "
                : field === "date"
                ? "date de l'événement"
                : field === "description"
                ? "déscription de l'événement"
                : field === "lieu"
                ? "lieu de l'événement"
                : null}
            </strong>{" "}
            est obligatoire à la block {index + 1}.
          </div>
        );
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.classList.add(
            "animate__animated",
            "animate__shakeX",
            "border-2",
            "border-red-500",
            "outline-none"
          );
          setTimeout(() => {
            inputRefs.current[index][field].current.classList.remove(
              "animate__animated",
              "animate__shakeX",
              "border-2",
              "border-red-500",
              "outline-none"
            );
          }, 5000);
          inputRefs.current[index][field].current.focus();
          return false;
        }
      }
      animateAndScrollToRef(inputRefs.current[index].titreMateriel);
    }
    return true;
  };

  function toRoman(num) {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
      "XXI",
      "XXII",
      "XXIII",
      "XXIV",
      "XXV",
      "XXVI",
      "XXVII",
      "XXVIII",
      "XXIX",
      "XXX",
    ];
    return romanNumerals[num - 1] || num.toString();
  }
  const utilisateurId = localStorage.getItem("userId");
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Créer un objet FormData
      const formData = new FormData();
      formData.append(`utilisateurId`, utilisateurId);
      // Boucler sur les événements et ajouter chaque champ au FormData
      for (let index = 0; index < evenements.length; index++) {
        const evenement = evenements[index];

        // Valider chaque bloc avant l'envoi
        if (!validateBloc(evenement, index)) {
          return;
        }

        // Ajouter les champs au FormData
        formData.append(`evenements[${index}][titre]`, evenement.titre);
        formData.append(`evenements[${index}][date]`, evenement.date);
        formData.append(`evenements[${index}][lieu]`, evenement.lieu);
        formData.append(
          `evenements[${index}][description]`,
          evenement.description
        );

        // Si une image est présente, l'ajouter au FormData
        if (evenement.image) {
          formData.append(`evenements[${index}][image]`, evenement.image);
        }
      }

      // Envoyer les données via Axios
      await axios.post(
        "https://imuhirabackend.eac-advisor.org/apiv1/evenements",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Si tout se passe bien, afficher un message de succès
      toast.success("Les événements ont été enregistrés avec succès !");
      navig("/comm");
    } catch (error) {
      console.error("Erreur lors de l'enregistrement des événements:", error);
      toast.error(
        "Une erreur est survenue lors de l'enregistrement des événements."
      );
    }
  };
  // Fonction pour obtenir la date minimale (1er janvier de l'année en cours)
  const getMinDate = () => {
    const currentYear = new Date().getFullYear();
    return `${currentYear}-01-01`;
  };
  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminCommunauteNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/comm")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4  rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        }  flex-col items-center`}
      >
        <div className="rounded-lg p-2   w-full   sm:p-4">
          {evenements.map((bloc, index) => (
            <div
              key={index}
              className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2  flex flex-col"
            >
              <div className="w-full">
                <div className="h-max w-full">
                  <div className="px-3 w-full">
                    <div className="w-full flex items_center justify-between">
                      <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                        {toRoman(index + 1)}. événements
                      </label>
                      {index > 0 && (
                        <button
                          type="button"
                          className="text-red-500 px-1 max-sm:text-xs py-2"
                          onClick={() => removeBloc(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <div className="rounded-xl p-2 w-full">
                  <div className="flex flex-col">
                    <div className="mb-2">
                      <div className="flex flex-col items-center">
                        <label
                          className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                          ref={inputRefs.current[index].image}
                          htmlFor={`id_photo_${index}`}
                        >
                          Photos de couverture
                        </label>
                        <input
                          type="file"
                          accept=".jpeg,.png,.jpg,.gif"
                          className="w-full hidden mt-1"
                          id={`id_photo_${index}`}
                          onChange={(e) =>
                            handleInputChange(index, "image", e.target.files[0])
                          }
                        />
                        <div className="flex w-full justify-start">
                          {bloc.preview && (
                            <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                              <img
                                src={bloc.preview}
                                alt={`Aperçu ${index}`}
                                className="object-cover w-full h-full"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor={`titre_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Titre du evenements
                      </label>
                      <input
                        email="text"
                        id={`titre_${index}`}
                        value={bloc.titre}
                        onChange={(e) =>
                          handleInputChange(index, "titre", e.target.value)
                        }
                        placeholder="Le  titre  du evenements"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].titre}
                      />
                    </div>
                    <div className="flex flex-col ">
                      <label
                        htmlFor={`data_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        id={`data_${index}`}
                        value={bloc.date}
                        onChange={(e) =>
                          handleInputChange(index, "date", e.target.value)
                        }
                        placeholder="dd-mm-yyyy"
                        min={getMinDate()}
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].date}
                      />
                    </div>

                    <div className="flex flex-col mb-10">
                      <label
                        htmlFor={`lieu_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Lieu
                      </label>
                      <input
                        id={`lieu_${index}`}
                        value={bloc.lieu}
                        onChange={(option) =>
                          handleInputChange(index, "lieu", option.target.value)
                        }
                        placeholder="Le  lieu "
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].lieu}
                      />
                    </div>

                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`description_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        description
                      </label>
                      <textarea
                        id={`description_${index}`}
                        value={bloc.description}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                        placeholder="Déscription de l'événement"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].description}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}

          <div className="w-full flex justify-start">
            <div
              className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
              onClick={addBloc}
            >
              Autre événement
            </div>
          </div>
        </div>
        <div className="flex justify-end my-5  w-[98%]">
          <button
            type="submit"
            disabled={boutLoading}
            htmlFor="send"
            className="mt-3 cursor-pointer w-max flex justify-end bg-[#3ad12c] p-2 rounded text-white"
          >
            Enregistre
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminCommunauteAjouter;
