import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Utiliser useParams pour obtenir l'id
import axios from "axios"; // N'oubliez pas d'importer axios si nécessaire
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";

function AdminLoisModi() {
  const { isDarVisitLoggined } = useThemes();
  const navigate = useNavigate();

  const { id } = useParams();
  const loiId = decryptData(id); // Décrypter l'ID si nécessaire

  const [mobile11, setMobile11] = useState(window.innerWidth < 501);
  const [mobile, setMobile] = useState(window.innerWidth <= 640);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loi, setLoi] = useState({
    image: "",
    titre: "",
    description: "",
    preview: "",
    photoCouverture: "",
  });

  const inputRefs = useRef({
    image: React.createRef(),
    titre: React.createRef(),
    description: React.createRef(),
  });

  useEffect(() => {
    const handleResize = () => {
      setMobile11(window.innerWidth < 501);
      setMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fonction pour récupérer les détails de la loi
  const fetchLoiDetails = async () => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/lois/${loiId}`
      );
      setLoi({
        ...response.data,
        photoCouverture: response.data.photoCouverture,
      });
      setLoading(false);
    } catch (err) {
      console.error(
        "Erreur lors de la récupération des détails de la loi :",
        err
      );
      setError("Erreur lors de la récupération des détails.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoiDetails();
  }, [loiId]);

  const handleInputChange = (field, value) => {
    const newBloc = { ...loi };

    if (field === "image") {
      newBloc.image = value;
      newBloc.preview = URL.createObjectURL(value);
    } else {
      newBloc[field] = value;
    }

    setLoi(newBloc);
  };

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateBloc = () => {
    const requiredFields = ["titre", "description"];
    for (const field of requiredFields) {
      if (!loi[field]) {
        toast.warning(
          <div>
            Le <strong>{field === "titre" ? "titre " : "déscription "}</strong>{" "}
            est obligatoire.
          </div>
        );
        animateAndScrollToRef(inputRefs.current[field]);
        return false;
      }
    }
    return true;
  };

  // Fonction pour soumettre la mise à jour
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateBloc()) return;

    try {
      const formData = new FormData();
      if (loi.image) {
        formData.append("photoCouverture", loi.image);
      }
      formData.append("titre", loi.titre);
      formData.append("description", loi.description);

      await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/lois/${loiId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Le loi a été modifié avec succès");
      navigate("/ressource/loi"); // Redirection après la modification
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la loi :", err);
      toast.error("Erreur lors de la mise à jour.");
    }
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navigate("/ressource/loi")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="rounded-lg p-2 w-full sm:p-4">
          <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
            <div className="mb-2">
              <div className="flex flex-col items-center">
                <label
                  className="w-full border border-gray-300 block cursor-pointer outline-none focus:border-[#3ad12c] rounded-md p-2.5 bg-transparent"
                  htmlFor="id_photo"
                >
                  Photos de couverture
                </label>
                <input
                  type="file"
                  accept=".jpeg,.png,.jpg,.gif"
                  className="w-full hidden mt-1"
                  id="id_photo"
                  onChange={(e) =>
                    handleInputChange("image", e.target.files[0])
                  }
                />
                <div className="flex w-full justify-start">
                  {loi.preview ? (
                    <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                      <img
                        src={loi.preview}
                        alt="Aperçu"
                        className="object-cover w-full h-full"
                      />
                    </div>
                  ) : (
                    loi.photoCouverture && (
                      <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                        <img
                          src={`https://imuhirabackend.eac-advisor.org/uploads/lois/${loi.photoCouverture}`}
                          alt={loi.titre}
                          className="object-cover w-full h-full"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="rounded-xl p-2 w-full">
                <div className="flex flex-col">
                  <label
                    htmlFor="titre"
                    className="block mt-5 uppercase tracking-wide text-lg mb-1"
                  >
                    Titre
                  </label>
                  <input
                    type="text"
                    id="titre"
                    value={loi.titre}
                    onChange={(e) => handleInputChange("titre", e.target.value)}
                    placeholder="Le titre"
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                  />
                </div>
                <div className="flex flex-col mt-5 mb-5">
                  <label
                    htmlFor="description"
                    className="block mt-5 uppercase tracking-wide text-lg mb-1"
                  >
                    Déscription
                  </label>
                  <textarea
                    id="description"
                    value={loi.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    placeholder="Déscription"
                    className="h-[12rem] w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="px-4 mb-2 font-semibold py-3 bg-[#3ad12c] flex items-center justify-center cursor-pointer transition-all rounded-md hover:bg-[#54bc60] w-full text-white"
          >
            Enregistrer les modifications
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminLoisModi;
