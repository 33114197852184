import React, { useState } from 'react'
import { problems } from '../../../Data/Data'
import { useThemes } from '../../../UserContext/UserContext'
import { Drawer } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import SignaleForm from '../../Visiteurs/Signal/SignaleForm'
import AdminSignalNavBars from './AdminSignalNavBars'
import Footer from '../../Visiteurs/FootentContent/Footer'

function AdminSignalPropose() {
  const { isDark, HundlwScrollTop, isDarVisitLoggined } = useThemes()
  const navig = useNavigate()
  const [isSigner, GetisSigner] = useState(false)

  return (
    <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
      <div className="container mx-auto p-4">
        <AdminSignalNavBars />
        <h1 className="text-3xl font-bold text-center mb-8">Liste des nouveau problèmes signalés</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {problems.map((problem, index) => (
            <div
              onClick={() => { navig("/signal/propose/detail"); HundlwScrollTop() }}
              key={index}
              className={`${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6 cursor-pointer`}
            >
              <h2 className="text-xl font-semibold mb-2">{problem.name}</h2>
              <p className="">
                <strong>Contact :</strong> {problem.contact}
              </p>
              <p className=" text-lg">
                <strong>Description :</strong> {problem.description}
              </p>
              <p className="flex">
                <strong>Cordonne :</strong>
                <div className='ml-3'>
                  <span className='ml-2'>long: 28,2829002</span>
                  <span className='ml-2'>lat: -34,373894</span>
                </div>
              </p>
              <p className="">
                <strong>Gravité :</strong> {problem.severity}
              </p>
              <p className="">
                <strong>Actions :</strong> {problem.actions}
              </p>
              <p className="">
                <strong>Attentes :</strong> {problem.expectations}
              </p>
            </div>
          ))}
        </div>
        <div className='flex justify-end w-full'>
          <div onClick={() => GetisSigner(true)} class="bg-[#3ad12c] cursor-pointer hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded-lg inline-block mt-4">
            Signaler un problème
          </div>
        </div>
      </div>

      <Drawer size="full" open={isSigner} placement='top' onClose={() => GetisSigner(false)}>
        <Drawer.Header>
          <Drawer.Title className='text-center uppercase'>Signaler un Problème</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <SignaleForm />
        </Drawer.Body>
      </Drawer>
      <Footer/>
    </div>
  )
}

export default AdminSignalPropose