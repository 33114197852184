/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import axios from "axios";

function AdminFaitsAjouter() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", hundlesSize);
    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", hundleSize);
    return () => {
      window.removeEventListener("resize", hundleSize);
    };
  }, []);

  const [fait, setBlocs] = useState([
    {
      titre: "",
      descript: "",
      partie: [
        {
          sous_titre: "",
          descript: "",
        },
      ],
    },
  ]);

  const addBloc = () => {
    setBlocs((prevBlocs) => [
      ...prevBlocs,
      {
        titre: "",
        source: "",
        partie: [
          {
            sous_titre: "",
            descript: "",
          },
        ],
      },
    ]);

    if (
      inputRefs.current[fait.length] &&
      inputRefs.current[fait.length].partie.length === 0
    ) {
      inputRefs.current[fait.length].partie.push({
        sous_titre: React.createRef(),
        descript: React.createRef(),
      });
    }
  };
  const removeBloc = (index) => {
    const newBlocs = [...fait];
    newBlocs.splice(index, 1);
    setBlocs(newBlocs);
  };
  const inputRefs = useRef([]);
  if (inputRefs.current.length !== fait.length) {
    inputRefs.current = Array(fait.length)
      .fill({})
      .map((_, index) => ({
        titre: React.createRef(null),
        source: React.createRef(null),
        partie: Array(fait[index].partie.length)
          .fill({})
          .map((__, tableIndex) => ({
            sous_titre: React.createRef(null),
            descript: React.createRef(null),
          })),
      }));
  }

  const handleInputChange = (index, field, value) => {
    const newBlocs = [...fait];
    newBlocs[index][field] = value;
    setBlocs(newBlocs);
  };

  // ...........................Module................
  const addpartie = (index) => {
    const newBlocs = [...fait];
    newBlocs[index].partie.push({ sous_titre: "" });
    setBlocs(newBlocs);
    // Vérifie si les références dans inputRefs sont synchronisées avec le nouveau partie
    if (
      inputRefs.current[index].partie.length !== newBlocs[index].partie.length
    ) {
      inputRefs.current[index].partie.push({
        sous_titre: React.createRef(),
        descript: React.createRef(),
      });
    }
  };
  const removepartie = (blocIndex, tableIndex) => {
    const newBlocs = [...fait];
    newBlocs[blocIndex].partie.splice(tableIndex, 1);
    inputRefs.current[blocIndex].partie.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };

  const handleModuleInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...fait];
    newBlocs[blocIndex].partie[tableIndex][field] = value;
    setBlocs(newBlocs);
  };

  // ....................................................................................................

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateBloc = (bloc, index) => {
    const requiredFields = ["titre", "source"];
    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "titre"
                ? "titre  "
                : field === "source"
                ? "source  "
                : null}
            </strong>{" "}
            est obligatoire à la block {index + 1}.
          </div>
        );
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.classList.add(
            "animate__animated",
            "animate__shakeX",
            "border-2",
            "border-red-500",
            "outline-none"
          );
          setTimeout(() => {
            inputRefs.current[index][field].current.classList.remove(
              "animate__animated",
              "animate__shakeX",
              "border-2",
              "border-red-500",
              "outline-none"
            );
          }, 5000);
          inputRefs.current[index][field].current.focus();
          return false;
        }
      }
      animateAndScrollToRef(inputRefs.current[index].titreMateriel);
    }
    return true;
  };

  function toRoman(num) {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
      "XXI",
      "XXII",
      "XXIII",
      "XXIV",
      "XXV",
      "XXVI",
      "XXVII",
      "XXVIII",
      "XXIX",
      "XXX",
    ];
    return romanNumerals[num - 1] || num.toString();
  }
  const userId = localStorage.getItem("userId");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      toast.error("Vous devez vous connecter pour ajouter un fait");
    }
    // Boucle pour valider chaque bloc fait
    for (let index = 0; index < fait.length; index++) {
      if (!validateBloc(fait[index], index)) {
        return;
      }
    }

    try {
      // Faire l'appel API avec axios pour créer les faits
      const response = await axios.post(
        `https://imuhirabackend.eac-advisor.org/apiv1/faitenvs?utilisateurId=${userId}`,
        fait,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Traiter la réponse de l'API
      if (response.status === 200 || response.status === 201) {
        toast.success("Le fait est enregistré avec succès");
        navig("/ressource/fait");
      } else {
        toast.error(
          `Erreur: ${response.data.message || "Une erreur s'est produite"}`
        );
      }
    } catch (error) {
      console.error("Erreur lors de la soumission des faits:", error);
      toast.error("Erreur lors de la soumission des faits");
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/ressource/fait")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4  rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        }  flex-col items-center`}
      >
        <div className="rounded-lg p-2   w-full   sm:p-4">
          {fait.map((bloc, index) => (
            <div
              key={index}
              className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2  flex flex-col"
            >
              <div className="w-full">
                <div className="h-max w-full">
                  <div className="px-3 w-full">
                    <div className="w-full flex items_center justify-between">
                      <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                        {toRoman(index + 1)}. Faits
                      </label>
                      {index > 0 && (
                        <button
                          type="button"
                          className="text-red-500 px-1 max-sm:text-xs py-2"
                          onClick={() => removeBloc(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <div className="rounded-xl p-2 w-full">
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <label
                        htmlFor={`titre_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Titre du fait
                      </label>
                      <input
                        email="text"
                        id={`titre_${index}`}
                        value={bloc.titre}
                        onChange={(e) =>
                          handleInputChange(index, "titre", e.target.value)
                        }
                        placeholder="Le  titre  du fait"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].titre}
                      />
                    </div>

                    <div className="flex flex-col mt-5 mb-10">
                      <div className="relative mt-2 text-lg ml-5">
                        Parties environnementales{" "}
                        <span className="pl-2 font-bold">{bloc.titre}</span>{" "}
                      </div>
                      {bloc.partie.map((table, tableIndex) => (
                        <div key={tableIndex} className="flex flex-col mb-4">
                          <label className="block text-lg mb-2">
                            Partie {tableIndex + 1}
                          </label>

                          <div className="flex flex-col">
                            <label
                              htmlFor={`sous_titre_${index}_${tableIndex}`}
                              className="block text-sm font-semibold mb-2"
                            >
                              Sous-Titre
                            </label>
                            <input
                              id={`sous_titre_${index}_${tableIndex}`}
                              value={table.sous_titre}
                              onChange={(e) =>
                                handleModuleInputChange(
                                  index,
                                  tableIndex,
                                  "sous_titre",
                                  e.target.value
                                )
                              }
                              placeholder="Le sous-titre"
                              className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                              ref={
                                inputRefs.current[index].partie[tableIndex]
                                  .sous_titre
                              }
                            />
                          </div>

                          <div className="flex flex-col mt-4">
                            <label
                              htmlFor={`descript_${index}_${tableIndex}`}
                              className="block text-sm font-semibold mb-2"
                            >
                              Description
                            </label>
                            <textarea
                              id={`descript_${index}_${tableIndex}`}
                              value={table.descript}
                              onChange={(e) =>
                                handleModuleInputChange(
                                  index,
                                  tableIndex,
                                  "descript",
                                  e.target.value
                                )
                              }
                              placeholder="La description"
                              className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                              ref={
                                inputRefs.current[index].partie[tableIndex]
                                  .descript
                              }
                            />
                          </div>

                          {tableIndex > 0 && (
                            <button
                              type="button"
                              className="text-red-500 text-sm mt-2"
                              onClick={() => removepartie(index, tableIndex)}
                            >
                              Supprimer cette partie
                            </button>
                          )}
                        </div>
                      ))}

                      <div className="flex justify-between mt-4">
                        <button
                          type="button"
                          className="text-green-500 text-sm"
                          onClick={() => addpartie(index)}
                        >
                          Ajouter une nouvelle partie
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <label
                        htmlFor={`source_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Source
                      </label>
                      <input
                        email="text"
                        id={`source_${index}`}
                        value={bloc.source}
                        onChange={(e) =>
                          handleInputChange(index, "source", e.target.value)
                        }
                        placeholder="Le  source "
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].source}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}

          <div className="w-full flex justify-start">
            <div
              className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
              onClick={addBloc}
            >
              Ajouter faits
            </div>
          </div>
        </div>
        <div className="flex justify-end my-5  w-[98%]">
          <button
            type="submit"
            disabled={boutLoading}
            htmlFor="send"
            className="mt-3 cursor-pointer w-max flex justify-end bg-[#3ad12c] p-2 rounded text-white"
          >
            Enregistre
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminFaitsAjouter;
