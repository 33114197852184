// auth.js

// Exporter l'ID utilisateur
export const getUserId = () => {
  return localStorage.getItem("userId");
};

// Exporter l'état admin
export const getIsAdmin = () => {
  return localStorage.getItem("isAdmin") === "true"; // Convertir en booléen
};

// Exporter l'état partenaire
export const getIsPartenaire = () => {
  return localStorage.getItem("isPartenaire") === "true"; // Convertir en booléen
};

// Exporter le CSRF token
export const getCsrfToken = () => {
  return localStorage.getItem("csrfToken");
};
