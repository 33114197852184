import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function AdminSignalNavBars() {
    const navig = useNavigate()
    const { pathname } = useLocation()
    let signalPropose = /^\/signal\/propose.*/
    let signal = /^\/signal.*/
    return (
        <div className='w-full flex justify-between mb-5 items-center'>
            <div onClick={() => navig("/signal")} className={`border p-2 cursor-pointer rounded-md  transition-all  ${signal.test(pathname) && !signalPropose.test(pathname) ? 'bg-[#3ad12c] text-white' : ''} `}>Problème</div>
            <div onClick={() => navig("/signal/propose")} className={`border p-2 cursor-pointer rounded-md  transition-all  ${signalPropose.test(pathname) ? 'bg-[#3ad12c] text-white' : ''} `}>Problème signal</div>
        </div>
    )
}

export default AdminSignalNavBars