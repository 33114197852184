import React from 'react'
import { useNavigate } from 'react-router-dom'

function EvenementDetail() {
    const navig = useNavigate()
    return (
        <div className='mt-[19vh] overflow-hidden'>
            <div onClick={() => navig("/")} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div className='h-[70vh] m-10'>
                <img src="../image/forest.jpg" className='h-full w-auto' alt=' ' />
            </div>
            <div class="py-6 p-8">
                <h2 class="text-xl font-bold mb-2">Initiatives éco-écol d'etend à nouvelle écoles</h2>
                <p class=" mb-4">Publié le 10 août 2023</p>
                <p class="">Découvrez les dernières mises à jour sur la nouvelle réglementation visant à réduire l'empreinte carbone des entreprises.</p>
            </div>
            <div data-aos="fade-down" className='p-10 rounded-xl'>
                <h3 class="text-lg font-semibold mb-2">Objectifs de la Formation</h3>
                <ul class="list-disc pl-6 mb-4">
                    <li className=' ml-[3em] text-lg'>Maîtriser les concepts clés de l'écologie et du développement durable</li>
                    <li className=' ml-[3em] text-lg'>Développer des compétences pédagogiques adaptées à l'éducation environnementale</li>
                    <li className=' ml-[3em] text-lg'>Concevoir et mettre en œuvre des programmes éducatifs innovants</li>
                    <li className=' ml-[3em] text-lg'>Acquérir des techniques de communication efficaces pour sensibiliser divers publics</li>
                    <li className=' ml-[3em] text-lg'>Comprendre les enjeux environnementaux spécifiques au Burundi et les solutions locales</li>
                </ul>
            </div>
        </div>
    )
}

export default EvenementDetail