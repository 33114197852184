import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { Accordion } from 'rsuite'
import { useNavigate } from 'react-router-dom'

function ProgrammeScolaire() {
  const { EdicRef,HundlwScrollTop } = useThemes()
  const  navig  = useNavigate()
  return (

    <div data-aos-duration="700" ref={EdicRef} className=" rounded-lg  p-8 my-8">
      <h2 className="text-3xl font-bold mb-6 ">Éduquer la Prochaine Génération pour un Burundi Durable</h2>
      <div className="grid grid-cols-1 gap-8">
        <div>
          <div className="text-[18px]  my-6">
            Le Mouvement IMUHIRA s'engage à intégrer l'éducation environnementale dans les écoles du Burundi. Nos programmes scolaires visent à sensibiliser les jeunes aux enjeux environnementaux locaux et globaux, tout en les équipant des connaissances et compétences nécessaires pour devenir des citoyens écoresponsables.
          </div>
          <div className="text-[22px] font-semibold mb-4">Nos Programmes par Niveau Scolaire</div>
          <div className="flex">
            <div className="flex items-center border p-4 rounded-xl border-l-[#3ad12c] border-l-2 ml-12">
              <div className="w-[5em] h-[5em] rounded overflow-hidden border-2 border-[#3ad12c] ">
                <img className="w-full h-full object-cover" src="image/ProjetForet.jpg" alt="" />
              </div>
              <div className="ml-6">
                <div className="text-lg font-bold">Ecole Primaire</div>
                <p className="text-[17px] ">"Petites Mains Vertes": Initiation ludique à l'écologie et à la protection de la nature.</p>
                <div className="text-[#3ad12cb0] mt-2 w-max hover:text-[#3ad12c] font-semibold cursor-pointer">Découvrir</div>
              </div>
            </div>
            <div className="flex items-center border p-4 rounded-xl border-l-[#3ad12c] border-l-2 ml-12">
              <div className="w-[5em] h-[5em] rounded overflow-hidden border-2 border-[#3ad12c]">
                <img className="w-full h-full object-cover" src="image/Eco-Leaders de Demain.webp" alt="" />
              </div>
              <div className="ml-6">
                <div className="text-lg font-bold">Ecole Secondaire</div>
                <p className="text-[17px] ">"Eco-Leaders de Demain": Approfondissement des connaissances et projets pratiques.</p>
                <div className="text-[#3ad12cb0] hover:text-[#3ad12c] mt-2 w-max font-semibold cursor-pointer">Explorer</div>
              </div>
            </div>
          </div>
        </div>

        <Accordion data-aos="flip-down">
          <Accordion.Panel header={
            <div className='flex items-center gap-4' >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              <div className="text-[22px] font-semibold">Caractéristiques de nos Programmes</div>
            </div>
          } eventKey={1} >
            <ul className="list-disc text-[17px] ml-[3em]  space-y-2">
              <li><span className='font-semibold'>Adaptés au contexte burundais</span> : Nos programmes sont conçus pour répondre aux défis environnementaux spécifiques du Burundi.</li>
              <li><span className='font-semibold'>Approche pratique</span> : Nous privilégions l'apprentissage par l'action avec des projets concrets et des expériences sur le terrain.</li>
              <li><span className='font-semibold'>Interdisciplinarité</span> : L'environnement est abordé à travers diverses matières (sciences, géographie, économie, etc.).</li>
              <li><span className='font-semibold'>Formation des enseignants </span>: Nous offrons des formations continues pour équiper les enseignants des compétences nécessaires.</li>
              <li><span className='font-semibold'>Implication communautaire</span> : Nos programmes encouragent la participation des familles et de la communauté locale.</li>
            </ul>
          </Accordion.Panel>
          <Accordion.Panel header={
            <div className='flex items-center gap-4' >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              <div className="text-[22px] font-semibold">Projets phares</div>
            </div>
          } eventKey={2} >
            <ul className="list-decimal text-[17px] ml-[3em]  space-y-2">
              <li><span className='font-semibold'>Mon Ecole Verte</span> : Concours national pour transformer les écoles en modèles de durabilité.</li>
              <li><span className='font-semibold'>Jeunes Reporters de l'Environnement</span> : Programme de journalisme environnemental pour les lycéens.</li>
              <li><span className='font-semibold'>Jeunes Solidaires Écologiques</span> :  Création de jardins pédagogiques dans les écoles primaires.</li>
              <li><span className='font-semibold'>Hackathon Vert</span>: Compétition annuelle d'innovation environnementale pour les étudiants universitaires.</li>
            </ul>
          </Accordion.Panel>
          <Accordion.Panel header={
            <div className='flex items-center gap-4' >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              <div className="text-[22px] font-semibold">Ressources Pédagogiques</div>
            </div>
          } eventKey={3} >
            <ul className="list-disc text-[17px] ml-[2em]  space-y-2">
              <p>Nous mettons à disposition des enseignants et des élèves une variété de ressources :</p>
              <li className='ml-[2em]'>Manuels scolaires adaptés à chaque niveau</li>
              <li className='ml-[2em]'>Fiches d'activités et expériences pratiques</li>
              <li className='ml-[2em]'>Vidéos éducatives en kirundi et en français</li>
              <li className='ml-[2em]'>Plateforme en ligne d'échange et de collaboration entre écoles</li>
            </ul>
            <div onClick={() => {navig("/ressource");HundlwScrollTop()}} className=" text-[#3ad12c] w-max cursor-pointer border border-[#3ad12c] font-semibold py-2 px-4 rounded">Accéder aux Ressources</div>
          </Accordion.Panel>


          <Accordion.Panel header={
            <div className="flex items-center gap-4 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              <div className="text-[22px] font-semibold">Partenariats</div>
            </div>
          } eventKey={4} >
            <ul className="list-disc text-[17px] ml-[2em]  space-y-2">
              <p>Nous collaborons étroitement avec :</p>
              <li className='ml-[2em]'>Le Ministère de l'Éducation du Burundi</li>
              <li className='ml-[2em]'>L'UNESCO</li>
              <li className='ml-[2em]'>Des ONG environnementales locales et internationales</li>
              <li className='ml-[2em]'>Des entreprises engagées dans la responsabilité environnementale</li>
            </ul>
          </Accordion.Panel>


          <Accordion.Panel header={
            <div className="flex items-center gap-4 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              <div className="text-[22px] font-semibold">Impact et Résultats</div>
            </div>
          } eventKey={5} >
            <ul className="list-disc text-[17px] ml-[2em]  space-y-2">
              <p>Depuis le lancement de nos programmes scolaires en 2024 :</p>
              <li className='ml-[3em]'>Plus de 500 écoles participantes à travers le Burundi</li>
              <li className='ml-[3em]'>100 000 élèves sensibilisés aux enjeux environnementaux</li>
              <li className='ml-[3em]'>2 000 enseignants formés à l'éducation environnementale</li>
              <li className='ml-[3em]'>50% de réduction moyenne de l'empreinte carbone des écoles participantes</li>
            </ul>
          </Accordion.Panel>
        </Accordion>
        <div >
          <blockquote className="bg-[#3ad12c25] border-l-4 border-[#3ad12c] pl-4 pr-8 py-2 mb-4">
            <p className="text-[#3ad12c] italic">"Le programme d'IMUHIRA a transformé notre école. Nos élèves sont maintenant les ambassadeurs du changement environnemental dans leurs familles et leur communauté. C'est inspirant de voir leur enthousiasme et leur engagement pour un Burundi plus vert."</p>
            <cite className=" font-semibold mt-4">- Jean Ndayishimiye, Directeur de l'École Primaire de Gitega</cite>
          </blockquote>
        </div>
        <div data-aos="flip-left">
          <div className="flex items-center gap-4 mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
            </svg>
            <div className="text-[22px] font-semibold">Comment Participer ?</div>
          </div>
          <ul data-aos="zoom-in"
            data-aos-anchor-placement="top-bottom"
            data-aos-easing="linear"
            data-aos-duration="1000" className="list-disc text-[17px] ml-[2em]  space-y-2">
            <p>Si vous êtes un établissement scolaire, un enseignant, ou un parent intéressé par nos programmes :</p>
          </ul>
          <div onClick={()=>{navig("/cours");HundlwScrollTop()}} className=" text-[#3ad12c] w-max cursor-pointer border border-[#3ad12c] font-semibold py-2 px-4 rounded">Rejoindre nos Programmes</div>
        </div>
      </div>
    </div>


  )
}

export default ProgrammeScolaire