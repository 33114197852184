import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import AdminRessourceNavBars from "./AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";

function AdminRessourceModifier() {
  const { id } = useParams(); // Pour récupérer l'ID de la ressource à modifier
  const resourceId = decryptData(id);
  const { isDarVisitLoggined } = useThemes();
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(window.innerWidth <= 640);
  const [resource, setResource] = useState({
    image: "",
    orderImage: "",
    titre: "",
    descript: "",
    file: "",
    orderfile: "",
    link: "",
    label: "",
    preview: "",
    previewfile: "",
    fileName: "",
  });

  const inputRefs = useRef({
    image: React.createRef(),
    titre: React.createRef(),
    descript: React.createRef(),
    file: React.createRef(),
    link: React.createRef(),
    label: React.createRef(),
  });

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Récupérer la ressource à modifier lors du chargement du composant
    const fetchResource = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/resources/${resourceId}`
        );
        const {
          titre,
          description,
          label,
          linkyoutube,
          photocouverture,
          fichier,
        } = response.data;
        setResource((prev) => ({
          ...prev,
          titre,
          descript: description,
          label,
          link: linkyoutube,
          orderImage: photocouverture,
          orderfile: fichier,
        }));
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la ressource :",
          error
        );
      }
    };

    fetchResource();
  }, [resourceId]);

  const handleInputChange = (field, value) => {
    setResource((prevResource) => {
      const updatedResource = { ...prevResource };
      if (field === "image") {
        updatedResource.preview = URL.createObjectURL(value);
      } else if (field === "file") {
        updatedResource.previewfile = URL.createObjectURL(value);
        updatedResource.fileName = value.name;
      }
      updatedResource[field] = value;
      return updatedResource;
    });
  };

  const animateAndScrollToRef = (ref) => {
    if (ref.current) {
      const animationClasses = [
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none",
      ];
      ref.current.classList.add(...animationClasses);
      setTimeout(() => {
        ref.current.classList.remove(...animationClasses);
      }, 5000);
      ref.current.focus();
    }
  };

  const validateResource = () => {
    const requiredFields = ["titre", "descript", "label"];
    for (const field of requiredFields) {
      if (!resource[field]) {
        toast.warning(`Le champ ${field} est obligatoire.`);
        animateAndScrollToRef(inputRefs.current[field]);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateResource()) return;

    const formData = new FormData();
    formData.append("titre", resource.titre);
    formData.append("description", resource.descript);
    formData.append("label", resource.label);
    formData.append("linkyoutube", resource.link);
    if (resource.image) formData.append("photocouverture", resource.image);
    if (resource.file) formData.append("fichier", resource.file);

    try {
      const response = await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/resources/update/${resourceId}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (response.status === 200) {
        toast.success("La ressource a été mise à jour avec succès");
        navigate("/ressource");
      }
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour de la ressource."
      );
      console.error("Erreur lors de la mise à jour de la ressource :", error);
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
      } h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navigate("/ressource")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="rounded-lg p-2 w-full sm:p-4">
          <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
            <div className="mb-2">
              <div className="flex flex-col items-center">
                <label className="block text-xs font-[700] mt-5 sm:text-lg mb-1">
                  Modifier la ressource
                </label>
                <div className="flex flex-col mt-5 mb-5">
                  <label htmlFor="file" className="block text-lg mb-1">
                    Photo de couverture
                  </label>
                  <input
                    type="file"
                    accept=".jpeg,.png,.jpg,.gif"
                    className="block bg-[#3ad12c] text-white rounded-md px-4 py-2"
                    onChange={(e) =>
                      handleInputChange("image", e.target.files[0])
                    }
                    ref={inputRefs.current.image}
                  />
                </div>
                {resource.preview ? (
                  <div className="relative w-full md:w-[15em] mt-5 overflow-hidden rounded-lg h-[15em] border border-gray-300 shadow-md">
                    <img
                      src={resource.preview}
                      alt="Preview"
                      className="object-cover w-full h-full rounded-lg transition-all duration-300 hover:scale-105"
                    />
                  </div>
                ) : (
                  resource.orderImage && (
                    <div className="relative w-full md:w-[15em] mt-5 overflow-hidden rounded-lg h-[15em] border border-gray-300 shadow-md">
                      <img
                        src={`https://imuhirabackend.eac-advisor.org/uploads/resources/${resource.orderImage}`}
                        alt="Preview"
                        className="object-cover w-full h-full rounded-lg transition-all duration-300 hover:scale-105"
                      />
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="titre" className="block text-lg mb-1">
                Titre de la ressource
              </label>
              <input
                type="text"
                placeholder="Titre de la ressource"
                value={resource.titre}
                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("titre", e.target.value)}
                ref={inputRefs.current.titre}
              />
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="descript" className="block text-lg mb-1">
                Description
              </label>
              <textarea
                placeholder="Entrez une description"
                rows="3"
                value={resource.descript}
                className="w-full border border-gray-300 bg-transparent rounded-md px-2 py-2 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("descript", e.target.value)}
                ref={inputRefs.current.descript}
              />
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="file" className="block text-lg mb-1">
                Fichier (facultatif)
              </label>
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                className="block bg-[#3ad12c] text-white rounded-md px-4 py-2"
                onChange={(e) => handleInputChange("file", e.target.files[0])}
                ref={inputRefs.current.file}
              />
            </div>
            {resource.previewfile ? (
              <div className="mt-5">
                <span>{resource.fileName}</span>
              </div>
            ) : (
              resource.orderfile && (
                <div className="relative w-full md:w-[15em] mt-5 overflow-hidden rounded-lg h-[15em] border border-gray-300 shadow-md">
                  <a
                    href={`https://imuhirabackend.eac-advisor.org/uploads/resources/${resource.orderfile}`}
                    target="_blank" // Ouvre le lien dans un nouvel onglet
                    rel="noopener noreferrer" // Sécurise l'ouverture dans un nouvel onglet
                    download // Cette ligne permet de télécharger le fichier au lieu de l'ouvrir dans le navigateur
                    className="flex items-center justify-center w-full h-full rounded-lg transition-all duration-300 hover:scale-105"
                  >
                    <span className="text-center text-sm text-gray-700">
                      Télécharger le fichier
                    </span>
                  </a>
                </div>
              )
            )}

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="link" className="block text-lg mb-1">
                Lien Youtube (facultatif)
              </label>
              <input
                type="text"
                placeholder="Lien Youtube"
                value={resource.link}
                className="w-full border border-gray-300 bg-transparent rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("link", e.target.value)}
                ref={inputRefs.current.link}
              />
            </div>

            <div className="flex flex-col mt-5 mb-5">
              <label htmlFor="label" className="block text-lg mb-1">
                Label
              </label>
              <input
                type="text"
                placeholder="Label"
                value={resource.label}
                className="w-full border border-gray-300 bg-transparent rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                onChange={(e) => handleInputChange("label", e.target.value)}
                ref={inputRefs.current.label}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-[#3ad12c] text-white rounded-md px-4 py-2 mt-4"
            >
              Modifier
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminRessourceModifier;
