/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { Drawer } from 'rsuite'
import InnovationForm from './InnovationForm'

function Services() {
    const { handleScrollToEduc, isAtelierComminity, SetisAtelierComminity, 
        handleScrollToEcomisme ,handleScrollToFormateur} = useThemes()
    return (
        <div class="container mx-auto py-8">
            <h1 class="text-3xl font-bold mb-6">Nos services</h1>

            <div data-aos-duration="700" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div data-aos="fade-up" data-aos-delay="350" class=" shadow-lg rounded-lg border border-dotted border-blue-500 overflow-hidden">
                    <div class="p-6">
                        <div class="flex items-center mb-4">
                            <div class="bg-blue-500 text-white p-2 rounded-full mr-4">
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <h2 class="text-xl font-bold">Programme scolaire</h2>
                        </div>
                        <p class=" mb-4">Nous proposons des programmes scolaires adaptés aux besoins des élèves et favorisant l'épanouissement personnel et académique.</p>
                        <div onClick={handleScrollToEduc} class="text-blue-500 border py-2 px-4 border-blue-700 w-max rounded-2xl hover:text-blue-700 cursor-pointer font-bold">En savoir plus</div>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="200" class=" shadow-lg rounded-lg  border border-dotted border-green-500 overflow-hidden">
                    <div class="p-6">
                        <div class="flex items-center mb-4">
                            <div class="bg-[#3ad12c] text-white p-2 rounded-full mr-4">
                                <svg fill="#ffffff" height="30px" width="30px" version="1.2" baseProfile="tiny" id="Layer_1" viewBox="0 0 256 256" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <circle cx="193.3" cy="113.5" r="16.8"></circle> <circle cx="172.6" cy="155.3" r="16.8"></circle> <path d="M145.8,175.2h-18.9h-18.9c-11.5,0-18.7,9.5-18.7,21.4v29.3h12.9V200c0-1.2,1-2,2-2c1.2,0,2,0.8,2,2v25.8h41.5V200 c0-1.2,1-2,2-2c1.2,0,2,1,2,2v25.8h12.9v-29.1C164.8,184.7,157.5,175.2,145.8,175.2z"></path> <circle cx="150.5" cy="113.5" r="16.8"></circle> <path d="M199.9,155.3c0,9.3,7.5,16.8,16.8,16.8c9.3,0,16.8-7.5,16.8-16.8c0-9.3-7.5-16.8-16.8-16.8 C207.4,138.5,199.9,146,199.9,155.3z"></path> <path d="M198.2,175.2c-11.5,0-18.7,9.5-18.7,21.4v29.3h12.9V200c0-1.2,1-2,2-2c1.2,0,2,0.8,2,2v25.8h41.5V200c0-1.2,1-2,2-2 c1.2,0,2,1,2,2v25.8h12.9v-29.1c0.2-12.1-7.1-21.6-18.7-21.6h-18.9H198.2z"></path> <circle cx="40.6" cy="155.3" r="16.8"></circle> <path d="M15.6,225.8V200c0-1.2,1-2,2-2c1.2,0,2,0.8,2,2v25.8h41.5V200c0-1.2,1-2,2-2c1.2,0,2,1,2,2v25.8h12.9v-29.1 c0.2-12.1-7.1-21.6-18.7-21.6H40.4H21.5c-11.5,0-18.7,9.5-18.7,21.4v29.3H15.6z"></path> <path d="M110.1,155.3c0,9.3,7.5,16.8,16.8,16.8c9.3,0,16.8-7.5,16.8-16.8c0-9.3-7.5-16.8-16.8-16.8 C117.6,138.5,110.1,146,110.1,155.3z"></path> <circle cx="62.1" cy="113.5" r="16.8"></circle> <circle cx="107.5" cy="113.5" r="16.8"></circle> <circle cx="171.5" cy="77.6" r="16.8"></circle> <circle cx="83.1" cy="77.6" r="16.8"></circle> <circle cx="128.4" cy="77.6" r="16.8"></circle> <circle cx="84.9" cy="155.3" r="16.8"></circle> </g> </g></svg>
                            </div>
                            <h2 class="text-xl font-bold">Atelier communautaire</h2>
                        </div>
                        <p class=" mb-4">Nous organisons des ateliers communautaires pour favoriser les échanges et l'entraide entre les participants.</p>
                        <div onClick={() => SetisAtelierComminity(true)} class="text-green-500 border py-2 px-4 border-green-700 w-max rounded-2xl hover:text-green-700 cursor-pointer font-bold">Participer</div>
                    </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="150" class=" shadow-lg rounded-lg overflow-hidden  border border-dotted border-yellow-500">
                    <div class="p-6">
                        <div class="flex items-center mb-4">
                            <div class="bg-yellow-500 text-white p-2 rounded-full mr-4">
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                            </div>
                            <h2 class="text-xl font-bold">Économisme éducatif</h2>
                        </div>
                        <p class=" mb-4">Nous proposons des programmes d'éducation économique pour développer les compétences financières et entrepreneuriales.</p>

                        <div onClick={handleScrollToEcomisme} class="text-yellow-500 border py-2 px-4 border-yellow-700 w-max rounded-2xl hover:text-yellow-700 cursor-pointer font-bold">S'inscrire</div>

                    </div>
                </div>

                <div data-aos="fade-up" data-aos-delay="100" class=" shadow-lg rounded-lg overflow-hidden cur  border border-dotted border-purple-500">
                    <div class="p-6">
                        <div class="flex items-center mb-4">
                            <div class="bg-purple-500 text-white p-2 rounded-full mr-4">
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <h2 class="text-xl font-bold">Formation de formateurs</h2>
                        </div>
                        <p class=" mb-4">Nous proposons des formations pour les formateurs afin de développer leurs compétences pédagogiques et les aider à mieux accompagner leurs apprenants.</p>
                        <div onClick={handleScrollToFormateur} class="text-purple-500 border py-2 px-4 border-purple-700 w-max rounded-2xl hover:text-purple-700 cursor-pointer font-bold">S'inscrire</div>
                    </div>
                </div>
            </div>
            <Drawer size="full" open={isAtelierComminity} placement='bottom' onClose={() => SetisAtelierComminity(false)}>
                <Drawer.Header>
                    <Drawer.Title className='text-center uppercase'>Soumettre mon Innovation</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <InnovationForm />
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default Services