/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import axios from "axios";

function AjouterCoursAdmin() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", hundlesSize);
    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", hundleSize);
    return () => {
      window.removeEventListener("resize", hundleSize);
    };
  }, []);

  const [cours, setBlocs] = useState([
    {
      image: "",
      titre: "",
      date: "",
      min: "",
      heures: "",
      jours: "",
      semaines: "",
      mois: "",
      annee: "",
      niveaux: "",
      modulecours: [
        {
          titre: "",
          modulecoursDescription: "",
          modulecourscontent: [
            {
              sous_titre: "",
              sous_description: "",
              photo_cover: "",
              url: "",
              preview: "",
            },
          ],
        },
      ],
      descript: "",
      question: [
        {
          question: "",
          reponse: "",
          option: [{ choix: "" }],
        },
      ],
      resource_complementaire: [
        {
          titre: "",
          fichier_resource_complementaire: "",
          url: "",
          preview: "",
        },
      ],
      preview: "",
    },
  ]);

  const addBloc = () => {
    setBlocs((prevBlocs) => [
      ...prevBlocs,
      {
        image: "",
        titre: "",
        date: "",
        min: "",
        heures: "",
        jours: "",
        semaines: "",
        mois: "",
        annee: "",
        niveaux: "",
        modulecours: [
          {
            titre: "",
            modulecoursDescription: "",
            modulecourscontent: [
              {
                sous_titre: "",
                sous_description: "",
                photo_cover: "",
                url: "",
                preview: "",
              },
            ],
          },
        ],
        descript: "",
        question: [
          {
            question: "",
            reponse: "",
            option: [{ choix: "" }],
          },
        ],
        resource_complementaire: [
          {
            titre: "",
            fichier_resource_complementaire: "",
            url: "",
            preview: "",
          },
        ],
        preview: "",
      },
    ]);

    if (
      inputRefs.current[cours.length] &&
      inputRefs.current[cours.length].modulecours.length === 0
    ) {
      // Ajout des références dans "modulecours"
      inputRefs.current[cours.length].modulecours.push({
        titre: React.createRef(),
        modulecoursDescription: React.createRef(),
        modulecourscontent: [
          {
            sous_titre: React.createRef(),
            sous_description: React.createRef(),
            photo_cover: React.createRef(),
            url: React.createRef(),
          },
        ],
      });
    }
    if (
      inputRefs.current[cours.length] &&
      inputRefs.current[cours.length].question.length === 0
    ) {
      // Ajout des références dans "modulecours"
      inputRefs.current[cours.length].question.push({
        question: React.createRef(),
        reponse: React.createRef(),
        option: [
          {
            choix: React.createRef(),
          },
        ],
      });
    }
    if (
      inputRefs.current[cours.length] &&
      inputRefs.current[cours.length].resource_complementaire.length === 0
    ) {
      inputRefs.current[cours.length].resource_complementaire.push({
        titre: React.createRef(),
        reponse: React.createRef(),
        fichier_resource_complementaire: React.createRef(),
      });
    }
  };
  const removeBloc = (index) => {
    const newBlocs = [...cours];
    newBlocs.splice(index, 1);
    setBlocs(newBlocs);
  };
  const inputRefs = useRef([]);
  if (inputRefs.current.length !== cours.length) {
    inputRefs.current = Array(cours.length)
      .fill({})
      .map((_, index) => ({
        image: React.createRef(null),
        titre: React.createRef(null),
        date: React.createRef(null),
        min: React.createRef(null),
        heures: React.createRef(null),
        jours: React.createRef(null),
        semaines: React.createRef(null),
        mois: React.createRef(null),
        annee: React.createRef(null),
        niveaux: React.createRef(null),
        descript: React.createRef(null),
        modulecours: Array(cours[index].modulecours.length)
          .fill({})
          .map((__, tableIndex) => ({
            titre: React.createRef(null),
            modulecoursDescription: React.createRef(null),
            modulecourscontent: Array(
              cours[index].modulecours[tableIndex].modulecourscontent.length
            )
              .fill({})
              .map((__, tableIndexOption) => ({
                sous_titre: React.createRef(null),
                sous_description: React.createRef(null),
                photo_cover: React.createRef(null),
                url: React.createRef(null),
              })),
          })),

        question: Array(cours[index].question.length)
          .fill({})
          .map((__, tableIndex) => ({
            question: React.createRef(null),
            reponse: React.createRef(null),
            option: Array(cours[index].question[tableIndex].option.length)
              .fill({})
              .map((__, tableIndexOption) => ({
                choix: React.createRef(null),
              })),
          })),
        resource_complementaire: Array(
          cours[index].resource_complementaire.length
        )
          .fill({})
          .map((__, tableIndex) => ({
            titre: React.createRef(null),
            fichier_resource_complementaire: React.createRef(null),
            url: React.createRef(null),
          })),
      }));
  }

  const handleInputChange = (index, field, value) => {
    const newBlocs = [...cours];
    if (field === "image") {
      newBlocs[index].preview = URL.createObjectURL(value);
      newBlocs[index].image = value;
    } else {
      newBlocs[index][field] = value;
    }
    setBlocs(newBlocs);
  };

  // ...........................modulecours................
  const addmodulecours = (index) => {
    const newBlocs = [...cours];
    newBlocs[index].modulecours.push({
      titre: "",
      modulecoursDescription: "",
      modulecourscontent: [
        {
          sous_titre: "",
          sous_description: "",
          photo_cover: "",
          url: "",
          preview: "",
        },
      ],
    });
    setBlocs(newBlocs);
    // Vérifie si les références dans inputRefs sont synchronisées avec le nouveau modulecours
    if (
      inputRefs.current[index].modulecours.length !==
      newBlocs[index].modulecours.length
    ) {
      inputRefs.current[index].modulecours.push({
        titre: React.createRef(),
        modulecoursDescription: React.createRef(),
        modulecourscontent: [
          {
            sous_titre: React.createRef(),
            sous_description: React.createRef(),
            photo_cover: React.createRef(),
            url: React.createRef(),
            preview: React.createRef(),
          },
        ],
      });
    }
  };
  const removemodulecours = (blocIndex, tableIndex) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].modulecours.splice(tableIndex, 1);
    inputRefs.current[blocIndex].modulecours.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const addmodulecourscontent = (blocIndex, modulecoursIndex) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].modulecours[modulecoursIndex].modulecourscontent.push({
      sous_titre: "",
      sous_description: "",
      photo_cover: "",
      url: "",
      preview: "",
    });
    setBlocs(newBlocs);
    // Synchroniser les références dans inputRefs pour les nouvelles options
    if (
      inputRefs.current[blocIndex].modulecours[modulecoursIndex]
        .modulecourscontent.length !==
      newBlocs[blocIndex].modulecours[modulecoursIndex].modulecourscontent
        .length
    ) {
      inputRefs.current[blocIndex].modulecours[
        modulecoursIndex
      ].modulecourscontent.push({
        sous_titre: React.createRef(),
        sous_description: React.createRef(),
        photo_cover: React.createRef(),
        url: React.createRef(),
        preview: React.createRef(),
      });
    }
  };
  const removemodulecourscontent = (
    blocIndex,
    modulecoursIndex,
    optionIndex
  ) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].modulecours[modulecoursIndex].modulecourscontent.splice(
      optionIndex,
      1
    );
    setBlocs(newBlocs);
    if (
      inputRefs.current[blocIndex].modulecours[modulecoursIndex]
        .modulecourscontent.length > optionIndex
    ) {
      inputRefs.current[blocIndex].modulecours[
        modulecoursIndex
      ].modulecourscontent.splice(optionIndex, 1);
    }
  };
  const handlemodulecoursInputChange = (
    blocIndex,
    tableIndex,
    field,
    value
  ) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].modulecours[tableIndex][field] = value;
    setBlocs(newBlocs);
  };
  const handlemodulecourscontentInputChange = (
    blocIndex,
    tableIndex,
    optionIndex,
    field,
    value
  ) => {
    const newBlocs = [...cours];
    if (field === "photo_cover") {
      newBlocs[blocIndex].modulecours[tableIndex].modulecourscontent[
        optionIndex
      ].preview = URL.createObjectURL(value);
      newBlocs[blocIndex].modulecours[tableIndex].modulecourscontent[
        optionIndex
      ][field] = value;
    } else {
      newBlocs[blocIndex].modulecours[tableIndex].modulecourscontent[
        optionIndex
      ][field] = value;
    }
    setBlocs(newBlocs);
  };

  // ...........................question................
  const addquestion = (index) => {
    const newBlocs = [...cours];
    newBlocs[index].question.push({
      question: "",
      reponse: "",
      option: [{ choix: "" }],
    });
    setBlocs(newBlocs);
    // Vérifie si les références dans inputRefs sont synchronisées avec le nouveau modulecours
    if (
      inputRefs.current[index].question.length !==
      newBlocs[index].question.length
    ) {
      inputRefs.current[index].question.push({
        question: React.createRef(),
        reponse: React.createRef(),
        option: [
          {
            choix: React.createRef(),
          },
        ],
      });
    }
  };
  const removequestion = (blocIndex, tableIndex) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].question.splice(tableIndex, 1);
    inputRefs.current[blocIndex].question.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const addoption = (blocIndex, modulecoursIndex) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].question[modulecoursIndex].option.push({
      choix: "",
    });
    setBlocs(newBlocs);
    // Synchroniser les références dans inputRefs pour les nouvelles options
    if (
      inputRefs.current[blocIndex].question[modulecoursIndex].option.length !==
      newBlocs[blocIndex].question[modulecoursIndex].option.length
    ) {
      inputRefs.current[blocIndex].question[modulecoursIndex].option.push({
        choix: React.createRef(),
      });
    }
  };
  const removeoption = (blocIndex, modulecoursIndex, optionIndex) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].question[modulecoursIndex].option.splice(
      optionIndex,
      1
    );
    setBlocs(newBlocs);
    if (
      inputRefs.current[blocIndex].question[modulecoursIndex].option.length >
      optionIndex
    ) {
      inputRefs.current[blocIndex].question[modulecoursIndex].option.splice(
        optionIndex,
        1
      );
    }
  };
  const handlequestionInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].question[tableIndex][field] = value;
    setBlocs(newBlocs);
  };
  const handleoptionInputChange = (
    blocIndex,
    tableIndex,
    optionIndex,
    field,
    value
  ) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].question[tableIndex].option[optionIndex][field] = value;
    setBlocs(newBlocs);
  };

  // ...........................resource_complementaire................
  const addresource_complementaire = (index) => {
    const newBlocs = [...cours];
    newBlocs[index].resource_complementaire.push({
      titre: "",
      fichier_resource_complementaire: "",
      url: "",
    });
    setBlocs(newBlocs);
    if (
      inputRefs.current[index].resource_complementaire.length !==
      newBlocs[index].resource_complementaire.length
    ) {
      inputRefs.current[index].resource_complementaire.push({
        titre: React.createRef(),
        fichier_resource_complementaire: React.createRef(),
        url: React.createRef(),
      });
    }
  };
  const removeresource_complementaire = (blocIndex, tableIndex) => {
    const newBlocs = [...cours];
    newBlocs[blocIndex].resource_complementaire.splice(tableIndex, 1);
    inputRefs.current[blocIndex].resource_complementaire.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };
  const handleresource_complementaireInputChange = (
    blocIndex,
    tableIndex,
    field,
    value
  ) => {
    const newBlocs = [...cours];
    if (field === "image") {
      newBlocs[blocIndex].resource_complementaire[tableIndex].preview =
        URL.createObjectURL(value);
      newBlocs[blocIndex].resource_complementaire[
        tableIndex
      ].fichier_resource_complementaire = value;
    } else {
      newBlocs[blocIndex].resource_complementaire[tableIndex][field] = value;
    }
    setBlocs(newBlocs);
  };

  // ....................................................................................................

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateBloc = (bloc, index) => {
    const requiredFields = [
      "image",
      "titre",
      "date",
      "min",
      "heures",
      "jours",
      "semaines",
      "mois",
      "annee",
      "descript",
      "niveaux",
    ];
    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "image"
                ? "image  du cours"
                : field === "titre"
                ? "titre du cours "
                : field === "date"
                ? "date du cours "
                : field === "min"
                ? "minute du cours "
                : field === "heures"
                ? "heures du cours "
                : field === "jours"
                ? "jours du cours "
                : field === "semaines"
                ? "semaines du cours "
                : field === "mois"
                ? "mois du cours "
                : field === "annee"
                ? "annee du cours "
                : field === "descript"
                ? "déscription du cours "
                : field === "niveaux"
                ? "niveaux du cours"
                : null}
            </strong>{" "}
            est obligatoire à la block {index + 1}.
          </div>
        );
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.classList.add(
            "animate__animated",
            "animate__shakeX",
            "border-2",
            "border-red-500",
            "outline-none"
          );
          setTimeout(() => {
            inputRefs.current[index][field].current.classList.remove(
              "animate__animated",
              "animate__shakeX",
              "border-2",
              "border-red-500",
              "outline-none"
            );
          }, 5000);
          // inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          inputRefs.current[index][field].current.focus();
          return false;
        }
      }
      animateAndScrollToRef(inputRefs.current[index].titreMateriel);
    }
    return true;
  };

  function toRoman(num) {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
      "XXI",
      "XXII",
      "XXIII",
      "XXIV",
      "XXV",
      "XXVI",
      "XXVII",
      "XXVIII",
      "XXIX",
      "XXX",
    ];
    return romanNumerals[num - 1] || num.toString();
  }
  const utilisateurId = localStorage.getItem("userId");
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Valider chaque bloc dans "cours"
    for (let index = 0; index < cours.length; index++) {
      if (!validateBloc(cours[index], index)) {
        return;
      }
    }

    // Créer l'objet FormData
    const formData = new FormData();

    // Parcourir le tableau "cours"
    cours.forEach((bloc, index) => {
      formData.append(`cours[${index}][titre]`, bloc.titre);
      formData.append(`cours[${index}][date]`, bloc.date);
      formData.append(`cours[${index}][min]`, bloc.min);
      formData.append(`cours[${index}][heures]`, bloc.heures);
      formData.append(`cours[${index}][jours]`, bloc.jours);
      formData.append(`cours[${index}][semaines]`, bloc.semaines);
      formData.append(`cours[${index}][mois]`, bloc.mois);
      formData.append(`cours[${index}][annee]`, bloc.annee);
      formData.append(`cours[${index}][niveaux]`, bloc.niveaux);
      formData.append(`cours[${index}][descript]`, bloc.descript);
      formData.append(`cours[${index}][preview]`, bloc.preview);
      formData.append(`cours[${index}][image]`, bloc.image);

      // Ajouter les "modulecours"
      bloc.modulecours.forEach((modulecours, modulecoursIndex) => {
        formData.append(
          `cours[${index}][modulecours][${modulecoursIndex}][titre]`,
          modulecours.titre
        );
        formData.append(
          `cours[${index}][modulecours][${modulecoursIndex}][modulecoursDescription]`,
          modulecours.modulecoursDescription
        );

        // Ajouter les "modulecourscontent"
        modulecours.modulecourscontent.forEach((option, optionIndex) => {
          formData.append(
            `cours[${index}][modulecours][${modulecoursIndex}][modulecourscontent][${optionIndex}][sous_titre]`,
            option.sous_titre
          );
          formData.append(
            `cours[${index}][modulecours][${modulecoursIndex}][modulecourscontent][${optionIndex}][sous_description]`,
            option.sous_description
          );

          // Gérer le fichier image ou le lien YouTube
          if (option.photo_cover instanceof File) {
            formData.append(
              `cours[${index}][modulecours][${modulecoursIndex}][modulecourscontent][${optionIndex}][photo_cover]`,
              option.photo_cover
            );
          } else if (option.url && option.url.includes("youtube.com")) {
            formData.append(
              `cours[${index}][modulecours][${modulecoursIndex}][modulecourscontent][${optionIndex}][youtube_link]`,
              option.url
            );
          }
        });
      });

      // Ajouter les "question"
      bloc.question.forEach((question, questionIndex) => {
        formData.append(
          `cours[${index}][question][${questionIndex}][question]`,
          question.question
        );
        formData.append(
          `cours[${index}][question][${questionIndex}][reponse]`,
          question.reponse
        );

        // Ajouter les options de réponse
        question.option.forEach((choix, choixIndex) => {
          formData.append(
            `cours[${index}][question][${questionIndex}][options][${choixIndex}]`,
            choix.choix
          );
        });
      });

      // Ajouter les "resource_complementaire"
      bloc.resource_complementaire.forEach((ressource, ressourceIndex) => {
        formData.append(
          `cours[${index}][resource_complementaire][${ressourceIndex}][titre]`,
          ressource.titre
        );

        // Gérer le fichier image ou le lien de ressource
        if (ressource.fichier_resource_complementaire instanceof File) {
          formData.append(
            `cours[${index}][resource_complementaire][${ressourceIndex}][fichier_resource_complementaire]`,
            ressource.fichier_resource_complementaire
          );
        } else if (ressource.url && ressource.url.includes("youtube.com")) {
          formData.append(
            `cours[${index}][resource_complementaire][${ressourceIndex}][youtube_link]`,
            ressource.url
          );
        }
      });
    });
    formData.append("utilisateurId", utilisateurId);
    // Envoyer le FormData avec Axios
    try {
      const response = await axios.post(
        "https://imuhirabackend.eac-advisor.org/apiv1/cours",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navig("/cours");
      toast.success("Le cours est enregistré avec succès !");
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du cours :", error);
      toast.error("Erreur lors de l'enregistrement du cours.");
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/cours")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4  rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        }  flex-col items-center`}
      >
        <div className="rounded-lg p-2   w-full   sm:p-4">
          {cours.map((bloc, index) => (
            <div
              key={index}
              className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2  flex flex-col"
            >
              <div className="w-full">
                <div className="h-max w-full">
                  <div className="px-3 w-full">
                    <div className="w-full flex items_center justify-between">
                      <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                        {toRoman(index + 1)}. cours
                      </label>
                      {index > 0 && (
                        <button
                          type="button"
                          className="text-red-500 px-1 max-sm:text-xs py-2"
                          onClick={() => removeBloc(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <div className="rounded-xl p-2 w-full">
                  <div className="flex flex-col">
                    <div className="mb-2">
                      <div className="flex flex-col items-center">
                        <label
                          className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                          ref={inputRefs.current[index].image}
                          htmlFor={`id_photo_${index}`}
                        >
                          Photos de couverture
                        </label>
                        <input
                          type="file"
                          accept=".jpeg,.png,.jpg,.gif"
                          className="w-full hidden mt-1"
                          id={`id_photo_${index}`}
                          onChange={(e) =>
                            handleInputChange(index, "image", e.target.files[0])
                          }
                        />
                        <div className="flex w-full justify-start">
                          {bloc.preview && (
                            <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                              <img
                                src={bloc.preview}
                                alt={`Aperçu ${index}`}
                                className="object-cover w-full h-full"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor={`titre_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Titre du cours
                      </label>
                      <input
                        email="text"
                        id={`titre_${index}`}
                        value={bloc.titre}
                        onChange={(e) =>
                          handleInputChange(index, "titre", e.target.value)
                        }
                        placeholder="Le  titre  du cours"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].titre}
                      />
                    </div>
                    <div className="flex flex-col ">
                      <label
                        htmlFor={`data_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        id={`data_${index}`}
                        value={bloc.date}
                        onChange={(e) =>
                          handleInputChange(index, "date", e.target.value)
                        }
                        placeholder="dd-mm-yyyy"
                        min="1910-01-01"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].date}
                      />
                    </div>
                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`descript_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Déscription
                      </label>
                      <textarea
                        email="text"
                        id={`descript_${index}`}
                        value={bloc.descript}
                        onChange={(e) =>
                          handleInputChange(index, "descript", e.target.value)
                        }
                        placeholder="Déscription"
                        className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].descript}
                      ></textarea>
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                      <div className="flex flex-col ">
                        <label
                          htmlFor={`min_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Minute
                        </label>
                        <input
                          type="text"
                          id={`min_${index}`}
                          value={bloc.min}
                          onChange={(e) =>
                            handleInputChange(index, "min", e.target.value)
                          }
                          placeholder="Minute"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2  outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].min}
                        />
                      </div>

                      <div className="flex flex-col ">
                        <label
                          htmlFor={`heures_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Heures
                        </label>
                        <input
                          type="text"
                          id={`heures_${index}`}
                          value={bloc.heures}
                          onChange={(e) =>
                            handleInputChange(index, "heures", e.target.value)
                          }
                          placeholder="Heures"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].heures}
                        />
                      </div>

                      <div className="flex flex-col ">
                        <label
                          htmlFor={`jours_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Jours
                        </label>
                        <input
                          type="text"
                          id={`jours_${index}`}
                          value={bloc.jours}
                          onChange={(e) =>
                            handleInputChange(index, "jours", e.target.value)
                          }
                          placeholder="jours"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].jours}
                        />
                      </div>

                      <div className="flex flex-col ">
                        <label
                          htmlFor={`semaines_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Semaines
                        </label>
                        <input
                          type="text"
                          id={`semaines_${index}`}
                          value={bloc.semaines}
                          onChange={(e) =>
                            handleInputChange(index, "semaines", e.target.value)
                          }
                          placeholder="Semaines"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].semaines}
                        />
                      </div>

                      <div className="flex flex-col ">
                        <label
                          htmlFor={`date_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Mois
                        </label>
                        <input
                          type="text"
                          id={`mois_${index}`}
                          value={bloc.mois}
                          onChange={(e) =>
                            handleInputChange(index, "mois", e.target.value)
                          }
                          placeholder="Mois"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].mois}
                        />
                      </div>

                      <div className="flex flex-col ">
                        <label
                          htmlFor={`date_${index}`}
                          className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                        >
                          Année
                        </label>
                        <input
                          type="text"
                          id={`date_${index}`}
                          value={bloc.annee}
                          onChange={(e) =>
                            handleInputChange(index, "annee", e.target.value)
                          }
                          placeholder="Annee"
                          className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                          ref={inputRefs.current[index].annee}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-10">
                      <label
                        htmlFor={`niveaux_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Niveau
                      </label>
                      <select
                        id={`niveaux_${index}`}
                        value={bloc.niveaux}
                        onChange={(option) =>
                          handleInputChange(
                            index,
                            "niveaux",
                            option.target.value,
                            option,
                            false
                          )
                        }
                        placeholder="Le  titre du restaurant "
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].niveaux}
                      >
                        <option hidden value="  ">
                          Sélectionne le niveau
                        </option>
                        <option className="text-black" value="Avancé">
                          Niveau Avancé
                        </option>
                        <option className="text-black" value="Intermédiaire">
                          Niveau Intermédiaire
                        </option>
                        <option className="text-black" value="Débutant">
                          Niveau Débutant
                        </option>
                        <option className="text-black" value="Tous niveaux">
                          Tous niveaux
                        </option>
                      </select>
                    </div>
                    <div className="flex flex-col mb-10">
                      {bloc.modulecours.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="relative mt-2 text-lg">
                            {toRoman(tableIndex + 1)}. Module du cours{" "}
                            <span className="pl-2 font-bold">{bloc.titre}</span>{" "}
                          </div>
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-2">
                                    <label
                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                      htmlFor={`titre_${index}_${tableIndex}`}
                                    >
                                      Titre
                                    </label>
                                    <input
                                      ref={
                                        inputRefs.current[index].modulecours[
                                          tableIndex
                                        ].sous_titre
                                      }
                                      className="appearance-none block  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.sous_titre}
                                      onChange={(e) =>
                                        handlemodulecoursInputChange(
                                          index,
                                          tableIndex,
                                          "sous_titre",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Titre"
                                      id={`titre_${index}_${tableIndex}`}
                                    />
                                  </div>
                                </div>

                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-5">
                                    <label
                                      className="block  tracking-wide text-grey-darker text-lg  mb-2"
                                      htmlFor={`titre_${index}_${tableIndex}`}
                                    >
                                      {" "}
                                      Déscription
                                    </label>
                                    <textarea
                                      type="number"
                                      id={`titre_${index}_${tableIndex}`}
                                      ref={
                                        inputRefs.current[index].modulecours[
                                          tableIndex
                                        ].sous_description
                                      }
                                      className="appearance-none block  min-h-[10em]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.sous_description}
                                      onChange={(e) =>
                                        handlemodulecoursInputChange(
                                          index,
                                          tableIndex,
                                          "sous_description",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Déscription"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="w-full mb-2">
                                  <label
                                    className="block tracking-wide text-grey-darker text-lg mb-2"
                                    htmlFor={`titre_${index}_${tableIndex}`}
                                  >
                                    Option de module cours:
                                  </label>
                                  <div className="ml-10">
                                    {table.modulecourscontent.map(
                                      (option, option_index) => (
                                        <>
                                          {option_index > 0 && (
                                            <button
                                              className="text-red-500 my-1"
                                              onClick={() =>
                                                removemodulecourscontent(
                                                  index,
                                                  tableIndex,
                                                  option_index
                                                )
                                              }
                                            >
                                              Supprimer cette option
                                            </button>
                                          )}
                                          <div className="flex flex-col">
                                            <div className="rounded-lg w-full">
                                              <div className="w-full mb-2">
                                                <label
                                                  className="block tracking-wide text-grey-darker text-lg mb-2"
                                                  htmlFor={`sous_titre_${index}_${tableIndex}_${option_index}`}
                                                >
                                                  Sous-titre
                                                </label>
                                                <input
                                                  ref={
                                                    inputRefs.current[index]
                                                      .modulecours[tableIndex]
                                                      .modulecourscontent[
                                                      option_index
                                                    ].sous_titre
                                                  }
                                                  className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                  value={option.sous_titre}
                                                  onChange={(e) =>
                                                    handlemodulecourscontentInputChange(
                                                      index,
                                                      tableIndex,
                                                      option_index,
                                                      "sous_titre",
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Sous-titre"
                                                  id={`sous_titre_${index}_${tableIndex}_${option_index}`}
                                                />
                                              </div>
                                            </div>

                                            <div className="rounded-lg w-full">
                                              <div className="w-full mb-5">
                                                <label
                                                  className="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                  htmlFor={`sous_description_${index}_${tableIndex}_${option_index}`}
                                                >
                                                  Déscription de sous titre
                                                </label>
                                                <textarea
                                                  type="number"
                                                  id={`sous_description_${index}_${tableIndex}_${option_index}`}
                                                  ref={
                                                    inputRefs.current[index]
                                                      .modulecours[tableIndex]
                                                      .modulecourscontent[
                                                      option_index
                                                    ].sous_description
                                                  }
                                                  className="appearance-none block  min-h-[6em]  outline-none focus:border-[#3ad12c] w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                  value={
                                                    option.sous_description
                                                  }
                                                  onChange={(e) =>
                                                    handlemodulecourscontentInputChange(
                                                      index,
                                                      tableIndex,
                                                      option_index,
                                                      "sous_description",
                                                      e.target.value
                                                    )
                                                  }
                                                  placeholder="Déscription"
                                                />
                                              </div>
                                            </div>
                                            {console.log(
                                              "table.url",
                                              table.url
                                            )}
                                            <div className="mb-2 flex gap-10">
                                              {table.url !== "" && (
                                                <div className="flex flex-col w-1/2 items-start">
                                                  <label
                                                    className="block tracking-wide text-grey-darker text-lg mb-2"
                                                    htmlFor={`titre_${index}_${tableIndex}`}
                                                  >
                                                    Photos de couverture
                                                  </label>

                                                  <label
                                                    className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                                                    ref={
                                                      inputRefs.current[index]
                                                        .modulecours[tableIndex]
                                                        .modulecourscontent[
                                                        option_index
                                                      ].photo_cover
                                                    }
                                                    htmlFor={`photo_cover_${index}_${tableIndex}_${option_index}`}
                                                  >
                                                    Photos
                                                  </label>
                                                  <input
                                                    type="file"
                                                    accept=".jpeg,.png,.jpg,.gif"
                                                    className="w-full hidden mt-1"
                                                    id={`photo_cover_${index}_${tableIndex}_${option_index}`}
                                                    onChange={(e) =>
                                                      handlemodulecourscontentInputChange(
                                                        index,
                                                        tableIndex,
                                                        option_index,
                                                        "photo_cover",
                                                        e.target.files[0]
                                                      )
                                                    }
                                                  />

                                                  <div className="flex w-full justify-start">
                                                    {option.preview && (
                                                      <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                                                        <img
                                                          src={option.preview}
                                                          alt={`Aperçu ${index}`}
                                                          className="object-cover w-full h-full"
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                              {!option.preview && (
                                                <div className="rounded-lg w-1/2">
                                                  <div className="w-full mb-2">
                                                    <label
                                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                                      htmlFor={`url_${index}_${tableIndex}_${option_index}`}
                                                    >
                                                      Url de video de youtube
                                                    </label>
                                                    <input
                                                      ref={
                                                        inputRefs.current[index]
                                                          .modulecours[
                                                          tableIndex
                                                        ].modulecourscontent[
                                                          option_index
                                                        ].url
                                                      }
                                                      className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                      value={table.url}
                                                      id={`url_${index}_${tableIndex}_${option_index}`}
                                                      onChange={(e) =>
                                                        handlemodulecourscontentInputChange(
                                                          index,
                                                          tableIndex,
                                                          option_index,
                                                          "url",
                                                          e.target.value
                                                        )
                                                      }
                                                      placeholder=" Url de video de youtube"
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </div>
                                  <div className="flex justify-end pl-[4%] w-full">
                                    <div
                                      className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                      onClick={() =>
                                        addmodulecourscontent(index, tableIndex)
                                      }
                                    >
                                      Ajouter autre option de modulecours
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              </div>
                            </div>

                            {bloc.modulecours.length > 1 && (
                              <div className="flex justify-end">
                                <div
                                  montant="button"
                                  className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                  onClick={() =>
                                    removemodulecours(index, tableIndex)
                                  }
                                >
                                  Supprimer
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => addmodulecours(index)}
                        >
                          Ajouter autre modulecours
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="relative mt-2 text-lg">Questions</div>
                      {bloc.question.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-2">
                                    <label
                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                      htmlFor={`question_${index}_${tableIndex}`}
                                    >
                                      {toRoman(tableIndex + 1)}.Question
                                    </label>
                                    <input
                                      ref={
                                        inputRefs.current[index].question[
                                          tableIndex
                                        ].question
                                      }
                                      className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.question}
                                      onChange={(e) =>
                                        handlequestionInputChange(
                                          index,
                                          tableIndex,
                                          "question",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Question"
                                      id={`question_${index}_${tableIndex}`}
                                    />
                                  </div>
                                </div>
                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-2">
                                    <label
                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                      htmlFor={`reponse_${index}_${tableIndex}`}
                                    >
                                      Réponse
                                    </label>
                                    <input
                                      ref={
                                        inputRefs.current[index].question[
                                          tableIndex
                                        ].reponse
                                      }
                                      className="appearance-none block  outline-none focus:border-[#3ad12c] w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.reponse}
                                      onChange={(e) =>
                                        handlequestionInputChange(
                                          index,
                                          tableIndex,
                                          "reponse",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Réponse"
                                      id={`reponse_${index}_${tableIndex}`}
                                    />
                                  </div>

                                  <div>
                                    <div className="w-full mb-2">
                                      <label
                                        className="block tracking-wide text-grey-darker text-lg mb-2"
                                        htmlFor={`titre_${index}_${tableIndex}`}
                                      >
                                        Question à choisir:
                                      </label>
                                      <div className="ml-10">
                                        {table.option.map(
                                          (dataOption_choisi, index_option) => (
                                            <div className="flex items-center mb-5">
                                              <div className="text-lg mr-1">
                                                {index_option + 1}.
                                              </div>
                                              <div className="w-full">
                                                <input
                                                  placeholder="Question à choisir"
                                                  className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2"
                                                  value={
                                                    dataOption_choisi.choix
                                                  }
                                                  ref={
                                                    inputRefs.current[index]
                                                      .question[tableIndex]
                                                      .option[index_option]
                                                      .choix
                                                  }
                                                  onChange={(e) =>
                                                    handleoptionInputChange(
                                                      index,
                                                      tableIndex,
                                                      index_option,
                                                      "choix",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              {index_option > 0 && (
                                                <div
                                                  onClick={() =>
                                                    removeoption(
                                                      index,
                                                      tableIndex,
                                                      index_option
                                                    )
                                                  }
                                                  className="border-red-500 border cursor-pointer block p-2 ml-2 rounded-md"
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                    class="bi text-red-700  bi-trash3-fill"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div className="flex justify-end pl-[4%] w-full">
                                        <div
                                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                          onClick={() =>
                                            addoption(index, tableIndex)
                                          }
                                        >
                                          Autre à choisir
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {bloc.modulecours.length > 1 && (
                              <div className="flex justify-end">
                                <div
                                  montant="button"
                                  className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                  onClick={() =>
                                    removequestion(index, tableIndex)
                                  }
                                >
                                  Supprimer
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => addquestion(index)}
                        >
                          Ajouter autre question
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col mb-10">
                      <div className="relative mt-2 text-lg">
                        Recource Complémentaires
                      </div>
                      {bloc.resource_complementaire.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-2">
                                    <label
                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                      htmlFor={`titre_${index}_${tableIndex}`}
                                    >
                                      {toRoman(tableIndex + 1)}.ressource
                                    </label>
                                    <input
                                      ref={
                                        inputRefs.current[index]
                                          .resource_complementaire[tableIndex]
                                          .titre
                                      }
                                      className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.titre}
                                      onChange={(e) =>
                                        handleresource_complementaireInputChange(
                                          index,
                                          tableIndex,
                                          "titre",
                                          e.target.value
                                        )
                                      }
                                      placeholder="titre"
                                      id={`titre_${index}_${tableIndex}`}
                                    />
                                  </div>
                                </div>

                                <div className="mb-2 flex gap-10">
                                  {table.url === "" && (
                                    <div className="flex flex-col w-1/2 items-start">
                                      <label className="block tracking-wide text-grey-darker text-lg mb-2">
                                        Fichier
                                      </label>

                                      <label
                                        className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                                        ref={
                                          inputRefs.current[index]
                                            .fichier_resource_complementaire
                                        }
                                        htmlFor={`fichier_resource_complementaire_${index}_${tableIndex}`}
                                      >
                                        Fichier
                                      </label>
                                      <input
                                        type="file"
                                        accept=".jpeg,.png,.jpg,.gif,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
                                        className="w-full hidden mt-1"
                                        id={`fichier_resource_complementaire_${index}_${tableIndex}`}
                                        onChange={(e) =>
                                          handleresource_complementaireInputChange(
                                            index,
                                            tableIndex,
                                            "image",
                                            e.target.files[0]
                                          )
                                        }
                                      />
                                      <div className="flex w-full justify-start">
                                        {table.preview && (
                                          <div className="w-[15em] mt-[2.5em] overflow-hidden  relative  rounded-lg h-[15em] border border-gray-300">
                                            <img
                                              src={table.preview}
                                              alt={`Aperçu ${index}`}
                                              className="object-cover w-full h-full"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  {!table.preview && (
                                    <div className="rounded-lg w-1/2">
                                      <div className="w-full mb-2">
                                        <label
                                          className="block tracking-wide text-grey-darker text-lg mb-2"
                                          htmlFor={`url_ressource_${index}_${tableIndex}`}
                                        >
                                          Url de video de youtube
                                        </label>
                                        <input
                                          ref={
                                            inputRefs.current[index]
                                              .resource_complementaire[
                                              tableIndex
                                            ].url
                                          }
                                          className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                          value={table.url}
                                          onChange={(e) =>
                                            handleresource_complementaireInputChange(
                                              index,
                                              tableIndex,
                                              "url",
                                              e.target.value
                                            )
                                          }
                                          placeholder=" Url de video de youtube"
                                          id={`url_ressource_${index}_${tableIndex}`}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {bloc.modulecours.length > 1 && (
                              <div className="flex justify-end">
                                <div
                                  montant="button"
                                  className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                  onClick={() =>
                                    removeresource_complementaire(
                                      index,
                                      tableIndex
                                    )
                                  }
                                >
                                  Supprimer
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => addresource_complementaire(index)}
                        >
                          Ajouter autre ressource
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}

          <div className="w-full flex justify-start">
            <div
              className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
              onClick={addBloc}
            >
              Ajouter cours
            </div>
          </div>
        </div>
        <div className="flex justify-end my-5  w-[98%]">
          <button
            type="submit"
            disabled={boutLoading}
            htmlFor="send"
            className="mt-3 cursor-pointer w-max flex justify-end bg-[#3ad12c] p-2 rounded text-white"
          >
            Enregistre
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AjouterCoursAdmin;
