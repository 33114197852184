import React, { useState } from 'react'
import { toast } from 'react-toastify';

function InscireFormation() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        birthdate: '',
        country: '',
        course: '',
        payment: '',
        billingAddress: '',
        education: '',
        experience: '',
        motivation: '',
        referral: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const validateForm = () => {
        let newErrors = {};

        if (!formData.name){
            toast.warning("Le nom complet est requis.")
            return false
        };
        if (!formData.email){
            toast.warning("L'adresse e-mail est requise.")
            return false
        }
         if (!/\S+@\S+\.\S+/.test(formData.email)){
            toast.error('L\'adresse e-mail est invalide.')
            return false
         } ;

        if (!formData.phone) newErrors.phone = 'Le numéro de téléphone est requis.';
        
        if (!formData.birthdate) newErrors.birthdate = 'La date de naissance est requise.';
        if (!formData.country) newErrors.country = 'Le pays/région est requis.';
        if (!formData.course) newErrors.course = 'Le nom de la formation est requis.';
        if (!formData.payment) newErrors.payment = 'La méthode de paiement est requise.';
        if (!formData.billingAddress) newErrors.billingAddress = 'L\'adresse de facturation est requise.';
        if (!formData.education) newErrors.education = 'Le niveau d\'études est requis.';
        if (!formData.experience) newErrors.experience = 'L\'expérience professionnelle est requise.';
        if (!formData.motivation) newErrors.motivation = 'La motivation est requise.';
        if (!formData.referral) newErrors.referral = 'La source de référence est requise.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
toast.success("Vous avez inscrit avec succèss")
            console.log('Form submitted:', formData);
        }
    };

    return (

        <form className="shadow-md w-full rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="name">Nom complet</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : ''}`}
                    id="name"
                    type="text"
                    placeholder="Entrez votre nom"
                    value={formData.name}
                    onChange={handleChange}
                />
                {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
            </div>
            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="email">Adresse e-mail</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.email ? 'border-red-500' : ''}`}
                    id="email"
                    type="email"
                    placeholder="Entrez votre adresse e-mail"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
            </div>
            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="phone">Numéro de téléphone</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.phone ? 'border-red-500' : ''}`}
                    id="phone"
                    type="tel"
                    placeholder="Entrez votre numéro de téléphone"
                    value={formData.phone}
                    onChange={handleChange}
                />
                {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
            </div>
            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="birthdate">Date de naissance</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.birthdate ? 'border-red-500' : ''}`}
                    id="birthdate"
                    type="date"
                    value={formData.birthdate}
                    onChange={handleChange}
                />
                {errors.birthdate && <p className="text-red-500 text-xs italic">{errors.birthdate}</p>}
            </div>
            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="country">Pays/Région</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.country ? 'border-red-500' : ''}`}
                    id="country"
                    type="text"
                    placeholder="Entrez votre pays/région"
                    value={formData.country}
                    onChange={handleChange}
                />
                {errors.country && <p className="text-red-500 text-xs italic">{errors.country}</p>}
            </div>





            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="course">Formation</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.course ? 'border-red-500' : ''}`}
                    id="course"
                    type="text"
                    placeholder="Entrez le nom de la formation"
                    value={formData.course}
                    onChange={handleChange}
                />
                {errors.course && <p className="text-red-500 text-xs italic">{errors.course}</p>}
            </div>

            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="payment">Méthode de paiement</label>
                <select
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.payment ? 'border-red-500' : ''}`}
                    id="payment"
                    value={formData.payment}
                    onChange={handleChange}
                >
                    <option hidden value="">Sélectionnez une méthode de paiement</option>
                    <option className='text-black' value="card">Carte bancaire</option>
                    <option className='text-black' value="paypal">PayPal</option>
                    <option className='text-black' value="bank">Virement bancaire</option>
                </select>
                {errors.payment && <p className="text-red-500 text-xs italic">{errors.payment}</p>}
            </div>

            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="billingAddress">Adresse de facturation</label>
                <textarea
                    className={`shadow appearance-none min-h-[15em] border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.billingAddress ? 'border-red-500' : ''}`}
                    id="billingAddress"
                    rows="3"
                    placeholder="Entrez votre adresse de facturation"
                    value={formData.billingAddress}
                    onChange={handleChange}
                ></textarea>
                {errors.billingAddress && <p className="text-red-500 text-xs italic">{errors.billingAddress}</p>}
            </div>

            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="education">Niveau d'études</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.education ? 'border-red-500' : ''}`}
                    id="education"
                    type="text"
                    placeholder="Entrez votre niveau d'études"
                    value={formData.education}
                    onChange={handleChange}
                />
                {errors.education && <p className="text-red-500 text-xs italic">{errors.education}</p>}
            </div>

            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="experience">Expérience professionnelle</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.experience ? 'border-red-500' : ''}`}
                    id="experience"
                    type="text"
                    placeholder="Entrez votre expérience professionnelle"
                    value={formData.experience}
                    onChange={handleChange}
                />
                {errors.experience && <p className="text-red-500 text-xs italic">{errors.experience}</p>}
            </div>

            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="motivation">Motivation</label>
                <textarea
                    className={`shadow appearance-none min-h-[15em] border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.motivation ? 'border-red-500' : ''}`}
                    id="motivation"
                    rows="3"
                    placeholder="Exprimez votre motivation pour suivre cette formation"
                    value={formData.motivation}
                    onChange={handleChange}
                ></textarea>
                {errors.motivation && <p className="text-red-500 text-xs italic">{errors.motivation}</p>}
            </div>

            <div className="mb-4">
                <label className="block  font-bold mb-2 text-lg" htmlFor="referral">Comment avez-vous entendu parler de cette formation ?</label>
                <input
                    className={`shadow appearance-none border rounded w-full  p-3 bg-transparent  focus:border-[#3ad12c] focus:outline-none focus:shadow-outline ${errors.referral ? 'border-red-500' : ''}`}
                    id="referral"
                    type="text"
                    placeholder="Entrez la source"
                    value={formData.referral}
                    onChange={handleChange}
                />
                {errors.referral && <p className="text-red-500 text-xs italic">{errors.referral}</p>}
            </div>

            <div className="flex items-center  justify-between">
                <button type="submit" className="bg-[#3ad12c] mb-10 hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" >
                    S'inscrire
                </button>
            </div>
        </form>

    )
}

export default InscireFormation