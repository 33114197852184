import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminRessourceAjouter from './AdminRessourceAjouter'

function AdminRessourceAjouterComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminRessourceAjouter />
        </div>
  )
}

export default AdminRessourceAjouterComponent