/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminCommunauteNavBars from "./AdminCommunauteNavBars";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { encryptData } from "../../../encryptionModule";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminCommunaute() {
  const navig = useNavigate();
  const { isDarVisitLoggined } = useThemes();
  const [evenements, setevenements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    fetchevenements(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchevenements = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/evenements?page=${page}&size=${size}&search=${
          search || ""
        }`
      );
      setevenements(response.data.evenements);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des evenements :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Réinitialiser lors de la recherche
    fetchevenements(1, pageSize, event.target.value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton:
          "bg-red-600 text-white hover:bg-red-700 border border-red-600 rounded-lg px-4 py-2",
        cancelButton:
          "bg-gray-300 text-gray-800 hover:bg-gray-400 border border-gray-300 rounded-lg px-4 py-2",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/evenements/${id}`
          );
          setevenements(evenements.filter((evenement) => evenement.id !== id));
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          toast.error("La suppression a échoué.");
        }
      }
    });
  };

  // Exemple de formatage de date en français
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminCommunauteNavBars />
      <div class=" ">
        <div class=" mx-auto">
          <div class="rounded-lg">
            <div class="p-6">
              <div className="flex w-full justify-between p-4 mb-4 items-center">
                <div className="flex items-center mb-4">
                  <label className="text-lg">Taille de la page :</label>
                  <select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                    className="ml-2 p-2 bg-white border border-gray-300 rounded-lg"
                  >
                    {[5, 10, 20, 50, 100].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex w-[60%]">
                  <div className="flex w-full items-center">
                    <div className="w-full">
                      <input
                        value={searchTerm}
                        onChange={handleSearch}
                        className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                        placeholder="Rechercher la evenement"
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => navig("/comm/ajouter")}
                    className="border ml-2 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 m-2 sm:grid-cols-4 gap-4">
        {evenements.map((evenement, index) => (
          <div
            key={index}
            className={`rounded-md shadow-lg ${
              isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
            } p-4`}
          >
            <div className="rounded-lg shadow-md overflow-hidden">
              <img
                src={`https://imuhirabackend.eac-advisor.org/uploads/event/${evenement.photocouverture}`}
                alt={evenement.titre}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                {/* Style pour le titre */}
                <h3 className="text-2xl font-bold text-blue-800 mb-2">
                  {evenement.titre}
                </h3>

                {/* Style pour la date */}
                <p className="text-sm text-gray-600 italic mb-4">
                  {formatDate(evenement.date)}
                </p>

                {/* Style pour le lieu et la description */}
                <p className="text-lg text-gray-700">
                  <span className="font-semibold">Lieu:</span> {evenement.lieu}
                </p>
                <p className="mt-2 text-gray-500 leading-relaxed">
                  {evenement.description}
                </p>
              </div>
            </div>
            <div className="flex w-full justify-between items-center p-2 mt-2 border-t border-gray-200">
              <button
                onClick={() =>
                  navig(
                    `/comm/modifier/${encryptData(evenement.id.toString())}`
                  )
                }
                className="text-blue-600 hover:text-blue-800"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                onClick={() =>
                  navig(`/comm/detail/${encryptData(evenement.id.toString())}`)
                }
                className="text-green-600 hover:text-green-800"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </button>
              <button
                onClick={() => handleDelete(evenement.id)}
                className="text-red-600 hover:text-red-800"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center p-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="p-2 border rounded"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="p-2 border rounded"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default AdminCommunaute;
