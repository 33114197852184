import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import axios from "axios";
import { toast } from "react-toastify";
import { encryptData } from "../../../encryptionModule";
import Swal from "sweetalert2";
import {
  faEdit,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Debounce function to limit API calls while typing
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function AdminConvention() {
  const navig = useNavigate();
  const { isDarVisitLoggined } = useThemes();
  const [conventions, setConventions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchConventions(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchConventions = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/convention?page=${page}&size=${size}&search=${
          search || ""
        }`
      );
      setConventions(response.data.conventions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des conventions :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const debouncedSearch = debounce((searchTerm) => {
    setCurrentPage(1); // Reset to first page when searching
    fetchConventions(1, pageSize, searchTerm);
  }, 500);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton:
          "bg-red-600 text-white hover:bg-red-700 border border-red-600 rounded-lg px-4 py-2", // Styles for confirmation button
        cancelButton:
          "bg-gray-300 text-gray-800 hover:bg-gray-400 border border-gray-300 rounded-lg px-4 py-2", // Styles for cancel button
      },
      buttonsStyling: false, // Prevent SweetAlert2 default styles
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/convention/${id}`
          );
          setConventions(
            conventions.filter((convention) => convention.id !== id)
          );
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          toast.error("La suppression a échoué.");
        }
      }
    });
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="flex w-full justify-between p-4 mb-4 items-center">
        <div className="inline-flex gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="ml-2 p-2 bg-white border border-gray-300 rounded-lg"
          >
            {[1, 5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="flex w-[60%]">
          <div className="flex w-full items-center">
            <div className="w-full">
              <input
                value={searchTerm}
                onChange={handleSearch}
                className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                placeholder="Rechercher dans les conventions"
              />
            </div>
          </div>
          <button
            onClick={() => navig("/ressource/convention/ajouter")}
            className="border ml-2 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]"
          >
            Ajouter
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 m-3 lg:grid-cols-4 gap-2">
        {conventions.map((convention, index) => (
          <div
            key={index}
            className={`rounded-md ${
              isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
            } p-4`}
          >
            <img
              src={`https://imuhirabackend.eac-advisor.org/uploads/convention/${convention.photoCouverture}`}
              alt={convention.titre}
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-2xl font-bold">{convention.titre}</h2>
            <h2 className="text-xl text-yellow-800 font-bold">
              {convention.type}
            </h2>
            <p>{convention.description}</p>
            <div className="flex justify-between items-center p-2">
              <button
                onClick={() =>
                  navig(
                    `/ressource/convention/modif/${encryptData(
                      convention.id.toString()
                    )}`
                  )
                }
                className="text-blue-600 hover:text-blue-700"
              >
                <FontAwesomeIcon icon={faEdit} /> Modifier
              </button>
              <button
                onClick={() =>
                  navig(
                    `/ressource/convention/detail/${encryptData(
                      convention.id.toString()
                    )}`
                  )
                }
                className="text-green-600 hover:text-green-700"
              >
                <FontAwesomeIcon icon={faInfoCircle} /> Détails
              </button>
              <button
                onClick={() => handleDelete(convention.id)}
                className="text-red-600 hover:text-red-700"
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Supprimer
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {conventions.length > 0 && (
        <div className="mt-6 flex justify-center space-x-2">
          <button
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Précédent
          </button>

          {/* Numéros de pages */}
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`px-4 py-2 ${
                currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-300"
              } rounded-lg`}
            >
              {i + 1}
            </button>
          ))}

          <button
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Suivant
          </button>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default AdminConvention;
