import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminSignalDetail from './AdminSignalDetail'

function AdminSignalDetailComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminSignalDetail />
        </div>
    )
}

export default AdminSignalDetailComponent