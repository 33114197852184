import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer } from "rsuite";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function AdminFaitsDetail() {
  const { isDarVisitLoggined } = useThemes();
  const navigate = useNavigate();
  const [isModifi, setIsModifi] = useState(false);
  const [faitData, setFaitData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPartie, setSelectedPartie] = useState(null); // State to track the selected item for editing
  const [updatedData, setUpdatedData] = useState({
    titre: "",
    description: "",
  }); // State to hold the updated data
  const { id } = useParams();
  const faitId = decryptData(id);

  useEffect(() => {
    const fetchFaitData = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/faitenvs/${faitId}`
        );
        setFaitData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the fait data:", error);
        setLoading(false);
      }
    };

    fetchFaitData();
  }, [faitId]);

  const handleEditClick = (partie) => {
    setSelectedPartie(partie);
    setUpdatedData({ titre: partie.titre, description: partie.description });
    setIsModifi(true); // Open the drawer
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    try {
      // Make a PUT or PATCH request to update the selected item
      await axios.put(
        `https://imuhirabackend.eac-advisor.org/apiv1/faitenvs/partiefaitenv/${selectedPartie.id}`,
        updatedData
      );
      setFaitData((prevData) => ({
        ...prevData,
        partiefaitenvs: prevData.partiefaitenvs.map((item) =>
          item.id === selectedPartie.id ? { ...item, ...updatedData } : item
        ),
      }));
      setIsModifi(false);
      toast.success("Le partie fait est modifié avec succès");
    } catch (error) {
      console.error("Error updating the fait part:", error);
    }
  };
  const handleDelete = async (id) => {
    // Display a confirmation dialog using SweetAlert2
    const { isConfirmed } = await Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Vous ne pourrez pas revenir en arrière !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
    });

    if (!isConfirmed) return; // If user cancels the delete, do nothing

    try {
      // Make a DELETE request to the backend to delete the selected item
      await axios.delete(
        `https://imuhirabackend.eac-advisor.org/apiv1/faitenvs/partiefaitenv/${id}`
      );

      // Update local state to remove the deleted item
      setFaitData((prevData) => ({
        ...prevData,
        partiefaitenvs: prevData.partiefaitenvs.filter(
          (item) => item.id !== id
        ),
      }));

      // Show a success message using SweetAlert2
      toast.info("Partie fait environnemental supprimée avec succès !");
    } catch (error) {
      console.error(
        "Erreur lors de la suppression de la partie fait environnemental:",
        error
      );

      // Show an error message using SweetAlert2
      toast.error(
        "Erreur lors de la suppression de la partie fait environnemental."
      );
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!faitData) {
    return <p>No data available</p>;
  }

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navigate("/ressource/fait")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <h2 className="text-2xl mt-5 p-3 font-bold mb-4">{faitData.titre}</h2>

      <div className="grid grid-cols-4 gap-4 p-3">
        {faitData.partiefaitenvs.map((partie, i) => (
          <div
            key={partie.id}
            className="p-5 rounded-e-xl border-l-2 border-[#3ad12c] bg-[#3ad12c2a]"
          >
            <div className="text-lg font-bold mb-2">
              {i + 1}. {partie.titre}
            </div>
            <p>{partie.description}</p>
            <div className="flex w-full mt-5 justify-between items-center">
              <div
                onClick={() => handleEditClick(partie)}
                className="border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </div>

              {/* Delete Button */}
              <div
                onClick={() => handleDelete(partie.id)}
                className="border p-2 border-red-600 rounded text-red-600 hover:scale-105 transition-all cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 0a.5.5 0 0 1 .5.5V1h5V.5a.5.5 0 0 1 1 0V1h1a1 1 0 0 1 1 1v1h-1v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3H1V2a1 1 0 0 1 1-1h1V.5a.5.5 0 0 1 .5-.5zM4 2v1h8V2H4zM3 4h10v10H3V4z" />
                </svg>
              </div>
            </div>
          </div>
        ))}
      </div>

      <p className="m-4 text-[20px]">
        <span className="text-[25px] text-[#3ad12c]">Source</span>:{" "}
        {faitData.source}
      </p>

      <Footer />
      <Drawer
        placement="right"
        size="xs"
        open={isModifi}
        onClose={() => setIsModifi(false)}
      >
        <Drawer.Header>
          <Drawer.Title className="text-lg font-semibold text-gray-800">
            Modifier
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="flex flex-col space-y-4">
            <div>
              <label className="block text-gray-600 text-sm font-medium mb-1">
                Sous-titre
              </label>
              <input
                name="titre"
                value={updatedData.titre}
                onChange={handleInputChange}
                className="bg-gray-100 focus:bg-white focus:ring-2 focus:ring-[#3ad12c] focus:outline-none w-full p-3 rounded-lg border border-gray-300 transition duration-200"
                type="text"
                placeholder="Entrez le sous-titre"
              />
            </div>

            <div>
              <label className="block text-gray-600 text-sm font-medium mb-1">
                Description
              </label>
              <textarea
                name="description"
                value={updatedData.description}
                onChange={handleInputChange}
                className="bg-gray-100 focus:bg-white focus:ring-2 focus:ring-[#3ad12c] focus:outline-none w-full p-3 rounded-lg border border-gray-300 transition duration-200 resize-none"
                rows="5"
                placeholder="Entrez la description"
              />
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={handleSave}
              className="w-full bg-[#3ad12c] hover:bg-[#2ba722] text-white font-semibold p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out"
            >
              Enregistrer
            </button>
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default AdminFaitsDetail;
