import React, { useEffect, useState } from "react";
import NavBarsRessource from "./NavBarsRessource";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Lois() {
  const { isDark } = useThemes();
  const navig = useNavigate();
  const { isDarVisitLoggined } = useThemes();
  const [lois, setLois] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);

  useEffect(() => {
    fetchLois(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchLois = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/lois?page=${page}&size=${size}`
      );
      setLois(response.data.lois);
    } catch (error) {
      console.error("Erreur lors de la récupération des lois :", error.message);
    }
  };

  return (
    <div
      data-aos-duration="1000"
      className={`mt-[18vh] ${
        isDark === "dark" ? null : "bg-gray-100"
      } overflow-hidden`}
    >
      <NavBarsRessource />
      <section className="resource-laws mb-12 p-8">
        <h2 data-aos="fade-up" className="text-2xl font-bold mb-6">
          Les principales lois régissant les ressources
        </h2>
        <ul data-aos="fade-up" className="list-disc list-inside space-y-2">
          {lois.map((loi, index) => (
            <li key={index} className="text-lg ml-3">
              {loi.titre}
            </li>
          ))}
        </ul>
      </section>

      <section data-aos="fade-up" className="resource-laws mb-12 p-8">
        <h2 className="text-2xl font-bold mb-8">
          Détails des principales lois
        </h2>
        {lois.map((loi, index) => (
          <div
            key={index}
            data-aos="fade-up"
            className={`flex flex-col md:flex-row ${
              index % 2 === 0 ? "md:flex-row-reverse" : ""
            } 
              items-center justify-between p-6 mb-8 ${
                isDark === "dark" ? "bg-gray-800" : "bg-white"
              } 
              shadow-lg rounded-lg`}
          >
            {/* Text Section */}
            <div className="md:w-1/2 md:mr-6 md:ml-6">
              <h3 className="text-xl font-semibold mb-4">{loi.titre}</h3>
              <p className="mb-4">{loi.description}</p>
              <ul className="list-disc list-inside space-y-2">
                {loi.axes && loi.axes.length > 0 ? (
                  loi.axes.map((axe, idx) => (
                    <li
                      key={idx}
                      className={`${
                        isDark === "dark" ? "text-gray-300" : "text-gray-700"
                      } text-lg ml-3`}
                    >
                      {axe.titre}
                    </li>
                  ))
                ) : (
                  <li>Aucun axe disponible</li>
                )}
              </ul>
            </div>

            {/* Image Section */}
            <div className="md:w-1/2 mt-4 md:mt-0">
              <img
                src={`https://imuhirabackend.eac-advisor.org/uploads/lois/${loi.photoCouverture}`}
                alt={loi.titre}
                className="w-full h-auto object-cover rounded-lg"
              />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Lois;
