import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";
import axios from "axios";

function AdminEspecesDetail() {
  const { isDark, isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const espesceId = decryptData(id);
  const [especesement, setEspecesement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchEspecesement = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/especes/${espesceId}`
        );
        setEspecesement(response.data);
      } catch (err) {
        setError(
          "Erreur lors de la récupération des détails de l'especesement."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchEspecesement();
  }, [espesceId]);

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div
        onClick={() => {
          navig("/ressource/espece");
        }}
        className="w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-arrow-left-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
          />
        </svg>
      </div>
      <div className="container mx-auto p-4">
        <div data-aos="fade-up" className="grid grid-cols-1 gap-6">
          <div
            className={`${
              isDark === "dark" ? "bg-gray-800" : "bg-white"
            } rounded-lg p-6`}
          >
            <div className="h-[60vh]">
              <img
                className="w-auto h-full"
                src={
                  especesement.image
                    ? `https://imuhirabackend.eac-advisor.org/uploads/especes/${especesement.image}`
                    : "default-image.jpg" // Remplacez par une image par défaut si l'image n'existe pas
                }
                alt={especesement.nomfrancais}
              />
            </div>

            <h2 className="text-xl font-semibold mb-2">
              {especesement.nomfrancais}
            </h2>
            <div>
              <label className="font-bold">Nom Latin :</label>
              <p>{especesement.nomlatin}</p>
            </div>
            <div>
              <label className="font-bold">Nom Kirundi :</label>
              <p>{especesement.nomkirundi}</p>
            </div>
            <div>
              <label className="font-bold">Région :</label>
              <p>{especesement.region}</p>
            </div>
            <div>
              <label className="font-bold">Description :</label>
              <p>{especesement.description}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AdminEspecesDetail;
