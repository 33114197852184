import React, { useEffect, useState } from "react";
import { Drawer, Progress } from "rsuite";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { encryptData } from "../../../encryptionModule";

function Projet() {
  // Define the function at the beginning of your component
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This ensures smooth scrolling to the top
    });
  };

  const { handleScrollToProjet, projetRef, isPropoProjet, SetisPropoProjet } =
    useThemes();
  const navig = useNavigate();

  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [association, setAssociation] = useState("");
  const [partenaires, setPartenaires] = useState("");
  const [parcent, setparcent] = useState("");
  const [objectifs, setObjectifs] = useState("");

  const validateForm = () => {
    if (!titre) {
      toast.warning("Titre du projet requis");
      return false;
    }
    if (!description) {
      toast.warning("Description du projet requise");
      return false;
    }
    if (!association) {
      toast.warning("Association responsable requise");
      return false;
    }
    if (!partenaires) {
      toast.warning("Partenaires potentiels requis");
      return false;
    }
    if (!objectifs) {
      toast.warning("Objectifs et résultats attendus requis");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        titre,
        description,
        association,
        partenaires,
        objectifs,
      };
      toast.success("Les données sont envoyées avec succèss");
      SetisPropoProjet(false);
      console.log("Formulaire soumis:", formData);
    }
  };
  const [projets, setProjets] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedProjet, setSelectedProjet] = useState(null);

  const fetchProjets = async (page = 1, size = 10, search = "") => {
    try {
      const response = await axios.get(
        "https://imuhirabackend.eac-advisor.org/apiv1/projet",
        {
          params: { page, size, search },
        }
      );
      setProjets(response.data.projets);
      setSelectedProjet(response.data.projets[0]);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Erreur lors de la récupération des projets :", error);
    }
  };
  useEffect(() => {
    fetchProjets(currentPage, pageSize, searchTerm);
  }, [currentPage, pageSize, searchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    fetchProjets(1, pageSize, searchTerm);
  };

  const handleProjetClick = (projet) => {
    setSelectedProjet(projet);
    handleScrollToProjet(); // Cette fonction scrolle vers la section de détails
  };

  return (
    <div className=" mt-[18vh]    overflow-hidden">
      <div
        data-aos-duration="1000"
        className="relative overflow-hidden h-[60vh]"
      >
        <img
          src="https://pictures.laprovence.com/cdn-cgi/image/width=3840,format=auto,quality=80,trim.left=0,trim.top=65,trim.height=526,trim.width=943/media/hermes/20240731/20240731_1_3_2_1_0_obj29567184_1.jpg"
          className="h-[95vh] w-full object-cover"
          alt="   "
        />
        <div className="absolute top-0 text-center bg-[#0f172b96] text-white left-0 w-full   flex items-start justify-around  h-full">
          <div className="w-full mt-[2em] flex items-center flex-col">
            <div
              data-aos="fade-up"
              className="text-7xl font-bold titre stroketext1"
            >
              Nos projets et initiatives
            </div>
            <div className="bg-[#5a0200] animate-ping w-10 h-10 bottom-2 rounded-full left-3 absolute"></div>
            <div
              data-aos="fade-left"
              className="text-3xl relative mt-[1em] sm:w-[50%]"
            >
              Découvrez les projets lancés par l'Association catholique pour la
              protection de l'environnement au Burundi (ACAPE Burundi) en
              collaboration avec la Fondation Venuste NIYONGABO (FVN) depuis
              2018.
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto p-8">
        <div className="flex w-full justify-between p-4 mb-4 items-center">
          <div className="inline-flex gap-2">
            <select
              className="bg-transparent border cursor-pointer border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border focus:border-[#3ad12c]"
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              <option value={1} className="text-black">
                1
              </option>
              <option value={5} className="text-black">
                5
              </option>
              <option value={10} className="text-black">
                10
              </option>
              <option value={20} className="text-black">
                20
              </option>
              <option value={50} className="text-black">
                50
              </option>
              <option value={100} className="text-black">
                100
              </option>
            </select>
          </div>
          <div className="flex w-[60%]">
            <div className="flex w-full items-center">
              <div className="w-full">
                <input
                  className="p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]"
                  placeholder="Rechercher un projet"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <button
                onClick={handleSearch}
                className="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold p-2 rounded-e inline-block"
              >
                Rechercher
              </button>
            </div>
          </div>
        </div>
        <div class="space-y-4">
          {projets.map((projet) => (
            <div
              key={projet.id} // Ajoutez une clé pour chaque projet
              data-aos="fade-up"
              onClick={() => handleProjetClick(projet)} // Mettez à jour l'état
              className="w-full bg-[#3ad12c28] flex items-center gap-3 cursor-pointer text-lg font-medium p-2 rounded shadow hover:bg-[#3ad12c] hover:text-white transition"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
              <div>{projet.titre}</div>
            </div>
          ))}
        </div>
      </div>

      {selectedProjet && (
        <div ref={projetRef} className="mx-auto px-4 py-10">
          <div data-aos="fade-up" className="rounded-md p-4">
            <h2 className="text-3xl font-semibold mb-2 text-[#3ad12c]">
              {selectedProjet.titre}
            </h2>
            <img
              src={`https://imuhirabackend.eac-advisor.org/uploads/projet/${selectedProjet.photoCouverture}`}
              alt={selectedProjet.titre}
              className="rounded-md sm:w-[50em] mb-2 h-max"
            />
            <div className="flex text-lg">
              <span className="font-bold">Description</span> :{" "}
              {selectedProjet.description}
            </div>
          </div>
          <div>
            <Progress.Line
              percent={selectedProjet.progression} // Progression du projet
              strokeWidth={20}
              strokeColor="#3ad12c"
            />
            <div className="pl-3 text-lg">
              <span className="font-bold">Progression</span>:
              <span className="ml-2">{selectedProjet.progression}%</span>
            </div>
          </div>
          <div className="rounded-lg p-4">
            <div className="mb-6">
              <div className="text-lg font-semibold mb-2">
                Impact du projet :
              </div>
              <ul className="list-disc pl-6 space-y-2 mx-5">
                {selectedProjet.impacts.map((impact) => (
                  <li key={impact.id} className="text-lg">
                    {impact.titre}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-6">
              <div className="text-lg font-semibold mb-2">
                Objectifs et résultats attendus :
              </div>
              <ul className="list-disc pl-6 space-y-2 mx-5">
                {selectedProjet.objectifprojets.map((objectif) => (
                  <li key={objectif.id} className="text-lg">
                    {objectif.titre}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex justify-start gap-2">
              <div
                onClick={() => {
                  navig(
                    `/projet/Soutenir/${encryptData(
                      selectedProjet.id.toString()
                    )}`
                  );
                  handleScrollToTop();
                }}
                className="bg-transparent font-medium p-2 rounded-lg cursor-pointer text-[#3ad12c] border border-[#3ad12c] transition"
              >
                Soutenir
              </div>
              <div
                onClick={() => {
                  navig(
                    `/projet/benevol/${encryptData(
                      selectedProjet.id.toString()
                    )}`
                  );
                  handleScrollToTop();
                }}
                className="bg-transparent font-medium p-2 rounded-lg cursor-pointer text-[#3ad12c] border border-[#3ad12c] transition"
              >
                Devenir bénévole
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Partenaires:</h3>
        {selectedProjet &&
        selectedProjet.partenaires &&
        selectedProjet.partenaires.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {selectedProjet.partenaires.map((partenaire) => (
              <div key={partenaire.id} className="text-center mb-2">
                <p>{partenaire.nom}</p>
                <img
                  className="w-20 h-20 mx-auto rounded-full"
                  src={`https://imuhirabackend.eac-advisor.org/uploads/projet/${partenaire.logo}`}
                  alt={partenaire.nom}
                />
              </div>
            ))}
          </div>
        ) : (
          <p>Aucun partenaire trouvé.</p>
        )}
      </div>
      <div class="w-full mx-auto px-6 rounded-lg">
        <h1 class="text-2xl font-bold mb-4">Soumettez Votre Projet</h1>
        <p class="mb-4 text-lg">
          Votre contribution est précieuse et peut faire une grande différence.
        </p>
        <h2 class="text-xl font-semibold mb-2">
          Comment Soumettre Votre Projet :
        </h2>
        <ul class="list-disc list-inside mb-4">
          <li className="ml-10 text-lg">
            Remplissez le formulaire de soumission avec les détails de votre
            projet.
          </li>
          <li className="ml-10 text-lg">
            Assurez-vous de fournir des informations claires et complètes.
          </li>
          <li className="ml-10 text-lg">
            Cliquez sur “Envoyer” pour soumettre votre projet.
          </li>
        </ul>
      </div>
      <div
        onClick={() => {
          SetisPropoProjet(true);
        }}
        class="bg-transparent w-max  mx-6 font-medium p-2  rounded-lg cursor-pointer text-[#3ad12c] border border-[#3ad12c]  transition"
      >
        Proposer un nouveau projet
      </div>
      <Drawer
        placement="right"
        open={isPropoProjet}
        size="full"
        onClose={() => SetisPropoProjet(false)}
      >
        <Drawer.Header>
          <Drawer.Title className="text-center uppercase">
            Proposer un Nouveau Projet
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <form onSubmit={handleSubmit}>
            <p className="mb-4 text-lg">
              Vous avez une idée de projet pour la conservation de la
              biodiversité ou l'éducation environnementale au Burundi ?
              Partagez-la avec nous !
            </p>

            <div className="mb-4">
              <label className="block text-lg mb-1">Titre du projet :</label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-transparent outline-none focus:border-[#3ad12c] border"
                placeholder="Titre du projet"
                value={titre}
                onChange={(e) => setTitre(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">
                Description du projet :
              </label>
              <textarea
                className="w-full p-3 rounded-lg bg-transparent outline-none focus:border-[#3ad12c] border"
                rows="4"
                placeholder="Description du projet"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">
                Association responsable :
              </label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-transparent outline-none focus:border-[#3ad12c] border"
                placeholder="Association responsable"
                value={association}
                onChange={(e) => setAssociation(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">
                Partenaires potentiels :
              </label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-transparent outline-none focus:border-[#3ad12c] border"
                placeholder="Partenaires potentiels"
                value={partenaires}
                onChange={(e) => setPartenaires(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">
                Pourcentage de projet :
              </label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-transparent outline-none focus:border-[#3ad12c] border"
                placeholder="Pourcentage de projet"
                value={parcent}
                onChange={(e) => setparcent(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-lg mb-1">
                Objectifs et résultats attendus :
              </label>
              <textarea
                className="w-full p-3 rounded-lg bg-transparent outline-none focus:border-[#3ad12c] border"
                rows="4"
                placeholder="Objectifs et résultats attendus"
                value={objectifs}
                onChange={(e) => setObjectifs(e.target.value)}
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-[#3ad12c] mb-5 text-white px-3 py-2 w-max rounded-lg"
            >
              Soumettre le projet
            </button>
          </form>
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default Projet;
