import React from 'react'
import AdminCours from './AdminCours'
import AdminSlide from '../AdminSlide/AdminSlide'

function AdminCoursComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
        <AdminSlide/>
        <AdminCours/>
    </div>
  )
}

export default AdminCoursComponent