import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
import AdminCommunauteNavBars from "./AdminCommunauteNavBars";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { decryptData } from "../../../encryptionModule";

function AdminCommunauteDetail() {
  const navigate = useNavigate();
  const { isDark, isDarVisitLoggined } = useThemes();
  const { id } = useParams();
  const evenementId = decryptData(id);

  const [evenement, setEvenement] = useState({});
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    // Appel API pour récupérer les détails de l'événement et les participants
    const fetchEventData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/evenements/${evenementId}`
        );
        const data = await response.json();

        // Vérifier si les données sont bien récupérées
        if (data) {
          setEvenement(data);
          setParticipants(data.participants || []); // Si l'API renvoie une clé 'participants'
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'événement:", error);
      }
    };

    fetchEventData();
  }, [evenementId]); // Le useEffect se déclenche à chaque fois que l'id de l'événement change
  // Exemple de formatage de date en français
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminCommunauteNavBars />
      <div
        onClick={() => navigate("/comm")}
        className="w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-arrow-left-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
          />
        </svg>
      </div>

      <div
        className={`rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } overflow-hidden m-5`}
      >
        <img
          src={`https://imuhirabackend.eac-advisor.org/uploads/event/${evenement.photocouverture}`}
          alt={evenement.titre}
          className="w-full h-[70vh] object-cover"
        />
        <div className="p-4">
          <h3 className="text-lg font-bold mb-2">
            {evenement.titre || "Nom de l'événement"}
          </h3>
          <p className="mb-4">
            {formatDate(evenement.date) || "Date de l'événement"}
          </p>
          <p className="text-lg">{evenement.lieu || "Lieu de l'événement"}</p>

          <p className="text-lg">
            {evenement.description || "Description de l'événement"}
          </p>
        </div>

        <div class="w-full mx-auto rounded-lg p-6">
          <h1 class="text-2xl font-bold mb-4 text-center">
            Liste des Participants
          </h1>
          {participants.length > 0 ? (
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nom Complet
                  </th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Téléphone
                  </th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quartier
                  </th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Profession
                  </th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Taille T-shirt
                  </th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Message
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                {participants.map((participant) => (
                  <tr key={participant.id}>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {participant.nomcomplet}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {participant.tel}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {participant.quartier}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {participant.profession}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {participant.tailleTshirt}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      {participant.text}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center">
              Aucun participant trouvé pour cet événement.
            </p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AdminCommunauteDetail;
