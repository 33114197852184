import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminProjetDetail from './AdminProjetDetail'

function AdminProjetDetailComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminProjetDetail />
        </div>
    )
}

export default AdminProjetDetailComponent