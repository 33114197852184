import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminCoursModifiee from './AdminCoursModifiee'

function AdminCoursModifieeComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
    <AdminSlide/>
    <AdminCoursModifiee/>
</div>
  )
}

export default AdminCoursModifieeComponent