import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminProjetAjouter from './AdminProjetAjouter'

function AdminProjetAjouterComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminProjetAjouter />
        </div>
    )
}

export default AdminProjetAjouterComponent