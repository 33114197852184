/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import axios from "axios";

function AdcategorieCommunauteBlogAjouter() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501);
    };
    window.addEventListener("resize", hundlesSize);
    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  const [mobile, SetMobile] = useState(window.innerWidth <= 640);
  const [boutLoading, setboutLoading] = useState(false);

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", hundleSize);
    return () => {
      window.removeEventListener("resize", hundleSize);
    };
  }, []);

  const [blog, setBlocs] = useState([
    {
      titre: "",
      categorie: "",
      description: "",
      partie_blogs: [
        {
          titre: "",
          description: "",
        },
      ],
    },
  ]);

  const addBloc = () => {
    setBlocs((prevBlocs) => [
      ...prevBlocs,
      {
        titre: "",
        categorie: "",
        description: "",
        partie_blogs: [
          {
            titre: "",
            description: "",
          },
        ],
      },
    ]);

    if (
      inputRefs.current[blog.length] &&
      inputRefs.current[blog.length].partie_blogs.length === 0
    ) {
      inputRefs.current[blog.length].partie_blogs.push({
        titre: React.createRef(),
        description: React.createRef(),
      });
    }
  };
  const removeBloc = (index) => {
    const newBlocs = [...blog];
    newBlocs.splice(index, 1);
    setBlocs(newBlocs);
  };
  const inputRefs = useRef([]);
  if (inputRefs.current.length !== blog.length) {
    inputRefs.current = Array(blog.length)
      .fill({})
      .map((_, index) => ({
        titre: React.createRef(null),
        categorie: React.createRef(null),
        description: React.createRef(null),
        partie_blogs: Array(blog[index].partie_blogs.length)
          .fill({})
          .map((__, tableIndex) => ({
            titre: React.createRef(null),
            description: React.createRef(null),
          })),
      }));
  }

  const handleInputChange = (index, field, value) => {
    const newBlocs = [...blog];
    newBlocs[index][field] = value;
    setBlocs(newBlocs);
  };

  // ...........................Module................
  const addpartie_blogs = (index) => {
    const newBlocs = [...blog];
    newBlocs[index].partie_blogs.push({
      titre: "",
      description: "",
    });
    setBlocs(newBlocs);
    // Vérifie si les références dans inputRefs sont synchronisées avec le nouveau partie_blogs
    if (
      inputRefs.current[index].partie_blogs.length !==
      newBlocs[index].partie_blogs.length
    ) {
      inputRefs.current[index].partie_blogs.push({
        titre: React.createRef(),
        description: React.createRef(),
      });
    }
  };
  const removepartie_blogs = (blocIndex, tableIndex) => {
    const newBlocs = [...blog];
    newBlocs[blocIndex].partie_blogs.splice(tableIndex, 1);
    inputRefs.current[blocIndex].partie_blogs.splice(tableIndex, 1);
    setBlocs(newBlocs);
  };

  const handleModuleInputChange = (blocIndex, tableIndex, field, value) => {
    const newBlocs = [...blog];
    newBlocs[blocIndex].partie_blogs[tableIndex][field] = value;
    setBlocs(newBlocs);
  };

  // ....................................................................................................

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 5000);
      ref.current.focus();
    }
  };

  const validateBloc = (bloc, index) => {
    const requiredFields = ["titre", "description", "categorie"];
    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{" "}
            <strong>
              {field === "titre"
                ? "titre  "
                : field === "description"
                ? "déscription du blog "
                : field === "categorie"
                ? "categorie "
                : null}
            </strong>{" "}
            est obligatoire à la block {index + 1}.
          </div>
        );
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.classList.add(
            "animate__animated",
            "animate__shakeX",
            "border-2",
            "border-red-500",
            "outline-none"
          );
          setTimeout(() => {
            inputRefs.current[index][field].current.classList.remove(
              "animate__animated",
              "animate__shakeX",
              "border-2",
              "border-red-500",
              "outline-none"
            );
          }, 5000);
          inputRefs.current[index][field].current.focus();
          return false;
        }
      }
      animateAndScrollToRef(inputRefs.current[index].titreMateriel);
    }
    return true;
  };

  function toRoman(num) {
    const romanNumerals = [
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
      "XI",
      "XII",
      "XIII",
      "XIV",
      "XV",
      "XVI",
      "XVII",
      "XVIII",
      "XIX",
      "XX",
      "XXI",
      "XXII",
      "XXIII",
      "XXIV",
      "XXV",
      "XXVI",
      "XXVII",
      "XXVIII",
      "XXIX",
      "XXX",
    ];
    return romanNumerals[num - 1] || num.toString();
  }
  const utilisateurId = localStorage.getItem("userId");
  const handleSubmit = async (e) => {
    e.preventDefault();
    for (let index = 0; index < blog.length; index++) {
      if (!validateBloc(blog[index], index)) {
        return;
      }
    }

    try {
      const response = await axios.post(
        "https://imuhirabackend.eac-advisor.org/apiv1/blog",
        {
          utilisateurId,
          blogs: blog,
        }
      );

      toast.success("Le blog a été enregistré avec succès !");
      navig("/comm/blog");
    } catch (error) {
      console.error("Erreur lors de l'envoi du blog:", error);
      toast.error("Erreur de connexion. Veuillez réessayer plus tard.");
    }
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/comm/blog")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4  rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        }  flex-col items-center`}
      >
        <div className="rounded-lg p-2   w-full   sm:p-4">
          {blog.map((bloc, index) => (
            <div
              key={index}
              className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2  flex flex-col"
            >
              <div className="w-full">
                <div className="h-max w-full">
                  <div className="px-3 w-full">
                    <div className="w-full flex items_center justify-between">
                      <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                        {toRoman(index + 1)}. blogs
                      </label>
                      {index > 0 && (
                        <button
                          type="button"
                          className="text-red-500 px-1 max-sm:text-xs py-2"
                          onClick={() => removeBloc(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <div className="rounded-xl p-2 w-full">
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <label
                        htmlFor={`titre_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Titre du blog
                      </label>
                      <input
                        email="text"
                        id={`titre_${index}`}
                        value={bloc.titre}
                        onChange={(e) =>
                          handleInputChange(index, "titre", e.target.value)
                        }
                        placeholder="Le  titre  du blog"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].titre}
                      />
                    </div>
                    <div className="flex flex-col mt-5 mb-5">
                      <label
                        htmlFor={`description_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Déscription
                      </label>
                      <textarea
                        email="text"
                        id={`description_${index}`}
                        value={bloc.description}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                        placeholder="Déscription"
                        className="w-full border bg-transparent min-h-[30vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].description}
                      ></textarea>
                    </div>
                    <div className="flex flex-col ">
                      <label
                        htmlFor={`categorie_${index}`}
                        className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1"
                      >
                        Catégorie
                      </label>
                      <input
                        type="text"
                        id={`categorie_${index}`}
                        value={bloc.categorie}
                        onChange={(e) =>
                          handleInputChange(index, "categorie", e.target.value)
                        }
                        placeholder="Catégorie"
                        className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                        ref={inputRefs.current[index].categorie}
                      />
                    </div>

                    <div className="flex flex-col mt-5 mb-10">
                      {bloc.partie_blogs.map((table, tableIndex) => (
                        <div key={tableIndex} className="">
                          <div className="relative mt-2 text-lg">
                            {toRoman(tableIndex + 1)}.partie blog{" "}
                            <span className="pl-2 font-bold">{bloc.titre}</span>{" "}
                          </div>
                          <div className="rounded-lg   w-[100%] pl-[4%]">
                            <div className="rounded-xl  w-full">
                              <div className="flex flex-col">
                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-2">
                                    <label
                                      className="block tracking-wide text-grey-darker text-lg mb-2"
                                      htmlFor={`titre_${index}_${tableIndex}`}
                                    >
                                      Titre
                                    </label>
                                    <input
                                      ref={
                                        inputRefs.current[index].partie_blogs[
                                          tableIndex
                                        ].titre
                                      }
                                      className="appearance-none block  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.titre}
                                      onChange={(e) =>
                                        handleModuleInputChange(
                                          index,
                                          tableIndex,
                                          "titre",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Titre"
                                      id={`titre_${index}_${tableIndex}`}
                                    />
                                  </div>
                                </div>

                                <div className="rounded-lg w-full">
                                  <div className="w-full mb-5">
                                    <label
                                      className="block  tracking-wide text-grey-darker text-lg  mb-2"
                                      htmlFor={`titre_${index}_${tableIndex}`}
                                    >
                                      {" "}
                                      Déscription
                                    </label>
                                    <textarea
                                      type="number"
                                      id={`titre_${index}_${tableIndex}`}
                                      ref={
                                        inputRefs.current[index].partie_blogs[
                                          tableIndex
                                        ].description
                                      }
                                      className="appearance-none block  min-h-[10em]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                      value={table.description}
                                      onChange={(e) =>
                                        handleModuleInputChange(
                                          index,
                                          tableIndex,
                                          "description",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Déscription"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {bloc.partie_blogs.length > 1 && (
                              <div className="flex justify-end">
                                <div
                                  montant="button"
                                  className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                  onClick={() =>
                                    removepartie_blogs(index, tableIndex)
                                  }
                                >
                                  Supprimer
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start pl-[4%] w-full">
                        <div
                          className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                          onClick={() => addpartie_blogs(index)}
                        >
                          Ajouter autre partie
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}

          <div className="w-full flex justify-start">
            <div
              className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
              onClick={addBloc}
            >
              Ajouter blogs
            </div>
          </div>
        </div>
        <div className="flex justify-end my-5  w-[98%]">
          <button
            type="submit"
            disabled={boutLoading}
            htmlFor="send"
            className="mt-3 cursor-pointer w-max flex justify-end bg-[#3ad12c] p-2 rounded text-white"
          >
            Enregistre
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdcategorieCommunauteBlogAjouter;
