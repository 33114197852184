/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import NavBarsRessource from "./NavBarsRessource";
import { Drawer } from "rsuite";
import RessourceFormularie from "./RessourceFormularie";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext";

function Ressource() {
  const [isRessource, SetisRessource] = useState(false);
  const { isDarVisitLoggined, SetDetailCours } = useThemes();
  const [mobile2, setMobile2] = useState(window.innerWidth <= 768);
  const [resources, setresources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, settotalItems] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchresources(currentPage, pageSize);
    // Optional: You could add a cleanup function for axios requests here
  }, [currentPage, pageSize]);

  const fetchresources = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/resources?page=${page}&size=${size}&search=${
          search || ""
        }`
      );
      setresources(response.data.resources);
      setTotalPages(response.data.totalPages);
      settotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des resources :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalItems);
    return `${from} à ${to} resources sur ${totalItems}`;
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
    fetchresources(1, pageSize, event.target.value); // Trigger search
  };

  return (
    <div data-aos-duration="1000" class="rounded-lg mt-[18vh] overflow-hidden">
      <NavBarsRessource />
      <div data-aos="fade-up" class=" ">
        <div class=" mx-auto">
          <div class="rounded-lg">
            <div class="p-6">
              <p class="mb-4">
                Découvrez notre collection de ressources pour approfondir vos
                connaissances sur l'environnement et le développement durable au
                Burundi.
              </p>

              <div class="flex items-center justify-between w-full mb-4">
                <div className={`${mobile2 ? "mb-5" : ""}`}>
                  <div className="flex items-center mb-4">
                    <p className="sm:text-xl">Taille de la page :</p>
                    <select
                      value={pageSize}
                      onChange={(e) =>
                        handlePageSizeChange(Number(e.target.value))
                      }
                      className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded-lg"
                    >
                      {[1, 5, 10, 20, 50, 100].map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="flex w-[60%] items-center">
                  <div className="mb-4">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearch}
                      placeholder="Rechercher un problème..."
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-300 transition-all"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 m-2 sm:grid-cols-4 gap-4">
        {resources.length > 0 ? (
          resources.map((resource, i) => (
            <div
              key={i}
              className={`rounded-md ${
                isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
              } p-1`}
            >
              <div class="">
                <div className="bg-[#3ad12c] flex justify-center items-center">
                  <img
                    src={`https://imuhirabackend.eac-advisor.org/uploads/resources/${resource.photocouverture}`}
                    alt={resource.title}
                    className="rounded-md mb-2"
                  />
                </div>
                <div class="mb-4 p-3">
                  <h2 class="text-lg font-bold ">{resource.titre}</h2>
                  <p class="">{resource.description}</p>
                </div>
                <div class="">
                  <a
                    href={`https://imuhirabackend.eac-advisor.org/uploads/resources/${resource.fichier}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="text-[#3ad12c] bg-white m-3 hover:bg-gray-200  cursor-pointer font-bold py-2 px-4 rounded-md inline-block"
                  >
                    {resource.label}
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Aucune ressource trouvée.</p>
        )}
      </div>

      {resources.length > 0 && (
        <>
          <div className="mt-6 flex items-center justify-center space-x-2">
            <button
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
              className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
            >
              Précédent
            </button>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={`px-3 py-1 rounded-lg transition-all ${
                    currentPage === pageNumber
                      ? "bg-blue-600 text-white"
                      : "bg-gray-300 text-gray-700 hover:bg-gray-400"
                  }`}
                >
                  {pageNumber}
                </button>
              )
            )}
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
              className="px-3 py-1 bg-gray-300 rounded-lg text-gray-700 hover:bg-gray-400 disabled:bg-gray-200"
            >
              Suivant
            </button>
          </div>

          <div className="mt-4 text-center">
            <p className="text-lg">{getRange()}</p>
          </div>
        </>
      )}

      <div data-aos="fade-up" class="mt-8 text-start">
        <h3 class="text-lg font-bold text-teal-500 mb-4">
          Contribuer aux ressources
        </h3>
        <p class="text-gray-700 mb-4">
          Vous avez des connaissances ou des ressources à partager ? Contribuez
          à notre base de données !
        </p>
        <div
          onClick={() => SetisRessource(true)}
          class="bg-transparent cursor-pointer hover:bg-transparent text-[#3ad12c] border border-[#3ad12c] font-bold py-2 px-4 rounded-md inline-block"
        >
          Proposer une ressource
        </div>
      </div>

      <Drawer
        open={isRessource}
        onClose={() => SetisRessource(false)}
        placement="top"
        size="full"
      >
        <Drawer.Header>
          <Drawer.Title className="text-center font-bold uppercase">
            Formulaire de Proposition d'une ressource
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <RessourceFormularie />
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default Ressource;
