/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";
import { decryptData } from "../../../encryptionModule";

function AdminutesCoursModifiee() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();

  const [mobile, setMobile] = useState(window.innerWidth < 501);
  const [cours, setCours] = useState({
    image: "",
    titre: "",
    minutes: "",
    heures: "",
    jours: "",
    semaines: "",
    mois: "",
    annees: "",
    niveau: "",
    description: "",
    preview: "",
    photocouverture: "",
  });

  // Écoute les changements de taille de la fenêtre
  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const inputRefs = useRef({});

  // Initialiser les refs
  useEffect(() => {
    inputRefs.current = {
      image: React.createRef(),
      titre: React.createRef(),
      minutes: React.createRef(),
      heures: React.createRef(),
      jours: React.createRef(),
      semaines: React.createRef(),
      mois: React.createRef(),
      annees: React.createRef(),
      niveau: React.createRef(),
      description: React.createRef(),
    };
  }, []);

  // Met à jour l'état du cours
  const handleInputChange = (field, value) => {
    const newCours = { ...cours };
    if (field === "image") {
      newCours.preview = URL.createObjectURL(value);
      newCours.image = value;
    } else {
      newCours[field] = value;
    }
    setCours(newCours);
  };

  // Animation et défilement vers l'élément en cas d'erreur
  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        "animate__animated",
        "animate__shakeX",
        "border-2",
        "border-red-500",
        "outline-none"
      );
      setTimeout(() => {
        ref.current.classList.remove(
          "animate__animated",
          "animate__shakeX",
          "border-2",
          "border-red-500",
          "outline-none"
        );
      }, 500);
      ref.current.focus();
    }
  };

  // Validation des champs requis
  const validateField = (field) => {
    if (!cours[field]) {
      toast.warning(
        <div>
          Le <strong>{field}</strong> est obligatoire
        </div>
      );
      if (inputRefs.current[field].current) {
        animateAndScrollToRef(inputRefs.current[field]);
      }
      return false;
    }
    return true;
  };

  // Validation de tous les champs avant l'envoi
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "image",
      "titre",
      "minutes",
      "heures",
      "jours",
      "semaines",
      "mois",
      "annees",
      "description",
      "niveau",
    ];

    // Validation des champs
    for (let index = 0; index < requiredFields.length; index++) {
      if (!validateField(requiredFields[index])) {
        return; // Sortir si un champ est invalide
      }
    }

    // Si tout est valide
    toast.success("Le cours est enregistré avec succès");
    console.log("cours", cours);
  };
  const { id } = useParams();
  const coursId = decryptData(id);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchcoursData = async () => {
      try {
        const response = await fetch(
          `https://imuhirabackend.eac-advisor.org/apiv1/cours/${coursId}`
        );
        const data = await response.json();
        setCours(data);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des données du cours.");
        setLoading(false);
      }
    };

    fetchcoursData();
  }, [coursId]);

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? "" : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/cours")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`flex m-4 rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } flex-col items-center`}
      >
        <div className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2 flex flex-col">
          <div className="w-full">
            <div className="h-max w-full">
              <div className="px-3 w-full">
                <div className="w-full flex items-center justify-between">
                  <label className="block text-xs mt-5 font-[700] first-letter:uppercase tracking-wide text-grey-darker sm:text-lg mb-1">
                    Cours
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <div className="rounded-xl p-2 w-full">
              <div className="flex flex-col">
                {/* Champ pour l'image */}
                <div className="mb-2">
                  <div className="flex flex-col items-center">
                    <label
                      className={`w-full border border-gray-300 block cursor-pointer outline-none focus:border-[#3ad12c] rounded-md p-2.5 bg-transparent`}
                      ref={inputRefs.current.image}
                      htmlFor={`id_photo`}
                    >
                      Photos de couverture
                    </label>
                    <input
                      type="file"
                      accept=".jpeg,.png,.jpg,.gif"
                      className="w-full hidden mt-1"
                      id={`id_photo`}
                      onChange={(e) =>
                        handleInputChange("image", e.target.files[0])
                      }
                    />
                    <div className="flex w-full justify-start">
                      {cours.preview && (
                        <div className="w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300">
                          <img
                            src={cours.preview}
                            alt={`Aperçu`}
                            className="object-cover w-full h-full"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Champ pour le titre */}
                <div className="flex flex-col">
                  <label
                    htmlFor={`titre`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    Titre du cours
                  </label>
                  <input
                    type="text"
                    id={`titre`}
                    value={cours.titre}
                    onChange={(e) => handleInputChange("titre", e.target.value)}
                    placeholder="Le titre du cours"
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.titre}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor={`minutes`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    minutesutes
                  </label>
                  <input
                    type="number"
                    id={`minutes`}
                    value={cours.minutes}
                    onChange={(e) =>
                      handleInputChange("minutes", e.target.value)
                    }
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.minutes}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor={`heures`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    Heures
                  </label>
                  <input
                    type="number"
                    id={`heures`}
                    value={cours.heures}
                    onChange={(e) =>
                      handleInputChange("heures", e.target.value)
                    }
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.heures}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor={`jours`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    Jours
                  </label>
                  <input
                    type="number"
                    id={`jours`}
                    value={cours.jours}
                    onChange={(e) => handleInputChange("jours", e.target.value)}
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.jours}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor={`semaines`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    Semaines
                  </label>
                  <input
                    type="number"
                    id={`semaines`}
                    value={cours.semaines}
                    onChange={(e) =>
                      handleInputChange("semaines", e.target.value)
                    }
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.semaines}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor={`mois`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    Mois
                  </label>
                  <input
                    type="number"
                    id={`mois`}
                    value={cours.mois}
                    onChange={(e) => handleInputChange("mois", e.target.value)}
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.mois}
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor={`annees`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    Année
                  </label>
                  <input
                    type="number"
                    id={`annees`}
                    value={cours.annees}
                    onChange={(e) =>
                      handleInputChange("annees", e.target.value)
                    }
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.annees}
                  />
                </div>

                {/* Champ pour le niveau */}
                <div className="flex flex-col">
                  <label
                    htmlFor={`niveau`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    niveau
                  </label>
                  <input
                    type="text"
                    id={`niveau`}
                    value={cours.niveau}
                    onChange={(e) =>
                      handleInputChange("niveau", e.target.value)
                    }
                    placeholder="niveau du cours"
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.niveau}
                  />
                </div>

                {/* Champ pour la descriptionion */}
                <div className="flex flex-col">
                  <label
                    htmlFor={`description`}
                    className="block mt-5 first-letter:uppercase tracking-wide text-lg mb-1"
                  >
                    descriptionion
                  </label>
                  <textarea
                    id={`description`}
                    value={cours.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    placeholder="descriptionion du cours"
                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                    ref={inputRefs.current.description}
                  />
                </div>

                {/* Bouton de soumission */}
                <button
                  type="submit"
                  className="mt-4 w-full bg-[#3ad12c] text-white font-bold py-2 px-4 rounded focus:outline-none"
                >
                  Enregistrer le cours
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default AdminutesCoursModifiee;
