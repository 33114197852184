/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { Accordion, Drawer } from 'rsuite'
import FormulaireConct from '../FormulaireConct/FormulaireConct'
import InscireFormation from '../FormulaireConct/InscireFormation'

function Formateurs() {
    const { formateursRef } = useThemes()
    const [isAtelierComminity, SetisAtelierComminity] = useState(false)
    const [isInsiscrire, SetIsSincrire] = useState(false)
    return (
        <div data-aos-duration="1000" ref={formateursRef} class=" relative rounded-lg p-6 my-6">
            <div className='flex gap-2  items-center'>
                <div className='w-1/2'>
                    <h2 data-aos="fade-down" class="text-2xl font-bold mb-4">Devenez un Éducateur Environnemental Certifié</h2>
                    <p data-aos="fade-down" class="mb-4 text-[17px]">
                        Le programme de Formation des Formateurs d'Umuhira vise à créer un réseau d'éducateurs environnementaux qualifiés à travers le Burundi. Cette formation intensive vous donnera les compétences et les connaissances nécessaires pour sensibiliser et éduquer efficacement sur les enjeux environnementaux spécifiques à notre pays.
                    </p>
                    <div data-aos="fade-down" className='bg-[#3ad12c1e] p-2 border-l-2 border-[#3ad12c] rounded-xl'>
                        <h3 class="text-lg font-semibold mb-2">Objectifs de la Formation</h3>
                        <ul class="list-disc pl-1 mb-4">
                            <li className=' ml-[2em] text-lg'>Maîtriser les concepts clés de l'écologie et du développement durable</li>
                            <li className=' ml-[2em] text-lg'>Développer des compétences pédagogiques adaptées à l'éducation environnementale</li>
                            <li className=' ml-[2em] text-lg'>Concevoir et mettre en œuvre des programmes éducatifs innovants</li>
                            <li className=' ml-[2em] text-lg'>Acquérir des techniques de communication efficaces pour sensibiliser divers publics</li>
                            <li className=' ml-[2em] text-lg'>Comprendre les enjeux environnementaux spécifiques au Burundi et les solutions locales</li>
                        </ul>
                    </div>
                </div>
                <div data-aos="fade-up" className='w-1/2 animation  h-full'>
                    <img className='rounded' src='https://naturelcd.net/wp-content/uploads/2023/04/IMG-20230425-WA0020-1024x683.jpg' alt='  ' />
                </div>
            </div>

            <h3 class="text-lg mt-10 font-semibold mb-2">Structure du Programme</h3>
            <p class="mb-4 text-[17px] italic">
                La formation se déroule sur 12 semaines, combinant des sessions en présentiel et des modules en ligne :
            </p>
            <div className='bg-[#3ad12c1e] p-2 border-l-2 border-[#3ad12c] rounded-xl'>
                <div data-aos="fade-up" data-aos-delay="100" class=" rounded-md p-2 ">
                    <div class="text-md font-semibold ">Module 1 : Fondamentaux de l'écologie et du développement durable (2 semaines)</div>
                </div>
                <div data-aos="fade-up" data-aos-delay="150" class=" rounded-md p-2">
                    <div class="text-md font-semibold ">Module 2 : Enjeux environnementaux spécifiques au Burundi (2 semaines)</div>
                </div>
                <div data-aos="fade-up" data-aos-delay="200" class=" rounded-md p-2">
                    <div class="text-md font-semibold ">Module 3 : Pédagogie et techniques d'animation en éducation environnementale (2 semaines)</div>
                </div>
                <div data-aos="fade-up" data-aos-delay="250" class=" rounded-md p-2">
                    <div class="text-md font-semibold ">Module 4 : Conception de programmes et de ressources éducatives (2 semaines)</div>
                </div>

                <div data-aos="fade-up" data-aos-delay="300" class=" rounded-md p-2">
                    <div class="text-md font-semibold">Module 5 : Communication et sensibilisation environnementale (2 semaines)</div>
                </div>

                <div data-aos="fade-up" data-aos-delay="350" class=" rounded-md p-2">
                    <div class="text-md font-semibold">Module 6 : Projet pratique et évaluation finale (1 semaine)</div>
                </div>
            </div>


            <div class=" rounded-lg  mt-10  my-6">
                <div className='flex gap-5'>
                    <div data-aos="fade-right" className='w-1/2'>
                        <h2 class="text-2xl font-bold mb-4">Méthodes d'Apprentissage</h2>
                        <ul class="list-disc pl-6  mb-4">
                            <li className=' ml-[3em] text-lg'>Cours théoriques et études pratiques</li>
                            <li className=' ml-[3em] text-lg'>Études de cas et simulation de situations</li>
                            <li className=' ml-[3em] text-lg'>Sorties sur le terrain et observations directes</li>
                            <li className=' ml-[3em] text-lg'>Travaux de groupe et présentations</li>
                            <li className=' ml-[3em] text-lg'>Mentorat personnalisé</li>
                            <li className=' ml-[3em] text-lg'>Plateforme d'apprentissage en ligne interactive</li>
                            <div className='ml-[3em] animation mt-[2em] rounded-xl overflow-hidden'>
                                <img className='h-[45vh] w-auto' src='https://www.fondation-merieux.org/wp-content/uploads/2023/09/ateliers-tech_benin-resaolab-septembre.jpg' alt='  ' />
                            </div>
                        </ul>

                        <div className='w-10 m-1 animate-ping absolute z-0 left-2  rounded-full h-10 bg-orange-500'></div>
                          
                    </div>
                    <div data-aos="fade-left" className='w-1/2'>
                        <h2 class="text-2xl font-bold mb-4">Certification</h2>
                        <p class=" mb-4">
                            À l'issue de la formation, les participants ayant satisfait à toutes les exigences recevront la certification officielle d'Éducateur Environnemental Umuhira, reconnu par le Ministère de l'Environnement du Burundi.
                        </p>
                        <blockquote class="border-l-[0.5em] border-[#3ad12c] pl-4  italic mb-4">
                            "Cette formation a considérablement transformé ma façon d'aborder l'éducation environnementale. Je me sens désormais outillé pour mener des actions de sensibilisation efficaces."
                            <cite class="block font-normal">- Jean-Pierre Hakizimana, Éducateur Environnemental Certifié, Promotion 2023</cite>
                        </blockquote>
                        <div className='mt-[2em] animate-bounce-up-down rounded-xl overflow-hidden'>
                            <img src='https://www.whizlabs.com/blog/wp-content/uploads/2019/12/importance_of_certifications.png' alt='  ' />
                        </div>
                    </div>
                </div>

                <h2 data-aos="fade-up" class="text-2xl font-bold mb-4 mt-10">Prochaines Sessions</h2>
                <div data-aos="fade-up" className='bg-[#3ad12c1e] p-2 border-l-2 border-[#3ad12c] rounded-xl'>
                    <div class=" rounded-md p-1">
                        <div class="text-md font-semibold">Session 1 : Du 15 mars au 7 juin 2024</div>
                    </div>
                    <div class=" rounded-md p-1">
                        <div class="text-md font-semibold">Session 2 : Du 1er septembre au 24 novembre 2024</div>
                    </div>
                </div>
                <h2 data-aos="fade-up" class="text-2xl font-bold mb-2 mt-10">Tarifs et Financement</h2>
                <p data-aos="fade-up" class=" mb-2">
                    Le coût de la formation est de 500 000 BIF. Des options de paiement échelonné sont disponibles. Umuhira offre également un nombre limité de bourses pour les candidats méritants.
                </p>
                <div onClick={() => SetIsSincrire(true)} data-aos="fade-up" class="bg-[#50914b]  cursor-pointer hover:bg-[#3ad12c]  text-white font-semibold py-2 px-4 rounded inline-block">S'inscrire à la Formation</div>
            </div>



            <div data-aos="fade-up" class="rounded-lg  mt-10 my-6">
                <h2 class="text-2xl font-bold mb-4">Foire aux Questions (FAQ)</h2>
                <Accordion bordered>
                    <Accordion.Panel header="Quels sont les prérequis pour suivre cette formation ?" eventKey={1} >
                        Un intérêt marqué pour l'environnement et une volonté d'apprendre et de s'engager sont les principaux prérequis. Aucune expérience préalable en éducation n'est nécessaire.
                    </Accordion.Panel>
                    <Accordion.Panel header="La formation est-elle à temps plein ?" eventKey={2} >
                        La formation est conçue pour être suivie à temps partiel, permettant aux participants de continuer leurs activités professionnelles.
                    </Accordion.Panel>
                    <Accordion.Panel header="Y a-t-il des opportunités d'emploi après la certification ?" eventKey={3} >
                        Oui, les diplômés de la formation sont recherchés par diverses organisations environnementales et éducatives pour faciliter la mise en place de programmes et le placement des formateurs certifiés.
                    </Accordion.Panel>
                </Accordion>

                <h2 data-aos="fade-up" class="text-2xl font-bold mt-6 mb-2">Contactez-nous</h2>
                <p data-aos="fade-up" class=" mb-2">Pour plus d'informations sur le programme de Formation des Formateurs :</p>
                <div data-aos="fade-up" class="my-1">
                    <div class="flex items-center gap-2 mt-2">
                        <div className='text-[#3ad12c]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
                                <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                                <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
                            </svg>
                        </div>
                        <div>Email : formation@umuhira.bi</div>
                    </div>
                    <div class="flex items-center gap-2 mt-2">
                        <div className='text-[#3ad12c]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708" />
                            </svg>
                        </div>
                        <div>Téléphone : +257 22 30 20 24</div>
                    </div>
                </div>
                <div onClick={() => SetisAtelierComminity(true)} data-aos="fade-up" class="bg-[#50914b] mt-4  cursor-pointer hover:bg-[#3ad12c]  text-white font-semibold py-2 px-4 rounded inline-block">Demander plus d'informations</div>
            </div>
            <Drawer open={isInsiscrire} onClose={() => SetIsSincrire(false)} placement='top' size="full">
                <Drawer.Header>
                    <Drawer.Title className='text-center font-bold uppercase'>Inscription à la formation en ligne</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <InscireFormation />
                </Drawer.Body>
            </Drawer>

            <Drawer size="full" open={isAtelierComminity} placement='bottom' onClose={() => SetisAtelierComminity(false)}>
                <Drawer.Header>
                    <Drawer.Title className='text-center uppercase'>Formulaire de contact</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <FormulaireConct />
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default Formateurs