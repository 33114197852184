import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import { Drawer } from 'rsuite';
import PartenariatForm from '../Acceuil/PartenariatForm';
import RejoignezFormulaire from '../Acceuil/RejoignezFormulaire';
import InnovationForm from '../Acceuil/InnovationForm';

function ParticiperAppropos() {
    const { isDark } = useThemes()
    const navig = useNavigate()
    const [isPartenariat, SetisPartenariat] = useState(false)
    const [isRejoinder, SetisRejoinder] = useState()

    const [isAtelierComminity, SetisAtelierComminity] = useState(false)
    return (
        <div className={` mt-[18vh] ${isDark === "dark" ? null : 'bg-gray-100'} overflow-hidden py-8`}>
            <div onClick={() => navig("/propos")} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div className="container mx-auto px-4">
                <h1 className="text-3xl font-bold mb-4">Comment s'impliquer dans le Mouvement Imuhira ?</h1>
                <p className="mb-8 text-lg">Le Mouvement Imuhira offre de nombreuses opportunités pour contribuer à un Burundi plus durable. Que vous soyez un individu passionné, une entreprise responsable ou une organisation engagée, il y a une place pour vous dans notre communauté.</p>
                <div className="grid md:grid-cols-2 gap-8">
                    <div data-aos="fade-up" className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
                        <div className="flex items-center mb-4">
                            <div className="w-12 h-12 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4">
                                <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                            </div>
                            <h2 className="text-2xl font-bold">Bénévolat</h2>
                        </div>
                        <p className="mb-4">Participez à nos projets de reforestation, de sensibilisation ou d'éducation environnementale.</p>
                        <div onClick={() => SetisRejoinder(true)} className="bg-[#3ad12c] hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg inline-block">Devenir Bénévole</div>
                    </div>
                    <div data-aos="fade-up" className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
                        <div className="flex items-center mb-4">
                            <div className="w-12 h-12 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4">
                                <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 9H11V3.055z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                                </svg>
                            </div>
                            <h2 className="text-2xl font-bold">Innovation</h2>
                        </div>
                        <p className="mb-4">Proposez des solutions innovantes pour résoudre les défis environnementaux du Burundi.</p>
                        <div onClick={() => SetisAtelierComminity(true)} className="bg-[#3ad12c] cursor-pointer hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg inline-block">Soumettre une Idée</div>
                    </div>
                    <div data-aos="fade-up" className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
                        <div className="flex items-center mb-4">
                            <div className="w-12 h-12 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4">
                                <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                                </svg>
                            </div>
                            <h2 className="text-2xl font-bold">Partenariats</h2>
                        </div>
                        <p className="mb-4">Collaborez avec nous en tant qu'entreprise ou organisation pour amplifier notre impact.</p>
                        <div onClick={() => SetisPartenariat(true)} className="bg-[#3ad12c] cursor-pointer hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg inline-block">Devenir Partenaire</div>
                    </div>
                    <div data-aos="fade-up" className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
                        <div className="flex items-center mb-4">
                            <div className="w-12 h-12 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4">
                                <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </div>
                            <h2 className="text-2xl font-bold">Éducation</h2>
                        </div>
                        <p className="mb-4">Devenez un ambassadeur de l'environnement en partageant vos connaissances.</p>
                        <div onClick={() => navig("/cours")} className="bg-[#3ad12c] cursor-pointer hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg inline-block">Éduquer</div>
                    </div>
                </div>
                <div className="mt-8">
                    <h2 className="text-2xl font-bold mb-4">Rejoignez-nous aujourd'hui</h2>
                    <p className="mb-4 text-lg">Remplissez le formulaire ci-dessous pour nous faire part de votre intérêt à rejoindre le Mouvement Imuhira. Nous vous contacterons avec plus d'informations sur la façon dont vous pouvez contribuer à notre mission.</p>
                </div>
                <div className="container mx-auto px-4 mt-6">
                    <h2 className="text-3xl font-bold mb-8">Autres façons de soutenir le Mouvement Imuhira</h2>
                    <ul className="space-y-4">
                        <li className="flex items-center text-lg">
                            <div className="w-6 h-6 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4 mt-1">
                                <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p>Suivez-nous sur les réseaux sociaux et partagez notre contenu</p>
                        </li>
                        <li className="flex items-center text-lg">
                            <div className="w-6 h-6 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4 mt-1">
                                <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p>Faites un don pour soutenir nos projets</p>
                        </li>
                        <li className="flex items-center text-lg">
                            <div className="w-6 h-6 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4 mt-1">
                                <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p>Adoptez des pratiques durables dans votre vie quotidienne</p>
                        </li>
                        <li className="flex items-center text-lg">
                            <div className="w-6 h-6 bg-[#3ad12c] rounded-full flex items-center justify-center mr-4 mt-1">
                                <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p>Parlez du Mouvement Imuhira à votre entourage</p>
                        </li>
                    </ul>
                    <div className="mt-8">
                        <p>Ensemble, nous pouvons créer un avenir durable pour le Burundi. Chaque action compte, et votre implication peut faire une réelle différence.</p>
                        <div onClick={() => navig("/projet")} className="bg-[#3ad12c] cursor-pointer hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg inline-block mt-4">Découvrir nos projets en cours</div>
                    </div>
                </div>
            </div>

            <Drawer size="full" open={isAtelierComminity} placement='bottom' onClose={() => SetisAtelierComminity(false)}>
                <Drawer.Header>
                    <Drawer.Title className='text-center uppercase'>Soumettre mon Innovation</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <InnovationForm />
                </Drawer.Body>
            </Drawer>

            <Drawer open={isRejoinder} onClose={() => SetisRejoinder(false)} placement='top' size="full">
                <Drawer.Header>
                    <Drawer.Title className='text-center font-bold uppercase'>Formulaire d'adhésion</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <RejoignezFormulaire />
                </Drawer.Body>
            </Drawer>


            <Drawer size="full" placement='top' open={isPartenariat} onClose={() => SetisPartenariat(false)}>
                <Drawer.Header>
                    <Drawer.Title className='text-center uppercase'>Formulaire de demande de partenariat</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <PartenariatForm />
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default ParticiperAppropos