import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminCommunauteDetail from './AdminCommunauteDetail'

function AdminCommunauteDetailComponent() {
  return (
    <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminCommunauteDetail />
        </div>
  )
}

export default AdminCommunauteDetailComponent