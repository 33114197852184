import React, { useState, useEffect } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "./AdminRessourceNavBars";
import { decryptData } from "../../../encryptionModule";

function AdminRessourceDetail() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams(); // Pour récupérer l'ID de la ressource à modifier
  const resourceId = decryptData(id);
  const [resource, setResource] = useState({
    titre: "",
    descript: "",
    label: "",
    link: "",
    orderImage: "",
    orderfile: "",
  });

  useEffect(() => {
    // Récupérer la ressource à modifier lors du chargement du composant
    const fetchResource = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/resources/${resourceId}`
        );
        const {
          titre,
          description,
          label,
          linkyoutube,
          photocouverture,
          fichier,
        } = response.data;
        setResource({
          titre,
          descript: description,
          label,
          link: linkyoutube,
          orderImage: photocouverture,
          orderfile: fichier,
        });
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la ressource :",
          error
        );
      }
    };

    fetchResource();
  }, [resourceId]);

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-gray-100"
      } overflow-x-hidden h-[87vh]`}
    >
      <AdminRessourceNavBars />
      <div className="w-full flex justify-start">
        <div
          onClick={() => navig("/ressource")}
          className="text-[#3ad12c] p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
          </svg>
        </div>
      </div>
      <div
        className={`rounded-md ${
          isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } m-2 p-4`}
      >
        {resource.orderImage && (
          <img
            src={`https://imuhirabackend.eac-advisor.org/uploads/resources/${resource.orderImage}`}
            alt="Preview"
            className="rounded-md mb-2"
          />
        )}
        <h2 className="text-2xl font-semibold mb-2">{resource.titre}</h2>
        <p className="mb-2">{resource.descript}</p>
        {resource.link && (
          <a
            href={resource.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Voir le lien YouTube
          </a>
        )}
        <div className="mt-2"></div>
        <div className="relative w-full md:w-[15em] mt-5 overflow-hidden rounded-lg h-[15em] border border-gray-300 shadow-md">
          <a
            href={`https://imuhirabackend.eac-advisor.org/uploads/resources/${resource.orderfile}`}
            target="_blank" // Ouvre le lien dans un nouvel onglet
            rel="noopener noreferrer" // Sécurise l'ouverture dans un nouvel onglet
            download // Cette ligne permet de télécharger le fichier au lieu de l'ouvrir dans le navigateur
            className="flex items-center justify-center w-full h-full rounded-lg transition-all duration-300 hover:scale-105"
          >
            <span className="text-center text-sm text-gray-700">
              {resource.label}
            </span>
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AdminRessourceDetail;
