import React from 'react'
import AdminSlide from '../AdminSlide/AdminSlide'
import AdminCommunauteBlog from './AdminCommunauteBlog'

function AdminCommunauteBlogComponent() {
    return (
        <div className='h-full w-full flex overflow-hidden'>
            <AdminSlide />
            <AdminCommunauteBlog />
        </div>
    )
}

export default AdminCommunauteBlogComponent