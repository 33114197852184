import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate } from "react-router-dom";
import Footer from "../../Visiteurs/FootentContent/Footer";
import AdminRessourceNavBars from "../AdminRessource/AdminRessourceNavBars";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import { encryptData } from "../../../encryptionModule";
function AdminFaits() {
  const { isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const [faitenvs, setfaitenvs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchFaitenvs(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchFaitenvs = async (page, size, search = "") => {
    try {
      const response = await axios.get(
        `https://imuhirabackend.eac-advisor.org/apiv1/faitenvs?page=${page}&size=${size}&search=${
          search || ""
        }`
      );
      setfaitenvs(response.data.faitenvs);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des faits :",
        error.message
      );
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset when searching
    fetchFaitenvs(1, pageSize, event.target.value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Cette action est irréversible !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://imuhirabackend.eac-advisor.org/apiv1/faitenvs/${id}`
          );
          setfaitenvs(faitenvs.filter((faitenv) => faitenv.id !== id));
          toast.info("La ressource a été supprimée avec succès.");
        } catch (error) {
          Swal.fire("Erreur", "La suppression a échoué.", "error");
        }
      }
    });
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalItems);
    return `${from} à ${to} faits sur ${totalItems}`;
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      } h-[87vh]`}
    >
      <AdminRessourceNavBars />

      <div className="flex flex-wrap w-full p-5 justify-between items-center mb-4">
        <div className="flex items-center mb-4">
          <label className="text-lg">Taille de la page :</label>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="ml-2 p-2 bg-white border border-gray-300 rounded-lg"
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="flex w-full lg:w-[60%] items-center mb-4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Rechercher..."
            className="flex-grow p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 transition"
          />
          <button
            onClick={() => navig("/ressource/fait/ajouter")}
            className="ml-4 px-4 py-2 border rounded-lg text-lg text-[#3ad12c] border-[#3ad12c] hover:bg-[#3ad12c] hover:text-white transition"
          >
            Ajouter
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-3">
        {faitenvs.length > 0 ? (
          faitenvs.map((faitenv, i) => (
            <div
              key={i}
              onClick={() =>
                navig(
                  `/ressource/fait/detail/${encryptData(faitenv.id.toString())}`
                )
              }
              className={`cursor-pointer p-5 border border-[#3ad12c] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ${
                isDarVisitLoggined === "dark"
                  ? "bg-gray-800 text-white"
                  : "bg-white text-gray-900"
              }`}
            >
              <h3 className="text-xl font-bold text-[#3ad12c] mb-2">
                {faitenv.titre}
              </h3>
              <hr className="my-2 border-gray-300" />
              <p className="text-sm">
                <span className="font-semibold">Source : </span>
                {faitenv.source}
              </p>
              <p className="text-sm mt-2">
                <span className="font-semibold">Date : </span>
                {new Date(faitenv.createdAt).toLocaleDateString("fr-FR", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </p>
            </div>
          ))
        ) : (
          <p className="text-center">Aucun fait trouvé.</p>
        )}
      </div>

      {faitenvs.length > 0 && (
        <div className="mt-6 flex justify-center space-x-2">
          <button
            disabled={currentPage === 1}
            onClick={handlePreviousPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Précédent
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 rounded-lg ${
                currentPage === index + 1
                  ? "bg-blue-600 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
            className="px-4 py-2 bg-gray-300 rounded-lg disabled:bg-gray-200"
          >
            Suivant
          </button>
        </div>
      )}

      <div className="mt-4 text-center">
        <p className="text-lg">{getRange()}</p>
      </div>

      <Footer />
    </div>
  );
}

export default AdminFaits;
