import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'


function AnimalDetail() {
    const { isDark ,HundlwScrollTop} = useThemes()
    const navig = useNavigate()
 

    return (
        <div className=' mt-[18vh]  overflow-hidden'>
            <div onClick={() => {navig("/ressource/espece/");HundlwScrollTop()}} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div className="container mx-auto p-4">
                <div data-aos="fade-up" className="grid grid-cols-1 gap-6">

                    <div


                        className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} rounded-lg p-6`}
                    >
                        <div className='h-[60vh]'>
                            <img className='w-auto h-full' src='https://cdn.britannica.com/30/150930-120-D3D93F1E/lion-Namibia.jpg' alt='  ' />
                        </div>

                        <h2 className="text-xl font-semibold mb-2">Lion</h2>
                        <p className="">
                           Roi de la savane
                        </p>
                       
                        <p className="">
                        Panthera leo
                        </p>
                       
                        <p className="">
                        Parc National de la Pendjari
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnimalDetail