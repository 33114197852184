/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import "apexcharts/dist/apexcharts.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "rsuite/dist/rsuite.min.css";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet/dist/leaflet.css";

import { ToastContainer } from "react-toastify";
import Aos from "aos";
import { useThemes } from "./UserContext/UserContext";
import { CustomProvider } from "rsuite";
import NavBars from "./Compenent/Visiteurs/NavBars/NavBars";
import Cours from "./Compenent/Visiteurs/Cours/Cours";
import Projet from "./Compenent/Visiteurs/Projet/Projet";
import Communaute from "./Compenent/Visiteurs/Communaute/Communaute";
import Apropos from "./Compenent/Visiteurs/Apropos/Apropos";
import AcceuillComponent from "./Compenent/Visiteurs/Acceuil/AcceuillComponent";
import { useEffect, useState } from "react";
import FootentContent from "./Compenent/Visiteurs/FootentContent/FootentContent";
import CoursDetail from "./Compenent/Visiteurs/Cours/CoursDetail";
import ModuleCours from "./Compenent/Visiteurs/Cours/ModuleCours";
import Soutenir from "./Compenent/Visiteurs/Projet/Soutenir";
import BenevoleProjets from "./Compenent/Visiteurs/Projet/BenevoleProjets";
import Forumu from "./Compenent/Visiteurs/Communaute/Forumu";
import Evenement_A_venir from "./Compenent/Visiteurs/Communaute/Evenement_A_venir";
import ParticiperAppropos from "./Compenent/Visiteurs/Apropos/ParticiperAppropos";
import EvenementDetail from "./Compenent/Visiteurs/Acceuil/EvenementDetail";
import Ressource from "./Compenent/Visiteurs/Ressource/Ressource";
import Animal from "./Compenent/Visiteurs/Ressource/Animal";
import Rapport from "./Compenent/Visiteurs/Ressource/Rapport";
import Climentique from "./Compenent/Visiteurs/Ressource/Climentique";
import Lois from "./Compenent/Visiteurs/Ressource/Lois";
import Convention from "./Compenent/Visiteurs/Ressource/Convention";
import ConventionRegion from "./Compenent/Visiteurs/Ressource/ConventionRegion";
import ODD from "./Compenent/Visiteurs/Ressource/ODD";
import Signale from "./Compenent/Visiteurs/Signal/Signale";
import DetailEvent_a_Venir from "./Compenent/Visiteurs/Communaute/DetailEvent_a_Venir";
import SignalerDetail from "./Compenent/Visiteurs/Signal/SignalerDetail";
import AnimalDetail from "./Compenent/Visiteurs/Ressource/AnimalDetail";
import SujetDetail from "./Compenent/Visiteurs/Communaute/SujetDetail";

// ....................admin.......................
import AdminNavrBars from "./Compenent/Admin/AdminNavrBars/AdminNavrBars";
import AdminAcceuillComponent from "./Compenent/Admin/AdminAcceuil/AdminAcceuilComponent";
import AdminCoursComponent from "./Compenent/Admin/AdminCours/AdminCoursComponent";
import AjouterCoursAdminComponent from "./Compenent/Admin/AdminCours/AjouterCoursAdminComponent";
import AdminCoursDetailComponent from "./Compenent/Admin/AdminCours/AdminCoursDetailComponent";
import AdminCoursModuleComponent from "./Compenent/Admin/AdminCours/AdminCoursModuleComponent";
import AdminCoursModifieeComponent from "./Compenent/Admin/AdminCours/AdminCoursModifieeComponent";
import AdminSignalComponent from "./Compenent/Admin/AdminSignal/AdminSignalComponent";
import AdminCoursProposeComponent from "./Compenent/Admin/AdminCours/AdminCoursProposeComponent";
import AdminCoursProposeDeatailComponent from "./Compenent/Admin/AdminCours/AdminCoursProposeDeatailComponent";
import AdminCoursProposeModulComponent from "./Compenent/Admin/AdminCours/AdminCoursProposeModulComponent";
import AdminSignalDetailComponent from "./Compenent/Admin/AdminSignal/AdminSignalDetailComponent";
import AdminSignalProposeComponent from "./Compenent/Admin/AdminSignal/AdminSignalProposeComponent";
import AdminSignalProposeDetailComponent from "./Compenent/Admin/AdminSignal/AdminSignalProposeDetailComponent";
import AdminRessourceComponent from "./Compenent/Admin/AdminRessource/AdminRessourceComponent";
import AdminRessourceAjouterComponent from "./Compenent/Admin/AdminRessource/AdminRessourceAjouterComponent";
import AdminRessourceDetailComponent from "./Compenent/Admin/AdminRessource/AdminRessourceDetailComponent";
import AdminRessourceModifierComponent from "./Compenent/Admin/AdminRessource/AdminRessourceModifierComponent";
import AdiminRapportComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportComponent";
import AdiminRapportAjouterComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportAjouterComponent";
import AdiminRapportDetailComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportDetailComponent";
import AdiminRapportModifierComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportModifierComponent";
import AdminFaitsComponent from "./Compenent/Admin/AdminFaits/AdminFaitsComponent";
import AdminFaitsDetailComponent from "./Compenent/Admin/AdminFaits/AdminFaitsDetailComponent";
import AdminFaitsAjouterCompoment from "./Compenent/Admin/AdminFaits/AdminFaitsAjouterCompoment";
import AdminEspecesComponent from "./Compenent/Admin/AdminEspeces/AdminEspecesComponent";
import AdminEspecesAjouterComponent from "./Compenent/Admin/AdminEspeces/AdminEspecesAjouterComponent";
import AdminEspecesModifierComponent from "./Compenent/Admin/AdminEspeces/AdminEspecesModifierComponent";
import AdminEspecesDetailComponent from "./Compenent/Admin/AdminEspeces/AdminEspecesDetailComponent";
import AdminLoisComponent from "./Compenent/Admin/AdminLois/AdminLoisComponent";
import AdminLoisAjouterComponent from "./Compenent/Admin/AdminLois/AdminLoisAjouterComponent";
import AdminLoisDetailComponent from "./Compenent/Admin/AdminLois/AdminLoisDetailComponent";
import AdminLoisModiComponent from "./Compenent/Admin/AdminLois/AdminLoisModiComponent";
import AdminConventionComponent from "./Compenent/Admin/AdminConvention/AdminConventionComponent";
import AdminConventionAjouterComponent from "./Compenent/Admin/AdminConvention/AdminConventionAjouterComponent";
import AdminConventionModifiierComponent from "./Compenent/Admin/AdminConvention/AdminConventionModifiierComponent";
import AdminConventioDetailComponent from "./Compenent/Admin/AdminConvention/AdminConventioDetailComponent";
import AdminODDComponent from "./Compenent/Admin/AdminODD/AdminODDComponent";
import AdminODDAjouterComponent from "./Compenent/Admin/AdminODD/AdminODDAjouterComponent";
import AdminODDModifierComponent from "./Compenent/Admin/AdminODD/AdminODDModifierComponent";
import AdminODDDetailComponent from "./Compenent/Admin/AdminODD/AdminODDDetailComponent";
import AdminProjetComponent from "./Compenent/Admin/AdminProjet/AdminProjetComponent";
import AdminProjetAjouterComponent from "./Compenent/Admin/AdminProjet/AdminProjetAjouterComponent";
import AdminProjetModofierComponent from "./Compenent/Admin/AdminProjet/AdminProjetModofierComponent";
import AdminProjetDetailComponent from "./Compenent/Admin/AdminProjet/AdminProjetDetailComponent";
import AdminProjetTemoiComponent from "./Compenent/Admin/AdminProjet/AdminProjetTemoiComponent";
import AdminProjetTemoidetailComponent from "./Compenent/Admin/AdminProjet/AdminProjetTemoidetailComponent";
import AdminProjetDonProjetCompoenent from "./Compenent/Admin/AdminProjet/AdminProjetDonProjetCompoenent";
import AdminCommunauteComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteComponent";
import AdminCommunauteAjouterComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteAjouterComponent";
import AdminCommunauteModifierComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteModifierComponent";
import AdminCommunauteDetailComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteDetailComponent";
import AdminCommunauteBlogComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteBlogComponent";
import AdminPartenaireComponent from "./Compenent/Admin/AdminPartenaire/AdminPartenaireComponent";
import AdminCommunauteBlogDetailComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteBlogDetailComponent";
import AdminCommunauteBlogAjouterComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteBlogAjouterComponent";
import AdminCommunauteBlogModofierComponent from "./Compenent/Admin/AdminCommunaute/AdminCommunauteBlogModofierComponent";
import AdminProjetDonMaterielComponent from "./Compenent/Admin/AdminProjet/AdminProjetDonMaterielComponent";
import AdminProjetDonMaterielDetailCompnent from "./Compenent/Admin/AdminProjet/AdminProjetDonMaterielDetailCompnent";
import AdminProjetSponsorComponent from "./Compenent/Admin/AdminProjet/AdminProjetSponsorComponent";
import AdminProjetSponsorDetailComponent from "./Compenent/Admin/AdminProjet/AdminProjetSponsorDetailComponent";
import AdminProjetBenevolComponent from "./Compenent/Admin/AdminProjet/AdminProjetBenevolComponent";
import AdminProjetBenevolDetailComponent from "./Compenent/Admin/AdminProjet/AdminProjetBenevolDetailComponent";
import AdminProjetProposeComponent from "./Compenent/Admin/AdminProjet/AdminProjetProposeComponent";
import AdminProjetProposeDetailComponent from "./Compenent/Admin/AdminProjet/AdminProjetProposeDetailComponent";
import Login from "./Compenent/Visiteurs/Formaire/Login";
import { ThemeProvider } from "./Compenent/Visiteurs/UseContext/ThemeContext";
import { getUserId, getIsAdmin, getIsPartenaire, getCsrfToken } from "./auth"; // Importer les fonctions individuellement
import axios from "axios";
import SignalementMap from "./SignalementMap";

function App() {
  const {
    isDark,
    isDarkadimin,
    isGoSite,
    isAdmin,
    SetisAdmin,
    isMargin,
    HundlwScrollTop,
    getWidth,
    elemetScroll,
    mobile,
    open,
    isScroll,
    Setopen,
    GetIsOpen,
    SetOpenModal,
    isVisitor,
    isDarVisitLoggined,
    isGoingToSite,
  } = useThemes();

  useEffect(() => {
    const initAos = () => {
      if (typeof Aos !== "undefined") {
        Aos.init({
          once: true,
          duration: 1000,
          easing: "ease-out-cubic",
        });
      }
    };

    const timer = setTimeout(initAos, 500);
    const hundleLoadWindows = () => {
      //    on met la page spinner
    };
    const hundleClick = () => {
      SetOpenModal(false);
    };
    window.addEventListener("load", () => {
      hundleLoadWindows();
    });
    window.addEventListener("click", () => {
      hundleClick();
    });

    return () => {
      clearTimeout(timer);
      window.removeEventListener("load", () => {
        hundleLoadWindows();
      });
      window.removeEventListener("click", () => {
        hundleClick();
      });
    };
  }, []);
  const [userId, setUserId] = useState(null);
  const [isAdmindb, setIsAdmin] = useState(false);
  const [isPartenaire, setIsPartenaire] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null);

  // Utiliser useEffect pour charger les valeurs depuis localStorage
  useEffect(() => {
    setUserId(getUserId());
    setIsAdmin(getIsAdmin());
    setIsPartenaire(getIsPartenaire());
    setCsrfToken(getCsrfToken());
  }, []);

  localStorage.setItem("userId", "1");
  localStorage.setItem("isAdmin", "true");
  localStorage.setItem("isPartenaire", "false");
  localStorage.setItem("csrfToken", "ZBFlpxkF-CizJgNJoko90umSBMkNYSlQ5x7o");

  return isVisitor ? (
    <ThemeProvider>
      <CustomProvider theme={isDark}>
        <div
          className="h-[3px] fixed  bg-[#3ad12c] top-0   left-0 z-[2000]"
          style={{
            width: `${getWidth * 100}%`,
          }}
        ></div>
        <NavBars />
        <Login SetisAdmin={SetisAdmin} />
        <div className="overflow-x-hidden">
          <Routes>
            <Route path="/" Component={AcceuillComponent} />
            <Route path="/signal" Component={Signale} />
            <Route path="/signal/detail/:id" Component={SignalerDetail} />

            <Route path="/detail" Component={EvenementDetail} />

            <Route path="/cours" Component={Cours} />
            <Route path="/cours/detail" Component={CoursDetail} />
            <Route path="/cours/module" Component={ModuleCours} />

            <Route path="/ressource" Component={Ressource} />
            <Route path="/ressource/espece" Component={Animal} />
            <Route path="/ressource/espece/detail" Component={AnimalDetail} />
            <Route path="/ressource/rapport" Component={Rapport} />
            <Route path="/ressource/Environnementaux" Component={Climentique} />
            <Route path="/ressource/lois" Component={Lois} />
            <Route path="/ressource/Convention" Component={Convention} />
            <Route path="/ressource/region" Component={ConventionRegion} />
            <Route path="/ressource/ODD" Component={ODD} />

            <Route path="/projet" Component={Projet} />
            <Route path="/projet/Soutenir/:id" Component={Soutenir} />
            <Route path="/projet/benevol/:id" Component={BenevoleProjets} />

            <Route path="/communaute" Component={Communaute} />
            <Route path="/communaute/forumu" Component={Forumu} />
            <Route path="/communaute/forumu/detail" Component={SujetDetail} />
            <Route path="/communaute/event" Component={Evenement_A_venir} />
            <Route
              path="/communaute/event/detail"
              Component={DetailEvent_a_Venir}
            />
            <Route path="/propos" Component={Apropos} />
            <Route path="/propos/participer" Component={ParticiperAppropos} />
          </Routes>
          <div
            onClick={HundlwScrollTop}
            className={`${
              isScroll
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            } transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 top-[90%] bg-[#617a5c] hover:bg-[#3ad12c] duration-75 text-white z-[30]`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-capslock-fill"
              viewBox="0 0 16 16"
            >
              <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
            </svg>
          </div>
        </div>
        <FootentContent />
        <ToastContainer />
      </CustomProvider>
    </ThemeProvider>
  ) : isAdmin ? (
    <CustomProvider theme={isAdmin ? isDarVisitLoggined : isDark}>
      {isGoingToSite ? (
        <>
          <NavBars />
          <div className="overflow-x-hidden">
            <Routes>
              <Route path="/" Component={AcceuillComponent} />
              <Route path="/signal" Component={Signale} />
              <Route path="/signal/detail/:id" Component={SignalerDetail} />

              <Route path="/detail" Component={EvenementDetail} />

              <Route path="/cours" Component={Cours} />
              <Route path="/cours/detail" Component={CoursDetail} />
              <Route path="/cours/module" Component={ModuleCours} />

              <Route path="/ressource" Component={Ressource} />
              <Route path="/ressource/espece" Component={Animal} />
              <Route path="/ressource/espece/detail" Component={AnimalDetail} />
              <Route path="/ressource/rapport" Component={Rapport} />
              <Route
                path="/ressource/Environnementaux"
                Component={Climentique}
              />
              <Route path="/ressource/lois" Component={Lois} />
              <Route path="/ressource/Convention" Component={Convention} />
              <Route path="/ressource/region" Component={ConventionRegion} />
              <Route path="/ressource/ODD" Component={ODD} />

              <Route path="/projet" Component={Projet} />
              <Route path="/projet/Soutenir/:id" Component={Soutenir} />
              <Route path="/projet/benevol/:id" Component={BenevoleProjets} />

              <Route path="/communaute" Component={Communaute} />
              <Route path="/communaute/forumu" Component={Forumu} />
              <Route path="/communaute/forumu/detail" Component={SujetDetail} />
              <Route path="/communaute/event" Component={Evenement_A_venir} />
              <Route
                path="/communaute/event/detail"
                Component={DetailEvent_a_Venir}
              />
              <Route path="/propos" Component={Apropos} />
              <Route path="/propos/participer" Component={ParticiperAppropos} />
            </Routes>
            <div
              onClick={HundlwScrollTop}
              className={`${
                isScroll
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
              } transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 top-[90%] bg-[#617a5c] hover:bg-[#3ad12c] duration-75 text-white z-[30]`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-capslock-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <div className="w-full h-screen overflow-hidden">
          <AdminNavrBars />
          <Routes>
            <Route path="/" Component={AdminAcceuillComponent} />

            <Route path="/cours" Component={AdminCoursComponent} />
            <Route path="/cours/ajout" Component={AjouterCoursAdminComponent} />
            <Route
              path="/cours/detail/:id"
              Component={AdminCoursDetailComponent}
            />
            <Route path="/cours/module" Component={AdminCoursModuleComponent} />
            <Route
              path="/cours/modif/:id"
              Component={AdminCoursModifieeComponent}
            />
            <Route
              path="/cours/propose"
              Component={AdminCoursProposeComponent}
            />

            <Route path="/signal" Component={AdminSignalComponent} />
            <Route path="/SignalementMap" Component={SignalementMap} />
            <Route
              path="/signal/detail/:id"
              Component={AdminSignalDetailComponent}
            />
            <Route
              path="/signal/propose"
              Component={AdminSignalProposeComponent}
            />
            <Route
              path="/signal/propose/detail"
              Component={AdminSignalProposeDetailComponent}
            />

            <Route path="/ressource" Component={AdminRessourceComponent} />
            <Route
              path="/ressource/ajouter"
              Component={AdminRessourceAjouterComponent}
            />
            <Route
              path="/ressource/detail/:id"
              Component={AdminRessourceDetailComponent}
            />
            <Route
              path="/ressource/modifier/:id"
              Component={AdminRessourceModifierComponent}
            />

            <Route
              path="/ressource/rapport"
              Component={AdiminRapportComponent}
            />
            <Route
              path="/ressource/rapport/ajouter"
              Component={AdiminRapportAjouterComponent}
            />
            <Route
              path="/ressource/rapport/detail/:id"
              Component={AdiminRapportDetailComponent}
            />
            <Route
              path="/ressource/rapport/modifier/:id"
              Component={AdiminRapportModifierComponent}
            />

            <Route path="/ressource/fait" Component={AdminFaitsComponent} />
            <Route
              path="/ressource/fait/detail/:id"
              Component={AdminFaitsDetailComponent}
            />
            <Route
              path="/ressource/fait/ajouter"
              Component={AdminFaitsAjouterCompoment}
            />

            <Route path="/ressource/espece" Component={AdminEspecesComponent} />
            <Route
              path="/ressource/espece/ajouter"
              Component={AdminEspecesAjouterComponent}
            />
            <Route
              path="/ressource/espece/modif/:id"
              Component={AdminEspecesModifierComponent}
            />
            <Route
              path="/ressource/espece/detail/:id"
              Component={AdminEspecesDetailComponent}
            />

            <Route path="/ressource/loi" Component={AdminLoisComponent} />
            <Route
              path="/ressource/loi/ajouter"
              Component={AdminLoisAjouterComponent}
            />
            <Route
              path="/ressource/loi/detail/:id"
              Component={AdminLoisDetailComponent}
            />
            <Route
              path="/ressource/loi/modifi/:id"
              Component={AdminLoisModiComponent}
            />

            <Route
              path="/ressource/convention"
              Component={AdminConventionComponent}
            />
            <Route
              path="/ressource/convention/ajouter"
              Component={AdminConventionAjouterComponent}
            />
            <Route
              path="/ressource/convention/modif/:id"
              Component={AdminConventionModifiierComponent}
            />
            <Route
              path="/ressource/convention/detail/:id"
              Component={AdminConventioDetailComponent}
            />

            <Route path="/ressource/odd" Component={AdminODDComponent} />
            <Route
              path="/ressource/odd/ajouter"
              Component={AdminODDAjouterComponent}
            />
            <Route
              path="/ressource/odd/modif/:id"
              Component={AdminODDModifierComponent}
            />
            <Route
              path="/ressource/odd/detail/:id"
              Component={AdminODDDetailComponent}
            />

            <Route path="/projet" Component={AdminProjetComponent} />
            <Route
              path="/projet/ajouter"
              Component={AdminProjetAjouterComponent}
            />
            <Route
              path="/projet/modif/:id"
              Component={AdminProjetModofierComponent}
            />
            <Route
              path="/projet/detail/:id"
              Component={AdminProjetDetailComponent}
            />
            <Route path="/projet/tem" Component={AdminProjetTemoiComponent} />
            <Route
              path="/projet/tem/detail"
              Component={AdminProjetTemoidetailComponent}
            />
            <Route
              path="/projet/don"
              Component={AdminProjetDonProjetCompoenent}
            />
            <Route
              path="/projet/mater"
              Component={AdminProjetDonMaterielComponent}
            />
            <Route
              path="/projet/mater/detait"
              Component={AdminProjetDonMaterielDetailCompnent}
            />
            <Route
              path="/projet/spons"
              Component={AdminProjetSponsorComponent}
            />
            <Route
              path="/projet/spons/detail"
              Component={AdminProjetSponsorDetailComponent}
            />
            <Route
              path="/projet/benevol"
              Component={AdminProjetBenevolComponent}
            />
            <Route
              path="/projet/benevol/detail"
              Component={AdminProjetBenevolDetailComponent}
            />
            <Route
              path="projet/propo"
              Component={AdminProjetProposeComponent}
            />
            <Route
              path="projet/propo/detail"
              Component={AdminProjetProposeDetailComponent}
            />

            <Route path="/comm" Component={AdminCommunauteComponent} />
            <Route
              path="/comm/ajouter"
              Component={AdminCommunauteAjouterComponent}
            />
            <Route
              path="/comm/modifier/:id"
              Component={AdminCommunauteModifierComponent}
            />
            <Route
              path="/comm/detail/:id"
              Component={AdminCommunauteDetailComponent}
            />
            <Route path="/comm/blog" Component={AdminCommunauteBlogComponent} />
            <Route
              path="/comm/blog/detail/:id"
              Component={AdminCommunauteBlogDetailComponent}
            />
            <Route
              path="/comm/blog/ajouter"
              Component={AdminCommunauteBlogAjouterComponent}
            />
            <Route
              path="/comm/blog/modifier/:id"
              Component={AdminCommunauteBlogModofierComponent}
            />

            <Route path="/partenaire" Component={AdminPartenaireComponent} />
          </Routes>
        </div>
      )}

      <ToastContainer />
    </CustomProvider>
  ) : (
    <>C'est null</>
  );
}

export default App;
