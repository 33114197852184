import React from 'react'
import Sloger from './Sloger'
import OurProuts from './OurProuts'
import Evenement from './Evenement'
import Services from './Services'
import Rejoignez from './Rejoignez'
import ProgrammeScolaire from './ProgrammeScolaire'
import Economisme from './Economisme'
import Formateurs from './Formateurs'

function AcceuillComponent() {
    return (
        <div className='mt-[18vh] overflow-hidden'>
            <Sloger />
            <OurProuts />
            <Evenement />
            <Services />
            <Rejoignez />
            <ProgrammeScolaire/>
            <Economisme/>
            <Formateurs/>
        </div>
    )
}

export default AcceuillComponent