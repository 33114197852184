/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'

function Evenement() {
    const { HundlwScrollTop } = useThemes()
    const navig = useNavigate()
    return (
        <div class="container mx-auto mt-20 py-8">
            <h1 class="text-2xl font-bold mb-6">Événements et Actualités</h1>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div class=" shadow-lg rounded-lg overflow-hidden">
                    <img src="image/forest.jpg" alt="Événement" class="w-full h-48 object-cover" />
                    <div class="py-6 px-2">
                        <h2 class="text-xl font-bold mb-2">Lancement du projet de reforestation à Muramvya</h2>
                        <p class=" mb-4">Date : 15 juillet 2023</p>
                        <p class="">Venez participer à notre événement pour découvrir les meilleures pratiques en matière de protection de l'environnement.</p>
                        <div onClick={() => { navig("/detail"); HundlwScrollTop() }} class="text-[#33752d]  hover:text-[#3ad12c] border-[#33752d] border hover:border-[#3ad12c] py-2 px-4 mt-4 inline-block rounded-md cursor-pointer">Lire plus</div>
                    </div>
                </div>

                <div class=" shadow-lg rounded-lg overflow-hidden">
                    <img src="https://media.letelegramme.fr/api/v1/images/view/63e31dc5bf9d10727d7abe11/web_golden_xl/63e31dc5bf9d10727d7abe11.1" alt="Actualité" class="w-full h-48 object-cover" />
                    <div class="py-6 px-2">
                        <h2 class="text-xl font-bold mb-2">Initiatives éco-écol d'etend à nouvelle écoles</h2>
                        <p class=" mb-4">Publié le 10 août 2023</p>
                        <p class="">Découvrez les dernières mises à jour sur la nouvelle réglementation visant à réduire l'empreinte carbone des entreprises.</p>
                        <div onClick={() => { navig("/detail"); HundlwScrollTop() }} class="text-[#33752d]  hover:text-[#3ad12c] border-[#33752d] border hover:border-[#3ad12c] py-2 px-4 mt-4 inline-block rounded-md cursor-pointer">Lire plus</div>
                    </div>
                </div>

                <div class=" shadow-lg rounded-lg overflow-hidden">
                    <img src="image/Fisherman_on_Lake_Tanganyika.jpg" alt="Actualité 2" class="w-full h-48 object-cover" />
                    <div class="py-6 px-2">
                        <h2 class="text-xl font-bold mb-2">Initiatives locales de recyclage</h2>
                        <p class=" mb-4">Publié le 5 août 2023</p>
                        <p class="">Découvrez les dernières initiatives mises en place dans votre région pour encourager le recyclage et la réduction des déchets.</p>
                        <div onClick={() => { navig("/detail"); HundlwScrollTop() }} class="text-[#33752d]  hover:text-[#3ad12c] border-[#33752d] border hover:border-[#3ad12c] py-2 px-4 mt-4 inline-block rounded-md cursor-pointer">Lire plus</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Evenement