import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'

function Apropos() {
  const { isDark } = useThemes()
  const navig=useNavigate()
  return (
    <div class={` mt-[18vh] ${isDark === "dark" ? null : 'bg-gray-100'} overflow-hidden py-8`}>
      <div class="container mx-auto px-4">
        <h1 data-aos="fade-up" class="text-3xl font-bold mb-4">À PROPOS DE NOUS</h1>
        <div class="grid md:grid-cols-2 gap-8">
          <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} text-lg shadow-md rounded-lg p-6`}>
            <h2 class="text-2xl font-bold mb-4">Le Mouvement Imihira : Une Initiative pour un Burundi Durable</h2>
            <p class="mb-4">Le Mouvement "Imihira" est né de la collaboration visionnaire entre l'Association Catholique pour la Protection de l'Environnement au Burundi (ACAPE Burundi) et la Fondation Venuste NIYONGABO. Lancé au début de l'année 2024, notre mission est de promouvoir le développement durable au Burundi, en parfaite harmonie avec la politique environnementale nationale et les Objectifs de Développement Durable (ODD) des Nations Unies.</p>
          </div>
          <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
            <h2 class="text-2xl font-bold mb-4">Défis Environnementaux du Burundi</h2>
            <ul class="list-disc pl-6 space-y-2">
              <li className='ml-3 sm:text-lg'>Déforestation et dégradation des sols</li>
              <li className='ml-3 sm:text-lg'>Pollution des eaux et assainissement inadéquat</li>
              <li className='ml-3 sm:text-lg'>Perte de biodiversité</li>
              <li className='ml-3 sm:text-lg'>Changement climatique et ses impacts sur l'agriculture</li>
              <li className='ml-3 sm:text-lg'>Gestion des déchets urbains</li>
            </ul>
          </div>
        </div>
        <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg  mt-8 p-6`}>
          <h2 class="text-2xl font-bold mb-4">Notre Impact Attendu d'ici 2034</h2>
          <ul class="list-disc pl-6 space-y-2">
            <li className='ml-3 sm:text-lg'>Reforestation : Planter 10 millions d'arbres, restaurant 20% des zones déboisées.</li>
            <li className='ml-3 sm:text-lg'>Éducation environnementale : Sensibiliser 75% de la population burundaise aux enjeux écologiques.</li>
            <li className='ml-3 sm:text-lg'>Agriculture durable : Convertir 30% des exploitations agricoles aux pratiques durables.</li>
            <li className='ml-3 sm:text-lg'>Énergie renouvelable : Faciliter l'accès à l'énergie solaire pour 50% des foyers ruraux.</li>
            <li className='ml-3 sm:text-lg'>Gestion des déchets : Mettre en place des systèmes de recyclage dans toutes les grandes villes.</li>
            <li className='ml-3 sm:text-lg'>Biodiversité : Créer 5 nouvelles aires protégées et restaurer les écosystèmes menacés.</li>
            <li className='ml-3 sm:text-lg'>Eau propre : Assurer l'accès à l'eau potable pour 90% de la population.</li>
          </ul>
        </div>
        <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  mt-8 shadow-md rounded-lg p-6`}>
          <h2 class="text-2xl font-bold mb-4">Notre Stratégie</h2>
          <p class="mb-4">Pour atteindre ces objectifs ambitieux, nous adoptons une approche multidimensionnelle :</p>
          <ul class="list-disc pl-6 space-y-2">
            <li className='ml-3 sm:text-lg'>Collaboration étroite avec les communautés locales</li>
            <li className='ml-3 sm:text-lg'>Partenariats stratégiques avec des acteurs nationaux et internationaux</li>
            <li className='ml-3 sm:text-lg'>Innovation technologique adaptée au contexte burundais</li>
            <li className='ml-3 sm:text-lg'>Plaidoyer pour des politiques environnementales efficaces</li>
            <li className='ml-3 sm:text-lg'>Programmes d'éducation et de formation</li>
          </ul>
        </div>
        <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg mt-8 p-6`}>
          <h2 class="text-2xl font-bold mb-4">Partenariats Stratégiques</h2>
          <p class="mb-4">Pour maximiser notre impact, nous allons créer et renforcer des partenariats avec :</p>
          <ul class="list-disc pl-6 space-y-2">
            <li className='ml-3 sm:text-lg'><span className='font-bold'>Gouvernement burundais</span> : Pour aligner nos actions avec les politiques nationales</li>
            <li className='ml-3 sm:text-lg'><span className='font-bold'>Institutions académiques </span>: Pour la recherche et l'innovation (Université du Burundi, École Polytechnique)</li>
            <li className='ml-3 sm:text-lg'><span className='font-bold'>ONG internationales</span> : Pour l'expertise technique et le financement (WWF, PNUD, FAO)</li>
            <li className='ml-3 sm:text-lg'><span className='font-bold'>Secteur privé </span>: Pour des solutions durables et l'investissement responsable</li>
            <li className='ml-3 sm:text-lg'><span className='font-bold'>Communautés religieuses</span> : Pour la mobilisation et la sensibilisation au niveau local</li>
            <li className='ml-3 sm:text-lg'><span className='font-bold'>Médias </span>: Pour la diffusion d'informations et la sensibilisation du public</li>
          </ul>
        </div>







        <div class="py-8">
          <div class="container mx-auto">
            <h1 class="text-3xl font-bold mb-4">Feuille de Route sur 10 Ans</h1>
            <div class="grid md:grid-cols-2 gap-8">
              <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  mt-8 shadow-md rounded-lg p-6`}>

                <h2 class="text-2xl font-bold mb-4">2024-2026 : Phase d'Établissement</h2>
                <ul class="list-disc pl-6 space-y-2">
                  <li>Lancement de projets pilotes dans 5 provinces</li>
                  <li>Création d'un réseau de partenaires nationaux et internationaux</li>
                  <li>Développement de programmes éducatifs</li>
                </ul>
              </div>
              <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  mt-8 shadow-md rounded-lg p-6`}>

                <h2 class="text-2xl font-bold mb-4">2027-2029 : Phase d'Expansion</h2>
                <ul class="list-disc pl-6 space-y-2">
                  <li>Extension des projets à l'échelle nationale</li>
                  <li>Mise en place de centres de formation en agroécologie</li>
                  <li>Lancement de campagnes nationales de sensibilisation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  mt-8 shadow-md rounded-lg p-6`}>

          <h2 class="text-2xl font-bold mb-4">2030-2032 : Phase d'Intensification</h2>
          <ul class="list-disc pl-6 space-y-2">
            <li>Mise en oeuvre de projets à grande échelle (reforestation massive, énergies renouvelables)</li>
            <li>Création d'un fonds d'investissement pour l'entrepreneuriat vert</li>
            <li>Organisation d'un sommet national sur le développement durable</li>
          </ul>
        </div>
        <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  mt-8 shadow-md rounded-lg p-6`}>

          <h2 class="text-2xl font-bold mb-4">2033-2034 : Phase de Consolidation</h2>
          <ul class="list-disc pl-6 space-y-2">
            <li>Évaluation de l'impact et ajustement des stratégies</li>
            <li>Intégration des pratiques durables dans les politiques nationales</li>
            <li>Préparation de la vision pour les 10 prochaines années</li>
          </ul>
        </div>
      </div>





      <div class="container mx-auto mt-8 px-4">
        <h1 class="text-3xl font-bold mb-4">Rejoignez le Mouvement Imuhira</h1>
        <p class="mb-8">Le futur du Burundi dépend de l'action collective. Ensemble, nous pouvons créer un avenir durable et prospère pour notre nation.</p>
        <div data-aos="fade-up" class={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  mt-8 shadow-md rounded-lg p-6`}>
          <h2 class="text-2xl font-bold mb-4">Contactez-nous</h2>
          <p class="mb-4">Pour plus d'informations, partenariats ou pour vous impliquer :</p>
          <ul class="space-y-2">
            <li>Email : contact@imuhira.bi</li>
            <li>Téléphone : +257 22 20 20 20</li>
            <li>Adresse : 45 Avenue de l'Environnement, Bujumbura, Burundi</li>
          </ul>
        </div>
        <div onClick={()=>navig("/propos/participer") } class="bg-[#3ad12c] mt-11 cursor-pointer transition-all hover:scale-105 text-white font-bold py-3 px-6 rounded-lg inline-block mb-8">
          Participez à la Transformation du Burundi
        </div>
      </div>
    </div>


  )
}

export default Apropos