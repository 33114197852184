import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import MapComponent from "../../Visiteurs/Signal/MapComponent";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { decryptData } from "../../../encryptionModule";
import axios from "axios";
import { toast } from "react-toastify";

function AdminSignalDetail() {
  const { isDark, isDarVisitLoggined } = useThemes();
  const navig = useNavigate();
  const { id } = useParams();
  const signalId = decryptData(id);
  const { HundlwScrollTop } = useThemes();
  const [isCommentaire, setIsCommentaire] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [signalData, setSignalData] = useState(null);

  useEffect(() => {
    const fetchSignalData = async () => {
      try {
        const response = await axios.get(
          `https://imuhirabackend.eac-advisor.org/apiv1/signalements/${signalId}`
        );
        setSignalData(response.data);
      } catch (error) {
        console.error("Error fetching signal data:", error);
        toast.error("Erreur lors du chargement des données du signal.");
      }
    };

    fetchSignalData();
  }, [signalId]);

  const handleDeleteSignalement = async () => {
    try {
      await axios.delete(
        `https://imuhirabackend.eac-advisor.org/apiv1/signalements/${signalId}`
      );
      toast.success("Signalement supprimé avec succès.");
      navig("/signal"); // Rediriger après la suppression
    } catch (error) {
      console.error("Erreur lors de la suppression du signalement :", error);
      toast.error("Erreur lors de la suppression du signalement.");
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await axios.delete(
        `https://imuhirabackend.eac-advisor.org/apiv1/signalements/${commentId}/comments`
      );
      toast.success("Commentaire supprimé avec succès.");
      // Mettre à jour l'état des commentaires pour retirer le commentaire supprimé
      setSignalData((prevData) => ({
        ...prevData,
        comments: prevData.comments.filter(
          (comment) => comment.id !== commentId
        ),
      }));
    } catch (error) {
      console.error("Erreur lors de la suppression du commentaire :", error);
      toast.error("Erreur lors de la suppression du commentaire.");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Date invalide"; // Retourner un message approprié si la date est invalide
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Date invalide"; // Retourner un message approprié si la date est toujours invalide
    }

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Intl.DateTimeFormat("fr-FR", options).format(date);
  };

  return (
    <div
      className={`w-full overflow-y-auto ${
        isDarVisitLoggined === "dark" ? null : "bg-gray-100"
      }  overflow-x-hidden h-[87vh]`}
    >
      <div className="container mx-auto p-4">
        <div
          onClick={() => navig("/signal")}
          className="w-7 h-7 mt-2 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-arrow-left-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
            />
          </svg>
        </div>
        <h1 className="text-3xl font-bold text-center mb-8">
          Problèmes signalés
        </h1>
        <div
          className={`${
            isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
          } rounded`}
        >
          <MapComponent />
          <div
            className={`${
              isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
            } shadow-md rounded-lg p-6 `}
          >
            {signalData && (
              <>
                <h2 className="text-xl font-semibold mb-2">{signalData.nom}</h2>
                <p>
                  <strong>Contact :</strong> {signalData.coordonnees}
                </p>
                <p className="text-lg">
                  <strong>Description :</strong> {signalData.description}
                </p>
                <p className="flex">
                  <strong>Coordonnées :</strong>
                  <div className="ml-3">
                    <span className="ml-2">long: {signalData.longitude}</span>
                    <span className="ml-2">lat: {signalData.latitude}</span>
                  </div>
                </p>
                <p>
                  <strong>Gravité :</strong> {signalData.gravite}
                </p>
                <p>
                  <strong>Actions :</strong> {signalData.actionprise}
                </p>
                <p>
                  <strong>Attentes :</strong> {signalData.attentes}
                </p>
              </>
            )}
          </div>

          <div className="m-2 flex gap-4 pb-5 justify-end w-[95%]">
            <div
              className="bg-red-600 w-max cursor-pointer text-white font-semibold py-2 px-4 rounded inline-block"
              onClick={handleDeleteSignalement}
            >
              Supprimer le signalement
            </div>
          </div>
        </div>

        <div className="container mx-auto">
          <div className="text-xl font-bold mb-6">
            Commentaires des Visiteurs (
            {signalData ? signalData.comments.length : 0} Commentaires)
          </div>
          {signalData?.comments.map((comment) => (
            <div
              key={comment.id}
              className={`mt-3 ${
                isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
              } shadow-md rounded-lg p-6`}
            >
              <div className="flex items-start">
                <div className="rounded-full bg-[#3ad12c] uppercase text-white w-8 h-8 flex items-center justify-center mr-4">
                  {comment.utilisateur.nom[0]}
                </div>
                <div>
                  <h3 className="font-bold mb-1">{comment.utilisateur.nom}</h3>
                  <div className="mb-1">{comment.utilisateur.email}</div>
                  <p className="text-lg">{comment.contenu}</p>
                  <div className="flex items-center mt-3 gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="currentColor"
                      className="bi text-[#3ad12c] bi-calendar-check"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                    </svg>
                    <div className="text-sm text-gray-500">
                      {formatDate(comment.created_at)}
                    </div>
                    <div
                      className="ml-auto cursor-pointer text-red-600"
                      onClick={() => handleDeleteComment(comment.id)}
                    >
                      Supprimer
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AdminSignalDetail;
