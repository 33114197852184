import React, { useRef, useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { toast } from 'react-toastify'

function DonationForm() {
    const { isDark } = useThemes()
    const [nomComplet, setNomComplet] = useState('');
    const [montant, setmontant] = useState('');
    const [type_payement, settype_payement] = useState('');
  
    const textareaRef = useRef(null);
    const inputRefs = {
        nomComplet: useRef(null),
        montant: useRef(null),
        type_payement: useRef(null),
       
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation des champs
        if (nomComplet.trim() === '') {
            toast.warning("Veuillez remplir votre nom complet !");
            inputRefs.nomComplet.current.focus();
            return;
        }

        if (montant.trim() === '') {
            toast.warning("Veuillez remplir votre numéro de téléphone !");
            inputRefs.montant.current.focus();
            return;
        }

        if (type_payement.trim() === '') {
            toast.warning("Veuillez completer  votre type payement !");
            inputRefs.type_payement.current.focus();
            return;
        }
        // Logique pour soumettre les données, par exemple, un appel API.
        toast.success("Don du projet soumis avec succès !");
    };

    return (
        <div>
            <div className="inset-0 bg-opacity-50 h-full flex items-start justify-center z-50">
                <div className={` ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg max-w-md mx-auto`}>
                    <h2 className="text-2xl font-bold mb-4">Faire un don</h2>
                    <p className="mb-4  text-center">
                        Merci pour votre générosité ! Votre don aidera à financer des projets importants.
                    </p>
                    <p>
                        <p className="mb-4 text-center">
                            Votre contribution financière joue un rôle crucial dans l'amélioration de l'éducation.
                            Elle aide à financer des fournitures pédagogiques, l'entretien des jardins scolaires,
                            et bien plus encore. Chaque don compte et fait une différence significative.
                        </p>
                    </p>
                    <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="nom" className="block text-lg font-semibold mb-2">Nom complet</label>
                    <input
                        type="text"
                        id="nom"
                        value={nomComplet}
                        onChange={(e) => setNomComplet(e.target.value)}
                        ref={inputRefs.nomComplet}
                        className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="montant" className="block text-lg font-semibold mb-2">Montant</label>
                    <input
                        type="text"
                        id="montant"
                        value={montant}
                        onChange={(e) => setmontant(e.target.value)}
                        ref={inputRefs.montant}
                        className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="type_payement" className="block text-lg font-semibold mb-2">Type de paiement</label>
                    <input
                        type="type_payement"
                        id="type_payement"
                        value={type_payement}
                        onChange={(e) => settype_payement(e.target.value)}
                        ref={inputRefs.type_payement}
                        className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 bg-transparent"
                        required
                    />
                </div>
                <div className='w-full flex justify-end items-center'>
                    <button type="submit" className=" w-max text-white py-2 px-4 bg-[#3ad12c] rounded-lg hover:scale-105 transition-all focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
                        Envoyer
                    </button>

                </div>
            </form>
                </div>
            </div>
        </div>
    )
}

export default DonationForm